import { useCallback, useMemo } from 'react';
import { usePreferences } from 'features/preferences';
import {
  DefermentsFilter,
  DeviationsFilter,
  WellsFilter,
  FilterComponent,
  FilterType,
  CommentFilter,
} from './types';
import {
  isFilterActive,
  sideBarFilterInitialState,
  wellSummaryFilterInitialState,
} from '.';

export type UseFilter<T> = {
  filter: T;
  isFilteringDeviations: boolean;
  isFilteringDeferments: boolean;
  isFilteringLowDeferments: boolean;
  isFilteringWells: boolean;
  isFilteringComment: boolean;
  setDeviationsFilter: (filter: Partial<DeviationsFilter>) => void;
  setDefermentsFilter: (filter: Partial<DefermentsFilter>) => void;
  setWellTypeFilter: (filter: Partial<WellsFilter>) => void;
  resetFilters: () => void;
  setLowDefermentsFilter: (filter: Partial<DefermentsFilter>) => void;
  setCommentFilter: (filter: Partial<CommentFilter>) => void;
};

export const useFilter = <T extends FilterType>(
  filterComponent: FilterComponent
): UseFilter<T> => {
  const { preferences, setPreferences } = usePreferences();

  const filterState = useMemo(
    () => preferences[filterComponent].filter,
    [filterComponent, preferences]
  );

  const setDeviationsFilter = useCallback(
    (filter: Partial<DeviationsFilter>) => {
      setPreferences({
        [filterComponent]: { filter: { deviations: filter } },
      });
    },
    [filterComponent, setPreferences]
  );

  const setDefermentsFilter = useCallback(
    (filter: Partial<DefermentsFilter>) => {
      setPreferences({
        [filterComponent]: { filter: { deferments: filter } },
      });
    },
    [filterComponent, setPreferences]
  );
  const setLowDefermentsFilter = useCallback(
    (filter: Partial<DefermentsFilter>) => {
      setPreferences({
        [filterComponent]: { filter: { lowDeferments: filter } },
      });
    },
    [filterComponent, setPreferences]
  );

  const setWellTypeFilter = useCallback(
    (filter: Partial<WellsFilter>) => {
      setPreferences({
        [filterComponent]: { filter: { wells: filter } },
      });
    },
    [filterComponent, setPreferences]
  );

  const setCommentFilter = useCallback(
    (filter: Partial<CommentFilter>) => {
      setPreferences({
        [filterComponent]: { filter: { comment: filter } },
      });
    },
    [filterComponent, setPreferences]
  );
  const resetFilters = useCallback(() => {
    setPreferences({
      [filterComponent]: {
        filter:
          filterComponent === 'wellSummary'
            ? wellSummaryFilterInitialState
            : sideBarFilterInitialState,
      },
    });
  }, [filterComponent, setPreferences]);

  const isFilteringDeviations = useMemo(
    () => isFilterActive(filterState.deviations),
    [filterState]
  );
  const isFilteringDeferments = useMemo(
    () => isFilterActive(preferences.sideBar.filter.deferments),
    [preferences.sideBar.filter.deferments]
  );
  const isFilteringWells = useMemo(
    () => isFilterActive(preferences.wellSummary.filter.wells),
    [preferences.wellSummary.filter.wells]
  );
  const isFilteringLowDeferments = useMemo(
    () => isFilterActive(preferences.sideBar.filter.lowDeferments),
    [preferences.sideBar.filter.lowDeferments]
  );
  const isFilteringComment = useMemo(
    () => isFilterActive(preferences.sideBar.filter.comment),
    [preferences.sideBar.filter.comment]
  );
  return {
    filter: filterState as T,
    isFilteringDeviations,
    isFilteringDeferments,
    isFilteringLowDeferments,
    isFilteringComment,
    isFilteringWells,
    setDeviationsFilter,
    setDefermentsFilter,
    setWellTypeFilter,
    resetFilters,
    setLowDefermentsFilter,
    setCommentFilter,
  };
};
