import { RootAsset } from 'hooks/types';
import { NetworkLevel } from 'utils/models/systems';
import { getRootAssetConfig } from 'utils/rootAssetConfig';
import { BestDayAsset } from './CurrentAssetProvider';

const rootAssetLevels: NetworkLevel[] = ['Country', 'Top Level Asset'];

export const getLowerMostRootAssetParent = (
  asset: BestDayAsset | undefined
): BestDayAsset | undefined => {
  if (!asset) {
    return undefined;
  }
  if (rootAssetLevels.includes(asset.networkLevel)) {
    return asset;
  }

  return getLowerMostRootAssetParent(asset.parent);
};

export const isAssetRelatedToSelectedAsset = (
  rootAssetExternalId: string | undefined,
  asset: BestDayAsset | undefined
): Boolean => {
  if (!asset || !rootAssetExternalId) {
    return false;
  }

  if (asset.externalId === rootAssetExternalId) {
    return true;
  }

  return isAssetRelatedToSelectedAsset(rootAssetExternalId, asset.parent);
};

export const getCorrectRootAssetConfig = (
  currentRootAssetConfig: RootAsset | undefined,
  newAsset: BestDayAsset | undefined
) => {
  if (!newAsset) return currentRootAssetConfig;

  const externalId = getLowerMostRootAssetParent(newAsset)?.externalId;

  if (!externalId)
    throw new Error(`Unable to find top level asset for ${externalId}`);

  return getRootAssetConfig(externalId);
};
