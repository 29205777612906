import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  [page: string]: boolean;
};

const fullScreenSlice = createSlice({
  name: 'fullscreen',
  initialState: {} as InitialState,
  reducers: {
    setIsFullScreen: (
      state,
      action: PayloadAction<{
        page: string;
        isFullScreen: boolean;
      }>
    ) => {
      const { page, isFullScreen } = action.payload;
      return {
        ...state,
        [page]: isFullScreen,
      };
    },
  },
});

export type FullScreenState = ReturnType<typeof fullScreenSlice.reducer>;

export default fullScreenSlice;
