import { Icon } from '@cognite/cogs.js';
import styled from 'styled-components/macro';

export const PageLayout = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
  position: relative;
`;

// After TopBar
export const PageContent = styled.div`
  height: 100%;
  position: relative;
  flex: 1;
  overflow-y: auto;
`;

export const FiltersContainer = styled.div`
  width: 100%;
  display: inline-grid;
  grid-auto-flow: column;
  padding: 7px 6px 6px;
  height: 48px;
  > span {
    justify-self: end;
  }
`;

export const FiltersBlock = styled.div`
  border: 1px solid var(--cogs-greyscale-grey4);
  border-style: solid none;
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const BlueCheckmarkIcon = styled(Icon).attrs({ type: 'Checkmark' })`
  color: var(--cogs-midblue);
`;
