import React, { useState } from 'react';
import { DateRange, Range } from 'react-date-range';
import { formatDateAs, getStartOfLocalDay } from 'utils/datetime';
import { Button, Menu, Dropdown, Icon, Colors, Body } from '@cognite/cogs.js';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';

import styled from 'styled-components/macro';
import { useLocale } from 'features/languages';

type Props = {
  start?: number;
  end?: number;
  maxDate?: Date;
  minDate?: Date;
  onChange: (newStart: number, newEnd: number) => void;
  disabled?: boolean;
};
const { date } = formatDateAs;

const DropdownContent = ({
  start,
  end,
  minDate,
  maxDate,
  onChange,
  setIsCalendarShown,
}: Props & {
  setIsCalendarShown: (is: boolean) => void;
}) => {
  const [range, setRange] = useState([start, end]);
  const setNewRange = (newRange: Range) => {
    if (!newRange) {
      return;
    }
    const { startDate, endDate } = newRange;
    if (!startDate || !endDate) {
      return;
    }
    setRange([+getStartOfLocalDay(+startDate), +getStartOfLocalDay(+endDate)]);
  };

  const { locale } = useLocale();
  const { t } = useTranslation('RangePicker');
  const confirmRangeSelection = () => {
    if (range?.[0] && range[1]) {
      onChange(range[0], range[1]);
    }
    setIsCalendarShown(false);
  };
  return (
    <Menu>
      <DateRange
        locale={locale}
        // @ts-ignore - outdated types
        onChange={(item) => setNewRange(item.ranges)}
        // @ts-ignore - outdated types
        dragSelectionEnabled
        minDate={minDate}
        maxDate={maxDate}
        ranges={[
          {
            startDate: range?.[0] ? new Date(range[0]) : undefined,
            endDate: range?.[1]
              ? getStartOfLocalDay(range[1])
              : new Date(Infinity),
            key: 'ranges',
            showDateDisplay: false,
            color: Colors.primary.hex(),
          },
        ]}
      />
      <Button
        type="primary"
        onClick={confirmRangeSelection}
        title={t('confirm-button_range-picker', { defaultValue: 'Apply' })}
      >
        <Trans t={t} i18nKey="confirm-button_range-picker">
          Apply
        </Trans>
      </Button>
    </Menu>
  );
};
const RangePicker = ({
  start,
  end,
  minDate,
  maxDate,
  onChange,
  disabled,
}: Props) => {
  const { t } = useTranslation('RangePicker');
  const [isCalendarShown, setIsCalendarShown] = useState(false);
  const rangeTitle =
    start && end
      ? `${date(start)} - ${date(end)}`
      : t('select-period_range-picker', { defaultValue: 'Select period' });

  return (
    <Dropdown
      content={
        <DropdownContent
          {...{ start, end, minDate, maxDate, onChange, setIsCalendarShown }}
        />
      }
      visible={isCalendarShown}
      onClickOutside={() => {
        setIsCalendarShown(false);
      }}
    >
      <Button
        type="ghost"
        title={t('open-calendar_button-title', {
          defaultValue: 'Open calendar',
        })}
        aria-label={t('open-calendar_button-title', {
          defaultValue: 'Open calendar',
        })}
        onClick={() => setIsCalendarShown(!isCalendarShown)}
        disabled={disabled}
        style={{
          background: disabled ? 'none' : undefined,
          padding: '8px 6px',
        }}
      >
        <Icon type="Calendar" />
        <StyledDatePreview level={1}>{rangeTitle}</StyledDatePreview>
        {!disabled && <Icon type="ChevronDown" />}
      </Button>
    </Dropdown>
  );
};

const StyledDatePreview = styled(Body)`
  font-weight: 400;
  padding-right: 7px;
  margin-left: 8px;
  font-size: 14px;
`;

export default withI18nSuspense(RangePicker);
