/* eslint-disable @typescript-eslint/no-explicit-any */
import { Aggregate, StringDatapoint } from '@cognite/sdk';
import {
  ChartConfig,
  System,
  Well,
  Colors,
  Product,
  Deferment as TemplateDeferment,
  Trend,
  UnitCategories,
  WellSummary,
  ProductDefinition,
} from 'graphql-types';
import {
  calculateGranularity,
  getDatapointsByTimeseriesType,
} from 'hooks/utils';
import { useQuery, QueryKey, UseQueryOptions } from 'react-query';
import retry from 'async-retry';
import { getClient } from 'utils/cognitesdk';
import { Deferment, DefermentVolume } from 'utils/models';
import find from 'lodash/find';
import get from 'lodash/get';
import gte from 'lodash/gte';
import isEmpty from 'lodash/isEmpty';
import keyBy from 'lodash/keyBy';
import size from 'lodash/size';
import uniqBy from 'lodash/uniqBy';
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import last from 'lodash/last';
import {
  Field,
  GetBestDayDefermentFieldsReturn,
  Level,
} from 'features/defermentMatrix/types';
import { HYDROCARBON, PRODUCT_TYPE_WATER_INJECTION } from 'utils/products';
import {
  GetDatapointsProps,
  GetDatapointsResult,
  GetDeviationsFromWellsResult,
  TemplatesMapping,
  TimeserieResult,
  TimeseriesType,
} from './types';

const retryOptions: retry.Options = {
  retries: 5,
  factor: 1,
};
export const useTemplatesQuery = <TReturn, TParams extends Object = {}>(
  query: {
    fn: Function;
    key: string;
  },
  data?: TParams,
  options?: Omit<
    UseQueryOptions<TReturn, Error, TReturn, QueryKey>,
    'queryKey' | 'queryFn'
  >
) => {
  return useQuery<TReturn, Error>(
    [query.key, data],
    async () => {
      const result = await retry(
        () => {
          return query.fn(data);
        },
        {
          ...retryOptions,
        }
      );
      return result;
    },
    options
  );
};

export const externalIdFilter = (externalIds: string[]) =>
  map(externalIds, (externalId) => `{ _externalId: { eq: "${externalId}" } }`);

export const productsFilter = (products?: string[]) => {
  return products?.filter(Boolean).length
    ? `(_filter: { type: { anyOfTerms: ${JSON.stringify(products)}}}) `
    : '';
};

export const syntheticTimeSeriesQuery = (
  start: number,
  end: number,
  limit: number,
  fetchSyntheticTimeSeries?: {
    granularity: string;
  }
) => {
  return fetchSyntheticTimeSeries
    ? `syntheticTimeSeries {
    name
    unit
    datapoints: datapointsWithGranularity(start: ${start}, end: ${end}, limit: ${limit}, granularity: "${fetchSyntheticTimeSeries.granularity}") {
      value
      timestamp
    }
  }`
    : '';
};

export const getProducts = async ({
  externalId,
  level,
  products = [],
  templateInfo,
}: {
  externalId: string;
  level?: 'well' | 'system';
  products: Array<string>;
  templateInfo: TemplatesMapping;
}): Promise<Product[]> => {
  return getClient()
    .templates.group(templateInfo.APPLICATION_DATA.group)
    .version(templateInfo.APPLICATION_DATA.version)
    .runQuery({
      query: `{
      ${level}Query(filter: { _externalId: { eq: "${externalId}" }}){
      items {
        products${
          // eslint-disable-next-line no-extra-boolean-cast
          products.length
            ? `(_filter: {
          type: { anyOfTerms: ${JSON.stringify(products)} }
        })`
            : ''
        } {
          type
        }
      }
    }
  }`,
    })
    .then((result) => {
      // Temporaly fix the returned doubled values
      const [system] = result.data?.systemQuery?.items || [];
      const [well] = result.data?.wellQuery?.items || [];
      const wellProducts = uniqBy(
        well?.products,
        'type'
      ) as unknown as Product[];
      return well?.products ? wellProducts : system?.products || [];
    });
};

export const getSystems = async ({
  externalIds,
  templateInfo,
}: {
  externalIds: string[];
  templateInfo: TemplatesMapping;
}): Promise<System[]> => {
  return getClient()
    .templates.group(templateInfo.APPLICATION_DATA.group)
    .version(templateInfo.APPLICATION_DATA.version)
    .runQuery({
      query: `{
        systemQuery(filter: { _externalId: { eq: ${JSON.stringify(
          externalIds
        )} } }){
          items {
            asset {
              externalId
            }
            subSystems {
              asset {
                externalId
              }
            }
            wells {
              asset {
                externalId
              }
            }
          }
        }
      }`,
    })
    .then((result) => {
      return result.data?.systemQuery?.items || [];
    });
};

export type GetDefermentsParams = {
  externalIds: string[];
  level?: 'well' | 'system';
  start?: number;
  end?: number;
  limit?: number;
  preferredUnit?: string;
  templateInfo: TemplatesMapping;
};

export const getDeferments = async ({
  externalIds,
  level,
  start,
  end,
  limit = 1000,
  templateInfo,
}: GetDefermentsParams): Promise<Array<Deferment>> => {
  return getClient()
    .templates.group(templateInfo.APPLICATION_DATA.group)
    .version(templateInfo.APPLICATION_DATA.version)
    .runQuery({
      query: `{
      ${level}Query(filter: { _externalId: { eq: ${JSON.stringify(
        externalIds
      )} }}, limit: ${limit}) {
        items {
          deferments(activeFrom: ${start}, activeTo: ${end}) {
            externalId
            assetIds
            name
            status
            type
            startTime
            endTime  
            reason
            comment
            choke
            subChoke
            facilityString
            PRODUCT_OIL
            PRODUCTUNIT_OIL
            PRODUCT_NONASSOC_GAS
            PRODUCTUNIT_NONASSOC_GAS
            PRODUCT_ASSOC_GAS
            PRODUCTUNIT_ASSOC_GAS
            PRODUCT_CONDENSATE
            PRODUCTUNIT_CONDENSATE
            lastUpdatedTime
          }
        }
      }
    }`,
    })
    .then((result) => {
      const items = result.data?.wellQuery?.items ||
        result.data?.systemQuery?.items || [{ deferments: [] }];

      const item = {
        deferments: items.flatMap((item: any) => item.deferments),
      };

      const { updateDeferments, originalDeferments } = (
        item?.deferments ?? []
      ).reduce(
        (
          acc: {
            updateDeferments: TemplateDeferment[];
            originalDeferments: TemplateDeferment[];
          },
          event: TemplateDeferment
        ) => {
          if ((event.externalId || '').includes('_UPDATE_')) {
            acc.updateDeferments.push(event);
          } else {
            acc.originalDeferments.push(event);
          }
          return {
            updateDeferments: acc.updateDeferments,
            originalDeferments: acc.originalDeferments,
          };
        },
        { updateDeferments: [], originalDeferments: [] }
      );

      return originalDeferments.map((deferment: TemplateDeferment) => {
        const facilityUpdate = updateDeferments.find(
          (update: TemplateDeferment) =>
            update.externalId.startsWith(deferment.externalId) &&
            !!update.facilityString
        );
        const volumes = Object.keys(deferment)
          .filter((key) => key.startsWith('PRODUCT_'))
          .map((key) => {
            const product = key.replace('PRODUCT_', '');

            return {
              product,
              type:
                product === 'OIL' || product === 'CONDENSATE' // should not use products like OIL and CONDENSATE that specific (will make it difficult to support different product types between customers)
                  ? 'liquid'
                  : 'gas',
              value: deferment[key as keyof TemplateDeferment],
              unit: deferment[
                `PRODUCTUNIT_${product}` as keyof TemplateDeferment
              ],
            } as DefermentVolume;
          });
        return {
          ...deferment,
          facility: facilityUpdate?.facilityString
            ? JSON.parse(facilityUpdate?.facilityString)
            : undefined,
          status: deferment.status?.toLowerCase(),
          volumes,
        };
      });
    });
};

const DAY_MS = 86400000;

export const getMainProduct = async ({
  externalId,
  end,
  templateInfo,
}: {
  externalId: string;
  start: number;
  end: number;
  templateInfo: TemplatesMapping;
}): Promise<string | undefined> => {
  return getClient()
    .templates.group(templateInfo.APPLICATION_DATA.group)
    .version(templateInfo.APPLICATION_DATA.version)
    .runQuery({
      query: `{
      wellQuery(filter: { _externalId: { eq: "${externalId}" }}){
        items {
          mainProduct {
            datapoints(start: ${
              end - DAY_MS * 364 * 5
            } end: ${end} limit: 1000) {
              timestamp
              ... on DatapointString {
                value: stringValue
              }
            }
          }
        }
      }
    }`,
    })
    .then((result) => {
      const [well] = result.data?.wellQuery?.items || [];
      const mainProduct: StringDatapoint | undefined = last(
        well?.mainProduct?.datapoints.filter(
          (it: StringDatapoint) => !!it.value
        )
      );
      return mainProduct?.value;
    });
};

export const getBestDayTimeSeries = async ({
  externalId,
  templateInfo,
  level = 'system',
}: {
  externalId: string;
  templateInfo: TemplatesMapping;
  level?: 'system' | 'well';
}): Promise<{ [key: string]: string }> => {
  const queryLevel = level === 'system' ? 'systemQuery' : 'wellQuery';
  const result = await getClient()
    .templates.group(templateInfo.APPLICATION_DATA.group)
    .version(templateInfo.APPLICATION_DATA.version)
    .runQuery({
      query: `{
        ${queryLevel}(filter: { _externalId: { eq: "${externalId}" }}){
          items {
            products {
              type
              capacity(_filter: { type: { eq: "BESTDAY_CAPACITY" } }) {
                type
                timeSeries {
                  externalId
                }
              }
            }
          }
        }
    }`,
    });
  const [system] = result.data?.[queryLevel]?.items || [];
  return system?.products.reduce(
    (acc: { [key: string]: string }, product: Product) => {
      const [bestDayCapacity] = product.capacity || [];
      if (bestDayCapacity?.timeSeries?.externalId) {
        return {
          ...acc,
          [product.type]: bestDayCapacity.timeSeries.externalId,
        };
      }
      return acc;
    },
    {}
  );
};

export const getWellSummaryChartData = async ({
  externalIds,
  start,
  end,
  products,
  limit = 1000,
  templateInfo,
}: {
  externalIds: string[];
  start: number;
  end: number;
  products?: string[];
  limit?: number;
  templateInfo: TemplatesMapping;
}): Promise<Array<{ wellExternalId: string; product: Product }>> => {
  const granularity = calculateGranularity([start, end], limit);
  const chunks = chunk(externalIds, 100);

  const data = await Promise.all(
    chunks.map(async (chunk: string[]) => {
      const externalIdFilters = externalIdFilter(chunk);
      const result = await getClient()
        .templates.group(templateInfo.APPLICATION_DATA.group)
        .version(templateInfo.APPLICATION_DATA.version)
        .runQuery({
          query: `{
            wellQuery(filter: { _or: [${externalIdFilters.join(', ')}]}) {
              items {
                asset {
                  externalId
                }
                mainProduct {
                  datapoints(end: ${end}) {
                    ... on DatapointString {
                      timestamp
                      value: stringValue
                    }
                  }
                }
                products ${productsFilter(products)} {
                  type
                  production {
                    frequency
                    timeSeries {
                      externalId
                      isStep
                      unit
                      datapoints(start: ${start}, end: ${end}, limit: ${limit}) {
                        timestamp
                        value
                      }
                    }
                    ${syntheticTimeSeriesQuery(start, end, limit, {
                      granularity,
                    })}
                  }
                  capacity {
                    type
                    timeSeries {
                      id
                      externalId
                      isStep
                      unit
                      datapoints(start: ${start}, end: ${end}, limit: ${limit}) {
                        timestamp
                        value
                      } 
                    }
                  }
                }
              }
            }
          }`,
        });
      const wells = result.data?.wellQuery?.items || [];
      return wells
        .map((well: Well) => {
          const mainProduct: StringDatapoint | undefined = last(
            well?.mainProduct?.datapoints
          );
          const product = well.products.find(
            (it: Product) =>
              it.type ===
              (mainProduct?.value === PRODUCT_TYPE_WATER_INJECTION
                ? PRODUCT_TYPE_WATER_INJECTION
                : HYDROCARBON) // TODO(BEST-1501): Remove this hardcoding of which products to fetch for well summary
          );
          return {
            wellExternalId: well.asset.externalId,
            product: product || {},
          };
        })
        .filter(Boolean);
    })
  );
  return data.flat();
};

export const getProductsConfig = ({
  templateInfo,
}: {
  templateInfo: TemplatesMapping;
}): Promise<ProductDefinition[]> => {
  return getClient()
    .templates.group(templateInfo.CONFIG.group)
    .version(templateInfo.CONFIG.version)
    .runQuery({
      query: `{
        productConfigQuery {
          items {
            products {
              type
              phase
              aggregatesTo
              position
            }
          }
        }
      }`,
    })
    .then((result) => {
      const [config] = result.data?.productConfigQuery?.items;
      return config?.products || [];
    });
};

export const getProductConfig = ({
  templateInfo,
}: {
  templateInfo: TemplatesMapping;
}): Promise<ProductDefinition[]> => {
  return getClient()
    .templates.group(templateInfo.CONFIG.group)
    .version(templateInfo.CONFIG.version)
    .runQuery({
      query: `{
        productDefinitionQuery {
          items {
            type
            phase
            aggregatesTo
            position
          }
        }
      }`,
    })
    .then((result) => {
      return result.data?.productDefinitionQuery?.items;
    });
};

export const getTrends = ({
  templateInfo,
}: {
  templateInfo: TemplatesMapping;
}): Promise<Array<Trend>> => {
  return getClient()
    .templates.group(templateInfo.CONFIG.group)
    .version(templateInfo.CONFIG.version)
    .runQuery({
      query: `{
        trendQuery {
          items {
            type
            forwardStep
            backwardStep
            area
            yAxisPosition
            showStepCircles
            hideByDefault
            style {
              dash
              strokeWidth
              color
            }
          }
        }
      }`,
    })
    .then((result) => {
      return result.data?.trendQuery?.items;
    });
};

export const getChartConfig = ({
  templateInfo,
}: {
  templateInfo: TemplatesMapping;
}): Promise<Array<ChartConfig>> => {
  return getClient()
    .templates.group(templateInfo.CONFIG.group)
    .version(templateInfo.CONFIG.version)
    .runQuery({
      query: `{
        chartConfigQuery {
          items {
            id
            name
            type
            levels
            phase
            position
            trends
          }
        }
      }`,
    })
    .then((result) => {
      return result.data?.chartConfigQuery?.items;
    })
    .catch((error) => Promise.reject(error));
};

export const getUnitCategories = ({
  templateInfo,
  dataSetId,
}: {
  templateInfo: TemplatesMapping;
  dataSetId?: number;
}): Promise<Array<UnitCategories>> => {
  return getClient()
    .templates.group(templateInfo.CONFIG.group)
    .version(templateInfo.CONFIG.version)
    .runQuery({
      query: `{
        unitCategoriesQuery${
          dataSetId ? `(filter: { _dataSetId: { eq: ${dataSetId}}})` : ''
        } {
          items {
            category
            units
            products
          }
        }
      }`,
    })
    .then((result) => {
      return result.data?.unitCategoriesQuery?.items;
    });
};

export const getColors = ({
  templateInfo,
}: {
  templateInfo: TemplatesMapping;
}): Promise<{
  [key: string]: Colors;
}> => {
  return getClient()
    .templates.group(templateInfo.CONFIG.group)
    .version(templateInfo.CONFIG.version)
    .runQuery({
      query: `{
        colorsQuery {
          items {
            type
            color
            areaColor
            label
          }
        }
      }`,
    })
    .then((result) => {
      return result.data?.colorsQuery?.items?.reduce(
        (
          acc: {
            [key: string]: Colors;
          },
          currentConfig: Colors
        ) => ({ ...acc, [currentConfig.type]: currentConfig }),
        {}
      );
    });
};

// Ideally a function for general-purpose datapoints. It might not cover all cases though
export const getDatapoints = async ({
  level,
  externalIds,
  start,
  end,
  frequency,
  limit = 1000,
  timeseriesTypes,
  products,
  measurements,
  granularity = calculateGranularity([start, end], limit),
  aggregates,
  templateInfo,
  normalizeTime = false,
}: GetDatapointsProps): Promise<GetDatapointsResult> => {
  if (!templateInfo) {
    return {};
  }

  // Build query for aggregated or non-aggregated datapoints
  const datapointsQuery = !aggregates
    ? `datapoints(limit: ${limit}, start: ${start}, end: ${end}) {
    value
    timestamp
  }`
    : `aggregatedDatapoints(limit: ${limit}, start: ${start}, end: ${end}, granularity: "${granularity}") {
        ${aggregates
          .map(
            (aggregate) => `${aggregate} {
          value
          timestamp
        }`
          )
          .join('\n')}
      }`;

  const getQueryByTimeseriesType = (tsType: TimeseriesType) => {
    switch (tsType) {
      case 'production':
      case 'deviations':
        return `${tsType}${
          frequency && tsType === 'production'
            ? `(_filter: { frequency: { eq: "${frequency}"} })`
            : ''
        } {
          ${tsType === 'production' ? 'frequency' : ''}
          timeSeries {
            id
            externalId
            unit
            ${datapointsQuery}
          }
          ${
            tsType === 'production'
              ? syntheticTimeSeriesQuery(start, end, limit, {
                  granularity,
                })
              : ''
          }
        }`;
      case 'actualDeferments':
      case 'futureDeferments':
        return `deferments(_filter: { type: {eq: "${
          tsType === 'actualDeferments' ? 'ACTUAL' : 'FUTURE'
        }"}}) {
          timeSeries {
            id
            externalId
            unit
            ${datapointsQuery}
          }
          ${
            tsType === 'actualDeferments'
              ? syntheticTimeSeriesQuery(start, end, limit, {
                  granularity,
                })
              : ''
          }
        }`;
      case 'mpc':
      case 'theor':
      case 'alloc':
      case 'ipsc':
        return `capacity(_filter: { type: { eq: "${tsType.toUpperCase()}" } }) {
          timeSeries {
            id
            externalId
            unit
            ${datapointsQuery}
          }
          ${syntheticTimeSeriesQuery(start, end, limit, {
            granularity,
          })}
        }`;
      case 'bestDay':
        return `capacity(_filter: { type: { eq: "BESTDAY_CAPACITY" } }) {
          timeSeries {
            id
            externalId
            unit
            ${datapointsQuery}
          }
        }`;
      default:
        return '';
    }
  };

  // Split the requests by timeseriesType to avoid an error from CDF
  // in which the number of aggregated datapoints is exceeded
  const finalQueries = timeseriesTypes.map((tsType) => {
    const query = `{
      ${level}Query(filter: { _externalId: { eq: ${JSON.stringify(
      externalIds
    )} } }) {
        items {
          _externalId
          ${
            products
              ? `products(_filter: { type: { eq: ${JSON.stringify(
                  products
                )} } }) {
              type
              ${getQueryByTimeseriesType(tsType)}
            }`
              : ''
          }
          
          ${
            measurements
              ? `measurements(_filter: { type: { eq: ${JSON.stringify(
                  measurements
                )} } }) {
                  type
                  timeSeries {
                    id
                    externalId
                    isStep
                    unit
                    ${datapointsQuery}
                  }
                }`
              : ''
          }
        }
      }
    }`;

    const getQueryTargetKey = () => {
      switch (tsType) {
        case 'measurements':
          return null;
        case 'production':
          return 'production';
        case 'deviations':
          return 'deviations';
        case 'actualDeferments' || 'futureDeferments':
          return 'deferments';
        default:
          return 'capacity';
      }
    };
    const queryTargetKey = getQueryTargetKey();
    return getClient()
      .templates.group(templateInfo.APPLICATION_DATA.group)
      .version(templateInfo.APPLICATION_DATA.version)
      .runQuery({ query })
      .then((result) => {
        return {
          [tsType]: (
            (result.data[`${level}Query`]?.items || []) as any[]
          ).flatMap((system) => {
            return [
              ...(map(groupBy(system.products, 'type'), (value) => {
                if (value.length === 1 || !queryTargetKey) return value[0];
                const hasLength = find(value, (o) =>
                  gte(size(get(o, queryTargetKey)), 1)
                );
                return hasLength || value[0];
              }) as any[]),
              ...(uniqBy(system.measurements, 'type') as any[]),
            ].reduce((acc: any[], product: { type: string }) => {
              const datapoints = getDatapointsByTimeseriesType(
                tsType,
                product,
                aggregates,
                normalizeTime
              );
              acc.push({
                // eslint-disable-next-line no-underscore-dangle
                assetExternalId: system._externalId as string,
                product: product.type,
                ...datapoints,
              });
              return acc;
            }, []);
          }),
        };
      });
  });

  // Merge results from all requests into a single object
  const results = (await Promise.all(finalQueries)).reduce((acc, data) => ({
    ...acc,
    ...data,
  }));

  return results as GetDatapointsResult;
};

type GetDeviationsFromWells = {
  externalIds?: string[];
  templateInfo: TemplatesMapping;
  products: string[];
  start: number;
  end: number;
  granularity?: string;
  limit?: number;
  aggregates?: Aggregate[];
  normalizeTime?: boolean;
  previousWellDeviations?: TimeserieResult[];
  previousWellExternalIds?: string[];
};

// Returns both all deviations from wells under the asset and all those wells externalsIds
export const getDeviationsFromWells = async ({
  externalIds,
  templateInfo,
  products,
  previousWellDeviations = [],
  previousWellExternalIds = [],
  limit = 1000,
  start,
  end,
  granularity,
  aggregates,
  normalizeTime = false,
}: GetDeviationsFromWells): Promise<GetDeviationsFromWellsResult> => {
  if (!externalIds) {
    return undefined;
  }

  const datapointsQuery = !aggregates
    ? `datapoints(limit: ${limit}, start: ${start}, end: ${end}) {
  value
  timestamp
}`
    : `aggregatedDatapoints(limit: ${limit}, start: ${start}, end: ${end}, granularity: "${granularity}") {
      ${aggregates
        .map(
          (aggregate) => `${aggregate} {
        value
        timestamp
      }`
        )
        .join('\n')}
    }`;

  const externalIdFilters = externalIdFilter(externalIds);
  const query = `{
      systemQuery(filter: { _or: [${externalIdFilters.join(', ')}]}) {
        items {
          externalId: _externalId
          subSystems {
            externalId: _externalId
          }
           wells {
            externalId: _externalId
            products(_filter: { type: { eq: ${JSON.stringify(products)} } }) {
              type
              deviations {
      
            timeSeries {
              externalId
              unit
             ${datapointsQuery}
            }
          }
            }
          }
        }
      }
    }`;

  const results = await getClient()
    .templates.group(templateInfo.APPLICATION_DATA.group)
    .version(templateInfo.APPLICATION_DATA.version)
    .runQuery({ query })
    .then((result) => result);

  const {
    subSystems,
    wellsData,
    wells,
  }: {
    subSystems: string[];
    wellsData: (Well & { externalId: string })[];
    wells: string[];
  } = results.data.systemQuery.items.reduce(
    (acc: any, item: any) => ({
      subSystems: acc.subSystems.concat(
        item.subSystems.map((sub: any) => sub.externalId)
      ),
      wellsData: acc.wellsData.concat(item.wells),
      wells: acc.wells.concat(item.wells.map((well: any) => well.externalId)),
    }),
    { subSystems: [], wellsData: [], wells: [] }
  );

  const wellDeviations: TimeserieResult[] = wellsData
    .map(({ products, externalId }) => {
      const result = products.reduce((acc, prod) => {
        const datapoints = getDatapointsByTimeseriesType(
          'deviations',
          prod,
          aggregates,
          normalizeTime
        );

        if (isEmpty(datapoints)) {
          return acc;
        }

        return [
          ...acc,
          {
            product: prod.type,
            assetExternalId: externalId,
            ...datapoints,
          },
        ];
      }, [] as any[]);

      return result;
    })
    .flat();

  const finalWellDeviations = previousWellDeviations.concat(wellDeviations);
  const finalWellExternalIds = previousWellExternalIds.concat(wells);

  if (subSystems.length !== 0) {
    return getDeviationsFromWells({
      externalIds: subSystems,
      templateInfo,
      products,
      previousWellDeviations: finalWellDeviations,
      previousWellExternalIds: finalWellExternalIds,
      limit,
      start,
      end,
      granularity,
      aggregates,
    });
  }

  return {
    deviationsFromWells: finalWellDeviations,
    wellExternalIds: finalWellExternalIds,
  };
};

export const getBestDayDefermentFields = async ({
  templateInfo,
}: {
  templateInfo: TemplatesMapping;
}): Promise<GetBestDayDefermentFieldsReturn> => {
  const result = await getClient()
    .templates.group(templateInfo.DEFERMENT_MATRIX.group)
    .version(templateInfo.DEFERMENT_MATRIX.version)
    .runQuery({
      query: `{
      defermentFieldsQuery {
        items {
          type
          fields {
            type
            isRequired
            dependsOn
            position
          }
        }
      }
    }`,
    });
  const { items } = result.data.defermentFieldsQuery;
  return items;
};

export const getBestDayDefermentMatrix = async ({
  templateInfo,
  filledFields,
}: {
  templateInfo: TemplatesMapping;
  filledFields: Field[];
}): Promise<Level[]> => {
  const keyed = keyBy(filledFields, 'position');
  let count = 0;
  const buildQuery = () => {
    const firstElement = [...filledFields].sort(
      (a, b) => a.position - b.position
    )[0];

    const subLevels = Object.entries(keyed)
      .reduce((acc, [position]) => {
        let base = acc;

        const restElementTypes = filledFields
          .slice(+position + 1)
          .map((it) => it.type);

        if (restElementTypes && restElementTypes.length > 0) {
          count += 1;
          base = base.concat(
            `subLevels(_filter: { _and: [{type: { eq: ${JSON.stringify(
              restElementTypes
            )}}}] }) {
              type
              key
              name
          `
          );
        }
        return base;
      }, '')
      .concat('}'.repeat(count));

    const query = `{
      levelQuery(filter: { _and: [{type: { eq: "${firstElement?.type}" }}]}, limit: 1000) {
        items {
          type
          key
          name
          ${subLevels}
        }
      }          
    }`;

    return query;
  };

  const result = await getClient()
    .templates.group(templateInfo.DEFERMENT_MATRIX.group)
    .version(templateInfo.DEFERMENT_MATRIX.version)
    .runQuery({ query: buildQuery() });

  const { items } = result.data.levelQuery;
  return items;
};

const getSubSystemAndWellsQuery = (depth: number = 4): string => {
  return `{
    type: __typename
    asset {
      externalId
      name
      id
    }
    ${
      depth === 0
        ? ''
        : ` 
        wells {
          type: __typename
          asset {
            externalId
            name
            id
          }
        } subSystems ${getSubSystemAndWellsQuery(depth - 1)}`
    }
  }`;
};

export const getSystemTree = async ({
  externalId,
  templateInfo,
  depth,
}: {
  externalId: string;
  templateInfo: TemplatesMapping;
  depth: number;
}) => {
  const query = `
  {
    systemQuery(filter: {_externalId: {eq: "${externalId}"}}) {
      items 
        ${getSubSystemAndWellsQuery(depth)}
    }
  }
  `;
  const result = await getClient()
    .templates.group(templateInfo.APPLICATION_DATA.group)
    .version(templateInfo.APPLICATION_DATA.version)
    .runQuery({ query });
  const { items } = result.data.systemQuery;
  return items;
};

export const getWellSummaryData = async ({
  externalIds,
  limit = 1000,
  templateInfo,
}: {
  externalIds: string[];
  templateInfo: TemplatesMapping;
  limit?: number;
  cursor?: string;
}): Promise<WellSummary[]> => {
  const result = await getClient()
    .templates.group(templateInfo.INTERNAL_DATA.group)
    .version(templateInfo.INTERNAL_DATA.version)
    .runQuery({
      query: `{
        wellSummaryQuery(filter: { _externalId: { eq: ${JSON.stringify(
          externalIds
        )} } } limit: ${limit}) {
          items {
            wellId
            wellExternalId
            name  
            mainProduct
            averages {
              type
              production {
                value
                daysAsString
                unit
              }
              capacities {
                type
                average {
                  value
                  daysAsString
                  unit
                }
              }
            }
            deviations {
              startDate
              endDate
              status
              volume
              fractionOfBestDay
              unit
            }
            lastUpdated
          }
        }
      }`,
    });
  return result.data.wellSummaryQuery?.items || [];
};

export const getEnrichmentConfig = async ({
  templateInfo,
  dataSetId,
}: {
  templateInfo: TemplatesMapping;
  dataSetId?: number;
}): Promise<{
  products: { type: string }[];
  capacities: { type: string; isReference: boolean }[];
}> => {
  return getClient()
    .templates.group(templateInfo.CONFIG.group)
    .version(templateInfo.CONFIG.version)
    .runQuery({
      query: `{
        enrichmentConfigQuery(filter: { _dataSetId: { eq: ${dataSetId}}}) {
          items {
            products {
              type
            }
            capacities {
              type
              isReference
            }
          }
        }
      }`,
    })
    .then((result) => {
      const [config] = result.data?.enrichmentConfigQuery?.items || [];
      return config || [];
    });
};
