/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { CSSProperties, useEffect, useState } from 'react';
import { Body, Input } from '@cognite/cogs.js';
import { Key } from 'antd/lib/table/interface';
import { withI18nSuspense } from '@cognite/react-i18n';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import SystemTree, { DataNodeWithExId } from 'components/SystemTree/SystemTree';
import { StyledSelectedWells } from 'components/CollectionsModal/elements';
import { useAssetTree, getWellListByExternalIds } from 'features/assetTree';
import {
  SplitView,
  AssetSystemTree,
  StyledSplitViewContainer,
  AssetSystemTreeContainer,
} from './elements';

export type Props = {
  selected: any[];
  setSelected: Function;
  translation: Function;
  assetType: 'well' | 'system';
  helperText?: string;
  style?: CSSProperties;
  onlySystems?: boolean;
  maxAssets?: number;
};

type SelectionModel = {
  id: number;
  externalId: string;
};

const AssetSelector = ({
  selected,
  setSelected,
  translation,
  helperText,
  style,
  onlySystems,
  assetType = 'well',
  maxAssets,
}: Props) => {
  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);
  const [searchText, setSearchText] = useState('');
  const [selectionModel, setSelectionModel] = useState<SelectionModel[]>([]);
  const { rootAsset } = useCurrentAsset();
  const { fetchNodeList } = useAssetTree();

  useEffect(() => {
    if (selected.length > 0) {
      getWellListByExternalIds(
        selected.map(({ externalId }) => externalId)
      ).then((assets) => {
        setSelectionModel(
          assets
            .filter((asset) => asset?.externalId)
            .map(({ id, externalId = '' }) => ({ id, externalId }))
        );
      });
    }
    // Only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkedKeys = selectionModel.map(({ id }) => id);

  const removeFavorite = (favorite: any) => {
    setSelectionModel((prevState: SelectionModel[]) =>
      prevState.filter(
        ({ externalId: modelExternalId }) =>
          modelExternalId !== favorite.externalId
      )
    );
  };

  const getResolvedChildNodes = async (nodes: DataNodeWithExId[]) => {
    let nodeList: DataNodeWithExId[] = [];
    for (let i = 0; i < nodes.length; i++) {
      const child = nodes[i];
      // We have to call this async function within this loop in order to resolve the tree
      // eslint-disable-next-line no-await-in-loop
      const childNodes = await recursivelyGetWells(child);
      nodeList = [...nodeList, ...childNodes];
    }
    return nodeList;
  };

  const getChildren = async (
    node: DataNodeWithExId
  ): Promise<DataNodeWithExId[]> => {
    if (node.children) {
      return getResolvedChildNodes(node.children);
    }
    const childNodes: DataNodeWithExId[] = await fetchNodeList(node);
    return getResolvedChildNodes(childNodes);
  };

  const recursivelyGetWells = async (
    node: DataNodeWithExId
  ): Promise<DataNodeWithExId[]> => {
    if (node.isLeaf) {
      return [node];
    }

    let nodes: DataNodeWithExId[] = [node];
    const childNodes = await getChildren(node);
    if (childNodes && childNodes.length) {
      nodes = [...nodes, ...childNodes];
    }
    return nodes;
  };

  const addWells = async (id: number, node: DataNodeWithExId) => {
    const nodes = await recursivelyGetWells(node);
    setSelectionModel((selectionModel: SelectionModel[]) => [
      ...selectionModel,
      { id, externalId: node?.externalId ?? '' },
      ...nodes.map(({ key, externalId = '' }) => {
        const id = key as number;
        return { id, externalId };
      }),
    ]);

    const selectedFavorites: any[] = nodes
      .filter(
        ({ externalId, isLeaf }) =>
          isLeaf &&
          !selected.find(
            ({ externalId: favoriteId }) => externalId === favoriteId
          )
      )
      .map(({ externalId, title }) => ({
        externalId,
        name: title,
      }));

    setSelected([...selected, ...selectedFavorites]);
  };

  const removeWells = async (node: DataNodeWithExId) => {
    const nodes = await recursivelyGetWells(node);
    const externalIdList: string[] = nodes.flatMap(
      ({ externalId }) => externalId || []
    );

    setSelectionModel((selectionModel: SelectionModel[]) =>
      selectionModel.filter(
        ({ externalId }) => externalIdList.includes(externalId) === false
      )
    );

    setSelected(
      selected.filter(
        ({ externalId }) => externalIdList.includes(externalId) === false
      )
    );
  };

  return (
    <StyledSplitViewContainer>
      {helperText && (
        <Body level={2} strong>
          {helperText}
        </Body>
      )}

      <AssetSystemTreeContainer style={style}>
        <SplitView>
          <div style={{ width: '100%' }}>
            <Input
              fullWidth
              icon="Search"
              clearable={{
                callback: () => setSearchText(''),
              }}
              placeholder={translation('assets-search_placeholder', {
                defaultValue: 'Search',
              })}
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
            <AssetSystemTree>
              {rootAsset?.externalId && (
                <SystemTree
                  rootExternalId={rootAsset.externalId}
                  checkable
                  onlySystems={onlySystems}
                  checkedKeys={checkedKeys}
                  expandedKeys={expandedKeys}
                  filterValue={searchText}
                  setExpandedKeys={setExpandedKeys}
                  onSelectCheckbox={({ id, node }, checked) => {
                    if (checked) {
                      addWells(id, node);
                    } else {
                      removeWells(node);
                    }
                  }}
                />
              )}
            </AssetSystemTree>
          </div>
          {selected.length > 0 && (
            <StyledSelectedWells
              favorites={selected}
              setFavorites={setSelected}
              removeFavorite={removeFavorite}
              assetType={assetType}
              maxAssets={maxAssets}
            />
          )}
        </SplitView>
      </AssetSystemTreeContainer>
    </StyledSplitViewContainer>
  );
};

export default withI18nSuspense(AssetSelector);
