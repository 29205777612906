import { DeviationSummary } from 'graphql-types';
import { Capacity, IWellListItemSortable, ValueWithUnit } from './types';

type WellListItemConstructor = {
  id: number;
  name: string;
  type: string;
  externalId: string;
  deviations: DeviationSummary[];
  productionAverage: ValueWithUnit;
  mainProduct: string;
  referenceCapacity: Capacity;
  capacities: Capacity[];
};

export class WellListItem implements IWellListItemSortable {
  id: number;
  name: string;
  type: string;
  externalId: string;
  deviations: Array<DeviationSummary>;

  referenceCapacity: Capacity;
  capacities: Capacity[];
  productionAverage: ValueWithUnit;
  mainProduct: string;

  constructor({
    id,
    name,
    type,
    externalId,
    deviations,
    productionAverage,
    mainProduct,
    referenceCapacity,
    capacities,
  }: WellListItemConstructor) {
    this.id = id;
    this.name = name;
    this.type = type;
    this.externalId = externalId;
    this.deviations = deviations;
    this.productionAverage = productionAverage;
    this.mainProduct = mainProduct;
    this.referenceCapacity = referenceCapacity;
    this.capacities = capacities;
  }

  get deviationsVolume(): ValueWithUnit {
    if (this.deviations.length === 0) {
      return {
        value: 0,
        unit: '',
      };
    }

    return {
      value: this.deviations.reduce(
        (acc: number, dev: DeviationSummary) => acc + dev.volume,
        0
      ),
      unit: this.deviations[0].unit,
    };
  }

  get productionEff(): ValueWithUnit {
    if (
      this.productionAverage?.value === undefined ||
      !this.referenceCapacity?.value
    ) {
      return {
        value: 0,
        unit: '',
      };
    }

    // Assuming productionAverage & bestDayCapacity having the same unit
    return {
      value:
        (this.productionAverage.value / this.referenceCapacity.value) * 100,
      unit: this.productionAverage.unit,
    };
  }
}
