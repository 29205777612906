import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ChartConfig } from 'graphql-types';

export type SelectedChartsState = (ChartConfig & { selected: boolean })[];

const selectedChartsSlice = createSlice({
  name: 'selectedCharts',
  initialState: [] as SelectedChartsState,
  reducers: {
    setSelectedCharts: (state, action: PayloadAction<SelectedChartsState>) =>
      action.payload,
  },
});

export default selectedChartsSlice;
