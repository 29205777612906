import { createSelector } from 'reselect';
import { RootState } from 'store';
import { Collection } from 'utils/models/collections';
import { CollectionsState } from './reducer';

const selectCollections = (state: RootState): CollectionsState =>
  state.collections;

export const getCollections = createSelector(
  [selectCollections],
  ({ collections }) => collections
);

export const getDynamicCollections = createSelector(
  [selectCollections],
  ({ dynamicCollections }) => dynamicCollections
);

export const getActiveCollection = createSelector(
  [selectCollections],
  ({ collections, dynamicCollections, activeCollectionKey }) => {
    const allCollections = [
      ...collections,
      ...dynamicCollections.map(
        (it) =>
          ({
            key: it.key,
            name: it.name,
            rootAssetExternalId: it.rootAssetExternalId,
            favorites: it.wells.map(({ externalId, name }) => ({
              externalId,
              name,
            })),
          } as Collection)
      ),
    ];
    return allCollections.find((it) => it.key === activeCollectionKey);
  }
);
