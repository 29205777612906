import { reportException } from '@cognite/react-errors';
import { DynamicCollection } from 'components/DynamicCollection/DynamicCollectionModal';
import { getClient } from 'utils/cognitesdk';
import { SIDECAR } from 'utils/sidecar';
import { Collection, Favorite } from '.';

const { bestdayApiBaseUrl } = SIDECAR;
export const COLLECTIONS_ENDPOINT = new URL('collections', bestdayApiBaseUrl)
  .href;
export const DYNAMIC_COLLECTIONS_ENDPOINT = new URL(
  'dynamic-collections',
  bestdayApiBaseUrl
).href;

export const fetchDynamicCollections = async (
  rootAssetExternalId: string,
  project: string
): Promise<DynamicCollection[]> => {
  return getClient()
    .get<DynamicCollection[]>(DYNAMIC_COLLECTIONS_ENDPOINT, {
      withCredentials: true,
      params: {
        rootAssetExternalId,
      },
      headers: {
        'x-cdp-project': project,
      },
    })
    .then(({ data }) => data);
};

export const fetchCollections = async (
  rootAssetExternalId: string,
  project: string
): Promise<Collection[]> => {
  return getClient()
    .get<Collection[]>(COLLECTIONS_ENDPOINT, {
      withCredentials: true,
      params: {
        rootAssetExternalId,
      },
      headers: {
        'x-cdp-project': project,
      },
    })
    .then(({ data }) => data);
};

export const insertDynamicCollection = async (
  dynamicCollection: DynamicCollection,
  project: string
): Promise<DynamicCollection> => {
  return getClient()
    .post<DynamicCollection>(DYNAMIC_COLLECTIONS_ENDPOINT, {
      withCredentials: true,
      data: {
        ...dynamicCollection,
      },
      headers: {
        'x-cdp-project': project,
      },
    })
    .then((data) => data.data);
};

export const insertCollection = async (
  name: string,
  favorites: Favorite[],
  rootAssetExternalId: string,
  project: string
): Promise<Collection> => {
  return getClient()
    .post<Collection>(COLLECTIONS_ENDPOINT, {
      withCredentials: true,
      data: {
        name,
        favoriteIds: favorites.map(({ externalId }) => externalId),
        rootAssetExternalId,
      },
      headers: {
        'x-cdp-project': project,
      },
    })
    .then(({ data }) => ({
      name,
      favorites,
      rootAssetExternalId,
      key: data.key,
    }));
};

export const updateDynamicCollection = async (
  dynamicCollection: DynamicCollection,
  project: string
): Promise<DynamicCollection> => {
  return getClient()
    .put<DynamicCollection>(
      `${DYNAMIC_COLLECTIONS_ENDPOINT}/${dynamicCollection.key}`,
      {
        withCredentials: true,
        data: {
          ...dynamicCollection,
        },
        headers: {
          'x-cdp-project': project,
        },
      }
    )
    .then(({ data }) => data);
};

export const updateCollection = async (
  collection: Collection,
  project: string
): Promise<Collection> => {
  return getClient()
    .put<Collection>(`${COLLECTIONS_ENDPOINT}/${collection.key}`, {
      withCredentials: true,
      data: {
        name: collection.name,
        favoriteIds: collection.favorites.map(({ externalId }) => externalId),
        rootAssetExternalId: collection.rootAssetExternalId,
      },
      headers: {
        'x-cdp-project': project,
      },
    })
    .then(({ data }) => ({ ...data, key: collection.key }));
};

export const deleteDynamicCollection = async (
  collectionId: string,
  project: string
): Promise<boolean> => {
  return getClient()
    .delete<Boolean>(`${DYNAMIC_COLLECTIONS_ENDPOINT}/${collectionId}`, {
      withCredentials: true,
      headers: {
        'x-cdp-project': project,
      },
    })
    .then(() => true)
    .catch((e) => {
      reportException(e);
      return true;
    });
};

export const deleteCollection = async (
  collectionId: string,
  project: string
): Promise<boolean> => {
  return getClient()
    .delete<Boolean>(`${COLLECTIONS_ENDPOINT}/${collectionId}`, {
      withCredentials: true,
      headers: {
        'x-cdp-project': project,
      },
    })
    .then(() => true)
    .catch((e) => {
      reportException(e);
      return true;
    });
};
