import numeral from 'numeral';
import { useCallback } from 'react';

export type DecimalOptions = {
  precision?: number;
  maxLength?: number;
};

type UseNumberFormats = () => {
  decimal: (v: number | string, options?: DecimalOptions) => string;
  decimalAndPostfix: (
    v: number | string,
    options?: DecimalOptions
  ) => [string, string];
  exponential: (value: number, format?: string) => string;
};

const useNumberFormats: UseNumberFormats = () => {
  // When we have user preferences, wire them up here.
  // We might also want to use something like numeraljs.com for this.

  const toFixed = (number: number, precision: number) => {
    const tenToPrecision = 10 ** precision;
    return (Math.round(number * tenToPrecision) / tenToPrecision).toFixed(
      precision
    );
  };

  const decimal = useCallback(
    (v: number | string, options?: DecimalOptions) => {
      const { precision = 0, maxLength = 0 } = options || {};
      const value = +v;
      if (Number.isNaN(value)) {
        throw new Error(`Non-number provided to decimal formatter (${v})`);
      }
      let string = value.toString();
      if (precision) {
        string = toFixed(value, precision);
      }
      if (maxLength && string.length >= maxLength) {
        let format = '0';
        for (let i = 0; i < precision; i += 1) {
          if (i === 0) {
            format += '.';
          }
          format += '0';
        }
        format += 'a';

        string = numeral(value.toFixed(precision)).format(format);
      }
      return string;
    },
    []
  );

  const decimalAndPostfix = useCallback(
    (v: number | string, options?: DecimalOptions) => {
      const string = decimal(v, options);

      const { index } = /[a-z]/i.exec(string) || { index: -1 };

      return (
        index === -1
          ? [string, '']
          : [string.substr(0, index), string.substr(index)]
      ) as [string, string];
    },
    [decimal]
  );

  const exponential = useCallback(
    (value: number, format = '0.00e+0') => numeral(value).format(format),
    []
  );

  return {
    decimal,
    decimalAndPostfix,
    exponential,
    toFixed,
  };
};

export default useNumberFormats;
