import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Level } from './types';

export type Field = {
  type: string;
  isRequired: boolean;
  dependsOn: string[];
  position: number;
};

type InitialState = {
  fields: Field[];
  ignoredFields: Field[];
  defermentMatrix: Level[];
  loading: boolean;
  error?: Error;
  matrixError?: Error;
};

const defermentFieldsSlice = createSlice({
  name: 'defermentFields',
  initialState: {
    fields: [],
    ignoredFields: [],
    defermentMatrix: [],
    loading: false,
  } as InitialState,
  reducers: {
    getDefermentFields: (
      state,
      action: PayloadAction<{ loading: boolean }>
    ) => {
      const { loading } = action.payload;
      return { ...state, loading, fields: [], ignoredFields: [] };
    },
    getDefermentFieldsSuccess: (
      state,
      action: PayloadAction<{
        fields: Field[];
        ignoredFields: Field[];
        loading: boolean;
      }>
    ) => {
      const { fields, ignoredFields, loading } = action.payload;
      return {
        ...state,
        fields,
        ignoredFields,
        loading,
      };
    },
    getDefermentFieldsError: (
      state,
      action: PayloadAction<{ loading: boolean; error: Error }>
    ) => {
      const { loading, error } = action.payload;
      return {
        ...state,
        fields: [],
        ignoredFields: [],
        loading,
        error,
      };
    },
    getDefermentMatrixSuccess: (
      state,
      action: PayloadAction<{
        defermentMatrix: Level[];
      }>
    ) => {
      const { defermentMatrix } = action.payload;
      return {
        ...state,
        defermentMatrix,
      };
    },
    getDefermentMatrixError: (
      state,
      action: PayloadAction<{
        error: Error;
      }>
    ) => {
      const { error } = action.payload;
      return {
        ...state,
        matrixError: error,
      };
    },
  },
});

export type DefermentFieldsState = ReturnType<
  typeof defermentFieldsSlice.reducer
>;

export default defermentFieldsSlice;
