import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PotentialChartComment } from './types';

type InitialState = {
  hoveredDeviationExternalId?: string;
  potentialChartComment?: PotentialChartComment;
};

const intradayChartSlice = createSlice({
  name: 'intradayChart',
  initialState: {} as InitialState,
  reducers: {
    setHoveredDeviation: (
      state,
      action: PayloadAction<{ hoveredDeviationExternalId?: string }>
    ) => {
      const { hoveredDeviationExternalId } = action.payload;
      return { ...state, hoveredDeviationExternalId };
    },
    setPotentialChartComment: (
      state,
      action: PayloadAction<{
        potentialChartComment?: PotentialChartComment;
      }>
    ) => {
      const { potentialChartComment } = action.payload;
      return { ...state, potentialChartComment };
    },
  },
});

export type intradayChartState = ReturnType<typeof intradayChartSlice.reducer>;

export default intradayChartSlice;
