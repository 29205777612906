/* eslint-disable no-param-reassign */
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ProductConversions } from './types';

const unitConversionSlice = createSlice({
  name: 'unitConversion',
  initialState: {
    assetSpecificConversions: {} as Record<string, ProductConversions>, // [asset.externalId]: {}
  },
  reducers: {
    setAssetSpecificConversion: (
      state,
      {
        payload: assetSpecificConversions,
      }: PayloadAction<Record<string, ProductConversions>>
    ) => {
      state.assetSpecificConversions = {
        ...state.assetSpecificConversions,
        ...assetSpecificConversions,
      };
    },
  },
});

export type UnitConversionState = ReturnType<
  typeof unitConversionSlice.reducer
>;

const selectUnitConversion = (state: RootState): UnitConversionState =>
  state.unitConversion;

export const selectAssetSpecificConversion = createSelector(
  [selectUnitConversion],
  ({ assetSpecificConversions }) => assetSpecificConversions
);

export default unitConversionSlice;
