import React, { useState } from 'react';
import { Input } from '@cognite/cogs.js';
import { useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import AssetSearchResult from './AssetSearchResult';
import {
  SearchResultDropdown,
  AssetSearchContainer,
  AssetSearchInnerContainer,
} from './element';

const AssetSearch = () => {
  const { t } = useTranslation('AssetsSearch');
  const [searchText, setSearchText] = useState('');
  const [dropdownVisibility, setDropdownVisibility] = useState(false);
  const shouldShowDropdown = dropdownVisibility && searchText.trim().length > 0;

  const renderDropdownContent = () => {
    if (!shouldShowDropdown) {
      return undefined;
    }

    return (
      <AssetSearchResult
        searchText={searchText}
        onAssetClicked={() => setSearchText('')}
      />
    );
  };

  return (
    <AssetSearchContainer>
      <AssetSearchInnerContainer>
        <SearchResultDropdown
          visible={shouldShowDropdown}
          content={renderDropdownContent()}
          onClickOutside={() => setDropdownVisibility(false)}
        >
          <Input
            fullWidth
            icon="Search"
            clearable={{
              callback: () => setSearchText(''),
            }}
            placeholder={t('assets-search_placeholder', {
              defaultValue: 'Search',
            })}
            value={searchText}
            onChange={(event) => {
              setDropdownVisibility(true);
              setSearchText(event.target.value);
            }}
          />
        </SearchResultDropdown>
      </AssetSearchInnerContainer>
    </AssetSearchContainer>
  );
};

export default withI18nSuspense(AssetSearch);
