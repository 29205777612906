import React, { useState, useCallback } from 'react';
import { Tooltip, TooltipProps } from '@cognite/cogs.js';
import TextOverflowEllipsis from 'components/accessibility/TextOverflowEllipsis';
import { StyledComponentProps } from 'styled-components/macro';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Props = StyledComponentProps<'p', any, {}, never> & {
  tooltipProps?: TooltipProps;
  children: string | number | React.ReactElement;
};

export function isEllipsisActive<T extends HTMLElement>(el: T | null): boolean {
  if (!el) {
    return false;
  }
  return el.offsetWidth < el.scrollWidth;
}

const TextOverflowEllipsisTooltip = ({
  tooltipProps,
  children,
  ...rest
}: Props) => {
  const [tooltipTitle, setTooltipTitle] = useState<
    string | number | React.ReactElement
  >();
  const textRef = useCallback(
    (node) => {
      if (node !== null && isEllipsisActive(node)) {
        setTooltipTitle(children);
      }
    },
    [children]
  );

  return (
    <Tooltip disabled={!tooltipTitle} {...tooltipProps} content={tooltipTitle}>
      <TextOverflowEllipsis {...rest} ref={textRef}>
        {children}
      </TextOverflowEllipsis>
    </Tooltip>
  );
};

export default TextOverflowEllipsisTooltip;
