export type LineType =
  | 'production'
  | 'ipsc'
  | 'theor'
  | 'alloc'
  | 'bestday'
  | 'mpc'
  | 'capacity'
  | 'deviation'
  | 'deferment_actual'
  | 'deferment_future'
  | 'investigating_deviation'
  | 'ignored_deviation'
  | 'best_day_deferment'
  | '';

export const getLineType = (extId?: string): LineType => {
  if (extId?.toUpperCase()?.endsWith('_PRODUCTION')) {
    return 'production';
  }
  if (extId?.toUpperCase()?.endsWith('_IPSC')) {
    return 'ipsc';
  }
  if (extId?.toUpperCase()?.endsWith('_THEOR')) {
    return 'theor';
  }
  if (extId?.toUpperCase()?.endsWith('_ALLOC')) {
    return 'alloc';
  }
  if (
    extId?.toUpperCase()?.endsWith('_BEST_DAY_PREDICTION') ||
    extId?.toUpperCase()?.endsWith('_BESTDAY')
  ) {
    return 'bestday';
  }
  if (
    extId?.toUpperCase()?.endsWith('_CAPACITY') ||
    extId?.toUpperCase()?.endsWith('_MPC')
  ) {
    return 'mpc';
  }
  if (extId?.toUpperCase()?.endsWith('_INVESTIGATING_DEVIATION_VOLUME')) {
    return 'investigating_deviation';
  }
  if (extId?.toUpperCase()?.endsWith('_IGNORED_DEVIATION_VOLUME')) {
    return 'ignored_deviation';
  }
  if (extId?.toUpperCase()?.endsWith('_BEST_DAY_DEFERMENT_VOLUME')) {
    return 'best_day_deferment';
  }
  if (extId?.toUpperCase()?.endsWith('_DEVIATION_VOLUME')) {
    return 'deviation';
  }
  if (
    extId?.toUpperCase()?.endsWith('_DEFERMENTS_ACTUAL') ||
    extId?.toUpperCase()?.endsWith('_DEFERMENT_ACTUAL') ||
    extId?.toUpperCase()?.endsWith('_DEFERMENTS')
  ) {
    return 'deferment_actual';
  }
  if (extId?.toUpperCase()?.endsWith('_DEFERMENTS_FUTURE')) {
    return 'deferment_future';
  }
  return '';
};

export const getSuffixByType = (lineType: LineType) => {
  switch (lineType) {
    case 'production':
      return 'PRODUCTION';
    case 'ipsc':
      return 'IPSC';
    case 'theor':
      return 'THEOR';
    case 'alloc':
      return 'ALLOC';
    case 'bestday':
      return 'BEST_DAY_PREDICTION';
    case 'mpc':
      return 'MPC';
    case 'deviation':
      return 'DEVIATION_VOLUME';
    case 'deferment_actual':
      return 'DEFERMENTS_ACTUAL';
    case 'deferment_future':
      return 'DEFERMENTS_FUTURE';
    default:
      return '';
  }
};

export const getDash = (lineType: LineType) => {
  switch (lineType) {
    case 'ipsc':
      return '5,5';
    case 'bestday':
      return '8,4';
    case 'mpc':
      return '5,5';
    default:
      return undefined;
  }
};

export const getStrokesWidth = (lineType: LineType) => {
  switch (lineType) {
    case 'production':
      return 2;
    case 'bestday':
      return 3;
    case 'ipsc':
      return 1;
    case 'mpc':
      return 1;
    default:
      return 1;
  }
};
