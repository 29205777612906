import React, { useCallback } from 'react';
import { Button, Body, Flex, Input, Menu, Row } from '@cognite/cogs.js';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import Select from 'components/Select';
import styled from 'styled-components/macro';
import { ProductConversions, useUnitConversion } from 'features/unitConversion';
import { useDynamicCollectionConfig } from 'pages/WellDeepDive/hooks/useDynamicCollectionConfig';
import isMatch from 'lodash/isMatch';
import uniqueId from 'lodash/uniqueId';

export type ConditionGroup = {
  id: string;
  conjunction?: 'and' | 'or';
  conditions: Condition[];
};

export type Condition = {
  id?: string;
  variable?: { category: string; type: string; name: string };
  symbol: string;
  value?: number;
  unit?: string;
};

export type Props = {
  index: number;
  conditionGroup: ConditionGroup;

  variables: { category: string; type: string; name: string; unit: string }[];
  symbols: string[];
  conjunctions: string[];
  productConversions: ProductConversions;
  dirtyControls: {
    [key: string]: boolean;
  };
  updateGroup: (group: ConditionGroup) => void;
  removeGroup: (group: ConditionGroup) => void;
  setDirty: (key: string) => void;
  validateCondition: (condition: Condition, field?: string) => boolean;
};

const ConditionGroupView = ({
  index,
  conditionGroup,
  variables,
  symbols,
  conjunctions,
  dirtyControls,
  updateGroup,
  removeGroup,
  setDirty,
  validateCondition,
}: Props) => {
  const { t: tSymbols } = useTranslation('Symbols');
  const { t: tUnits } = useTranslation('Units');
  const { t: tProducts } = useTranslation('Products');
  const { t: tDynamicCollection } = useTranslation('DynamicCollection');
  const { units: getUnits } = useDynamicCollectionConfig();
  const { getPreferredUnitByProduct } = useUnitConversion();

  const translateProduct = useCallback(
    (product: string) => {
      return tProducts(`${product}-product`, { defaultValue: product });
    },
    [tProducts]
  );

  const translateSymbol = useCallback(
    (symbol: string) => {
      return tSymbols(`${symbol}`, { defaultValue: symbol });
    },
    [tSymbols]
  );

  const translateUnit = useCallback(
    (unit: string) => {
      return tUnits(`${unit}-unit`, { defaultValue: unit });
    },
    [tUnits]
  );

  const valueErrorStyles = useCallback(
    (condition: Condition) => {
      if (
        dirtyControls[`value-${condition.id}`] &&
        !validateCondition(condition, 'value')
      ) {
        return { border: '2px solid #e32351' };
      }
      return {};
    },
    [dirtyControls, validateCondition]
  );

  const addCondition = useCallback(() => {
    updateGroup({
      ...conditionGroup,
      conditions: [
        ...conditionGroup.conditions,
        { id: uniqueId(), symbol: 'gt' },
      ],
    });
  }, [conditionGroup, updateGroup]);

  const removeCondition = useCallback(
    (removedCondition: Condition) => {
      const updatedConditions = conditionGroup.conditions.filter(
        (it) => it.id !== removedCondition.id
      );
      updateGroup({
        ...conditionGroup,
        conditions: updatedConditions.length
          ? updatedConditions
          : [{ id: uniqueId(), symbol: 'gt', value: 0 }],
      });
    },
    [conditionGroup, updateGroup]
  );

  const updateCondition = useCallback(
    (updatedCondition: Condition) => {
      updateGroup({
        ...conditionGroup,
        conditions: conditionGroup.conditions.map((it) => {
          if (updatedCondition.id === it.id) {
            return updatedCondition;
          }
          return it;
        }),
      });
    },
    [conditionGroup, updateGroup]
  );

  const updateConjunction = useCallback(
    (conjunction: string) => {
      updateGroup({
        ...conditionGroup,
        conjunction,
      } as ConditionGroup);
    },
    [conditionGroup, updateGroup]
  );

  const requiredText = tDynamicCollection('required', {
    defaultValue: 'Required',
  });
  const deleteConditionAriaText = tDynamicCollection('aria_delete_condition', {
    defaultValue: 'Delete condition',
  });

  const selectText = tDynamicCollection('placeholder_condition_symbol', {
    defaultValue: 'Select',
  });

  return (
    <Flex
      direction="column"
      style={{
        backgroundColor: '#FAFAFA',
        padding: '16px',
        border: '1px solid #D9D9D9',
        borderRadius: '8px',
      }}
    >
      <Flex style={{ justifyContent: 'end' }}>
        {index > 0 && (
          <Button
            type="ghost"
            onClick={() => removeGroup(conditionGroup)}
            icon="Delete"
            aria-label="Delete condition"
          />
        )}
      </Flex>
      {conditionGroup?.conditions.map((condition, index) => {
        const conditionTypeUnit = variables.find(
          (it) => condition.variable && isMatch(it, condition.variable)
        )?.unit;
        const units = condition.variable
          ? getUnits(condition.variable.name)
          : [];
        return (
          <Row cols={7} style={{ paddingBottom: '8px' }}>
            <Flex
              style={{
                width: '80px',
                justifyContent: 'end',
                alignItems: 'center',
              }}
            >
              {index === 0 && (
                <Col>
                  <Body level="2" strong>
                    <Trans t={tDynamicCollection} i18nKey="conjunction_If">
                      If
                    </Trans>
                  </Body>
                </Col>
              )}
              {index === 1 && (
                <Col>
                  <Select
                    items={conjunctions.map((it) => {
                      return (
                        <Menu.Item
                          key={it}
                          onClick={() => updateConjunction(it)}
                        >
                          {it}
                        </Menu.Item>
                      );
                    })}
                  >
                    <>{conditionGroup?.conjunction}</>
                  </Select>
                </Col>
              )}
              {index > 1 && (
                <Col>
                  <Body level="2" strong>
                    <Trans
                      t={tDynamicCollection}
                      i18nKey={`conjunction_${conditionGroup.conjunction}`}
                    >
                      {conditionGroup.conjunction}
                    </Trans>
                  </Body>
                </Col>
              )}
            </Flex>
            <Flex style={{ width: '220px' }}>
              <Select
                style={{ width: '212px' }}
                onHide={() => setDirty(`variable-${condition.id}`)}
                error={
                  dirtyControls[`variable-${condition.id}`] &&
                  !validateCondition(condition, 'variable')
                    ? requiredText
                    : undefined
                }
                items={variables.map((it) => {
                  return (
                    <Menu.Item
                      key={`${it.name}-${it.type}`}
                      onClick={() =>
                        updateCondition({
                          ...condition,
                          unit: getPreferredUnitByProduct(it.name),
                          variable: {
                            category: it.category,
                            type: it.type,
                            name: it.name,
                          },
                        })
                      }
                    >
                      {translateProduct(`${it.name}-${it.type}`)}
                    </Menu.Item>
                  );
                })}
              >
                <>
                  {condition.variable
                    ? translateProduct(
                        `${condition.variable.name}-${condition.variable.type}`
                      )
                    : tDynamicCollection('placehodler_this-variable', {
                        defaultValue: 'this variable',
                      })}
                </>
              </Select>
            </Flex>
            <Flex
              style={{
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Body level="2" strong>
                <Trans t={tDynamicCollection} i18nKey="verb_is">
                  is
                </Trans>
              </Body>
            </Flex>
            <Flex>
              <Select
                style={{ width: '100%' }}
                items={symbols.map((it) => {
                  return (
                    <Menu.Item
                      key={it}
                      onClick={() =>
                        updateCondition({ ...condition, symbol: it })
                      }
                    >
                      {translateSymbol(it)}
                    </Menu.Item>
                  );
                })}
              >
                <>
                  {condition.symbol
                    ? translateSymbol(condition.symbol)
                    : selectText}
                </>
              </Select>
            </Flex>
            <Flex>
              <Input
                style={{ width: '70px', ...valueErrorStyles(condition) }}
                type="number"
                value={condition.value}
                onBlur={() => validateCondition(condition, 'value')}
                onChange={({ target }) => {
                  updateCondition({ ...condition, value: +target?.value });
                }}
              />
            </Flex>
            <Flex style={{ width: '100px', alignItems: 'center' }}>
              {units.length > 0 ? (
                <Select
                  onHide={() => setDirty(`unit-${condition.id}`)}
                  error={
                    dirtyControls[`unit-${condition.id}`] &&
                    !validateCondition(condition, 'unit')
                      ? requiredText
                      : undefined
                  }
                  items={units.map((it) => {
                    return (
                      <Menu.Item
                        key={it}
                        onClick={() =>
                          updateCondition({ ...condition, unit: it })
                        }
                      >
                        {translateUnit(it)}
                      </Menu.Item>
                    );
                  })}
                >
                  <>
                    {condition.unit
                      ? translateUnit(condition.unit)
                      : selectText}
                  </>
                </Select>
              ) : (
                <Body level="2" strong>
                  {conditionTypeUnit && translateUnit(conditionTypeUnit)}
                </Body>
              )}
            </Flex>
            <Col>
              <Button
                type="ghost"
                onClick={() => removeCondition(condition)}
                icon="Close"
                aria-label={deleteConditionAriaText}
              />
            </Col>
          </Row>
        );
      })}
      <Flex style={{ justifyContent: 'end', paddingTop: '8px' }}>
        <Button
          type="ghost"
          style={{ backgroundColor: 'white', border: '1px solid #D9D9D9' }}
          onClick={() => addCondition()}
        >
          <Trans t={tDynamicCollection} i18nKey="addCondition">
            + Add condition
          </Trans>
        </Button>
      </Flex>
    </Flex>
  );
};

const Col = styled.div`
  justify-content: right;
`;
export default withI18nSuspense(ConditionGroupView);
