import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Deviation } from 'utils/models';

type InitialState = {
  deviationsLoading: boolean;
  deviations: Deviation[];
  convertedDeviations: Deviation[];
  error?: Error;
  updateLoading: boolean;
  updateError?: Error;
  selectedDeviationExternalId?: string;
};

const deviationsSlice = createSlice({
  name: 'deviations',
  initialState: {
    deviationsLoading: false,
    deviations: [],
    convertedDeviations: [],
    updateLoading: false,
  } as InitialState,
  reducers: {
    getDeviations: (
      state,
      action: PayloadAction<{ deviationsLoading: boolean }>
    ) => {
      const { deviationsLoading } = action.payload;
      return { ...state, deviationsLoading, deviations: [] };
    },
    getDeviationsSuccess: (
      state,
      action: PayloadAction<{
        deviations: Deviation[];
        convertedDeviations?: Deviation[];
        deviationsLoading: boolean;
      }>
    ) => {
      const { deviationsLoading, deviations, convertedDeviations } =
        action.payload;
      return {
        ...state,
        deviationsLoading,
        deviations,
        convertedDeviations: convertedDeviations || [],
      };
    },
    getDeviationsError: (
      state,
      action: PayloadAction<{ deviationsLoading: boolean; error: Error }>
    ) => {
      const { deviationsLoading, error } = action.payload;
      return { ...state, deviations: [], deviationsLoading, error };
    },
    updateEvent: (state, action: PayloadAction<{ updateLoading: boolean }>) => {
      const { updateLoading } = action.payload;
      return { ...state, updateLoading };
    },
    updateEventSuccess: (
      state,
      action: PayloadAction<{
        updatedDeviation: Deviation;
        updateLoading: boolean;
      }>
    ) => {
      const { updatedDeviation, updateLoading } = action.payload;
      return {
        ...state,
        deviations: state.deviations.map((dev: Deviation) => {
          if (dev.externalId !== updatedDeviation.externalId) {
            return dev;
          }
          return updatedDeviation;
        }),
        updateLoading,
      };
    },
    updateEventError: (
      state,
      action: PayloadAction<{
        updateLoading: boolean;
        updateError: Error;
      }>
    ) => {
      const { updateLoading, updateError } = action.payload;
      return { ...state, updateLoading, updateError };
    },
    setSelectedDeviation: (
      state,
      action: PayloadAction<{ selectedDeviationExternalId?: string }>
    ) => {
      const { selectedDeviationExternalId } = action.payload;
      return { ...state, selectedDeviationExternalId };
    },
  },
});

export type DeviationsState = ReturnType<typeof deviationsSlice.reducer>;

export default deviationsSlice;
