import { useTranslation } from '@cognite/react-i18n';
import { CommentFilter, FilterKey } from 'features/preferences/filter';
import { useCallback } from 'react';

type Props = {
  setCommentFilter: (filter: Partial<CommentFilter>) => void;
};

const useCommentFilterChips = ({ setCommentFilter }: Props) => {
  const { t: tEventStatus } = useTranslation('EventStatus');
  const getChipsData = useCallback(
    (filters: CommentFilter) => {
      const groupChips = filters.group.map((f) => ({
        filterKey: 'status' as FilterKey,
        title: tEventStatus(`${f}-status`, { defaultValue: f }),
        value: f,
        customOnRemove: () => setCommentFilter({ group: [] }),
      }));

      return [...groupChips];
    },
    [setCommentFilter, tEventStatus]
  );
  return { getChipsData };
};

export default useCommentFilterChips;
