import { TabContent } from 'components/EventDistribution';
import { useMemo } from 'react';
import { filterByVolume } from 'utils/filter';
import { useTranslation } from '@cognite/react-i18n';
import { EventVolumeFilter } from 'features/preferences/filter';

export const EventDuration = {
  LESS_THAN_ONE_DAY: '< 1',
  ONE_TO_THREE_DAYS: '1 - 3',
  THREE_TO_SEVEN_DAYS: '3 - 7',
  SEVEN_TO_FOURTEEN_DAYS: '7 - 14',
  FOURTEEN_TO_THIRTY_DAYS: '14 - 30',
  OVER_THIRTY_DAYS: '> 30',
} as const;

export type EventDurationType =
  typeof EventDuration[keyof typeof EventDuration];

type MixedEvent = {
  externalId: string;
  fractionOfBestDay?: number;
  volumeInCommonUnit?: number;
  bestDayBoeVolume?: number;
};

export const VolumePercentage = {
  LESS_THAN_FIVE_PERCENT: '<5%',
  FIVE_TO_TEN_PERCENT: '5%-10%',
  MORE_THAN_TEN_PERCENT: '>10%',
} as const;

export type VolumePercentageType =
  typeof VolumePercentage[keyof typeof VolumePercentage];

export const durationTabSortOrder = [
  EventDuration.LESS_THAN_ONE_DAY,
  EventDuration.ONE_TO_THREE_DAYS,
  EventDuration.THREE_TO_SEVEN_DAYS,
  EventDuration.SEVEN_TO_FOURTEEN_DAYS,
  EventDuration.FOURTEEN_TO_THIRTY_DAYS,
  EventDuration.OVER_THIRTY_DAYS,
];

export const volumeTabSortOrder = [
  VolumePercentage.LESS_THAN_FIVE_PERCENT,
  VolumePercentage.FIVE_TO_TEN_PERCENT,
  VolumePercentage.MORE_THAN_TEN_PERCENT,
];

export type TabContentValue = {
  value: number;
  group?: string;
};

export const useTabContent = (
  title: string,
  values: Record<string, TabContentValue>,
  total: number,
  order: string[],
  groupMappings?: Record<string, string>
) => {
  const tabContent = useMemo(
    () =>
      order.map((groupKey) => {
        const { value } = values[groupKey];
        const group = values[groupKey].group ?? groupKey;
        const valueInPercent = +((value / total) * 100).toFixed(0);
        return {
          group: (groupMappings && groupMappings[group]) || group,
          value,
          valueInPercent,
        } as TabContent;
      }),
    [order, total, values, groupMappings]
  );

  return {
    key: title,
    title,
    tabContent,
  };
};

export const toVolumePercentages = (
  events: Array<MixedEvent>,
  filter: EventVolumeFilter[]
) =>
  events.filter((event) =>
    filterByVolume(
      filter,
      event?.fractionOfBestDay,
      event?.volumeInCommonUnit,
      event?.bestDayBoeVolume
    )
  ).length;

export const useTranslateEventDistributionGroup = () => {
  const { t: tEventDistribution } = useTranslation('EventDistribution');
  const eventDistributionTranslationMapping = useMemo(() => {
    const dayTranslation = tEventDistribution(`day_event-distribution-group`, {
      defaultValue: 'day',
    });

    const daysTranslation = tEventDistribution(
      `days_event-distribution-group`,
      {
        defaultValue: 'days',
      }
    );

    return durationTabSortOrder.reduce((acc, key) => {
      acc[key] = `${key} ${
        key === EventDuration.LESS_THAN_ONE_DAY
          ? dayTranslation
          : daysTranslation
      }`;
      return acc;
    }, {} as Record<string, string>);
  }, [tEventDistribution]);

  return { eventDistributionTranslationMapping };
};
