import React, { MouseEvent, useState } from 'react';
import { Body, Button, Dropdown, Icon, Menu, toast } from '@cognite/cogs.js';
import { Collection } from 'utils/models/collections';
import { useTranslation, withI18nSuspense, Trans } from '@cognite/react-i18n';
import CollectionDeleteModal from 'components/CollectionDeleteModal';
import createToastOptions from 'utils/toasts/createToastOptions';
import useNavigation from 'utils/useNavigation';
import UnstyledLink from 'components/accessibility/UnstyledLink';
import {
  StyledCollectionRow,
  StyledCollectionTreeRow,
  StyledDeleteButton,
  StyledDeleteCollectionRow,
  StyledDropdownWrapper,
  StyledEditButton,
  StyledMenu,
} from './elements';

type collectionDropDownProps = {
  onDelete: Function;
  onEdit: Function;
};

const CollectionDropdownMenu = ({
  onDelete,
  onEdit,
}: collectionDropDownProps) => {
  const { t } = useTranslation('CollectionDropdownMenu');
  const editOptionTitle = t('collectionTree_edit', { defaultValue: 'Edit' });
  const deleteOptionTitle = t('collectionTree_delete', {
    defaultValue: 'Delete',
  });

  return (
    <>
      <StyledMenu>
        <StyledEditButton type="ghost" size="small" onClick={() => onEdit()}>
          <StyledCollectionRow>
            <div>{editOptionTitle}</div>
            <Icon type="Edit" />
          </StyledCollectionRow>
        </StyledEditButton>
        <Menu.Divider />
        <StyledDeleteButton
          type="ghost"
          size="small"
          onClick={() => onDelete()}
        >
          <StyledDeleteCollectionRow>
            <div>{deleteOptionTitle}</div>
            <Icon type="Delete" />
          </StyledDeleteCollectionRow>
        </StyledDeleteButton>
      </StyledMenu>
    </>
  );
};

export const CollectionRow = ({
  collection,
  setIsCollectionsModalOpen,
  removeCollection,
  children,
}: {
  collection: Collection;
  setIsCollectionsModalOpen: (value?: string) => void;
  removeCollection: Function;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation('CollectionRow');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCollectionDeleteModalOpen, setIsCollectionDeleteModalOpen] =
    useState(false);

  const { currentPage } = useNavigation();

  const onDropdownClick = (event: MouseEvent) => {
    event.stopPropagation();
    setIsDropdownOpen(!isDropdownOpen);
  };

  const onDelete = () => {
    setIsCollectionDeleteModalOpen(true);
  };

  const onEdit = () => {
    setIsCollectionsModalOpen(collection.key);
  };

  return (
    <StyledCollectionTreeRow key={collection.key}>
      <UnstyledLink
        to={`/assets/collection/${currentPage}/${encodeURIComponent(
          collection.key
        )}`}
        style={{
          width: '100%',
          display: 'inline-block',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {collection.name}
      </UnstyledLink>
      <StyledDropdownWrapper>
        <Dropdown
          content={
            <CollectionDropdownMenu onEdit={onEdit} onDelete={onDelete} />
          }
          visible={isDropdownOpen}
          onClickOutside={() => {
            setIsDropdownOpen(false);
          }}
        >
          <Button
            type="ghost"
            size="small"
            className="edit-collections-button"
            onClick={onDropdownClick}
          >
            <Icon type="EllipsisHorizontal" />
          </Button>
        </Dropdown>
      </StyledDropdownWrapper>
      {children}
      {isCollectionDeleteModalOpen && (
        <CollectionDeleteModal
          collection={collection}
          removeCollection={removeCollection}
          onClose={(isDeleted: boolean, name: string) => {
            if (isDeleted && name) {
              toast.success(
                <Body level={2}>
                  <Trans t={t} i18nKey="toast-delete-success">
                    The collection <strong>{{ name }}</strong> was deleted
                  </Trans>
                </Body>,
                createToastOptions()
              );
            }
          }}
        />
      )}
    </StyledCollectionTreeRow>
  );
};

export default withI18nSuspense(CollectionRow);
