import { UnitCategories } from 'graphql-types';
import useConfig from 'hooks/useConfig';
import { getUnitCategories, useTemplatesQuery } from 'hooks/useGraphQlQuery';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

export const useUnitCategories = () => {
  const { rootAssetConfig } = useConfig();
  const { datasets } = useSelector((state: RootState) => state.datasets);

  const { data } = useTemplatesQuery<Array<UnitCategories>>(
    {
      fn: getUnitCategories,
      key: 'getUnitCategories',
    },
    {
      templateInfo: rootAssetConfig?.templates,
      dataSetId: datasets?.CUSTOMER_CONFIGURATION,
    },
    {
      enabled: !!rootAssetConfig?.templates,
      cacheTime: 60 * 60 * 1000,
    }
  );

  const getCategory = useCallback(
    (product: string) => {
      const [category] = (data || [])
        .map((category) => {
          if (category?.products?.includes(product)) {
            return category.category;
          }
          return undefined;
        })
        .filter(Boolean);
      return category;
    },
    [data]
  );

  return {
    unitCategories: data || [],
    getCategory,
  };
};
