import { useEffect, useMemo, useState } from 'react';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import useNavigation from 'utils/useNavigation';
import { useCollections } from 'features/collections';
import chunk from 'lodash/chunk';
import {
  getSystems,
  getWellSummaryData,
  useTemplatesQuery,
} from 'hooks/useGraphQlQuery';
import useConfig from 'hooks/useConfig';
import { useQueries } from 'react-query';
import { Pages } from 'utils/models/enums';
import { PerfMetrics } from '@cognite/metrics';
import { METRICS } from 'utils/metrics/enums';

export const useWellAssets = () => {
  const { selectedAsset } = useCurrentAsset();
  const { currentPage, isCollectionPage } = useNavigation();
  const { getCurrentCollection } = useCollections();
  const { rootAssetConfig } = useConfig();
  const [hasError, setHasError] = useState(false);
  const [hasLoadingStarted, setHasLoadingStarted] = useState(false);

  const CHUNK_SIZE = 200;

  const getWellsUnderSystems = async ({
    externalIds,
  }: {
    externalIds: string[];
  }): Promise<string[]> => {
    const systems = await getSystems({
      externalIds,
      templateInfo: rootAssetConfig?.templates!,
    });
    const subSystems = systems
      .flatMap((it) => it.subSystems?.map((it) => it.asset.externalId))
      .filter((item): item is string => !!item);
    const wells = systems
      .flatMap((it) => it.wells?.map((it) => it.asset.externalId))
      .filter((item): item is string => !!item);
    if (subSystems.length <= 0) {
      return wells;
    }
    const subSystemWells = await getWellsUnderSystems({
      externalIds: subSystems,
    });
    return [...wells, ...subSystemWells];
  };

  const {
    data: wellExternalIds,
    isLoading: isLoadingWellExternalIds,
    isFetching: isFetchingWellExternalIds,
  } = useTemplatesQuery<string[]>(
    { fn: getWellsUnderSystems, key: `getWells` },
    { externalIds: [selectedAsset?.externalId] },
    {
      enabled: !!rootAssetConfig?.templates && !!selectedAsset?.externalId,
      onError: () => setHasError(true),
    }
  );

  const externalIdsBatched = useMemo(() => {
    if (isCollectionPage && currentPage === Pages.Wells) {
      const currentCollection = getCurrentCollection();
      const favoriteList = currentCollection ? currentCollection.favorites : [];
      const favorites = favoriteList.map(({ externalId }) => externalId);

      const assetIdList = favorites.filter(
        (identifier) => !!identifier && Number.isNaN(+identifier)
      );
      return chunk(assetIdList, CHUNK_SIZE);
    }
    return chunk(wellExternalIds, CHUNK_SIZE);
  }, [currentPage, getCurrentCollection, isCollectionPage, wellExternalIds]);

  const queries = useQueries(
    externalIdsBatched.map((chunk: string[]) => {
      return {
        queryKey: ['getWellSummaryData', chunk],
        queryFn: () =>
          getWellSummaryData({
            externalIds: chunk,
            limit: CHUNK_SIZE,
            templateInfo: rootAssetConfig?.templates!,
          }),
        enabled: !!rootAssetConfig?.templates && !!externalIdsBatched,
        onError: () => setHasError(true),
      };
    })
  );

  const loading = useMemo(() => {
    return (
      isLoadingWellExternalIds ||
      isFetchingWellExternalIds ||
      queries.some((it) => it.isLoading || it.isFetching)
    );
  }, [isFetchingWellExternalIds, isLoadingWellExternalIds, queries]);

  const wells = useMemo(() => {
    return queries.flatMap((it) => it.data).filter(Boolean);
  }, [queries]);

  useEffect(() => {
    if (isFetchingWellExternalIds) {
      PerfMetrics.trackPerfStart(
        METRICS.WellSummaryDataFetching,
        undefined,
        true
      );
      setHasLoadingStarted(true);
    }
  }, [isFetchingWellExternalIds]);

  useEffect(() => {
    if (!hasLoadingStarted) {
      return;
    }

    if (!loading && !hasError) {
      PerfMetrics.trackPerfEnd(METRICS.WellSummaryDataFetching);
      PerfMetrics.logSuccessEvent(METRICS.WellSummaryDataFetching);
      setHasLoadingStarted(false);
    }

    if (hasError) {
      PerfMetrics.logFailureEvent(METRICS.WellSummaryDataFetching);
      setHasLoadingStarted(false);
    }
  }, [hasLoadingStarted, hasError, loading]);

  return {
    wells,
    loading,
  };
};
