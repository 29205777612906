import React from 'react';

type HtmlElementProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export interface FlexProps extends HtmlElementProps {
  as?: keyof React.ReactHTML;
  direction?: 'row' | 'column';
  gap?: number;
}

/**
 * A simple component that is set to display: flex.
 */
export const Flex = ({
  as = 'div',
  direction,
  gap,
  children,
  ...rest
}: FlexProps) => {
  return React.createElement(
    as,
    {
      ...rest,
      style: {
        ...rest.style,
        display: 'flex',
        flexDirection: direction,
        gap,
      },
    },
    children
  );
};

Flex.Row = ({ as = 'div', gap, children, ...rest }: FlexProps) => {
  return React.createElement(
    as,
    {
      ...rest,
      style: {
        ...rest.style,
        display: 'flex',
        flexDirection: 'row',
        gap,
      },
    },
    children
  );
};

Flex.Column = ({ as = 'div', gap, children, ...rest }: FlexProps) => {
  return React.createElement(
    as,
    {
      ...rest,
      style: {
        ...rest.style,
        display: 'flex',
        flexDirection: 'column',
        gap,
      },
    },
    children
  );
};

export default Flex;
