import { useEffect, useState, useMemo } from 'react';
import { reportException, ReportedError } from '@cognite/react-errors';
import { useAppDispatch } from 'store';
import datasetsSlice from 'features/datasets';
import useConfig from 'hooks/useConfig';
import { isVerificationError, verifyDatasets } from './util';

export type Errors = {
  dataSetsNotDefined?: ReportedError;
  unknown?: ReportedError;
};

type UsePermissionsChecks = () => {
  success: boolean;
  errors: Errors;
};

export const usePermissionsChecks: UsePermissionsChecks = () => {
  const [errors, setErrors] = useState<Errors>({});
  const dispatch = useAppDispatch();

  const { rootAssetConfig } = useConfig();

  const handleException = (ex: Error) => {
    reportException(ex).then((reported) => {
      if (isVerificationError(ex)) {
        const { key, source, msg } = ex;
        setErrors((prev) => ({
          ...prev,
          [key]: {
            errorId: reported.errorId,
            error: source || new Error(msg),
          },
        }));
      }
    });
  };

  useEffect(() => {
    if (!rootAssetConfig?.dataSetMapping) {
      return;
    }

    setErrors({});
    verifyDatasets(rootAssetConfig.dataSetMapping)
      .then((datasets) => {
        dispatch(
          datasetsSlice.actions.getDatasetsSuccess({
            datasets,
          })
        );
      })
      .catch(handleException);
  }, [dispatch, rootAssetConfig]);

  const success = useMemo(() => {
    return Object.values(errors).every((error) => !error);
  }, [errors]);

  return {
    success,
    errors,
  };
};
