import { useCallback, useMemo } from 'react';
import { usePreferences } from 'features/preferences';
import { UseFilterEvents, useFilterEvents } from 'utils/models/events';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import { useUnitConversion } from 'features/unitConversion';
import { useWellAssets } from 'utils/models/wellListItems/hooks/useWellAssets';
import { isDeviationsFiltered, useFilter } from 'features/preferences/filter';
import { RANGE, getstartOfPeriod } from 'utils/datetime';
import { AggregateValue, DeviationSummary } from 'graphql-types';
import { useQuery } from 'react-query';
import { getEnrichmentConfig } from 'hooks/useGraphQlQuery';
import useConfig from 'hooks/useConfig';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  DEFAULT_UNIT,
  HYDROCARBON,
  PRODUCT_TYPE_WATER_INJECTION,
} from 'utils/products';
import { Capacity, WellListItemSortColumn } from '../types';
import { WellListItem } from '../model';

const getSummaryFilterProps = (
  deviationSummary: DeviationSummary
): UseFilterEvents => {
  const { status, startDate, endDate, fractionOfBestDay, volume } =
    deviationSummary;

  return {
    status,
    startTime: startDate,
    endTime: endDate,
    fractionOfBestDay,
    boeVolume: volume,
    isOngoing: !endDate,
  };
};

export const useWellListItems = () => {
  const { rootAssetConfig } = useConfig();
  const { loading, wells } = useWellAssets();
  const { convertValue } = useUnitConversion();
  const { datasets } = useSelector((state: RootState) => state.datasets);
  const {
    preferences: {
      predefinedTimerange,
      wellSummary: { sort },
    },
  } = usePreferences();

  const {
    filter: {
      // @ts-ignore Local error!
      wells: { type: wellTypeFilters },
      deviations: deviationsFilter,
    },
  } = useFilter('wellSummary');

  const { filterEvents } = useFilterEvents(deviationsFilter, true);

  const timeRangeIndex = useMemo(
    () => RANGE.findIndex((val) => val === predefinedTimerange),
    [predefinedTimerange]
  );

  const startOfPeriod = useMemo(
    () => getstartOfPeriod(timeRangeIndex),
    [timeRangeIndex]
  );

  const convert = useCallback(
    (it: AggregateValue) => {
      const converted = convertValue({
        value: it.value,
        sourceUnit: it.unit,
        targetUnit: DEFAULT_UNIT,
        frequency: 'daily',
        product: HYDROCARBON, // TODO(BEST-1501): Remove this hardcoding
        convertRateAnyway: true,
      });
      return {
        value: converted?.value || 0,
        unit: converted?.convertedUnit || '',
      };
    },
    [convertValue]
  );

  const { data: config } = useQuery({
    queryKey: ['getEnrichmentConfig', datasets?.CUSTOMER_CONFIGURATION],
    queryFn: () =>
      getEnrichmentConfig({
        templateInfo: rootAssetConfig?.templates!,
        dataSetId: datasets?.CUSTOMER_CONFIGURATION,
      }),
    enabled: !!rootAssetConfig?.templates && !!datasets?.CUSTOMER_CONFIGURATION,
  });

  const referenceCapacityType = useMemo(() => {
    return (
      config?.capacities?.find(({ isReference }) => isReference)?.type || ''
    );
  }, [config]);

  const wellListItems: WellListItem[] = useMemo(() => {
    return map(wells, (it) => {
      const [productAverage] = it?.averages || [];
      const allCapacities: Capacity[] =
        productAverage?.capacities?.map((capacity) => {
          return {
            ...(capacity?.average
              .filter(
                (it) => it.daysAsString === predefinedTimerange.toString()
              )
              .map((average) => convert(average))[0] || [
              { value: 0, unit: '' },
            ]),
            type: capacity.type,
          };
        }) || [];

      const referenceCapacity = {
        ...(productAverage?.capacities
          .find(({ type }) => type === referenceCapacityType)
          ?.average.filter(
            (it) => it.daysAsString === predefinedTimerange.toString()
          )
          .map((average) => convert(average))[0] || { value: 0, unit: '' }),
        type: referenceCapacityType,
      };

      const [productionAverage] = productAverage?.production
        .filter((it) => it.daysAsString === predefinedTimerange.toString())
        .map(convert) || [{ value: 0, unit: '' }];
      return new WellListItem({
        id: it?.wellId!,
        externalId: it?.wellExternalId || '',
        type: productAverage?.type || '',
        mainProduct: it?.mainProduct || '',
        name: it?.name || '',
        deviations:
          it?.deviations.filter((it) => {
            if (!it.endDate) {
              return true;
            }
            return it.endDate > startOfPeriod;
          }) || [],
        productionAverage,
        capacities: allCapacities,
        referenceCapacity,
      });
    });
  }, [
    wells,
    referenceCapacityType,
    convert,
    predefinedTimerange,
    startOfPeriod,
  ]);

  const filteredWells = useMemo(
    () =>
      wellListItems
        .filter(
          (item) =>
            wellTypeFilters.length === 0 ||
            wellTypeFilters.includes(item.mainProduct)
        )
        .map((item) => {
          if (!isDeviationsFiltered(deviationsFilter)) {
            return item;
          }

          const filteredDeviations = item.deviations.filter((dev) =>
            filterEvents(getSummaryFilterProps(dev))
          );

          if (filteredDeviations.length > 0) {
            return new WellListItem({
              ...item,
              deviations: filteredDeviations,
            });
          }
          return null;
        })
        .filter(Boolean) as WellListItem[],
    [wellListItems, wellTypeFilters, deviationsFilter, filterEvents]
  );

  const sortedWells = useMemo(() => {
    if (sort) {
      const copiedWells = [...filteredWells];

      const sortOrder = sort.order === 'DESC' ? 1 : -1;

      if (sort.column === 'name') {
        copiedWells.sort(
          (well1, well2) =>
            sortOrder *
            well2.name.localeCompare(well1.name, undefined, { numeric: true })
        );
        return copiedWells;
      }

      if (sort.column === 'wellType') {
        copiedWells.sort(
          (well1, well2) => sortOrder * well2.type.localeCompare(well1.type)
        );
        return copiedWells;
      }

      const groupedWellsByType = groupBy(
        copiedWells,
        (well) =>
          well.type === PRODUCT_TYPE_WATER_INJECTION ? 'Water' : 'Others' // TODO(BEST-1501): Remove this hardcoding
      );

      const findCapacity = (well: WellListItem) =>
        well.capacities.find(({ type }) => `well${type}` === sort.column);
      const sortByCapacity = (
        well1: WellListItem,
        well2: WellListItem
      ): number => {
        return (
          (findCapacity(well2)?.value ?? 0) - (findCapacity(well1)?.value ?? 0)
        );
      };

      const isSortingCapacity = !!config?.capacities
        ?.map(({ type }) => `well${type}`)
        .includes(sort.column);

      return [
        groupedWellsByType.Others ?? [],
        groupedWellsByType.Water ?? [],
      ].reduce(
        (acc, groupedWells) =>
          acc.concat(
            groupedWells.sort(
              (well1, well2) =>
                sortOrder *
                (isSortingCapacity
                  ? sortByCapacity(well1, well2)
                  : (well2[sort.column as WellListItemSortColumn]?.value ?? 0) -
                    (well1[sort.column as WellListItemSortColumn]?.value ?? 0))
            )
          ),
        [] as WellListItem[]
      );
    }
    return filteredWells;
  }, [config, filteredWells, sort]);

  return {
    referenceCapacityType,
    capacitiesTypes: config?.capacities,
    wellListItems: sortedWells,
    loading,
  };
};
