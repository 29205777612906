import React from 'react';
import { Favorite } from 'utils/models/collections';
import { Icon, Button, Flex } from '@cognite/cogs.js';

type FavoriteListItemProps = {
  onRemove: Function;
  favorite: Favorite;
};

const FavoriteListItem = ({ onRemove, favorite }: FavoriteListItemProps) => {
  return (
    <Flex justifyContent="space-between" alignItems="center">
      <div>{favorite.name}</div>
      <Button
        type="ghost"
        onClick={() => {
          onRemove(favorite);
        }}
      >
        <Icon type="Close" />
      </Button>
    </Flex>
  );
};

export default FavoriteListItem;
