import { CogniteEvent } from '@cognite/sdk';
import { Observations } from './types';

export const normalizeObservation = ({
  data,
}: {
  data: CogniteEvent;
}): Observations => {
  const startDate = new Date(data?.startTime!);
  const endDate = new Date(data?.endTime!);
  return {
    ...data,
    externalId: data?.externalId!,
    dateRange: [+startDate, +endDate],
    assetExternalId: data?.metadata?.assetExternalId,
    metadata: data.metadata,
    startDate: startDate.toDateString(),
    endDate: endDate.toDateString(),
  } as Observations;
};
