import React, { MouseEvent, useMemo, useState } from 'react';
import { useTranslation, withI18nSuspense, Trans } from '@cognite/react-i18n';
import { Tree } from 'antd';
import { Body, Button, Icon, Title, toast } from '@cognite/cogs.js';
import CollectionsModal from 'components/CollectionsModal/CollectionsModal';
import { getCollections } from 'features/collections/selectors';
import { useSelector } from 'react-redux';
import useNavigation from 'utils/useNavigation';
import createToastOptions from 'utils/toasts/createToastOptions';
import UnstyledLink from 'components/accessibility/UnstyledLink';
import { Pages } from 'utils/models/enums';
import { useCollections } from 'features/collections';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import {
  CollectionWrapper,
  StyledCollectionRow,
  TreeWrapper,
} from './elements';
import CollectionRow from './CollectionRow';

const CollectionTree = () => {
  const [isCollectionsModalOpen, setIsCollectionsModalOpen] = useState(false);
  const [isEditCollectionsModalOpen, setIsEditCollectionsModalOpen] = useState<
    string | undefined
  >();

  const { t } = useTranslation('CollectionsTree');
  const { selectedWell } = useCurrentAsset();
  const { isCollectionPage } = useNavigation();
  const { removeCollection, activeCollection } = useCollections();
  const collections = useSelector(getCollections);
  const collectionsTitle = t('CollectionsRootTitle', {
    defaultValue: 'Collections',
  });

  const selectedKeys: string[] = useMemo(() => {
    if (isCollectionPage && activeCollection) {
      return [activeCollection.key];
    }
    return collections
      .filter((collection) => {
        return collection.favorites.some(
          (favorite) => favorite.externalId === selectedWell?.externalId
        );
      })
      .map((collection) => `${collection.key}-${selectedWell?.externalId}`);
  }, [
    activeCollection,
    collections,
    isCollectionPage,
    selectedWell?.externalId,
  ]);

  const [expandedKeys, setExpandedKeys] = useState(
    isCollectionPage ? [0, ...selectedKeys] : []
  );

  const treeModel = useMemo(
    () =>
      collections.map((collection) => ({
        title: (
          <CollectionRow
            collection={collection}
            removeCollection={removeCollection}
            setIsCollectionsModalOpen={setIsEditCollectionsModalOpen}
          >
            {isEditCollectionsModalOpen === collection.key && (
              <CollectionsModal
                collection={collection}
                onClose={() => setIsEditCollectionsModalOpen(undefined)}
              />
            )}
          </CollectionRow>
        ),
        key: collection.key,
        children:
          collection.favorites && collection.favorites.length
            ? collection.favorites.map(({ externalId: key, name: title }) => ({
                key: `${collection.key}-${key}`,
                title: (
                  <UnstyledLink
                    to={`/assets/${encodeURIComponent(key)}/${Pages.DeepDive}`}
                    style={{
                      width: '100%',
                      display: 'inline-block',
                    }}
                  >
                    {title}
                  </UnstyledLink>
                ),
              }))
            : undefined,
      })),
    [collections, isEditCollectionsModalOpen, removeCollection]
  );

  return (
    <CollectionWrapper direction="column">
      <StyledCollectionRow>
        <Title level={6}>{collectionsTitle}</Title>
        <Button
          size="small"
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            setIsCollectionsModalOpen(true);
          }}
          style={{ padding: '4px 6px' }}
        >
          <Icon type="Add" />
        </Button>
      </StyledCollectionRow>
      <TreeWrapper>
        {treeModel.length > 0 ? (
          <Tree
            treeData={treeModel}
            selectedKeys={selectedKeys}
            expandedKeys={expandedKeys}
            onExpand={setExpandedKeys}
            switcherIcon={<Icon type="CaretDown" />}
          />
        ) : (
          <Body level={2}>
            <Trans t={t} i18nKey="no-static-collections-message">
              You don’t have any static collections. <br />
              Use the + button above to create one
            </Trans>
          </Body>
        )}
      </TreeWrapper>
      {isCollectionsModalOpen && (
        <CollectionsModal
          onClose={(isSuccessful: boolean, name: string) => {
            setIsCollectionsModalOpen(false);
            if (isSuccessful && name) {
              toast.success(
                <Body level={2}>
                  <Trans t={t} i18nKey="toast-created">
                    The collection <strong>{{ name }}</strong> was created
                  </Trans>
                </Body>,
                createToastOptions()
              );
            }
          }}
        />
      )}
    </CollectionWrapper>
  );
};

export default withI18nSuspense(CollectionTree);
