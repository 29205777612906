import { Deferment, DefermentStatus, DefermentType } from 'utils/models';

export const DEFERMENT_TYPES: DefermentType[] = ['actual', 'future'];
export const DEFERMENT_STATUSES: DefermentStatus[] = ['planned', 'unplanned'];

export const applyUnitConversionToDeferments = (
  deferments: Deferment[],
  convert?: Function,
  preferredUnit?: string
): Deferment[] => {
  const convertFunction = convert;
  if (convertFunction) {
    return deferments.map((deferment) => {
      return {
        ...deferment,
        volumes: deferment.volumes.map((volume) => {
          const convertedVolume = convertFunction({
            product: volume.product,
            value: volume.value,
            sourceUnit: volume.unit,
            targetUnit: preferredUnit,
            skipRateConversion: true,
          });
          return {
            ...volume,
            unit: convertedVolume?.convertedUnit || volume.unit,
            value: convertedVolume?.value || volume.value,
          };
        }),
      };
    });
  }
  return deferments;
};
