import styled from 'styled-components/macro';
import { Menu, Button, Flex } from '@cognite/cogs.js';

export const StyledCollectionRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const TreeButton = styled(Button)`
  text-align: left;
  padding: 0 !important;
  justify-content: flex-start;

  &:hover {
    background: none;
  }
`;

const ellipsisButtonWidth = 32;

export const CollectionWrapper = styled(Flex)`
  padding: 16px 0 16px 16px;
`;

export const StyledCollectionTreeRow = styled.div`
  display: grid;
  grid-template-columns: 1fr ${ellipsisButtonWidth}px;
  width: 100%;

  .edit-collections-button {
    visibility: hidden;
  }

  &:hover {
    .edit-collections-button {
      visibility: visible;
    }
  }
`;

export const StyledDropdownWrapper = styled.div`
  width: ${ellipsisButtonWidth}px;
`;

export const StyledDeleteCollectionRow = styled(StyledCollectionRow)`
  color: var(--cogs-text-danger);

  button {
    color: var(--cogs-text-danger);
  }
`;

export const TreeWrapper = styled.div`
  padding: 26px 0 0 0; /* Needed to align tree to other elements in assetnavigator */

  .ant-tree {
    background: none;

    /* Tweaks on the node item */
    .ant-tree-treenode {
      width: 100%;
      padding: 6px;
      border-radius: 4px;
    }

    span[title] {
      display: block;
      width: 100%;
    }

    /* Vertically align tree arrows to the center */
    span.ant-tree-switcher.ant-tree-switcher_close,
    span.ant-tree-switcher.ant-tree-switcher_open {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    /* Change selected node's background color */
    .ant-tree-treenode.ant-tree-treenode-selected,
    .ant-tree-node-content-wrapper.ant-tree-node-selected {
      background: var(--cogs-midblue-6);
    }
  }
`;

export const StyledMenu = styled(Menu)`
  padding: 16px;
`;

export const StyledEditButton = styled(Button)`
  margin-bottom: 8px;
`;

export const StyledDeleteButton = styled(Button)`
  margin-top: 8px;
`;
