import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { EventFilter, IdEither } from '@cognite/sdk';
import { PerfMetrics } from '@cognite/metrics';
import { reportException } from '@cognite/react-errors';
import { RootState } from 'store';
import find from 'lodash/find';
import { useProjectContext } from 'containers/AuthContainer';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useComments } from 'features/comments';
import { useCollections } from 'features/collections';
import { useTimeRange } from 'features/timeRange';
import { useDatasets } from 'features/datasets';
import {
  Observations,
  createObservation,
  normalizeObservation,
  updateObservation,
} from 'utils/models/observations';
import { NewComment, NotificationDetails } from 'utils/models/comments';
import { METRICS } from 'utils/metrics/enums';
import { sleep } from 'utils/sleep';
import { deleteEvents, getObservations } from 'hooks/useOperationClient';
import useChartSelectionState from 'components/Chart/hooks/useChartSelectionState';
import { CommentsState } from 'features/comments/reducer';
import { useToast } from 'features/toast';
import observationsGroupsSlice, { ObservationsState } from './reducer';

type HandleObservation = {
  comment: NewComment;
  datasetExternalId: string;
  notificationDetails: NotificationDetails;
  observation?: Observations;
};

let lastObservationsFetchIdx: number = 0;

export const useObservations = (externalId = '') => {
  const { observations, loading, error, isCreating } = useSelector<
    RootState,
    ObservationsState
  >((state) => {
    return state.observations;
  });
  const commentsState = useSelector<RootState, CommentsState>((state) => {
    return state.comments;
  });
  const dispatch = useDispatch();
  const { project } = useProjectContext();
  const { selectedAsset } = useCurrentAsset();
  const { selectedTimerange } = useChartSelectionState();
  const { getCurrentCollection } = useCollections();
  const { getDataset } = useDatasets();
  const { start, end } = useTimeRange();
  const { setToastAction } = useToast();
  const { createComment } = useComments({
    resourceType: 'event',
    externalId,
  });
  const create = (isCreating: boolean) =>
    dispatch(observationsGroupsSlice.actions.toggleCreate({ isCreating }));
  const handleObservation = async ({
    comment,
    datasetExternalId,
    notificationDetails,
    observation,
  }: HandleObservation) => {
    PerfMetrics.trackPerfStart(METRICS.ObservationsCreation);
    const { dateRange, ...event } = comment;
    const [startTime, endTime] = dateRange!;
    await (observation?.externalId
      ? updateObservation({
          ...observation,
        })
      : createObservation({
          assetExternalId: selectedAsset?.externalId!,
          startTime,
          endTime,
          project,
          dataSetId: getDataset('OBSERVATION'),
        })
    )
      .then((newObservation) => {
        dispatch(observationsGroupsSlice.actions.create({ newObservation }));
        createComment(
          event,
          datasetExternalId,
          notificationDetails,
          newObservation.externalId
        );
        PerfMetrics.trackPerfEnd(METRICS.ObservationsCreation);
        PerfMetrics.logSuccessEvent(METRICS.ObservationsCreation);
        setToastAction();
      })
      .catch((e) => {
        PerfMetrics.logFailureEvent(METRICS.ObservationsCreation);
        throw e;
      });
  };
  const handleObservations = useCallback(
    ({
      externalIds = selectedAsset?.externalId
        ? [selectedAsset.externalId || '']
        : getCurrentCollection()?.favorites?.map((fav) => fav.externalId) || [],
      delayedFetching = false,
    }: {
      externalIds?: string[];
      alternativeUnit?: string;
      delayedFetching?: boolean;
    } = {}) => {
      let canceled = false;
      const cancel = () => {
        canceled = true;
      };
      const filter: EventFilter = {
        assetExternalIds: externalIds,
        activeAtTime: {
          min: start,
          max: end,
        },
      };
      if (
        !externalIds ||
        externalIds?.length === 0 ||
        externalIds?.every((e) => !e)
      ) {
        dispatch(
          observationsGroupsSlice.actions.getObservationsSuccess({
            loading: false,
            observations: [],
          })
        );
        return cancel;
      }

      filter.assetExternalIds = externalIds;

      dispatch(
        observationsGroupsSlice.actions.getObservations({
          loading: true,
        })
      );
      sleep(delayedFetching ? 2500 : 0).then(() => {
        lastObservationsFetchIdx += 1;
        const myObservationsFetchIdx = lastObservationsFetchIdx;
        getObservations(filter)
          .then((observations) => {
            if (canceled) {
              return;
            }

            if (myObservationsFetchIdx !== lastObservationsFetchIdx) {
              return;
            }
            dispatch(
              observationsGroupsSlice.actions.getObservationsSuccess({
                loading: false,
                observations: observations.map((data) =>
                  normalizeObservation({ data })
                ),
              })
            );
          })
          .catch((ex) => {
            reportException(ex);
            dispatch(
              observationsGroupsSlice.actions.getObservationsError({
                loading: false,
                error: ex,
              })
            );
          });
      });
      return cancel;
    },
    [dispatch, end, getCurrentCollection, selectedAsset?.externalId, start]
  );
  const commentingIsDisabled = useMemo<Observations | undefined>(() => {
    return selectedTimerange?.length
      ? (find(observations, {
          dateRange: [
            selectedTimerange[0],
            selectedTimerange[1] ?? selectedTimerange[0],
          ],
          metadata: {
            assetExternalId: selectedAsset?.externalId,
          },
        }) as Observations)
      : undefined;
  }, [observations, selectedAsset?.externalId, selectedTimerange]);
  const removeEvent = () => {
    deleteEvents(
      observations
        .map(({ externalId }) =>
          Object.keys(commentsState)
            .map((key) => {
              if (key.match(externalId)) {
                return commentsState[key].map(({ externalId }) => externalId);
              }
              return undefined;
            })
            .flat()
            .filter((v) => v)
            .concat([externalId])
            .map((externalId) => ({ externalId }))
        )
        .flat() as IdEither[]
    );
  };
  return {
    observations,
    loading,
    error,
    isCreating,
    commentingIsDisabled,
    handleObservation,
    getObservations: handleObservations,
    removeEvent,
    create,
  };
};
