/* eslint-disable no-param-reassign */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReportedError } from '@cognite/react-errors';

import {
  Advisor,
  SelectedAdvisor,
  AdvisorView,
} from 'utils/models/advisors/types';

type InitialState = {
  advisors: Advisor[];
  advisorView: AdvisorView;
  selectedAdvisor?: SelectedAdvisor;
  error: ReportedError | null;
  loading: boolean;
  removing: boolean;
};

type AdvisorsLoaded = {
  advisors: Advisor[];
};

const advisorsSlice = createSlice({
  name: 'advisors',
  initialState: {
    advisors: [],
    advisorView: 'list',
    error: null,
    loading: false,
    removing: false,
  } as InitialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    getAdvisors: (state, action: PayloadAction<AdvisorsLoaded>) => {
      state.advisors = action.payload.advisors;
      state.loading = false;
    },

    addNewAdvisor: (state, action: PayloadAction<Advisor>) => {
      state.advisors.push(action.payload);
      state.loading = false;
    },

    updateAdvisor: (state, action: PayloadAction<Advisor>) => {
      const index = state.advisors.findIndex(
        (advisor) => advisor.externalId === action.payload.externalId
      );

      if (index !== -1) {
        state.advisors[index] = action.payload;
      }
      state.loading = false;
    },

    removeAdvisorStart: (state) => {
      state.removing = true;
    },

    removeAdvisorSuccess: (state, action: PayloadAction<Advisor>) => {
      state.advisors = state.advisors.filter(
        (advisor) => advisor.externalId !== action.payload.externalId
      );
      state.removing = false;
    },

    removeAdvisorError: (state) => {
      state.removing = false;
    },

    setAdvisorView: (state, action: PayloadAction<AdvisorView>) => {
      state.advisorView = action.payload;
    },

    setSelectedAdvisor: (
      state,
      action: PayloadAction<SelectedAdvisor | undefined>
    ) => {
      state.selectedAdvisor = action.payload;
    },

    setError: (state, action: PayloadAction<ReportedError | null>) => {
      state.error = action.payload;
      state.loading = false;
    },
  },
});

export const {
  setLoading,
  getAdvisors,
  addNewAdvisor,
  updateAdvisor,
  removeAdvisorStart,
  removeAdvisorSuccess,
  removeAdvisorError,
  setAdvisorView,
  setSelectedAdvisor,
  setError,
} = advisorsSlice.actions;

export type AdvisorsState = ReturnType<typeof advisorsSlice.reducer>;

export default advisorsSlice;
