/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type GroupEventTab = 'classification' | 'activity';

const groupEventTabSlice = createSlice({
  name: 'groupEventTab',
  initialState: {} as Record<string, GroupEventTab>,
  reducers: {
    setTab: (
      state,
      {
        payload: { selectedTab, deviationExternalId },
      }: PayloadAction<{
        selectedTab: GroupEventTab;
        deviationExternalId: string;
      }>
    ) => {
      state[deviationExternalId] = selectedTab;
    },
  },
});

export type GroupEventTabState = ReturnType<typeof groupEventTabSlice.reducer>;

export default groupEventTabSlice;
