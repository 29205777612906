import React from 'react';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { formatDateAs } from 'utils/datetime';

export type Props = { lastUpdatedTime: Date };

const { humanizedTime } = formatDateAs;
const LastUpdatedOn = ({ lastUpdatedTime }: Props) => {
  const { t } = useTranslation('LastUpdatedOn');
  return (
    <>
      <Trans t={t} i18nKey="last-changed">
        Last Changed
      </Trans>
      : {humanizedTime(lastUpdatedTime)}
    </>
  );
};

export default withI18nSuspense(LastUpdatedOn);
