import React, { useMemo, useState } from 'react';
import {
  TopBar as CogsTopBar,
  Avatar,
  Title,
  TopBarActionProps,
  NotificationDot,
  Dropdown,
  Icon,
  Flex,
  Tooltip,
} from '@cognite/cogs.js';
import { useTranslation, withI18nSuspense, Trans } from '@cognite/react-i18n';
import { SIDECAR } from 'utils/sidecar';

import ExportModal from 'components/ExportModal';
import { useAuth } from 'features/auth';
import useNavigation from 'utils/useNavigation';
import { Divider } from 'components/Divider';
import { Pages } from 'utils/models/enums';
import { DownloadNotification } from 'features/export';
import { useWellListItems } from 'utils/models/wellListItems';
import { StyledExportButton } from './elements';
import SupportMenu from './SupportMenu';
import ProfileMenu from './ProfileMenu/ProfileMenu';
import { getCustomerLogo } from './CustomerLogo';
import { LogoImage } from './Logo/elements';
import AssetSelector from './AssetSelector';
import NotificationMenu from './NotificationMenu/NotificationMenu';
import { useNotifications } from './NotificationMenu/hook';

export const TOP_BAR_HEIGHT = 56;

const TopBar = () => {
  const { t } = useTranslation('TopBar');
  const { privacyPolicyUrl } = SIDECAR;
  const { currentPage, ...navigation } = useNavigation();
  const { user, project } = useAuth();
  const { loading } = useWellListItems();
  const [exporting, setExporting] = useState(false);

  const { count } = useNotifications();
  const goBackFromUserPage = () => {
    // POP here, in this case, means that there's no page to go back because the user
    // profile page was accessed via browser's url bar. So just redicect to the index page
    if (navigation.action === 'POP') {
      navigation.navigate({ page: Pages.KPI });
    } else {
      navigation.goBack();
    }
  };

  const CustomerLogo = getCustomerLogo(project);

  const [notificationMenuInstance, setNotificationMenuInstance] =
    useState<{ hide: () => void }>();

  const actions = [
    {
      key: 'notifications',
      icon: 'Bell',
      component: React.useMemo(
        () => (
          <Dropdown
            onCreate={setNotificationMenuInstance}
            disabled={count === 0}
            content={
              <NotificationMenu
                onClose={() => notificationMenuInstance?.hide()}
              />
            }
          >
            <Flex style={{ padding: 20 }}>
              <NotificationDot value={count} hidden={!count}>
                <Icon type="Bell" />
              </NotificationDot>
            </Flex>
          </Dropdown>
        ),
        [count, notificationMenuInstance]
      ),
      name: t('notification-menu_tooltip', { defaultValue: 'Notifications' }),
    },
    {
      key: 'support',
      icon: 'Help',
      name: t('support-menu_tooltip', { defaultValue: 'Help' }),
      menu: React.useMemo(() => <SupportMenu />, []),
    },
    {
      key: 'profile',
      component: <Avatar text={user} />,
      name: t('profile-menu_tooltip', { defaultValue: 'Profile' }),
      menu: <ProfileMenu privacyPolicyUrl={privacyPolicyUrl} />,
    },
  ].filter(Boolean);
  const disabledExporting = useMemo(
    () => (currentPage === Pages.Wells && loading ? true : exporting),
    [currentPage, exporting, loading]
  );
  const exportTitle = (
    <Title level={6}>
      <Trans t={t} i18nKey="export_button">
        Export
      </Trans>
    </Title>
  );
  return (
    <>
      <DownloadNotification />
      <CogsTopBar>
        <CogsTopBar.Left>
          {currentPage === Pages.User && (
            <CogsTopBar.Action
              icon="ArrowLeft"
              onClick={goBackFromUserPage}
              title={t('Back_header', { defaultValue: 'Back' })}
            />
          )}
          <CogsTopBar.Logo
            logo={<LogoImage />}
            title="Cognite BestDay"
            subtitle={<AssetSelector />}
          />
        </CogsTopBar.Left>
        <CogsTopBar.Right>
          <Divider />
          <StyledExportButton
            type="ghost"
            icon="Download"
            iconPlacement="right"
            onClick={() => setExporting(true)}
            disabled={disabledExporting}
          >
            {disabledExporting ? (
              <Tooltip content="Loadig! Please wait …." placement="bottom">
                {exportTitle}
              </Tooltip>
            ) : (
              exportTitle
            )}
          </StyledExportButton>

          <CogsTopBar.Actions actions={actions as TopBarActionProps[]} />
          {CustomerLogo && (
            <CogsTopBar.Item
              style={{
                width: 72,
                justifyContent: 'center',
                borderLeft: 'none',
              }}
            >
              <CustomerLogo />
            </CogsTopBar.Item>
          )}
        </CogsTopBar.Right>
      </CogsTopBar>
      {exporting && <ExportModal onClose={() => setExporting(false)} />}
    </>
  );
};

export default withI18nSuspense(TopBar);
