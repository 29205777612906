import React from 'react';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { Body, Detail, Flex } from '@cognite/cogs.js';
import styled from 'styled-components/macro';
import ValueTooltip from 'components/ValueTooltip';
import { asDecimal } from 'utils/numbers';
import { Divider } from 'components/Divider';
import { ProductVolumeUnitTrans } from 'components/Translations';

export type Props = {
  productList: {
    product: string;
    value: number;
    unit: string;
  }[];
  total?: number;
};

const ProductList = ({ productList, total }: Props) => {
  const { t } = useTranslation('Sidebar');
  const { t: tProducts } = useTranslation('Products');
  const { t: tUnits } = useTranslation('Units');

  const renderProduct = (product: string, volume: number, unit: string) => {
    if (!volume || !unit) {
      return null;
    }
    const translatedProduct = tProducts(`${product}-product`, {
      defaultValue: product,
    });
    const translatedUnit = tUnits(`${unit}-unit`, { defaultValue: unit });
    const formattedVolume = (
      <ValueTooltip content={volume}>
        <>{asDecimal(volume, { maxLength: 5, precision: 2 })}</>
      </ValueTooltip>
    );
    return (
      <Product key={product}>
        <LightBodyWithMargin level={3} strong>
          {translatedProduct}
        </LightBodyWithMargin>
        <LightBody level={3}>
          {formattedVolume} {translatedUnit}
        </LightBody>
        <Separator>
          <Divider height={16} style={{ color: '#D9D9D9' }} />
        </Separator>
      </Product>
    );
  };

  return (
    <ProductListContainer>
      {!!total && (
        <>
          <BodyWithMargin level={3} strong>
            <Trans t={t} i18nKey="total-volume_label">
              Total
            </Trans>
          </BodyWithMargin>
          <Body level={3}>
            <ProductVolumeUnitTrans
              unit="BOE"
              volume={total}
              unitWithBrackets={false}
              showTooltip
            />
          </Body>
          <Separator>
            <Divider height={16} style={{ color: '#D9D9D9' }} />
          </Separator>
        </>
      )}
      {productList.map(({ product, value, unit }) =>
        renderProduct(product, value, unit)
      )}
    </ProductListContainer>
  );
};

const LightBody = styled(Body)`
  color: var(--cogs-greyscale-grey6);
`;

const LightBodyWithMargin = styled(LightBody)`
  margin-right: 4px;
`;

const BodyWithMargin = styled(Body)`
  margin-right: 4px;
`;

const Separator = styled(Detail)`
  margin: 0px 8px;
`;

const Product = styled(Flex)``;
const ProductListContainer = styled(Flex)`
  flex-wrap: wrap;
  ${Product}:last-child {
    ${Separator} {
      display: none;
    }
  }
`;

export default withI18nSuspense(ProductList);
