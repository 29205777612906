import { DataSet } from '@cognite/sdk';
import { DataSetMapping } from 'hooks/types';
import { DatasetType } from '.';

export const createDatasets = (
  datasets: DataSet[],
  datasetMapping: DataSetMapping
) =>
  datasets.reduce((acc, dataset) => {
    const { externalId, id } = dataset;
    if (!externalId || !id) {
      return acc;
    }

    const key = (Object.keys(datasetMapping) as DatasetType[]).find(
      (e) => datasetMapping[e] === externalId
    );

    if (!key) {
      return acc;
    }

    return { ...acc, [key]: id };
  }, {});
