import React from 'react';
import { Title, Icon, Button } from '@cognite/cogs.js';
import { useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { useMetrics } from '@cognite/metrics';
import { usePreferences } from 'features/preferences';
import { AssetHeaderContainer } from './elements';

const AssetHeaderBar = () => {
  const { t } = useTranslation();
  const {
    preferences: {
      layout: { showAssetNavigation },
    },
    setPreferences,
  } = usePreferences();
  const metrics = useMetrics('AssetHeaderBar');
  const title = t('asset-header-title', { defaultValue: 'System selector' });

  const onClose = () => {
    metrics.track('Hide');
    setPreferences({ layout: { showAssetNavigation: !showAssetNavigation } });
  };

  return (
    <AssetHeaderContainer>
      <Title level={5}>{title}</Title>
      <Button type="ghost" onClick={onClose}>
        <Icon type="Close" />
      </Button>
    </AssetHeaderContainer>
  );
};

export default withI18nSuspense(AssetHeaderBar);
