export const getTenant = () => {
  const { pathname } = window.location;
  if (!pathname) {
    return '';
  }
  const match = pathname.match(/^\/([a-z0-9-]+)\/?/);
  if (!match) {
    return '';
  }
  return match[1];
};
