import { getClient } from 'utils/cognitesdk';
import { SIDECAR } from 'utils/sidecar';
import { Observations } from './types';
import { normalizeObservation } from './utils';

const { bestdayApiBaseUrl } = SIDECAR;
export const OBSERVATIONS_ENDPOINT = new URL('observations', bestdayApiBaseUrl)
  .href;

type ObservationsRequest = {
  project: string;
  assetExternalId: string;
  startTime: number;
  endTime: number;
  dataSetId: number;
};
export const createObservation = async ({
  assetExternalId,
  startTime,
  endTime,
  project,
  dataSetId,
}: ObservationsRequest): Promise<Observations> =>
  getClient()
    .post<Observations>(OBSERVATIONS_ENDPOINT, {
      withCredentials: true,
      data: {
        assetExternalId,
        startTime,
        endTime,
        dataSetId,
      },
      headers: {
        'x-cdp-project': project,
      },
    })
    .then(normalizeObservation);
export const updateObservation = async (
  observation: Observations
): Promise<Observations> => {
  // TBD if needed
  // eslint-disable-next-line no-console
  console.log(observation);
  return Promise.reject();
};
