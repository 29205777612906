import { useCallback, useEffect, useMemo, useState } from 'react';
import { reportException } from '@cognite/react-errors';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { DataNode, EventDataNode } from 'antd/lib/tree';
import { Key } from 'antd/lib/table/interface';
import map from 'lodash/map';
import difference from 'lodash/difference';
import flatMapDeep from 'lodash/flatMapDeep';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import some from 'lodash/some';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { getSystemTree } from 'hooks/useGraphQlQuery';
import useConfig from 'hooks/useConfig';
import {
  getLoadedKeysFromDataNodes,
  mapSystemTreeToDataNodes,
} from 'utils/systemSelector';
import { getLabel } from 'utils/labels';
import { getClient } from 'utils/cognitesdk';
import {
  getPathFromRelationships,
  DataNodeWithExId,
  updateTreeData,
} from 'components/SystemTree';
import isEqual from 'lodash/isEqual';
import assetTreeSlice, {
  selectExpendedKeysWithWells,
  selectTreeDataWithWells,
} from './reducer';
import { selectLoadedKeysWithWells } from '.';

const ASSET_FETCH_DEPTH = 4;

const loading: Record<Key, true> = {};

type AssteTree = { title: string; children: unknown[]; externalId: string };

const removeLoadingKey = (key: Key) => {
  delete loading[key];
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const findChildrenTitleRecursive: any = (
  { title, children, externalId }: AssteTree,
  response: string,
  befor: string,
  counter = 0
) => {
  if (externalId === response) {
    if (!counter) {
      return [title];
    }
    return [...difference(flatMapDeep(befor), [title, response]), title];
  }
  if (children) {
    return compact(
      map(children, (nextAssteTree: AssteTree) =>
        findChildrenTitleRecursive(
          nextAssteTree,
          response,
          befor ? [befor, title] : title,
          counter + 1
        )
      ).flat()
    );
  }
  return undefined;
};
export const useAssetTree = () => {
  const {
    selectedAsset,
    loading: loadingCurrentAsset,
    rootAsset,
  } = useCurrentAsset();
  const { rootAssetConfig } = useConfig();
  const treeData = useSelector(selectTreeDataWithWells);
  const loadedKeys = useSelector(selectLoadedKeysWithWells);
  const expandedKeys = useSelector(selectExpendedKeysWithWells);
  const dispatch = useDispatch();

  const [mergedWells, setMergedWells] = useState<DataNode[]>([]);

  const fetchNodeList = useCallback(
    async (
      oldTreeNode: EventDataNode | DataNodeWithExId
    ): Promise<DataNodeWithExId[]> => {
      if (
        !rootAsset?.externalId ||
        !rootAssetConfig?.templates ||
        !loadedKeys[rootAsset.externalId]
      ) {
        return [];
      }

      const treeNode = oldTreeNode as DataNodeWithExId;
      if (
        loadedKeys[rootAsset.externalId]?.includes(treeNode.key) ||
        loading[treeNode.key]
      ) {
        return [];
      }

      loading[treeNode.key] = true;

      try {
        const data = await getSystemTree({
          externalId: treeNode.externalId as string,
          templateInfo: rootAssetConfig.templates,
          depth: ASSET_FETCH_DEPTH,
        });

        if (!data?.[0]) return [];

        const assets = mapSystemTreeToDataNodes(data[0]).children || [];

        const updatedTreeData = updateTreeData(
          treeData[rootAsset.externalId],
          treeNode.key,
          assets
        );

        const subLevelsLoadedKeys = getLoadedKeysFromDataNodes(
          assets,
          ASSET_FETCH_DEPTH - 1
        );

        dispatch(
          assetTreeSlice.actions.setTreeData({
            treeData: {
              [rootAsset.externalId]: updatedTreeData,
            },
            loadedKeys: {
              [rootAsset.externalId]: [
                ...(loadedKeys[rootAsset.externalId] || []),
                ...subLevelsLoadedKeys,
              ],
            },
          })
        );
        return assets;
      } finally {
        removeLoadingKey(treeNode.key);
      }
    },
    [
      dispatch,
      loadedKeys,
      rootAsset?.externalId,
      rootAssetConfig?.templates,
      treeData,
    ]
  );
  const rootPathToResponse = useCallback(
    (externalId?: string) =>
      externalId
        ? flatMapDeep(
            compact(
              map(treeData[rootAsset?.externalId as string], (data) =>
                findChildrenTitleRecursive(data, externalId, externalId)
              ).filter((a) => a.length)
            )
          )
        : undefined,
    [rootAsset?.externalId, treeData]
  );
  const isWell = useCallback(
    (eId?: string) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isLeafRecursive = ({ children, externalId, isLeaf }: any): any => {
        if (externalId === eId) {
          return isLeaf;
        }
        return some(map(children, isLeafRecursive));
      };
      return some(
        map(treeData[rootAsset?.externalId as string], (data) =>
          isLeafRecursive(data)
        )
      );
    },
    [rootAsset?.externalId, treeData]
  );
  useEffect(() => {
    if (!rootAsset?.externalId || isEmpty(treeData)) {
      return;
    }
    const tempMergedWells: DataNode[] = [];

    const mergedItem = (item: DataNode) => {
      if (item.children) {
        tempMergedWells.push({ ...item, children: undefined });

        item.children.forEach((element: DataNode) => {
          mergedItem(element);
        });
      } else {
        tempMergedWells.push(item);
      }
    };
    // eslint-disable-next-line lodash/collection-method-value
    map(treeData[rootAsset.externalId], (item) => mergedItem(item));

    // adding rootAsset
    tempMergedWells.push({ title: rootAsset.name, key: rootAsset.id });

    if (!isEqual(mergedWells, tempMergedWells)) setMergedWells(tempMergedWells);
  }, [treeData, rootAsset, mergedWells]);
  const setexpandedKeys = (nextExpandedKeys: Key[]) => {
    dispatch(assetTreeSlice.actions.setExpandedKeys(nextExpandedKeys));
  };
  // Memoized callback function meant to be attached to Tree instances.
  const onLoadData = useCallback(
    async (oldTreeNode: EventDataNode | DataNodeWithExId) => {
      await fetchNodeList(oldTreeNode);
    },
    [fetchNodeList]
  );
  const { data: assetPath, isLoading } = useQuery(
    ['FetchAssetPath', selectedAsset?.externalId],
    async () => {
      if (!selectedAsset?.externalId) {
        return undefined;
      }
      const data = await getPathFromRelationships(
        getClient(),
        selectedAsset?.externalId,
        getLabel('BELONGS_TO').externalId,
        rootAsset?.externalId
      );
      return (data || []).filter((asset) =>
        asset.labels?.some((label) =>
          label.externalId.includes('BEST_DAY_NETWORK_LEVEL')
        )
      );
    },
    {
      onError: (err) => {
        reportException(err as Error);
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );
  const { lastAsset, restPath, expandedNamedKeys, expandedIddKeys } =
    useMemo(() => {
      const uniqueAssetPath = assetPath ? assetPath.filter(Boolean) : [];

      const [lastAsset] = uniqueAssetPath.slice(
        uniqueAssetPath.length - 1,
        uniqueAssetPath.length
      );

      const restPath = uniqueAssetPath.slice(0, uniqueAssetPath.length - 1);
      return {
        lastAsset,
        restPath,
        expandedNamedKeys: map(uniqueAssetPath, 'name'),
        expandedIddKeys: map(uniqueAssetPath, 'id'),
      };
    }, [assetPath]);

  return {
    onLoadData,
    fetchNodeList,
    treeData,
    mergedWells,
    expandedKeys,
    rootPathToResponse,
    isWell,
    setexpandedKeys,
    lastAsset,
    restPath,
    loadingCurrentPath: loadingCurrentAsset || isLoading,
    assetPath,
    expandedNamedKeys,
    expandedIddKeys,
  };
};
