import getOS from './getOS';

const getErrorMode = () => {
  const mode = process.env.REACT_APP_ERROR_WATCHER_MODE;
  switch (mode) {
    case 'off':
    case 'flash':
    case 'break':
    case 'fatal':
    case '':
      return mode;
    default:
      return '';
  }
};

export default {
  env: (process.env.REACT_APP_ENV || 'development') as
    | 'development'
    | 'staging'
    | 'preview'
    | 'production',
  locizeProjectId: process.env.REACT_APP_LOCIZE_PROJECT_ID || '',
  locizeLanguageVersion: 'latest',
  errors: {
    mode: getErrorMode() || 'flash',
    strict: process.env.REACT_APP_ERROR_WATCHER_STRICT === 'true',
  },
  os: getOS(),
} as const;
