import { SIDECAR } from 'utils/sidecar';
import { getClient } from 'utils/cognitesdk';
import { Deferment } from './types';

const { bestdayApiBaseUrl } = SIDECAR;
export const DEFERMENTS_ENDPOINT = new URL('deferments', bestdayApiBaseUrl)
  .href;

type UpdateParams = {
  base: Deferment;
  patch: Partial<Deferment>;
  project: string;
  datasetExternalId: string;
};
export const updateDeferment = async ({
  base,
  patch,
  project,
  datasetExternalId,
}: UpdateParams) => {
  const response = await getClient().put<Deferment>(DEFERMENTS_ENDPOINT, {
    withCredentials: true,
    data: { base, patch, datasetExternalId },
    headers: {
      'x-cdp-project': project,
    },
  });
  return response.data;
};
