import styled from 'styled-components/macro';
import { Link } from 'react-router-dom';
import { A } from '@cognite/cogs.js';

const UnstyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: inherit;
  }
`;

export const UnstyledA = styled(A)`
  text-decoration: none;
  color: inherit;
`;

export default UnstyledLink;
