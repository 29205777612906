import { useTranslation } from '@cognite/react-i18n';
import { STATUS_ICONS } from 'components/Sidebar/DeviationsPanel/DeviationPanelCard';
import { DeviationsFilter, FilterKey } from 'features/preferences/filter';
import { useCallback } from 'react';
import { durationChipTitle, useFilterChips } from 'utils/filter';

export type Props = {
  setDeviationsFilter: (filter: Partial<DeviationsFilter>) => void;
};

function useDeviationFilterChips({ setDeviationsFilter }: Props) {
  const { t: tEventStatus } = useTranslation('EventStatus');
  const { t: tUnits } = useTranslation('Units');
  const { volumeChipTitle } = useFilterChips();
  const { t: tFilters } = useTranslation('Filters');

  const getChipsData = useCallback(
    (filters: DeviationsFilter) => {
      const statusChips = filters.status.map((f) => ({
        filterKey: 'status' as FilterKey,
        title: tEventStatus(`${f}-status`, { defaultValue: f }),
        value: f,
        icon: STATUS_ICONS[f],
      }));

      const typeChips = filters.type.map((f) => ({
        filterKey: 'type' as FilterKey,
        title: tEventStatus(`${f}-type`, { defaultValue: f }),
        value: f,
      }));

      const otherChips = [];

      const [min, max] = filters.duration;
      if (min && max) {
        const dayAbbreviationUnit = tUnits('day-abbreviation-unit', {
          defaultValue: 'd',
        });

        const title = durationChipTitle(min, max, dayAbbreviationUnit);

        const durationChip = {
          filterKey: 'duration' as FilterKey,
          title,
          value: min,
          customOnRemove: () => setDeviationsFilter({ duration: [] }),
        };
        otherChips.push(durationChip);
      }
      const [minVolumeRation, maxVolumeRation] = filters.volume;
      if (minVolumeRation && maxVolumeRation) {
        const volumeRationChip = {
          filterKey: 'volume' as FilterKey,
          title: volumeChipTitle(minVolumeRation, maxVolumeRation),
          value: minVolumeRation,
          customOnRemove: () => setDeviationsFilter({ volume: [] }),
        };
        otherChips.push(volumeRationChip);
      }
      if (filters.isOngoing) {
        otherChips.push({
          filterKey: 'isOngoing' as FilterKey,
          title: tFilters('ongoing-only_filter-title', {
            defaultValue: 'Ongoing only',
          }),
          value: String(filters.isOngoing),
          customOnRemove: () => setDeviationsFilter({ isOngoing: false }),
        });
      }
      return [...typeChips, ...statusChips, ...otherChips];
    },
    [setDeviationsFilter, tEventStatus, tFilters, tUnits, volumeChipTitle]
  );

  return { getChipsData };
}

export default useDeviationFilterChips;
