import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { DoubleDatapoint } from '@cognite/sdk';
import { useUnitCategories } from 'pages/WellDeepDive/hooks/useUnitCategories';
import {
  RatePreferences,
  UnitPreferences,
  usePreferences,
} from 'features/preferences';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { ProductionFrequency } from 'hooks/types';
import { useTranslation } from '@cognite/react-i18n';
import {
  getRateFromFrequency,
  getRateCoffecient,
  getRateUnit,
  removeRateFromUnit,
} from 'utils/rateConversion';
import { selectAssetSpecificConversion } from './reducer';
import {
  createUnitConverter,
  createUnitConverterClientSide,
  getConversionFormula,
} from './utils';
import { ProductConversions } from '.';

export type ConvertValueOptions = {
  value: number;
  product: string;
  sourceUnit: string;
  targetUnit?: string;
  frequency?: ProductionFrequency;
  skipRateConversion?: boolean;
  // this will handle corner use cases where conversion formulae is not available
  // but rate conversion is still needed
  convertRateAnyway?: boolean;
};

export type ConvertValueReturn =
  | {
      value: number;
      convertedUnit: string;
    }
  | undefined;

export type ConvertTimeSeriesOptions = {
  dataPoints: DoubleDatapoint[];
  product: string;
  sourceUnit?: string;
  targetUnit?: string;
  frequency?: ProductionFrequency;
  skipRateConversion?: boolean;
};

export type ConvertTimeSeriesReturn =
  | {
      dataPoints: DoubleDatapoint[];
      convertedUnit: string;
    }
  | undefined;

export const useUnitConversion = () => {
  const assetSpecificConversions = useSelector(selectAssetSpecificConversion);

  const { selectedAsset, rootAsset } = useCurrentAsset();

  const usedAsset = selectedAsset || rootAsset;

  const usedAssetConversionId = usedAsset?.externalId || usedAsset?.id || '';

  const productConversions: ProductConversions =
    assetSpecificConversions?.[usedAssetConversionId] ||
    assetSpecificConversions?.[rootAsset?.externalId || ''];

  const { getCategory } = useUnitCategories();
  const {
    units,
    preferences: { rate },
  } = usePreferences();

  const { t: tUnits } = useTranslation('Units');

  const convertUnit = useCallback(
    (unit: string) => `${unit}/${getRateUnit(rate)}`,
    [rate]
  );

  const translateUnit = useCallback(
    (unit: string) =>
      unit
        .split('/')
        .map((u) =>
          tUnits(`${u}-unit`, {
            defaultValue: u,
          })
        )
        .join('/'),
    [tUnits]
  );

  const getPreferredUnitByProduct = useCallback(
    (product: string, preferredUnits: UnitPreferences) => {
      const unitCategory = getCategory(product) as string;
      return unitCategory && preferredUnits[unitCategory];
    },
    [getCategory]
  );

  const convertedQuery = useMemo(
    () =>
      createUnitConverter(units, productConversions, getPreferredUnitByProduct),
    [units, productConversions, getPreferredUnitByProduct]
  );

  const convertValue = useCallback(
    ({
      value,
      sourceUnit,
      targetUnit,
      frequency,
      product,
      skipRateConversion,
      convertRateAnyway,
    }: ConvertValueOptions): ConvertValueReturn => {
      // separate out rate from unit
      // rate coefficient will take care of that
      const formattedSourceUnit = removeRateFromUnit(sourceUnit);
      const sourceRate = getRateFromFrequency(frequency);

      const toUnit =
        targetUnit ||
        getPreferredUnitByProduct(product, units) ||
        formattedSourceUnit;

      if (
        formattedSourceUnit === toUnit &&
        ((skipRateConversion && sourceRate === 'Daily') ||
          (!skipRateConversion && rate === sourceRate))
      ) {
        return {
          value,
          convertedUnit: skipRateConversion
            ? sourceUnit
            : convertUnit(formattedSourceUnit),
        };
      }

      const formula = getConversionFormula(
        productConversions,
        product,
        formattedSourceUnit,
        toUnit
      );

      const rateCoffecient = getRateCoffecient(
        sourceRate,
        rate,
        skipRateConversion
      );

      if (formula) {
        const coefficient = +formula.replace(/[(]?{{ ?value ?}}[)]? ?\*/, '');
        if (!Number.isNaN(coefficient)) {
          return {
            value: value * coefficient * rateCoffecient,
            convertedUnit: skipRateConversion ? toUnit : convertUnit(toUnit),
          };
        }
        // for places where we are taking sum, we must convert value to same unit
        // otherwise it is safe to show rates where required
      } else if (convertRateAnyway) {
        return {
          value: value * rateCoffecient,
          convertedUnit: skipRateConversion
            ? formattedSourceUnit
            : convertUnit(formattedSourceUnit),
        };
      }
      return undefined;
    },
    [getPreferredUnitByProduct, units, rate, productConversions, convertUnit]
  );

  const convertedQueryClientSide = useMemo(
    () =>
      createUnitConverterClientSide(
        units,
        productConversions,
        convertValue,
        getPreferredUnitByProduct
      ),
    [units, productConversions, convertValue, getPreferredUnitByProduct]
  );

  const convertTimeSeries = useCallback(
    ({
      dataPoints,
      product,
      sourceUnit,
      targetUnit,
      frequency,
      skipRateConversion,
    }: ConvertTimeSeriesOptions): ConvertTimeSeriesReturn => {
      const preferredUnit = getPreferredUnitByProduct(product, units);
      const toUnit = targetUnit || preferredUnit;

      if (!sourceUnit) {
        return { convertedUnit: sourceUnit || '', dataPoints };
      }

      const formattedSourceUnit = skipRateConversion
        ? sourceUnit.replace('/HR', 'PERHOUR').replace('/H', 'PERHOUR')
        : removeRateFromUnit(sourceUnit);
      const sourceRate: RatePreferences = getRateFromFrequency(frequency);

      const formula = getConversionFormula(
        productConversions,
        product,
        formattedSourceUnit,
        toUnit
      );

      const rateCoffecient =
        skipRateConversion && frequency === 'hourly'
          ? 24
          : getRateCoffecient(sourceRate, rate, skipRateConversion);

      if (formula) {
        const coefficient = +formula.replace(/[(]?{{ ?value ?}}[)]? ?\*/, '');
        if (!Number.isNaN(coefficient)) {
          return {
            convertedUnit: skipRateConversion ? toUnit : convertUnit(toUnit),
            dataPoints: dataPoints.map((dp: DoubleDatapoint) => {
              return {
                timestamp: dp.timestamp,
                value: dp.value * coefficient * rateCoffecient,
              };
            }),
          };
        }
      }
      return {
        convertedUnit: skipRateConversion
          ? sourceUnit
          : convertUnit(removeRateFromUnit(sourceUnit)),
        dataPoints: dataPoints.map((dp: DoubleDatapoint) => ({
          timestamp: dp.timestamp,
          value: dp.value * rateCoffecient,
        })),
      };
    },
    [getPreferredUnitByProduct, units, productConversions, rate, convertUnit]
  );

  return {
    productConversions,
    getPreferredUnitByProduct: useCallback(
      (product: string) => getPreferredUnitByProduct(product, units),
      [units, getPreferredUnitByProduct]
    ),
    convertedQuery,
    convertedQueryClientSide,
    convertValue,
    convertUnit,
    translateUnit,
    convertTimeSeries,
  };
};
