/* eslint-disable no-nested-ternary */
import React, { useMemo } from 'react';
import { Progress, notification } from 'antd';
import parseInt from 'lodash/parseInt';
import { useExportService } from './hooks';

const key = 'updatble';
export const DownloadNotification = () => {
  const { message, counter, total, status, stopDownload } = useExportService();
  const percentage = useMemo(() => (counter * 100) / total, [counter, total]);
  const isDone = useMemo(() => percentage === 100, [percentage]);
  const isCanceled = useMemo(() => message === ' Canceled… ', [message]);
  const config = useMemo(
    () => ({
      duration: isDone ? 3 : isCanceled ? 5 : 0,
      description: message,
      key,
      message: !isCanceled && (
        <Progress
          percent={parseInt(`${percentage}`)}
          status={status as 'exception' | undefined}
          steps={20}
        />
      ),
      onClose: () => {
        if (!isDone) {
          stopDownload();
        }
        notification.close(key);
      },
      style: {
        width: 470,
        height: 85,
      },
    }),
    [isCanceled, isDone, message, percentage, status, stopDownload]
  );
  const selector = useMemo(
    () =>
      status === 'exception'
        ? 'error'
        : !status
        ? 'warn'
        : isDone
        ? 'success'
        : 'info',
    [isDone, status]
  );
  if (total) {
    notification[selector](config);
  }
  return <div />;
};
