import React from 'react';
import { DatePicker } from 'antd';
import type { DatePickerProps } from 'antd';
import { withI18nSuspense } from '@cognite/react-i18n';
import { useExportService } from 'features/export';

type Props = {
  onChange: (exportChunk: string) => void;
};

const MonthPicker = ({ onChange }: Props) => {
  const { disableExport, disabled } = useExportService();
  const onPick: DatePickerProps['onChange'] = (date) => {
    const exportChunk = `${date?.format('YYYY')}-${date?.format('MM')}`;
    if (date) {
      if (disabled) disableExport(false);
      onChange(exportChunk);
    } else disableExport(true);
  };
  const customFormat: DatePickerProps['format'] = (value) =>
    `${value.format('MMMM')} of ${value.format('yyyy')}`;
  return (
    <DatePicker
      style={{ width: 200 }}
      onChange={onPick}
      mode="month"
      picker="month"
      format={customFormat}
    />
  );
};

export default withI18nSuspense(MonthPicker);
