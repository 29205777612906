import React from 'react';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { ReactComponent as StatusDetected } from 'assets/Detected.svg';
import { ReactComponent as StatusIgnored } from 'assets/Ignored.svg';
import { ReactComponent as StatusResolved } from 'assets/Resolved.svg';
import { ReactComponent as StatusInvestigating } from 'assets/Investigating.svg';
import { Deviation, DeviationStatus } from 'utils/models';

import { useMetrics } from '@cognite/metrics';
import PanelCard, { StatusPart } from 'components/Sidebar/PanelCard/PanelCard';
import { Body, Colors, Flex, LabelVariants } from '@cognite/cogs.js';
import { StatusColors } from 'pages/KPIDashboard/Charts/utils';
import { asDecimal } from 'utils/numbers';
import { DeviationGroup, getIfIsGroup } from 'utils/models/deviationGroupes';
import { useIgnoredDeviationOptions } from 'features/deviations';
import ValueTooltip from 'components/ValueTooltip';

export type Props = {
  deviationPanelEvent: Deviation | DeviationGroup;
  isHovered: boolean;
  commentsCount: number;
  loadingComments: boolean;
  setSelectedDeviation: (deviationId: string) => void;
  updateDeviationStatus: (
    externalId: string,
    newStatus: DeviationStatus
  ) => void;
  wellName?: string;
  wellExternalId?: string;
};

export const STATUS_ICONS = {
  detected: <StatusDetected />,
  ignored: <StatusIgnored />,
  resolved: <StatusResolved />,
  investigating: <StatusInvestigating />,
  explained: <StatusResolved />,
  commenting: <></>,
} as const;

const DeviationPanelCard = ({
  deviationPanelEvent,
  isHovered,
  commentsCount,
  loadingComments,
  setSelectedDeviation,
  updateDeviationStatus,
}: Props) => {
  const metrics = useMetrics('Sidebar');
  const { t: tDeviationsPanel } = useTranslation('DeviationsPanel');
  const ignoredReasonOptions = useIgnoredDeviationOptions();

  const {
    volumes,
    inputVolume,
    volume: totalCurrentVolume,
    unit,
    status,
    externalId,
    volumePerStatus,
  } = deviationPanelEvent;

  let defermentName;
  const isGroup = getIfIsGroup(deviationPanelEvent);

  if (getIfIsGroup(deviationPanelEvent)) {
    defermentName =
      deviationPanelEvent.status === 'ignored'
        ? ignoredReasonOptions.find(
            (option) => option.value === deviationPanelEvent.ignoredReason
          )?.label ?? ''
        : deviationPanelEvent.defermentName ||
          tDeviationsPanel('unnamed-deferment_placeholder', {
            defaultValue: 'Unnamed Deferment',
          });
  }

  const updateStatus = (newStatus: DeviationStatus) => {
    updateDeviationStatus(externalId, newStatus);
  };

  const handleClickOnDeviation = () => {
    metrics.track('Deviations.Card_opened');
    setSelectedDeviation(externalId);
  };

  const getStatusColor = () => {
    if (StatusColors[status]) {
      return StatusColors[status].main;
    }
    return Colors.white.hex();
  };

  const getStatusLabelVariant = (): LabelVariants => {
    if (status === 'detected') {
      return 'danger';
    }
    if (status === 'ignored') {
      return 'warning';
    }
    if (status === 'explained') {
      return 'default';
    }
    return 'default';
  };

  const productList = volumes.map((volume) => ({
    product: volume.product,
    value: volume.currentValue,
    unit: volume.unit,
  }));

  return (
    <PanelCard
      panelEvent={deviationPanelEvent}
      startDate={deviationPanelEvent.startDate}
      endDate={deviationPanelEvent.endDate}
      title={
        defermentName ||
        (inputVolume && totalCurrentVolume !== inputVolume ? (
          <Flex direction="row" alignItems="baseline">
            <Trans t={tDeviationsPanel} i18nKey="deviation-volume_label">
              <ValueTooltip content={totalCurrentVolume}>
                <>
                  {{
                    currentVolume: `${asDecimal(totalCurrentVolume || 0, {
                      maxLength: 5,
                      precision: 2,
                    })} ${unit.toLowerCase()}`,
                  }}
                </>
              </ValueTooltip>
              <Body
                level={2}
                style={{
                  fontWeight: 'normal',
                  color: Colors['text-color-secondary'].hex(),
                }}
              >
                &nbsp;out of initial&nbsp;
                <ValueTooltip content={inputVolume || 0}>
                  <>
                    {{
                      initialVolume: `${asDecimal(inputVolume || 0, {
                        maxLength: 5,
                        precision: 2,
                      })} ${unit.toLowerCase()}`,
                    }}
                  </>
                </ValueTooltip>
              </Body>
            </Trans>
          </Flex>
        ) : (
          <ValueTooltip content={totalCurrentVolume}>
            <>
              {`${asDecimal(totalCurrentVolume || 0, {
                maxLength: 5,
                precision: 2,
              })} ${unit.toLowerCase()}`}
            </>
          </ValueTooltip>
        ))
      }
      products={productList}
      color={getStatusColor()}
      details={{
        type: 'Deviation',
        status,
        statusLabelVariant: getStatusLabelVariant(),
        isHovered,
        updateStatus,
        loadingComments,
        commentsCount,
        statusParts: inputVolume
          ? (volumePerStatus?.map((item) => ({
              ...item,
              percentage: (item.volume / inputVolume) * 100,
            })) as StatusPart[])
          : undefined,
      }}
      onClick={handleClickOnDeviation}
      productListTotal={isGroup ? totalCurrentVolume : undefined}
      // we want to show only start date on the card for deviations
      showOnlyStart={!isGroup}
      withHighlight
    />
  );
};

export default withI18nSuspense(DeviationPanelCard);
