import { Asset, CogniteClient } from '@cognite/sdk';
import { DataNode } from 'antd/lib/tree';

/**
 * Recursively goes through a list of DataNodes, and adds in new children at the relevant key
 */
export const updateTreeData = (
  list: DataNode[],
  key: React.Key,
  children: DataNode[]
): DataNode[] => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
};

export const getPathFromRelationships = async (
  client: CogniteClient,
  assetExternalId: string,
  relationshipLabelExternalId: string,
  rootAssetExId?: string
): Promise<Asset[]> => {
  const asset = await client.assets
    .retrieve([{ externalId: assetExternalId }])
    .then((res) => res[0]);
  if (asset.externalId === rootAssetExId) {
    return [asset];
  }

  const parentAsset = await client.relationships
    .list({
      filter: {
        sourceExternalIds: [assetExternalId],
        sourceTypes: ['asset'],
        labels: {
          containsAll: [{ externalId: relationshipLabelExternalId }],
        },
      },
    })
    .then(async (res) => {
      if (res.items.length <= 0) {
        return false;
      }
      const assets = await client.assets.retrieve(
        res.items.map((item) => ({ externalId: item.targetExternalId })),
        { ignoreUnknownIds: true }
      );

      const relevantAssets = assets.filter((asset) =>
        asset.labels?.some((label) =>
          label.externalId.includes('NETWORK_LEVEL')
        )
      );
      return relevantAssets[0];
    });

  if (!parentAsset) {
    return [asset];
  }
  const parentExternalId = parentAsset.externalId!;

  return [
    ...(await getPathFromRelationships(
      client,
      parentExternalId,
      relationshipLabelExternalId,
      rootAssetExId
    )),
    asset,
  ];
};
