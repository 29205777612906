import { reportException } from '@cognite/react-errors';
import { Asset } from '@cognite/sdk';
import { useQuery } from 'react-query';
import { getRootAssets } from './api';

export const useRootAssets = () =>
  useQuery<Asset[]>('Root Asset List', () => getRootAssets(), {
    cacheTime: Infinity,
    staleTime: Infinity,
    retry: 5,
    onError: (err) => {
      reportException(err as Error);
    },
  });
