import CollectionsTree from 'components/CollectionsTree';
import styled from 'styled-components/macro';

import zIndex from 'utils/zindex';

export const Wrapper = styled.aside`
  border-right: solid 1px var(--cogs-greyscale-grey3);
  display: flex;
  flex-direction: column;
  position: sticky;
  right: 0;
  top: 0;
  flex-shrink: 0;
  z-index: ${zIndex.SIDE_BAR};
  height: 100%;
  white-space: nowrap;
  transition: width 0.15s ease-in;
  background: var(--cogs-greyscale-grey1);
`;

export const ScrollContainer = styled.div`
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;

  .ant-tree {
    height: auto;
  }
`;

export const CollectionsTreeWrapper = styled(CollectionsTree)`
  padding-top: 8px;
`;
