/* eslint-disable @typescript-eslint/no-explicit-any */
import { Aggregate, DoubleDatapoint } from '@cognite/sdk';
import { TimeSeries, AggregatedDatapoints } from 'graphql-types';

import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { getStartOfDay } from 'utils/datetime';
import {
  GetDatapointsResultDatapoints,
  GetDatapointsResultFrequency,
  TimeseriesType,
} from './types';

export const calculateGranularity = (
  domain: [number, number],
  pointsPerSeries: number
) => {
  const diff = domain[1] - domain[0];

  for (let i = 1; i <= 60; i += 1) {
    const points = diff / (1000 * i);
    if (points < pointsPerSeries) {
      return `${i === 1 ? '' : i}s`;
    }
  }

  for (let i = 1; i <= 60; i += 1) {
    const points = diff / (1000 * 60 * i);
    if (points < pointsPerSeries) {
      return `${i === 1 ? '' : i}m`;
    }
  }
  for (let i = 1; i < 24; i += 1) {
    const points = diff / (1000 * 60 * 60 * i);
    if (points < pointsPerSeries) {
      return `${i === 1 ? '' : i}h`;
    }
  }
  for (let i = 1; i < 100; i += 1) {
    const points = diff / (1000 * 60 * 60 * 24 * i);
    if (points < pointsPerSeries) {
      return `${i === 1 ? '' : i}day`;
    }
  }
  return 'day';
};

export const getDatapointsByTimeseriesType = (
  tsType: TimeseriesType,
  product: any,
  aggregates: Aggregate[] | undefined,
  normalizeTime: boolean
): GetDatapointsResultFrequency | GetDatapointsResultDatapoints => {
  const buildResult = (productionArray: any[]) => {
    if (!productionArray) {
      return {};
    }

    return (
      isArray(productionArray) ? productionArray : [productionArray]
    ).reduce((acc, { frequency, timeSeries, bestDay, syntheticTimeSeries }) => {
      if (!timeSeries && !bestDay && !syntheticTimeSeries) {
        return acc;
      }

      const isSynthetic = !bestDay && !timeSeries && !!syntheticTimeSeries;

      const datapointsProp =
        aggregates && !isSynthetic ? 'aggregatedDatapoints' : 'datapoints';

      const timeseries = (
        isSynthetic ? syntheticTimeSeries : bestDay || timeSeries
      ) as TimeSeries;

      let datapoints: DoubleDatapoint[] | AggregatedDatapoints;

      // Results are built differently for aggregated and non-aggregated datapoints.
      // If non-aggregated, then the object returned will simply contain
      // a property holding the datapoints (`datapoints?: DoubleDatapoint[]`).
      // If aggregated, then the object returned will contain a property with the aggregations
      // and then each aggregation will contain the datapoints (`aggregatedDatapoints?: AggregatedDatapoints`).
      if (aggregates && !isSynthetic) {
        datapoints = Object.entries(
          (timeseries.aggregatedDatapoints || {}) as AggregatedDatapoints
        )
          .filter(([_, dps]) => dps?.length > 0)
          .reduce(
            (acc, [aggregate, dps]) => ({
              ...acc,
              [aggregate]: normalizeTime
                ? dps.map((dp) => ({
                    ...dp,
                    timestamp: getStartOfDay(dp.timestamp),
                  }))
                : dps,
            }),
            {} as AggregatedDatapoints
          );
      } else {
        datapoints = normalizeTime
          ? (timeseries.datapoints || []).map((dp) => ({
              ...dp,
              timestamp: getStartOfDay(dp.timestamp),
            }))
          : timeseries.datapoints || [];
      }

      if (isEmpty(datapoints)) {
        return acc;
      }

      // Some types of timeseries do not differentiate the datapoints per frequency
      // and, for that reason, we need to treat them differently. Only
      // production and bestDay timeseries have frequencies (daily, hourly, realtime).
      if (tsType === 'production') {
        if (!acc.frequencies) {
          acc.frequencies = {} as GetDatapointsResultFrequency;
        }
        if (!acc.frequencies[frequency]) {
          acc.frequencies[frequency] = {};
        }
        acc.frequencies[frequency] = {
          ...acc.frequencies[frequency],
          unit: timeseries.unit?.toUpperCase() || '',
          externalId: timeseries.externalId,
          id: timeseries.id,
          [datapointsProp]:
            datapointsProp === 'aggregatedDatapoints'
              ? {
                  ...(acc.frequencies[frequency][datapointsProp] || {}),
                  ...datapoints,
                }
              : [
                  ...(acc.frequencies[frequency][datapointsProp] || []),
                  ...(datapoints as DoubleDatapoint[]),
                ],
        };
        return acc;
      }

      return {
        ...acc,
        unit: timeseries.unit?.toUpperCase() || '',
        [datapointsProp]: datapoints,
        externalId: timeseries.externalId,
        id: timeseries.id,
      };
    }, {} as GetDatapointsResultFrequency | GetDatapointsResultDatapoints);
  };

  switch (tsType) {
    case 'production':
    case 'deviations':
      return buildResult(
        // The `bestDay` from templates is not an independent object,
        // but it's actually inside the `production` object
        product[tsType]
      );
    case 'actualDeferments':
    case 'futureDeferments':
      return buildResult(product.deferments);
    case 'bestDay':
    case 'mpc':
    case 'theor':
    case 'alloc':
    case 'ipsc':
      return buildResult(product.capacity);
    case 'measurements':
      return buildResult(product);
    default:
      return {};
  }
};
