import { getClient } from 'utils/cognitesdk';
import { SIDECAR } from 'utils/sidecar';

const { bestdayApiBaseUrl } = SIDECAR;
export const NOTIFICATIONS_ENDPOINT = new URL(
  'notifications',
  bestdayApiBaseUrl
).href;

export type Notification = {
  notificationId: string;
  commentExtId: string;
  assetExternalId: string;
  eventExternalId: string;
  message: string;
  userName: string;
  assetName: string;
  deviationDate: Date;
  createdTime: Date;
};

type NotificationResponse = Omit<
  Notification,
  'createdTime' | 'deviationDate'
> & {
  createdTime: number;
  deviationDate: number;
};

export const getNotifications = ({ project }: { project: string }) =>
  getClient()
    .get<NotificationResponse[]>(NOTIFICATIONS_ENDPOINT, {
      withCredentials: true,
      headers: {
        'x-cdp-project': project,
      },
    })
    .then((data) => ({
      ...data,
      data: data.data.map(
        (e) =>
          ({
            ...e,
            createdTime: new Date(e.createdTime),
            deviationDate: new Date(e.deviationDate),
          } as Notification)
      ),
    }));

export const deleteNotification = ({
  project,
  notificationId,
}: {
  project: string;
  notificationId: string;
}) =>
  getClient().delete<void>(`${NOTIFICATIONS_ENDPOINT}/${notificationId}`, {
    withCredentials: true,
    headers: {
      'x-cdp-project': project,
    },
  });
