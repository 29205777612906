import { Body, Button, Icon } from '@cognite/cogs.js';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import Flex from 'components/Layout/Flex';
import React from 'react';

type AcknowledgeButtonProps = {
  acknowledged: boolean;
  onAcknowledge: () => void;
};
export const AcknowledgeButton = withI18nSuspense(
  ({ acknowledged, onAcknowledge }: AcknowledgeButtonProps) => {
    const { t } = useTranslation('Notifications');

    return (
      <Flex.Row style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
        {acknowledged && (
          <Flex.Row
            style={{ alignItems: 'center', height: 36, paddingRight: '16px' }}
            gap={5}
          >
            <Body level={2}>
              <Trans t={t} i18nKey="notification-acknowledged">
                Acknowledged
              </Trans>
            </Body>
            <div style={{ marginTop: 6 }}>
              <Icon
                type="CheckmarkFilled"
                style={{ color: 'var(--cogs-graphics-success)' }}
              />
            </div>
          </Flex.Row>
        )}
        {!acknowledged && (
          <Button
            type="ghost"
            onClick={(ev) => {
              ev.stopPropagation();
              ev.preventDefault();
              onAcknowledge();
            }}
          >
            <Trans t={t} i18nKey="notification-acknowledge">
              Acknowledge
            </Trans>
            <div style={{ marginLeft: 5, marginTop: 2 }}>
              <Icon type="CheckmarkAlternative" />
            </div>
          </Button>
        )}
      </Flex.Row>
    );
  }
);
