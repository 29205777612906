import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import useNavigation from 'utils/useNavigation';
import {
  subtractTimezoneOffset,
  getEndOfLocalDay,
  getStartOfMonth,
  getLastDayOfMonth,
  getStartOfLocalDay,
  getEndOfDay,
} from 'utils/datetime';
import timeRangeSlice, { TimeRangeState } from './reducer';

export const useTimeRange = () => {
  const { start, end, summaryPageStart, summaryPageEnd, exportChunk } =
    useSelector<RootState, TimeRangeState>((state) => {
      return state.timeRange;
    });

  const dispatch = useDispatch();
  const { isSummaryPage } = useNavigation();
  const setTimeRange = useCallback(
    (start: number, newEnd: number) => {
      const end = subtractTimezoneOffset(getEndOfLocalDay(newEnd));
      if (isSummaryPage) {
        dispatch(
          timeRangeSlice.actions.adjustSummaryPage({
            start,
            end,
          })
        );
      } else {
        dispatch(timeRangeSlice.actions.adjust({ start, end }));
      }
    },
    [dispatch, isSummaryPage]
  );
  const setExportTime = useCallback(
    (exportChunk: string) => {
      dispatch(timeRangeSlice.actions.adjustExportChunk({ exportChunk }));
    },
    [dispatch]
  );
  const slide = (direction: 'forwards' | 'backwards') => {
    dispatch(timeRangeSlice.actions.slide(direction));
  };
  const exportStart = useMemo(
    () => +getStartOfLocalDay(getStartOfMonth(exportChunk)),
    [exportChunk]
  );
  const exportEnd = useMemo(
    () => +getEndOfDay(`${exportChunk}-${getLastDayOfMonth(exportChunk)}`),
    [exportChunk]
  );
  return {
    start: isSummaryPage ? summaryPageStart : start,
    end: isSummaryPage ? summaryPageEnd : end,
    setTimeRange,
    slideTimeWindow: slide,
    exportChunk,
    setExportTime,
    exportStart,
    exportEnd,
  };
};
