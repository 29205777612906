import { Flex, Body } from '@cognite/cogs.js';
import TextOverflowEllipsisTooltip from 'components/accessibility/TextOverflowEllipsisTooltip';
import React from 'react';
import styled from 'styled-components/macro';

export type TabContent = {
  group: string;
  value: number;
  valueInPercent: number;
};

export const DistributionTabContent = ({
  tabContent,
}: {
  tabContent: TabContent[];
}) => {
  return (
    <>
      {tabContent.map(({ group, value, valueInPercent }) => (
        <StyledContainer key={group}>
          <StyledGroupTitle>
            <TextOverflowEllipsisTooltip>{group}</TextOverflowEllipsisTooltip>
          </StyledGroupTitle>
          <Flex alignItems="center">
            <StyledRectangle width={`${valueInPercent}%`} />
            <Body level={2} strong>
              {valueInPercent}%
            </Body>
            <StyledBody level={2}>({value})</StyledBody>
          </Flex>
        </StyledContainer>
      ))}
    </>
  );
};

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-gap: 12px;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid var(--cogs-greyscale-grey4);
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledGroupTitle = styled.div`
  max-width: 100px;
`;

const StyledRectangle = styled.div<{ width: string }>`
  background: #1b6c5f;
  border-radius: 3px;
  height: 16px;
  width: ${(props) => props.width};
  margin-right: 9px;
`;

const StyledBody = styled(Body)`
  color: var(--cogs-greyscale-grey7);
  padding-left: 4px;
`;
