/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { Collection } from 'utils/models/collections';
import { useTranslation, withI18nSuspense, Trans } from '@cognite/react-i18n';
import { Button, Body, toast } from '@cognite/cogs.js';
import { useCollections } from 'features/collections';
import createToastOptions from 'utils/toasts/createToastOptions';
import { useHistory } from 'react-router';
import { StyledCollectionsModal } from 'components/CollectionsModal/elements';

type CollectionDeleteModalProps = {
  onClose: Function;
  collection: Collection;
  removeCollection: Function;
};

const CollectionDeleteModal = ({
  collection,
  onClose,
  removeCollection,
}: CollectionDeleteModalProps) => {
  const [visible, setVisible] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const { t } = useTranslation('CollectionsDeleteModal');
  const history = useHistory();
  const { getCurrentCollection } = useCollections();

  const modalTitle = t('deleteTitle', {
    defaultValue: 'Delete collection?',
  });

  const cancelText = t('cancelText', { defaultValue: 'Cancel' });
  const deleteText = t('deleteText', { defaultValue: 'Delete' });

  const onCancel = () => {
    setVisible(false);
    onClose();
  };

  const onDelete = useCallback(async () => {
    const title = collection.name;
    setIsSaving(true);
    removeCollection(collection)
      .then(() => {
        const currentCollection = getCurrentCollection();
        if (currentCollection && currentCollection.key === collection.key) {
          history.push('/');
        }
        setIsSaving(false);
        setVisible(false);
        onClose(true, title);
      })
      .catch((error: any) => {
        setIsSaving(false);
        toast.error(
          <Body level={2}>
            <Trans t={t} i18nKey="toast-error">
              Could not delete collection{' '}
              {{ errorMessage: error?.message ?? '' }}
            </Trans>
          </Body>,
          createToastOptions()
        );
        Sentry.captureException(error);
      });
  }, [collection, history, onClose, getCurrentCollection, removeCollection, t]);
  return (
    <StyledCollectionsModal
      width={400}
      title={modalTitle}
      appElement={document.getElementById('root')!}
      visible={visible}
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel} type="secondary">
          {cancelText}
        </Button>,
        <Button key="save" onClick={onDelete} loading={isSaving} type="primary">
          {deleteText}
        </Button>,
      ]}
    >
      <Body level={2} strong>
        <Trans t={t} i18nKey="deleteConfirmation">
          This will delete collection{' '}
          <strong>{{ name: collection.name }}</strong>
        </Trans>
      </Body>
    </StyledCollectionsModal>
  );
};

export default withI18nSuspense(CollectionDeleteModal);
