/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useMemo, useState } from 'react';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components/macro';
import {
  Button,
  Flex,
  Menu,
  Modal,
  TextInput,
  Collapse,
  Body,
  toast,
} from '@cognite/cogs.js';
import Select from 'components/Select';
import uniqueId from 'lodash/uniqueId';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import AssetSelector from 'components/AssetSelector/AssetSelector';
import { useDynamicCollectionConfig } from 'pages/WellDeepDive/hooks/useDynamicCollectionConfig';
import { mapWellTypeToWellTypeTitle } from 'utils/models/wellListItems';
import { Asset } from '@cognite/sdk';
import createToastOptions from 'utils/toasts/createToastOptions';
import { useCollections } from 'features/collections';
import { useUnitConversion } from 'features/unitConversion';
import isUndefined from 'lodash/isUndefined';
import ConditionGroupView, {
  Condition,
  ConditionGroup,
} from './ConditionGroupView';

export type DynamicCollection = {
  key?: string;
  name?: string;
  rootAssetExternalId?: string;
  systems?: Asset[];
  wells: Asset[];
  wellType?: string;
  conditionGroups?: ConditionGroups;
};

export type ConditionGroups = {
  conjunction?: 'and' | 'or' | string;
  groups?: ConditionGroup[];
};

export type Props = {
  collection?: DynamicCollection;
  onClose: Function;
};
const DynamicCollectionModal = ({ collection, onClose }: Props) => {
  const { t } = useTranslation('DynamicCollection');
  const { rootAsset } = useCurrentAsset();
  const { config } = useDynamicCollectionConfig();
  const { addDynamicCollection, editDynamicCollection } = useCollections();
  const { productConversions } = useUnitConversion();

  const [dirtyControls, setDirtyControls] = useState<{
    [key: string]: boolean;
  }>({});
  const [isSaving, setSaving] = useState(false);
  const isNewCollection = collection === undefined;

  const [dynamicCollection, setDynamicCollection] = useState<DynamicCollection>(
    () => {
      if (collection) {
        return { ...collection };
      }
      return {
        rootAssetExternalId: rootAsset?.externalId,
        wells: [],
        conditionGroups: {
          conjunction: 'and',
          groups: [
            {
              id: uniqueId(),
              conjunction: 'and',
              conditions: [{ id: uniqueId(), symbol: 'gt' } as Condition],
            },
          ],
        },
      };
    }
  );

  const onCancel = () => {
    onClose();
  };

  const onTitleBlur = () => {
    setDirtyControls({
      ...dirtyControls,
      name: true,
    });
  };

  const setDirty = useCallback(
    (fieldKey: string) => {
      setDirtyControls({
        ...dirtyControls,
        [fieldKey]: true,
      });
    },
    [dirtyControls]
  );

  const nameValid = useCallback(() => {
    return dynamicCollection.name && dynamicCollection.name?.trim() !== '';
  }, [dynamicCollection.name]);

  const systemsValid = useCallback(() => {
    return !!(
      dynamicCollection.systems && dynamicCollection.systems?.length > 0
    );
  }, [dynamicCollection.systems]);

  const wellTypeValid = useCallback(() => {
    return dynamicCollection.wellType && dynamicCollection.wellType !== '';
  }, [dynamicCollection.wellType]);

  const setConditionGroupsDirty = useCallback(() => {
    return dynamicCollection.conditionGroups?.groups?.reduce(
      (groupsAcc, group) => {
        return {
          ...groupsAcc,
          ...group.conditions.reduce((acc, condition) => {
            return {
              ...acc,
              [`variable-${condition.id}`]: true,
              [`unit-${condition.id}`]: true,
              [`value-${condition.id}`]: true,
            };
          }, {}),
        };
      },
      {}
    );
  }, [dynamicCollection.conditionGroups?.groups]);

  const validateCondition = useCallback(
    (condition: Condition, field?: string): boolean => {
      if (field) {
        if (field === 'value') {
          const value = condition[field as keyof Condition] as number;
          return !isUndefined(value) && value >= 0;
        }

        return !!condition[field as keyof Condition];
      }
      return !!condition.variable && !!condition.value && condition.value >= 0;
    },
    []
  );

  const validateGroups = useCallback(() => {
    return dynamicCollection.conditionGroups?.groups
      ?.flatMap((group) => {
        return group.conditions.map((condition) => {
          return validateCondition(condition);
        });
      })
      .every((it) => !!it);
  }, [dynamicCollection.conditionGroups?.groups, validateCondition]);

  const validate = useCallback(() => {
    setDirtyControls({
      ...dirtyControls,
      name: true,
      systems: true,
      wellType: true,
      ...setConditionGroupsDirty(),
    });
    return [
      nameValid(),
      systemsValid(),
      wellTypeValid(),
      validateGroups(),
    ].every((it) => it);
  }, [
    dirtyControls,
    nameValid,
    setConditionGroupsDirty,
    systemsValid,
    validateGroups,
    wellTypeValid,
  ]);

  const onSave = useCallback(async () => {
    if (!rootAsset?.externalId) {
      return;
    }

    const result = validate();
    if (!result) {
      return;
    }

    setSaving(true);
    if (!collection) {
      addDynamicCollection(dynamicCollection)
        .then(() => {
          setSaving(false);
          onClose(true, dynamicCollection.name);
        })
        .catch((error) => {
          setSaving(false);
          toast.error(
            <Body level={2}>
              <Trans t={t} i18nKey="toast-error-create-dynamic-collection">
                Unable to create dynamic collection
              </Trans>
              <p>{error.message}</p>
            </Body>,
            createToastOptions()
          );
          Sentry.captureException(error);
        });
    } else {
      editDynamicCollection(dynamicCollection)
        .then(() => {
          setSaving(false);
          onClose(true, dynamicCollection.name);
        })
        .catch((error) => {
          setSaving(false);
          toast.error(
            <Body level={2}>
              <Trans t={t} i18nKey="toast-error-edit-dynamic-collection">
                Unable to edit dynamic collection
              </Trans>
              <p>{error.message}</p>
            </Body>,
            createToastOptions()
          );
          Sentry.captureException(error);
        });
    }
  }, [
    addDynamicCollection,
    collection,
    dynamicCollection,
    editDynamicCollection,
    onClose,
    rootAsset?.externalId,
    t,
    validate,
  ]);

  const updateGroup = useCallback(
    (conditionGroup: ConditionGroup) =>
      setDynamicCollection({
        ...dynamicCollection,
        conditionGroups: {
          ...dynamicCollection.conditionGroups,
          groups: dynamicCollection.conditionGroups?.groups?.map((it) => {
            if (it.id === conditionGroup.id) {
              return {
                ...it,
                ...conditionGroup,
              };
            }
            return it;
          }),
        },
      }),
    [dynamicCollection]
  );

  const removeGroup = useCallback(
    (group: ConditionGroup) => {
      setDynamicCollection({
        ...dynamicCollection,
        conditionGroups: {
          ...dynamicCollection.conditionGroups,
          groups: dynamicCollection.conditionGroups?.groups?.filter(
            (it) => it.id !== group.id
          ),
        },
      });
    },
    [dynamicCollection]
  );

  if (!rootAsset?.externalId) {
    return null;
  }

  const cancel = t('cancel_button', { defaultValue: 'Cancel' });
  const save = isNewCollection
    ? t('save_new_button', { defaultValue: 'Create new collection' })
    : t('save_button', { defaultValue: 'Save' });

  const title = isNewCollection
    ? t('title_new_colleciton', { defaultValue: 'Add new dynamic collection' })
    : t('title_update_colleciton', { defaultValue: 'Edit collection' });

  const selectSystemsTitle = t('selectSystemsTitle', {
    defaultValue: 'Select systems: {{num}} system selected',
    num: dynamicCollection.systems?.length || 0,
  });

  const selectSystemsHelperText = t('selectSystemsHelperText', {
    defaultValue: 'Select systems',
  });

  const selectItemText = t('select_item', {
    defaultValue: 'Select item',
  });

  const requiredText = t('required', {
    defaultValue: 'Required',
  });

  const nameErrorStyles = useMemo(() => {
    if (dirtyControls.name && !nameValid()) {
      return { border: '2px solid #e32351' };
    }
    return {};
  }, [dirtyControls.name, nameValid]);

  return (
    <StyledDynamicReportModal
      width={720}
      title={title}
      appElement={document.getElementById('root')!}
      onCancel={onCancel}
      visible
      footer={[
        <Button key="cancel" onClick={onCancel} type="secondary">
          {cancel}
        </Button>,
        <Button
          key="save"
          onClick={onSave}
          disabled={isSaving}
          loading={isSaving}
          type="primary"
        >
          {save}
        </Button>,
      ]}
    >
      <StyledInputContainer>
        <Body level="2" strong>
          <Trans t={t} i18nKey="collection_name_title">
            Name the colleciton
          </Trans>
        </Body>
        <TextInput
          variant="default"
          style={nameErrorStyles}
          value={dynamicCollection.name}
          onBlur={onTitleBlur}
          onChange={({ target: { value } }) =>
            setDynamicCollection({ ...dynamicCollection, name: value })
          }
          fullWidth
        />
      </StyledInputContainer>
      <Flex style={{ padding: '8px 0px 8px 0px' }}>
        <Collapse accordion>
          <Collapse.Panel
            header={selectSystemsTitle}
            style={{
              border:
                dirtyControls.systems && !systemsValid()
                  ? '2px solid #e32351'
                  : '1px solid #d9d9d9',
              borderRadius: '8px',
            }}
          >
            <AssetSelector
              selected={dynamicCollection.systems || []}
              setSelected={(selected: any) => {
                setDynamicCollection({
                  ...dynamicCollection,
                  systems: selected,
                });
              }}
              assetType="system"
              translation={t}
              onlySystems
              helperText={selectSystemsHelperText}
              style={{
                border: '0px',
                padding: '0px',
                height: '300px',
              }}
            />
          </Collapse.Panel>
        </Collapse>
      </Flex>
      <Flex direction="column" style={{ padding: '8px 0px 8px 0px' }}>
        <Body level="2" strong>
          <Trans t={t} i18nKey="wellType">
            Well type
          </Trans>
        </Body>
        <Select
          style={{ width: '200px' }}
          onHide={() => setDirty('wellType')}
          error={
            dirtyControls.wellType && !wellTypeValid()
              ? requiredText
              : undefined
          }
          items={config.wellTypes.map((it) => {
            return (
              <Menu.Item
                key={it}
                onClick={() => {
                  setDynamicCollection({
                    ...dynamicCollection,
                    wellType: it,
                  });
                  wellTypeValid();
                }}
              >
                {mapWellTypeToWellTypeTitle(it)}
              </Menu.Item>
            );
          })}
        >
          <span>
            {(dynamicCollection.wellType &&
              mapWellTypeToWellTypeTitle(dynamicCollection.wellType)) ||
              selectItemText}
          </span>
        </Select>
      </Flex>
      <Flex direction="column" style={{ padding: '8px 0px 8px 0px' }}>
        <Body level="2" strong>
          <Trans t={t} i18nKey="add_conditions_title">
            Add conditions
          </Trans>
        </Body>
        {dynamicCollection?.conditionGroups?.groups?.map(
          (conditionGroup, index) => {
            return (
              <>
                <ConditionGroupView
                  index={index}
                  conditionGroup={conditionGroup}
                  variables={config.variables}
                  symbols={config.symbols}
                  conjunctions={config.conjunctions}
                  productConversions={productConversions}
                  dirtyControls={dirtyControls}
                  setDirty={setDirty}
                  validateCondition={validateCondition}
                  updateGroup={updateGroup}
                  removeGroup={removeGroup}
                />
                {dynamicCollection?.conditionGroups?.groups &&
                  dynamicCollection?.conditionGroups?.groups?.length > 1 &&
                  index !==
                    dynamicCollection?.conditionGroups?.groups?.length - 1 && (
                    <Flex style={{ justifyContent: 'center', padding: '8px' }}>
                      <Select
                        style={{ width: '100%' }}
                        items={config.conjunctions.map((it) => {
                          return (
                            <Menu.Item
                              key={it}
                              onClick={() =>
                                setDynamicCollection({
                                  ...dynamicCollection,
                                  conditionGroups: {
                                    ...dynamicCollection.conditionGroups,
                                    conjunction: it,
                                  },
                                })
                              }
                            >
                              {it}
                            </Menu.Item>
                          );
                        })}
                      >
                        <Trans
                          t={t}
                          i18nKey={`conjunction_${dynamicCollection.conditionGroups?.conjunction}`}
                        >
                          {dynamicCollection.conditionGroups?.conjunction}
                        </Trans>
                      </Select>
                    </Flex>
                  )}
              </>
            );
          }
        )}
      </Flex>
      {(!dynamicCollection?.conditionGroups ||
        (dynamicCollection.conditionGroups?.groups &&
          dynamicCollection.conditionGroups?.groups.length < 2)) && (
        <Flex
          style={{
            padding: '8px',
            minWidth: '200px',
            justifyContent: 'center',
          }}
        >
          <Button
            type="ghost"
            style={{ border: '1px solid #D9D9D9' }}
            onClick={() =>
              setDynamicCollection({
                ...dynamicCollection,
                conditionGroups: {
                  ...dynamicCollection.conditionGroups,
                  groups: [
                    ...(dynamicCollection.conditionGroups?.groups || []),
                    {
                      id: uniqueId(),
                      conjunction: 'and',
                      conditions: [
                        { id: uniqueId(), symbol: 'gt' } as Condition,
                      ],
                    },
                  ],
                },
              })
            }
          >
            <Trans t={t} i18nKey="add_condition_group">
              + Add condition group
            </Trans>
          </Button>
        </Flex>
      )}
    </StyledDynamicReportModal>
  );
};

const StyledInputContainer = styled.div`
  margin-top: 16px;
  padding: 8px 0px 8px 0px;

  label.title {
    font-weight: 500;
    font-size: 14px;
    color: var(--cogs-greyscale-grey9);
  }
`;

const StyledDynamicReportModal = styled(Modal)`
  .cogs-modal-header {
    font-size: var(--cogs-t5-font-size);
    font-weight: 600;
    padding: 0px 0px 16px 0px;
  }

  .cogs-modal-content {
    padding: 0px;
  }

  .cogs-modal-footer {
    display: flex;
    justify-content: flex-end;

    > button:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export default withI18nSuspense(DynamicCollectionModal);
