import UnstyledLink, { UnstyledA } from 'components/accessibility/UnstyledLink';
import styled from 'styled-components/macro';

export const MenuItemSpan = styled.span`
  width: 100%;
  text-align: left;
  padding-left: 8px;
`;

export const MenuItemLink = styled(UnstyledLink)`
  width: 100%;
  text-align: left;
  padding-left: 8px;
`;

export const MenuItemA = styled(UnstyledA)`
  width: 100%;
  text-align: left;
  padding-left: 8px;
`;
