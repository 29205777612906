import React from 'react';
import { Tooltip, TooltipProps } from '@cognite/cogs.js';
import useNumberFormats from 'utils/useNumberFormats';

type Props = Omit<TooltipProps, 'disabled' | 'content'> & {
  content: number | string;
};

const ValueTooltip = ({ content, children, ...rest }: Props) => {
  const { decimal } = useNumberFormats();
  const croppedValue = decimal(content, {
    precision: 4,
  });

  return (
    <Tooltip disabled={!content} content={croppedValue} {...rest}>
      {children}
    </Tooltip>
  );
};

export default ValueTooltip;
