import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import groupEventTabSlice, {
  GroupEventTab,
  GroupEventTabState,
} from './reducer';

export const useGroupEventTab = (deviationExternalId: string) => {
  const selectedTabs = useSelector<RootState, GroupEventTabState>((state) => {
    return state.groupEventTab;
  });

  const dispatch = useDispatch();

  const setSelectedTab = useCallback(
    (selectedTab: GroupEventTab) => {
      dispatch(
        groupEventTabSlice.actions.setTab({ selectedTab, deviationExternalId })
      );
    },
    [deviationExternalId, dispatch]
  );

  const selectedTab = useMemo(
    () => selectedTabs[deviationExternalId],
    [deviationExternalId, selectedTabs]
  );

  return {
    selectedTab,
    setSelectedTab,
  };
};
