import styled from 'styled-components/macro';
import { Button } from '@cognite/cogs.js';

export const StyledExportButton = styled(Button)`
  margin: 8px;
`;

export const StyledAssetSelectorWrapper = styled.span`
  display: flex;
  align-items: center;
`;
