import { DeepPartial } from '@reduxjs/toolkit';
import { Preferences } from 'features/preferences';
import { getClient } from 'utils/cognitesdk';
import { SIDECAR } from 'utils/sidecar';

const { bestdayApiBaseUrl } = SIDECAR;
export const PREFERENCES_ENDPOINT = new URL('preferences', bestdayApiBaseUrl)
  .href;

export const getPreferencesApi = ({ project }: { project: string }) =>
  getClient().get<Preferences>(PREFERENCES_ENDPOINT, {
    withCredentials: true,
    headers: {
      'x-cdp-project': project,
    },
  });

export const setPreferencesApi = ({
  project,
  preferences,
}: {
  project: string;
  preferences: DeepPartial<Preferences>;
}) =>
  getClient().patch(PREFERENCES_ENDPOINT, {
    withCredentials: true,
    data: preferences,
    headers: {
      'x-cdp-project': project,
    },
  });
