import React, { useMemo, useState } from 'react';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import Layers from 'utils/zindex';
import FeedbackLink from 'components/FeedbackLink';
import { Button } from '@cognite/cogs.js';

import {
  UnreachableCaseError,
  ImpossibleStateError,
  ErrorId,
} from '@cognite/react-errors';
import Overlay from 'components/Overlay';
import config from 'utils/config';
import ErrorAlert from 'components/ErrorAlert';
import { usePermissionsChecks, Errors } from './hooks';

type Props = {
  children: React.ReactNode;
};

const PermissionsCheckContainer = ({ children }: Props) => {
  const { t } = useTranslation('PermissionsCheckContainer');
  const { success, errors } = usePermissionsChecks();
  const [showOverlay, setShowOverlay] = useState(true);

  const overlayContents = useMemo(() => {
    if (success) {
      return null;
    }
    const errorMessages = Object.entries(errors)
      .filter((entry) => {
        return !!entry[1];
      })
      .map(([key, error]) => {
        if (!error) {
          // TypeScript can't see that error is definitely not undefined here.
          // If we ever wind up in this situation then it means something went
          // drastically wrong.
          throw new ImpossibleStateError(
            'If an error was undefined it would have been filtered above'
          );
        }
        const { errorId } = error;
        const typedKey = key as keyof Errors;
        switch (typedKey) {
          case 'dataSetsNotDefined':
            return (
              <ErrorAlert
                key="dataSetsNotDefined"
                type="error"
                message={
                  <Trans t={t} i18nKey="dataSetsNotDefined_message">
                    No datasets found
                  </Trans>
                }
                description={
                  <>
                    <Trans t={t} i18nKey="dataSetsNotDefined_description">
                      We were unable to get datasets for your account&apos;s
                      permissions. Please contact{' '}
                      <FeedbackLink
                        id="PermissionCheck_dataSetsNotDefined"
                        subject={t('dataSetsNotDefined_subject', {
                          defaultValue: 'Unable to use application',
                        })}
                      >
                        support@cognite.com
                      </FeedbackLink>{' '}
                      to be granted write access to this application.
                    </Trans>
                    <ErrorId id={errorId} />
                  </>
                }
              />
            );
          case 'unknown':
            return (
              <ErrorAlert
                key="unknown"
                type="error"
                message={
                  <Trans t={t} i18nKey="unknown_message">
                    An unknown error occurred
                  </Trans>
                }
                description={
                  <>
                    <Trans t={t} i18nKey="unknown_description">
                      The application could not be initialized. Please contact{' '}
                      <FeedbackLink
                        id="PermissionCheck_unknown"
                        subject={t('unknown_subject', {
                          defaultValue: 'Unable to use application',
                        })}
                      >
                        support@cognite.com
                      </FeedbackLink>{' '}
                      to get help with this issue.
                    </Trans>
                    <ErrorId id={errorId} />
                  </>
                }
              />
            );
          default:
            throw new UnreachableCaseError(typedKey);
        }
      });

    return errorMessages;
  }, [errors, success, t]);

  return (
    <>
      {success && children}
      {overlayContents && showOverlay && (
        <Overlay zIndex={Layers.FATAL_ERROR_OVERLAY}>
          {overlayContents}
          {config.env === 'development' && (
            <div>
              <Button type="danger" onClick={() => setShowOverlay(false)}>
                Thanks for the warning
              </Button>
            </div>
          )}
        </Overlay>
      )}
    </>
  );
};

export default withI18nSuspense(PermissionsCheckContainer);
