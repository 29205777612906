import React from 'react';
import { SIDECAR } from 'utils/sidecar';
import { useAuth } from 'features/auth';
import { Alert } from 'antd';
import styled from 'styled-components/macro';
import { withI18nSuspense, useTranslation, Trans } from '@cognite/react-i18n';

type Props = {
  children: React.ReactNode;
};

const ClusterCheckContainer = ({ children }: Props) => {
  const { t } = useTranslation('ClusterCheckContainer');
  const { cdfApiBaseUrl, applicationId } = SIDECAR;
  const { project } = useAuth();

  if (project.startsWith('omv-') && !cdfApiBaseUrl.includes('omv')) {
    if (applicationId === 'bestday-staging') {
      return (
        <Container>
          <Alert
            style={{ width: '50vw' }}
            message={
              <Trans t={t} i18nKey="wrongCluster_message">
                Wrong cluster :-(
              </Trans>
            }
            description={
              <div>
                It looks like you tried to open an OMV project, but aren&apos;t
                pointing to the OMV cluster. This will give you a very broken
                experience.
                <br />
                For this app to work, you need to be on this domain: <br />
                <a href="https://staging.bestday.omv.cogniteapp.com">
                  <code>
                    staging.bestday<strong>.omv</strong>.cogniteapp.com
                  </code>
                </a>
                <div style={{ marginLeft: '10em' }}>
                  <span role="img" aria-label="point up">
                    ☝️
                  </span>{' '}
                  This is important!
                </div>
              </div>
            }
          />
        </Container>
      );
    }
    if (applicationId === 'bestday') {
      window.location.href = 'https://bestday.omv.cogniteapp.com';
    }
  }

  return <>{children}</>;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

export default withI18nSuspense(ClusterCheckContainer);
