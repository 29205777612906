import React, { MouseEvent, useMemo, useState } from 'react';
import { Body, Button, Icon, Title, toast } from '@cognite/cogs.js';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { Tree } from 'antd';
import { Key } from 'antd/lib/table/interface';
import { getDynamicCollections } from 'features/collections/selectors';
import UnstyledLink from 'components/accessibility/UnstyledLink';
import { Pages } from 'utils/models/enums';
import { Collection, Favorite } from 'utils/models/collections';
import { useSelector } from 'react-redux';
import { useCollections } from 'features/collections';
import createToastOptions from 'utils/toasts/createToastOptions';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { Asset } from '@cognite/sdk';
import useNavigation from 'utils/useNavigation';
import DynamicCollectionModal, {
  DynamicCollection,
} from '../DynamicCollection/DynamicCollectionModal';
import {
  CollectionWrapper,
  StyledCollectionRow,
  TreeWrapper,
} from './elements';
import CollectionRow from './CollectionRow';

const DynamicCollectionTree = () => {
  const [isCollectionsModalOpen, setIsCollectionsModalOpen] = useState(false);
  const [isEditCollectionsModalOpen, setIsEditCollectionsModalOpen] = useState<
    string | undefined
  >();
  const { removeDynamicCollection, activeCollection } = useCollections();
  const { isCollectionPage } = useNavigation();
  const collections = useSelector(getDynamicCollections);
  const { t } = useTranslation('CollectionsTree');
  const collectionsTitle = t('DynamicCollectionsRootTitle', {
    defaultValue: 'Dynamic Collections',
  });
  const { selectedWell } = useCurrentAsset();

  const selectedKeys: string[] = useMemo(() => {
    if (isCollectionPage && activeCollection) {
      return [activeCollection.key];
    }
    return collections
      .filter((collection) => {
        return collection.wells.some(
          (well: Asset) => well.externalId === selectedWell?.externalId
        );
      })
      .map((collection) => `${collection.key}-${selectedWell?.externalId}`);
  }, [
    activeCollection,
    collections,
    isCollectionPage,
    selectedWell?.externalId,
  ]);

  const [expandedKeys, setExpandedKeys] = useState<Key[]>([]);

  const treeModel = useMemo(
    () =>
      collections
        .map((dynamicCollection: DynamicCollection) => ({
          collection: {
            key: dynamicCollection.key,
            name: dynamicCollection.name,
            favorites: dynamicCollection.wells.map(
              ({ externalId, name }) =>
                ({
                  externalId,
                  name,
                } as Favorite)
            ),
            rootAssetExternalId: dynamicCollection.rootAssetExternalId,
          } as Collection,
          dynamicCollection,
        }))
        .map(
          ({
            collection,
            dynamicCollection,
          }: {
            collection: Collection;
            dynamicCollection: DynamicCollection;
          }) => ({
            title: (
              <CollectionRow
                collection={collection}
                removeCollection={removeDynamicCollection}
                setIsCollectionsModalOpen={setIsEditCollectionsModalOpen}
              >
                {isEditCollectionsModalOpen === dynamicCollection.key && (
                  <DynamicCollectionModal
                    collection={dynamicCollection}
                    onClose={() => setIsEditCollectionsModalOpen(undefined)}
                  />
                )}
              </CollectionRow>
            ),
            key: collection.key,
            children:
              collection.favorites && collection.favorites.length
                ? collection.favorites
                    .sort((favA, favB) =>
                      favA.name.localeCompare(favB.name, undefined, {
                        numeric: true,
                      })
                    )
                    .map(({ externalId: key, name: title }) => ({
                      key: `${collection.key}-${key}`,
                      title: (
                        <UnstyledLink
                          to={`/assets/${encodeURIComponent(key)}/${
                            Pages.DeepDive
                          }`}
                          style={{
                            width: '100%',
                            display: 'inline-block',
                          }}
                        >
                          {title}
                        </UnstyledLink>
                      ),
                    }))
                : undefined,
          })
        ),
    [collections, isEditCollectionsModalOpen, removeDynamicCollection]
  );

  return (
    <CollectionWrapper direction="column">
      <StyledCollectionRow>
        <Title level={6}>{collectionsTitle}</Title>
        <Button
          size="small"
          onClick={(event: MouseEvent) => {
            event.stopPropagation();
            setIsCollectionsModalOpen(true);
          }}
          style={{ padding: '4px 6px' }}
        >
          <Icon type="Add" />
        </Button>
      </StyledCollectionRow>
      <TreeWrapper>
        {treeModel.length > 0 ? (
          <Tree
            treeData={treeModel}
            selectedKeys={selectedKeys}
            expandedKeys={expandedKeys}
            onExpand={setExpandedKeys}
            switcherIcon={<Icon type="CaretDown" />}
          />
        ) : (
          <Body level={2}>
            <Trans t={t} i18nKey="no-dynamic-collections-message">
              You don’t have any dynamic collections. <br />
              Use the + button above to create one
            </Trans>
          </Body>
        )}
      </TreeWrapper>
      {isCollectionsModalOpen && (
        <DynamicCollectionModal
          onClose={(isSuccessful: boolean, name: string) => {
            setIsCollectionsModalOpen(false);
            if (isSuccessful && name) {
              toast.success(
                <Body level={2}>
                  <Trans t={t} i18nKey="toast-created-dynamic-collection">
                    The dynamic collection <strong>{{ name }}</strong> was
                    created
                  </Trans>
                </Body>,
                createToastOptions()
              );
            }
          }}
        />
      )}
    </CollectionWrapper>
  );
};

export default withI18nSuspense(DynamicCollectionTree);
