import { ToastContainer } from '@cognite/cogs.js';
import styled from 'styled-components/macro';

export enum ToastContainers {
  Default = 'default',
  ChartSelection = 'chart-selection',
  ZoomProTip = 'zoom-pro-tip',
  Notifications = 'notifications',
}

export const StyledToastContainer = styled(ToastContainer).attrs({
  containerId: ToastContainers.Default,
  enableMultiContainer: true,
})`
  width: 600px;
`;

export const ChartSelectionToastContainer = styled(ToastContainer).attrs({
  containerId: ToastContainers.ChartSelection,
  enableMultiContainer: true,
})`
  width: 90vw;
`;

export const ZoomProTipToastContainer = styled(ToastContainer).attrs({
  containerId: ToastContainers.ZoomProTip,
  enableMultiContainer: true,
})`
  width: 328px;
  height: 272px;

  // We don't show the default close button and therefore don't want it taking space
  #zoom-pro-tip > button {
    width: 0;
  }
`;

export const NotificationToastContainer = styled(ToastContainer).attrs({
  containerId: ToastContainers.Notifications,
  enableMultiContainer: true,
})`
  .cogs-toast {
    padding: 8px;

    background: var(--cogs-primary);

    border-radius: var(--cogs-border-radius--small);

    box-shadow: 0px 1px 1px 1px rgba(79, 82, 104, 0.06),
      0px 1px 2px 1px rgba(79, 82, 104, 0.04);
  }

  button[aria-label='close'] {
    display: none;
  }
`;
