import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import { Title, Checkbox } from '@cognite/cogs.js';
import RangePicker from 'components/RangePicker';
import { useTimeRange } from 'features/timeRange';

import { useUnitConversion } from 'features/unitConversion';

import ProductionData from './ProductionData';
import {
  CheckboxList,
  Container,
  Indented,
  Section,
  StyledLi,
} from './elements';
import {
  DateRange,
  ExportData,
  FormatSpec,
  ProductionDataOptions,
} from '../types';

export type Props = {
  onChange: (spec: FormatSpec) => void;
};

const Contents = ({ onChange }: Props) => {
  const { t } = useTranslation('ExportModalContents');
  const { t: tProducts } = useTranslation('Products');
  const { start, end } = useTimeRange();

  const { getPreferredUnitByProduct } = useUnitConversion();

  const [dateRange, setDateRange] = useState<DateRange>(() => [start, end]);

  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [exportData, setExportData] = useState<
    Omit<ExportData, 'productionData'>
  >(() => ({
    comments: true,
    deferments: true,
    bestDayDeferments: true,
    deviations: true,
    bestDay: true,
  }));

  const onDataSelected = (field: keyof ExportData) => (ticked: boolean) => {
    setExportData((prev) => ({ ...prev, [field]: ticked }));
  };

  const onDateChanged = (newStart: number, newEnd: number) => {
    if (!newStart || !newEnd) {
      return;
    }
    setDateRange([newStart, newEnd]);
  };

  const updateProductionData = useCallback((changed: string[]) => {
    setSelectedProducts(changed);
  }, []);

  useEffect(() => {
    const updatedSpec: FormatSpec = {
      data: {
        ...exportData,
        productionData: selectedProducts.reduce(
          (acc, product) => ({
            ...acc,
            [product]: {
              unit: getPreferredUnitByProduct(product),
              displayName: tProducts(`${product}-product`, {
                defaultValue: product,
              }),
            },
          }),
          {}
        ) as ProductionDataOptions,
      },
      dateRange,
    };
    onChange(updatedSpec);
  }, [
    dateRange,
    exportData,
    getPreferredUnitByProduct,
    onChange,
    selectedProducts,
    tProducts,
  ]);

  const isAnyChecked = () => {
    return exportData.deferments || exportData.bestDayDeferments || false;
  };

  const onSelectDeferments = (nextState: boolean) => {
    setExportData((prev) => ({
      ...prev,
      deferments: nextState,
      bestDayDeferments: nextState,
    }));
  };

  return (
    <Container>
      <Section>
        <Title level={6}>
          <Trans t={t} i18nKey="dateRange_header">
            Date range
          </Trans>
        </Title>
        <RangePicker
          onChange={onDateChanged}
          start={dateRange[0]}
          end={dateRange[1]}
        />
      </Section>

      <Section>
        <Title level={6}>
          <Trans t={t} i18nKey="dataChoice_header">
            Data to export
          </Trans>
        </Title>
        <CheckboxList>
          <StyledLi>
            <Checkbox
              name="deferments"
              checked={isAnyChecked()}
              onChange={(nextState) => onSelectDeferments(nextState)}
            >
              <Trans t={t} i18nKey="deferments_label">
                Deferments
              </Trans>
            </Checkbox>
          </StyledLi>
          <Indented>
            <StyledLi>
              <Checkbox
                name="sourceDeferments"
                checked={!!exportData.deferments}
                onChange={onDataSelected('deferments')}
              >
                <Trans t={t} i18nKey="source_deferments_label">
                  Source Deferments
                </Trans>
              </Checkbox>
            </StyledLi>
            <StyledLi>
              <Checkbox
                name="bestDayDeferments"
                checked={!!exportData.bestDayDeferments}
                onChange={onDataSelected('bestDayDeferments')}
              >
                <Trans t={t} i18nKey="best_day_deferments_label">
                  BestDay Deferments
                </Trans>
              </Checkbox>
            </StyledLi>
          </Indented>
          <StyledLi>
            <Checkbox
              name="deviations"
              checked={!!exportData.deviations}
              onChange={onDataSelected('deviations')}
            >
              <Trans t={t} i18nKey="deviations_label">
                Deviations
              </Trans>
            </Checkbox>
          </StyledLi>
          <StyledLi>
            <Checkbox
              name="comments"
              checked={!!exportData.comments}
              onChange={onDataSelected('comments')}
            >
              <Trans t={t} i18nKey="comments_label">
                Comments
              </Trans>
            </Checkbox>
          </StyledLi>
          <StyledLi>
            <Checkbox
              name="bestDay"
              checked={!!exportData.bestDay}
              onChange={onDataSelected('bestDay')}
            >
              <Trans t={t} i18nKey="bestDay_label">
                BestDay
              </Trans>
            </Checkbox>
          </StyledLi>
          <StyledLi>
            <ProductionData onChange={updateProductionData} />
          </StyledLi>
        </CheckboxList>
      </Section>
    </Container>
  );
};

export default withI18nSuspense(Contents);
