import { getClient } from 'utils/cognitesdk';
import { useEffect, useState } from 'react';

const DATABASE_NAME = 'BEST_DAY';
const TABLE_NAME = 'CONFIGURATION';

const useCustomerSupport = () => {
  const [customerSupport, setCustomerSupport] = useState('');
  const [customerFeedback, setCustomerFeedback] = useState('');

  useEffect(() => {
    let canceled = false;
    const cancel = () => {
      canceled = true;
    };

    const fetch = async () => {
      const { items: tables } = await getClient().raw.listTables('BEST_DAY');
      if (tables.find((table) => table.name === TABLE_NAME)) {
        const { items: rows } = await getClient().raw.listRows(
          DATABASE_NAME,
          TABLE_NAME
        );
        const customerSupportRow = rows.find(
          (row) => row.key === 'CUSTOMER_SUPPORT'
        );

        const customerFeedbackRow = rows.find(
          (row) => row.key === 'CUSTOMER_FEEDBACK'
        );

        if (canceled) {
          return;
        }

        setCustomerSupport(
          customerSupportRow ? customerSupportRow.columns.VALUE.trim() : ''
        );
        setCustomerFeedback(
          customerFeedbackRow ? customerFeedbackRow.columns.VALUE.trim() : ''
        );
      }
    };

    fetch();

    return cancel;
  }, []);

  return { customerSupport, customerFeedback };
};

export default useCustomerSupport;
