import React from 'react';
import { useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import ValueTooltip from 'components/ValueTooltip';
import useNumberFormats from 'utils/useNumberFormats';
import { useUnitConversion } from 'features/unitConversion';

type ProductVolumeUnitTransProps = {
  product?: string;
  unit: string;
  volume?: number;
  showTooltip?: boolean;
  afterProduct?: string;
  unitWithBrackets?: boolean;
};

export const ProductVolumeUnitTrans = withI18nSuspense(
  ({
    product,
    unit,
    volume,
    showTooltip,
    afterProduct,
    unitWithBrackets = true,
  }: ProductVolumeUnitTransProps) => {
    const { t: tProducts } = useTranslation('Products');
    const { translateUnit } = useUnitConversion();
    const { decimal } = useNumberFormats();

    const getVolumeLayout = (children: React.ReactElement) => {
      if (showTooltip) {
        return <ValueTooltip content={volume || ''}>{children}</ValueTooltip>;
      }
      return children;
    };

    const translatedProduct = product
      ? tProducts(`${product}-product`, {
          defaultValue: product,
        })
      : '';
    const translatedUnit = translateUnit(unit);
    const unitString = unitWithBrackets
      ? `(${translatedUnit})`
      : translatedUnit;
    const formattedVolume =
      volume !== undefined ? (
        getVolumeLayout(<>{decimal(volume, { maxLength: 5, precision: 2 })}</>)
      ) : (
        <></>
      );
    return (
      <>
        {translatedProduct}
        {afterProduct} {formattedVolume} {unit ? unitString : ''}
      </>
    );
  }
);
