import { CogniteEvent, EventFilter } from '@cognite/sdk';
import { getProductConfig } from 'hooks/useGraphQlQuery';
import { SIDECAR } from 'utils/sidecar';
import { getClient } from 'utils/cognitesdk';
import { TemplatesMapping } from 'hooks/types';
import { getEventsGroupList, getEventsSegment } from 'hooks/useOperationClient';
import { PropsToEdit } from './types';

const { bestdayApiBaseUrl } = SIDECAR;
const DEVIATIONS_ENDPOINT = new URL('deviations', bestdayApiBaseUrl).href;

export type Product = {
  prefix: string;
  type: string;
  phase: string;
  aggregatesTo: string[];
};

export const fetchDeviationGroups = async (
  customFilter: Partial<EventFilter>,
  templateInfo: TemplatesMapping
) => {
  const product = await getProductConfig({ templateInfo });
  const products = product.map((product) => ({
    ...product,
    prefix: product.type.toLowerCase(),
  }));
  const filteredGroups = await getEventsGroupList({ customFilter });
  const promises = await Promise.all(
    filteredGroups.map((group) =>
      getEventsSegment({ customFilter, group, products })
    )
  );
  return promises.flat();
};

export const editGroup = async (
  groupId: string,
  updateProps: PropsToEdit,
  project: string
): Promise<CogniteEvent[]> => {
  const updatedEvent = await getClient().post(`${DEVIATIONS_ENDPOINT}/edit`, {
    withCredentials: true,
    data: {
      groupId,
      propsToEdit: updateProps,
    },
    headers: {
      'x-cdp-project': project,
    },
  });

  return updatedEvent.data;
};
