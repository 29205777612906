import styled, { css } from 'styled-components/macro';

export type Props = {
  zIndex?: number;
};

/**
 * Note: In order to not cover the whole screen, the parent needs to be
 * using "position: relative".
 */
export default styled.div<Props>`
  ${(props) => {
    if (props.zIndex !== undefined) {
      return css`
        z-index: ${props.zIndex};
      `;
    }
    return '';
  }}
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fffa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  backdrop-filter: blur(4px);
`;
