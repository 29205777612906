import React, {
  useState,
  useCallback,
  useMemo,
  PropsWithChildren,
} from 'react';
import {
  Dropdown,
  Menu,
  Button,
  ButtonProps,
  DropdownProps,
} from '@cognite/cogs.js';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import './styles.css';
import { CSSProperties } from 'styled-components/macro';

export type Props = DropdownProps &
  PropsWithChildren<{
    header?: React.ReactNode | string;
    items: React.ReactElement[];
    buttonProps?: ButtonProps;
    unstyled?: boolean;
    style?: CSSProperties;
    className?: string;
    error?: string;
  }>;

const Select = ({
  header = null,
  items,
  children,
  buttonProps = {},
  unstyled = false,
  style,
  className = '',
  error,
  ...dropDownProps
}: Props) => {
  const [visible, setVisible] = useState(false);

  const onClick = useCallback((delegate): MenuItemProps['onClick'] => {
    return (...args: unknown[]) => {
      setVisible(false);
      delegate(...args);
    };
  }, []);

  const interceptedChildren = useMemo(() => {
    return React.Children.map(items, (child) => {
      const { onClick: customOnClick, ...rest } = child.props || {};
      return React.cloneElement(child, {
        ...rest,
        onClick: onClick(customOnClick),
      });
    });
  }, [items, onClick]);

  const { onClick: underlyingOnClick } = buttonProps;
  const onButtonClick: ButtonProps['onClick'] = useCallback(
    (e) => {
      e.stopPropagation();
      setVisible((prev) => !prev);
      if (underlyingOnClick) {
        underlyingOnClick(e);
      }
    },
    [underlyingOnClick]
  );

  const allStyles = useMemo(() => {
    if (!error) {
      return style;
    }
    return {
      ...style,
      border: '2px solid #e32351',
    };
  }, [style, error]);

  return (
    <Dropdown
      onClickOutside={() => {
        setVisible(false);
      }}
      visible={visible}
      content={
        <Menu onClick={(e) => e.stopPropagation()}>
          {header && <Menu.Header>{header}</Menu.Header>}
          {interceptedChildren}
        </Menu>
      }
      trigger={undefined}
      className={className}
      {...dropDownProps}
    >
      <Button
        icon="ChevronDown"
        iconPlacement="right"
        unstyled={unstyled}
        {...buttonProps}
        onClick={onButtonClick}
        className={[!unstyled && 'styled', 'select'].filter(Boolean).join(' ')}
        style={allStyles}
      >
        {children}
      </Button>
    </Dropdown>
  );
};

export default Select;
