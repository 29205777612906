import retry from 'retry';
import { CogniteEvent, EventFilter, IdEither } from '@cognite/sdk';
import includes from 'lodash/includes';
import indexOf from 'lodash/indexOf';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import get from 'lodash/get';
import filter from 'lodash/filter';
import compact from 'lodash/compact';
import last from 'lodash/last';
import uniqBy from 'lodash/uniqBy';
import pMap, { Mapper } from 'p-map';
import { getClient } from 'utils/cognitesdk';
import { Product } from 'utils/models/deviationGroupes';
import { HYDROCARBON } from 'utils/products';
import { reduceArrToObj } from 'utils';
import { ExportedWell, MonthSummeryExport, TemplatesMapping } from './types';

type ServiceProps = {
  chunks: string[][];
  fetchOperation: Mapper<string[], unknown>;
  callback: Function;
  signal?: AbortSignal;
};
type ExportDataProps = {
  query: string;
  templateInfo: TemplatesMapping;
  defermentEvents: CogniteEvent[];
  deviationEvents: CogniteEvent[];
};

const aggregateList = [HYDROCARBON];
const BESTDAY_DEVIATION_SEGMENT = 'bestday_deviation_segment';
const BESTDAY_DEVIATION_GROUP = 'bestday_deviation_group';
const BESTDAY_DEVIATION = 'bestday_deviation';
const PRODUCTION_DEVIATION = 'production_deviation';
const operation = retry.operation({
  minTimeout: 50,
  maxTimeout: 100,
  forever: true,
});
const filterAggregatedProduct = (
  products: Product[],
  aggregateTo: string
): Product[] => {
  return products.filter((product) =>
    product.aggregatesTo.includes(aggregateTo)
  );
};
export const operationSequenceService = async ({
  chunks,
  fetchOperation,
  callback,
  signal,
}: ServiceProps) => {
  try {
    const responses = await pMap(chunks, fetchOperation, {
      concurrency: 10,
      signal,
    });
    callback({ responses });
  } catch (error) {
    callback({
      message: `${error}`.includes('signal') ? ' Canceled… ' : `${error}`,
    });
  } finally {
    callback({ isEnded: true });
  }
};
export const getDeviationGrup = (
  segments: CogniteEvent[],
  productsConfig: Product[]
): Promise<CogniteEvent[]> =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const responses = await Promise.all(
        map(
          groupBy(segments, 'metadata.groupId'),
          async (segmentsList, assetExternalIds) => {
            const [
              {
                // @ts-ignore
                metadata: { status },
              },
            ] = await getClient().events.retrieve([
              { externalId: assetExternalIds },
            ]);
            return map(segmentsList, (segment) => {
              return {
                startTime: segment.startTime!,
                endTime: segment.endTime!,
                status,
                asset_external_id: get(segment, 'metadata.assetExternalId'),
                volumes: map(productsConfig, (product) => ({
                  type: product.prefix,
                  volume: Number(get(segment, `metadata.${product.prefix}`)),
                }))
                  .concat(
                    aggregateList.map((aggregateTo) => ({
                      type: aggregateTo.toLowerCase(),
                      volume: filterAggregatedProduct(
                        productsConfig,
                        aggregateTo
                      ).reduce((sum, product) => {
                        return (
                          sum +
                          (Number(get(segment, `metadata.${product.prefix}`)) ||
                            0)
                        );
                      }, 0),
                    }))
                  )
                  .filter((v) => !!v.volume),
              };
            });
          }
        )
      );
      resolve(responses.flat() as unknown as CogniteEvent[]);
    });
  });
export const getSegmentsEvents = (filter: {
  activeAtTime: { min: number; max: number };
  assetExternalIds: string[];
}): Promise<CogniteEvent[]> =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const events = await getClient()
        .events.list({
          filter: {
            type: BESTDAY_DEVIATION_SEGMENT,
            ...filter,
          },
          sort: { createdTime: 'asc' },
          limit: 1000,
        })
        .autoPagingToArray({ limit: -1 });
      resolve(events);
    });
  });
export const getDefermentEvents = (filter: {
  activeAtTime: { min: number; max: number };
  assetExternalIds: string[];
}): Promise<CogniteEvent[]> =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const events = await getClient()
        .events.list({
          filter: {
            type: PRODUCTION_DEVIATION,
            subtype: BESTDAY_DEVIATION,
            ...filter,
          },
          sort: { createdTime: 'asc' },
          limit: 1000,
        })
        .autoPagingToArray({ limit: -1 });
      resolve(events);
    });
  });
export const getExportData = async ({
  query,
  templateInfo,
  defermentEvents,
  deviationEvents,
}: ExportDataProps): Promise<MonthSummeryExport[]> =>
  new Promise((resolve, reject) => {
    operation.attempt(async () => {
      const result = await getClient()
        .templates.group(templateInfo.APPLICATION_DATA.group)
        .version(templateInfo.APPLICATION_DATA.version)
        .runQuery({ query });
      if (result?.errors?.length) {
        const msgList = map(result?.errors, 'message');
        const msgListSplited = map(msgList, (elem: string) => {
          const splited = elem.split(' ');
          let status = splited[indexOf(splited, 'status') + 1];
          if (!includes(splited, 'status')) {
            status = splited[indexOf(splited, 'code') + 1];
          }
          return status.slice(0, status.length - 1);
        });
        const e = uniq(msgListSplited)[0];
        if (
          e === '400' ||
          e === '401' ||
          e === '429' ||
          e === '503' ||
          e === '504'
        ) {
          operation.retry(new Error('retry'));
        } else {
          // eslint-disable-next-line no-console
          console.warn('Error:', e, query);
          reject(operation.mainError());
        }
        return;
      }
      resolve(
        map(result.data?.wellQuery?.items, (well) => ({
          wellName: well.asset.name,
          wellType: get(last(well?.mainProduct?.datapoints), 'value') || '',
          products: map(
            groupBy(well.products, 'type'),
            (value: ExportedWell[]) =>
              last(
                value.sort((a, b) => {
                  if (a.production?.length > b.production?.length) return 1;
                  if (a.capacity?.length > b.capacity?.length) return 1;
                  return -1;
                })
              )
          ) as unknown as ExportedWell[],
          deferments: well?.deferments || [],
          eventsGroup: filter(deviationEvents, {
            asset_external_id: well.asset.externalId!,
          }),
          defermentEvent: map(
            filter(defermentEvents, {
              metadata: { asset_external_id: well.asset.externalId! },
            }),
            (event) =>
              reduceArrToObj(
                compact(
                  map(
                    get(event, 'metadata'),
                    (value, key) =>
                      key.includes('deferment') &&
                      key.includes('current') && { [key]: value }
                  )
                ),
                {
                  startTime: get(event, 'startTime'),
                  endTime: get(event, 'startTime'),
                }
              )
          ),
        })).sort((a, b) =>
          get(a, 'wellName') > get(b, 'wellName') ? 1 : -1
        ) as unknown as MonthSummeryExport[]
      );
    });
  });
export const getEventsGroupList = ({
  customFilter,
}: {
  customFilter: Partial<EventFilter>;
}): Promise<CogniteEvent[]> =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const items = await getClient()
        .events.list({
          filter: {
            type: BESTDAY_DEVIATION_GROUP,
            ...customFilter,
          },
          limit: 1000,
        })
        .autoPagingToArray({ limit: -1 });
      resolve(items);
    });
  });
export const getEventsSegment = ({
  group,
  products,
}: {
  group: CogniteEvent;
  products: Product[];
  customFilter?: Partial<EventFilter>;
}): Promise<CogniteEvent[]> =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const segments = await getClient()
        .events.list({
          filter: {
            // ...customFilter,
            type: BESTDAY_DEVIATION_SEGMENT,
            metadata: { groupId: group.externalId! },
          },
          limit: 1000,
        })
        .autoPagingToArray({ limit: -1 });
      resolve({
        ...group,
        // @ts-ignore
        deviationSegments: segments.map((event) => {
          const { metadata = {} } = event || {};
          return {
            id: event.externalId,
            groupId: metadata.groupId,
            creator: JSON.parse(metadata.creator || '{}'),
            deviationId: metadata.deviationId,
            startTime: event.startTime?.valueOf(),
            endTime: event.endTime?.valueOf(),
            volumes: products
              .map((product) => ({
                type: product.prefix,
                volume: Number(metadata[product.prefix]),
              }))
              .concat(
                aggregateList.map((aggregateTo) => ({
                  type: aggregateTo.toLowerCase(),
                  volume: filterAggregatedProduct(products, aggregateTo).reduce(
                    (sum, product) => {
                      return sum + (Number(metadata[product.prefix]) || 0);
                    },
                    0
                  ),
                }))
              )
              .filter((v) => !!v.volume),
          };
        }),
      });
    });
  });
export const getLowDeferments = (
  customFilter: Partial<EventFilter>
): Promise<CogniteEvent[]> =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const response = await getClient()
        .events.list({
          filter: {
            type: 'low-deferment',
            ...customFilter,
          },
        })
        .autoPagingToArray({ limit: -1 });
      resolve(response);
    });
  });
export const getObservations = (
  customFilter: Partial<EventFilter>
): Promise<CogniteEvent[]> =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const response = await getClient()
        .events.list({
          filter: {
            type: 'bestday_observations',
            ...customFilter,
          },
        })
        .autoPagingToArray({ limit: -1 });
      resolve(response);
    });
  });
export const getProducts = ({
  externalId,
  level,
  products = [],
  templateInfo,
}: {
  externalId: string;
  level?: 'well' | 'system';
  products: Array<string>;
  templateInfo: TemplatesMapping;
}): Promise<Product[]> =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const response = getClient()
        .templates.group(templateInfo.APPLICATION_DATA.group)
        .version(templateInfo.APPLICATION_DATA.version)
        .runQuery({
          query: `{
    ${level}Query(filter: { _externalId: { eq: "${externalId}" }}){
    items {
      products${
        // eslint-disable-next-line no-extra-boolean-cast
        products.length
          ? `(_filter: {
        type: { anyOfTerms: ${JSON.stringify(products)} }
      })`
          : ''
      } {
        type
      }
    }
  }
}`,
        })
        .then((result) => {
          // fix the returned doubled values if any
          const [system] = result.data?.systemQuery?.items || [];
          const [well] = result.data?.wellQuery?.items || [];
          const wellProducts = uniqBy(
            well?.products,
            'type'
          ) as unknown as Product[];
          return well?.products ? wellProducts : system?.products || [];
        });
      resolve(response);
    });
  });
export const deleteEvents = (ids: IdEither[]) =>
  new Promise((resolve) => {
    operation.attempt(async () => {
      const response = await getClient().events.delete(ids);
      resolve(response);
    });
  });
