import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Observations } from 'utils/models/observations';

type InitialState = {
  observations: Observations[];
  loading: boolean;
  error?: Error;
  isCreating: boolean;
};

const observationsGroupsSlice = createSlice({
  name: 'observations',
  initialState: {
    observations: [],
    loading: false,
    isCreating: false,
  } as InitialState,
  reducers: {
    toggleCreate: (state, action: PayloadAction<{ isCreating: boolean }>) => ({
      ...state,
      isCreating: action.payload.isCreating,
    }),
    create: (
      state,
      action: PayloadAction<{ newObservation: Observations }>
    ) => {
      const { newObservation } = action.payload;
      return {
        ...state,
        observations: [...state.observations, newObservation],
        isCreating: false,
      };
    },
    getObservations: (state, action: PayloadAction<{ loading: boolean }>) => {
      const { loading } = action.payload;
      return { ...state, loading };
    },
    getObservationsSuccess: (
      state,
      action: PayloadAction<{
        observations: Observations[];
        loading: boolean;
      }>
    ) => {
      const { observations, loading } = action.payload;
      return {
        ...state,
        observations,
        loading,
        error: undefined,
      };
    },
    getObservationsError: (
      state,
      action: PayloadAction<{ loading: boolean; error: Error }>
    ) => {
      const { loading, error } = action.payload;
      return {
        ...state,
        observations: [],
        loading,
        error,
      };
    },
  },
});

export type ObservationsState = ReturnType<
  typeof observationsGroupsSlice.reducer
>;

export default observationsGroupsSlice;
