import { Asset } from '@cognite/sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  facilities: Asset[];
  loading: boolean;
  error?: Error;
};

const facilitiesSlice = createSlice({
  name: 'facilities',
  initialState: {
    facilities: [],
    loading: false,
  } as InitialState,
  reducers: {
    getFacilities: (state, action: PayloadAction<{ loading: boolean }>) => {
      const { loading } = action.payload;
      return { ...state, loading, facilities: [] };
    },
    getFacilitiesSuccess: (
      state,
      action: PayloadAction<{
        facilities: Asset[];
        loading: boolean;
      }>
    ) => {
      const { facilities, loading } = action.payload;
      return {
        ...state,
        facilities,
        loading,
      };
    },
    getFacilitiesError: (
      state,
      action: PayloadAction<{ loading: boolean; error: Error }>
    ) => {
      const { loading, error } = action.payload;
      return {
        ...state,
        facilities: [],
        loading,
        error,
      };
    },
  },
});

export type FacilitiesState = ReturnType<typeof facilitiesSlice.reducer>;

export default facilitiesSlice;
