import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import {
  Button,
  Title,
  Checkbox,
  Body,
  Flex,
  Detail,
  Dropdown,
  Switch,
} from '@cognite/cogs.js';
import { useMetrics } from '@cognite/metrics';

import styled from 'styled-components/macro';
import useNavigation from 'utils/useNavigation';
import { useCollections } from 'features/collections';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useFilter, WellSummaryFilter } from 'features/preferences/filter';
import useDeviationFilterChips from 'features/deviationFilterChips/hooks';
import { mapWellTypeToWellTypeTitle } from 'utils/models/wellListItems';
import { useTimeRange } from 'features/timeRange';
import MonthPicker from 'components/MonthPicker/MonthPicker';
import { useUnitConversion } from 'features/unitConversion';
import { DEFAULT_UNIT, HYDROCARBON } from 'utils/products';
import {
  ExportWellSummaryDataOptions,
  FormatWellSummarySpec,
  ProductionDataOptions,
} from '../types';
import {
  CheckboxList,
  Container,
  Indented,
  Section,
  StyledLi,
} from './elements';
import ProductionData from './ProductionData';

export type Props = {
  products: {
    type: string;
    capacities: { type: string; isReference: boolean }[];
  }[];
  onChange: (spec: FormatWellSummarySpec) => void;
};
const WellSummaryContents = ({ onChange, products }: Props) => {
  const { t } = useTranslation('ExportModalContents');
  const { t: tProducts } = useTranslation('Products');
  const { t: tEventStatus } = useTranslation('EventStatus');
  const { t: tPreferences } = useTranslation('Preferences');
  const metrics = useMetrics('ExportModal');
  const { isCollectionPage } = useNavigation();
  const { activeCollection } = useCollections();
  const { selectedAsset } = useCurrentAsset();

  const { setExportTime, exportChunk } = useTimeRange();
  const { filter, setDeviationsFilter } =
    useFilter<WellSummaryFilter>('wellSummary');

  const {
    wells: { type: wellTypeFilters },
    deviations: deviationsFilters,
  } = filter;

  const { getChipsData } = useDeviationFilterChips({
    setDeviationsFilter,
  });
  const { getPreferredUnitByProduct } = useUnitConversion();
  const deviationsFilterChipsData = getChipsData(deviationsFilters);
  const referenceProduct = useMemo(() => {
    return products.find((it) => it.capacities.some((it) => it.isReference))
      ?.type;
  }, [products]);
  const [exportData, setExportData] = useState<ExportWellSummaryDataOptions>(
    () =>
      Object.assign(
        {
          well: { export: true },
          wellType: { export: true },
          dayByDay: { export: false },
        },
        ...products
          .filter((product) => product.type === referenceProduct)
          .map((product) => {
            const referenceCapacity = product.capacities
              .filter((capacity) => capacity.isReference)
              .map((capacity) => {
                return {
                  [`${product.type}_${capacity.type}`]: {
                    export: true,
                    product: product.type,
                    type: capacity.type,
                    multiplier: true,
                  },
                };
              });
            return Object.assign(
              {
                [`${product.type}_production`]: {
                  export: true,
                  product: product.type,
                  type: 'production',
                  multiplier: true,
                },
              },
              ...referenceCapacity
            );
          }),
        {
          productionEfficiency: {
            export: true,
            multiplier: true,
          },
        },
        ...products
          .filter((product) => product.type === referenceProduct)
          .map((product) => {
            const capacities = product.capacities
              .filter((capacity) => !capacity.isReference)
              .map((capacity) => {
                return {
                  [`${product.type}_${capacity.type}`]: {
                    export: true,
                    product: product.type,
                    type: capacity.type,
                    multiplier: true,
                  },
                };
              });
            return Object.assign({}, ...capacities);
          }),
        {
          THEOR: {
            export: true,
            multiplier: true,
            monthly: true,
          },
          deviations: {
            export: true,
            props: ['DETECTED', 'IGNORED', 'INVESTIGATING', 'EXPLAINED'],
            multiplier: true,
          },
          DETECTED: {
            export: true,
            monthly: true,
            intended: true,
            multiplier: true,
          },
          IGNORED: {
            export: true,
            monthly: true,
            intended: true,
            multiplier: true,
          },
          INVESTIGATING: {
            export: true,
            monthly: true,
            intended: true,
            multiplier: true,
          },
          EXPLAINED: {
            export: true,
            monthly: true,
            intended: true,
            multiplier: true,
          },
        },
        ...products
          .filter((product) => product.type !== referenceProduct)
          .map((product) => {
            const capacities = product.capacities
              .filter((capacity) => !capacity.isReference)
              .map((capacity) => {
                return {
                  [`${product.type}_${capacity.type}`]: {
                    export: true,
                    product: product.type,
                    type: capacity.type,
                    multiplier: true,
                  },
                };
              });
            return Object.assign(
              {
                [`${product.type}_production`]: {
                  export: true,
                  product: product.type,
                  type: 'production',
                  multiplier: true,
                },
              },
              ...capacities
            );
          }),
        {
          montly: { export: false },
          deferments: {
            export: true,
            monthly: true,
          },
        }
      )
  );
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const onDataSelected =
    (field: keyof ExportWellSummaryDataOptions) => (ticked: boolean) => {
      setExportData((prev) => ({
        ...prev,
        [field]: Object.assign(prev[field], { export: ticked }),
      }));
    };
  const onCheckAll = useCallback(
    (ticked: boolean) => {
      setExportData(
        Object.keys(exportData)
          .filter((key) => !['montly', 'dayByDay'].includes(key))
          .reduce(
            (acc, key) => {
              return {
                ...acc,
                [key]: Object.assign(exportData[key], { export: ticked }),
              };
            },
            {
              montly: exportData.montly,
              dayByDay: exportData.dayByDay,
            } as ExportWellSummaryDataOptions
          )
      );
    },
    [exportData]
  );
  const onDateChanged = useCallback(
    (newExportChunk: string) => {
      if (!newExportChunk) {
        return;
      }
      if (exportChunk !== newExportChunk) {
        setExportTime(newExportChunk);
      }
    },
    [exportChunk, setExportTime]
  );
  const [{ positive, aggregation }, setCustomFilters] = useState({
    positive: false,
    aggregation: false,
  });
  useEffect(() => {
    onChange({
      customFilters: { positive, aggregation },
      data: exportData,
      productionData: selectedProducts.reduce(
        (acc, product) => ({
          ...acc,
          [product]: {
            unit:
              product === HYDROCARBON
                ? DEFAULT_UNIT
                : getPreferredUnitByProduct(product),
            displayName: tProducts(`${product}-product`, {
              defaultValue: product,
            }),
          },
        }),
        {}
      ) as ProductionDataOptions,
    });
  }, [
    exportData,
    exportChunk,
    getPreferredUnitByProduct,
    onChange,
    selectedProducts,
    tProducts,
    positive,
    aggregation,
  ]);
  const [isVisible, setIsVisible] = useState(false);
  const allChecked = useMemo(() => {
    const checkValues = (object: ExportWellSummaryDataOptions): boolean[] =>
      Object.entries(object)
        .filter(([key]) => !['montly', 'dayByDay'].includes(key))
        .map(([_, value]) => {
          return value.export === true;
        })
        .flat();
    return checkValues(exportData).every((it: boolean) => it === true);
  }, [exportData]);
  const onSelectedGroup = useCallback((dataKey: string, ticked: boolean) => {
    setExportData((prev) => ({
      ...prev,
      [dataKey]: Object.assign(prev[dataKey], { export: ticked }),
      ...(prev[dataKey].props as string[])
        .map((key) => ({ [key]: Object.assign(prev[key], { export: ticked }) }))
        .reduce((acc, next) => ({ ...acc, ...next }), {}),
    }));
  }, []);
  const updateProductionData = useCallback((changed: string[]) => {
    setSelectedProducts(changed);
  }, []);
  return (
    <Container>
      <Section>
        <Checkbox
          name="Monthly Support"
          checked={exportData.montly?.export}
          onChange={onDataSelected('montly')}
        >
          <Trans t={t} i18nKey="montly_label">
            Month Support
          </Trans>
        </Checkbox>
        {exportData.montly?.export && (
          <div
            style={{
              display: 'flex',
            }}
          >
            <MonthPicker
              onChange={(exportChunk) => {
                metrics.track('Timerange_adjustedExport');
                onDateChanged(exportChunk);
              }}
            />
            <Dropdown
              visible={isVisible}
              onClickOutside={() => setIsVisible((prevState) => !prevState)}
              content={
                <Flex
                  direction="column"
                  style={{
                    margin: '0 16px',
                  }}
                >
                  <Checkbox
                    name="Day by Day"
                    checked={exportData.dayByDay?.export}
                    onChange={onDataSelected('dayByDay')}
                    style={{
                      margin: '4px 0',
                    }}
                  >
                    <Trans t={t} i18nKey="day_by_day">
                      day-by-day
                    </Trans>
                  </Checkbox>
                  <Checkbox
                    name="positive"
                    checked={!!positive}
                    onChange={() => {
                      setCustomFilters((prev) => ({
                        ...prev,
                        positive: !positive,
                      }));
                    }}
                    style={{
                      margin: '4px 0',
                    }}
                  >
                    <Trans t={tEventStatus} i18nKey="positive-only-status">
                      POSITIVE
                    </Trans>
                  </Checkbox>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '4px 0',
                    }}
                  >
                    <Trans t={tPreferences} i18nKey="average-aggregation">
                      Average
                    </Trans>
                    <Switch
                      name="split"
                      checked={aggregation}
                      onChange={() => {
                        setCustomFilters((prev) => ({
                          ...prev,
                          aggregation: !aggregation,
                        }));
                      }}
                      style={{ marginLeft: 6 }}
                    />
                    <Trans t={tPreferences} i18nKey="sum-aggregation">
                      Total
                    </Trans>
                  </div>
                </Flex>
              }
            />
            <Button
              icon="ChevronDown"
              iconPlacement="right"
              type="ghost"
              aria-label="trend-target"
              onClick={() => setIsVisible((prevState) => !prevState)}
              style={{ marginLeft: 16 }}
            >
              <StyledBody level={3}>Filters</StyledBody>
            </Button>
          </div>
        )}
        {isCollectionPage && activeCollection ? (
          <>
            <Title level={6}>
              <Trans t={t} i18nKey="collection_header">
                Collection
              </Trans>
            </Title>
            <Body style={{ marginTop: '8px' }} level={2}>
              {activeCollection.name}
            </Body>
          </>
        ) : (
          <>
            <Title level={6}>
              <Trans t={t} i18nKey="system_header">
                System
              </Trans>
            </Title>
            <Body style={{ marginTop: '8px' }} level={2}>
              {selectedAsset?.name}
            </Body>
          </>
        )}
      </Section>
      {(!!wellTypeFilters?.length || !!deviationsFilterChipsData?.length) && (
        <Section>
          <Title level={6}>
            <Trans t={t} i18nKey="filters_header">
              Filters applied
            </Trans>
          </Title>
          <FilterContainer>
            {wellTypeFilters.map((it) => (
              <Chip key={it}>
                <Detail
                  style={{
                    color: 'var(--cogs-text-icon--status-neutral)',
                    padding: '4px',
                  }}
                >
                  {mapWellTypeToWellTypeTitle(it)}
                </Detail>
              </Chip>
            ))}
            {deviationsFilterChipsData.map((it) => (
              <Chip key={it.title}>
                <Detail
                  style={{
                    color: 'var(--cogs-text-icon--status-neutral)',
                    padding: '4px',
                  }}
                >
                  {it.title}
                </Detail>
              </Chip>
            ))}
          </FilterContainer>
        </Section>
      )}
      <Section>
        <Title level={6}>
          <Trans t={t} i18nKey="dataChoice_header">
            Data to export
          </Trans>
        </Title>
        <CheckboxList>
          <StyledLi>
            <Checkbox name="all" checked={!!allChecked} onChange={onCheckAll}>
              <Trans t={t} i18nKey="checkall_label">
                Select all
              </Trans>
            </Checkbox>
          </StyledLi>
          {Object.entries(exportData)
            .filter(([key]) => !['montly', 'dayByDay'].includes(key))
            .map(([key, value]) => {
              if (value.monthly && !exportData.montly?.export) return null;
              if (exportData.montly?.export) {
                if (value.props) {
                  return (
                    <StyledLi key={key}>
                      <Checkbox
                        name={key}
                        checked={!!value.export}
                        onChange={(nextState) =>
                          onSelectedGroup(key, nextState)
                        }
                      >
                        <Trans t={t} i18nKey={`${key}_label`}>
                          {key}
                        </Trans>
                      </Checkbox>
                    </StyledLi>
                  );
                }
                if (value.intended) {
                  const title = key.toLowerCase();
                  return (
                    <Indented key={key}>
                      <StyledLi>
                        <Checkbox
                          name={key}
                          checked={!!value.export}
                          onChange={onDataSelected(key)}
                        >
                          <Trans t={tEventStatus} i18nKey={`${title}-status`}>
                            {title}
                          </Trans>
                        </Checkbox>
                      </StyledLi>
                    </Indented>
                  );
                }
              }
              return (
                <StyledLi key={key}>
                  <Checkbox
                    name={key}
                    checked={!!value.export}
                    onChange={onDataSelected(key)}
                  >
                    <Trans t={t} i18nKey={`${key}_label`}>
                      {key}
                    </Trans>
                  </Checkbox>
                </StyledLi>
              );
            })}
          {exportData.montly?.export && (
            <StyledLi>
              <ProductionData onChange={updateProductionData} />
            </StyledLi>
          )}
        </CheckboxList>
      </Section>
    </Container>
  );
};

const Chip = styled.div`
  display: inline-grid;
  grid-auto-flow: column;
  grid-gap: 8px;
  width: fit-content;
  align-items: center;
  border-radius: 4px;
  text-transform: capitalize;
  color: var(--cogs-midblue-1);
  background-color: rgba(110, 133, 252, 0.12);
`;

const FilterContainer = styled(Flex)`
  align-items: center;
  grid-gap: 8px;
  flex-wrap: wrap;
  padding-top: 4px;
  padding-bottom: 4px;
`;
const StyledBody = styled(Body)`
  font-weight: 500;
  color: rgba(0, 0, 0, 0.7);
`;
export default withI18nSuspense(WellSummaryContents);
