import chartSlice, { ChartState } from 'features/chart/reducer';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';

const useChartSelectionState = () => {
  const { hoveredTimestamp, selectedTimerange, unorderedSelectedTimerange } =
    useSelector<RootState, ChartState>((state) => {
      return state.chart;
    });

  const dispatch = useDispatch();

  const resetChartSelection = useCallback(() => {
    dispatch(
      chartSlice.actions.setSelectedTimerange({
        selectedTimerange: undefined,
        unorderedSelectedTimerange: undefined,
      })
    );
  }, [dispatch]);

  return {
    hoveredTimestamp,
    selectedTimerange,
    unorderedSelectedTimerange,
    resetChartSelection,
  };
};

export default useChartSelectionState;
