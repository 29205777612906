import React from 'react';
import { Menu } from '@cognite/cogs.js';
import { Asset } from '@cognite/sdk';

export type RootAssetItemProps = {
  asset: Asset;
  onSelect: (asset: Asset) => void;
  isChild?: boolean;
};

const RootAssetItem = ({
  asset,
  onSelect,
  isChild = false,
}: RootAssetItemProps) => {
  return (
    <Menu.Item
      style={{ paddingLeft: isChild ? 40 : undefined }}
      onClick={() => onSelect(asset)}
    >
      {asset.name}
    </Menu.Item>
  );
};

export default RootAssetItem;
