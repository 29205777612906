import styled from 'styled-components/macro';
import { Tree } from 'antd';

export const TreeWrapper = styled(Tree)`
  width: 100%;
  height: 100%;
  padding: 16px;
  background: inherit;

  /* Tweaks on the node item */
  .ant-tree-treenode {
    padding: 6px;
    border-radius: 4px;
  }

  /* Change selected node's background color */
  .ant-tree-treenode.ant-tree-treenode-selected,
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background: var(--cogs-midblue-6);
  }

  /* Vertically align tree arrows to the center */
  span.ant-tree-switcher.ant-tree-switcher_close,
  span.ant-tree-switcher.ant-tree-switcher_open {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .ant-tree-title {
    width: 100%;
    height: 100%;
    display: inline-block;
  }
`;
