import { Label } from '@cognite/sdk';
import startCase from 'lodash/startCase';
import isString from 'lodash/isString';
import { PRODUCT_TYPE_WATER } from 'utils/products';

enum Labels {
  NETWORK_LEVEL_COUNTRY,
  NETWORK_LEVEL_PRODUCTION_SYSTEM,
  NETWORK_LEVEL_PRODUCTION_SUBSYSTEM,
  NETWORK_LEVEL_WELL,
  NETWORK_LEVEL_TOP_LEVEL_ASSET,

  // Comments
  COMMENT,
  // Advisors
  ADVISOR,
  // Linked Deferments
  LINKED_DEFERMENT,
  // Bundled Deviations (only for deviations on the well level)
  GROUPED_WITH,
  // Cause event (only for deviations on the well level)
  RELATES_TO,

  // Artificial lift data
  ARTIFICIAL_LIFT_FREE_FLOWING,
  ARTIFICIAL_LIFT_PUMP,
  ARTIFICIAL_LIFT_GAS_LIFT,
  ARTIFICIAL_LIFT_INJECTOR,
  ARTIFICIAL_LIFT_SHUT_IN,
  ARTIFICIAL_LIFT_ABANDONED,

  // Pumps
  PUMP_PCP,
  PUMP_SRP,
  PUMP_ESP,

  MOTOR_FREQUENCY,
  PRESSURE_DISCHARGE,
  MOTOR_RUN_FLAG,
  STROKES_PER_MINUTE,

  // Oil wells
  GAS_OIL_RATIO,
  WATERCUT,
  WELL_HEAD_TUBING_PRESSURE,
  BOTTOM_HOLE_TUBING_TEMPERATURE,
  WELL_HEAD_CASING_PRESSURE,
  WELL_HEAD_CASING_TEMPERATURE,
  PRODUCTION_RATE_OIL_NET,
  PRODUCTION_RATE_ASSOC_GAS_NET,
  ACTUAL_DEFERMENT_CONTRIBUTION_OIL_NET,

  // Gas wells
  CONDENSATE_GAS_RATIO,
  WATER_GAS_RATIO,
  INJECTION_RATE_H20,
  PRODUCTION_RATE_NONASSOC_GAS_NET,
  FUTURE_DEFERMENT_CONTRIBUTION_NONASSOC_GAS_NET,
  ACTUAL_DEFERMENT_CONTRIBUTION_NONASSOC_GAS_NET,

  SOURCE_FREQUENCY_PER_DAY,
  SOURCE_FREQUENCY_PER_HOUR,
  SOURCE_FREQUENCY_LIVE,

  WELL_FLAG_WATER_DISPOSAL,
  WELL_FLAG_SUSPENDED,
  WELL_FLAG_WAITING_FOR_PA = 'WELL_FLAG_WAITING_FOR_P&A',

  // Relationships between assets
  BELONGS_TO = 'belongsTo',
  FLOWS_TO = 'flowsTo',

  // Well Types
  OIL_WELL = 'BEST_DAY_WELL_FLAG_OIL',
  GAS_WELL = 'BEST_DAY_WELL_FLAG_GAS',
  WATER_INJECTION_WELL = 'BEST_DAY_WELL_FLAG_WATER_INJECTION',
  GAS_STORAGE_WELL = 'BEST_DAY_WELL_FLAG_GAS_STORAGE',

  NET_ASSOC_GAS = 'BEST_DAY_PRODUCTION_RATE_ASSOC_GAS_NET',
  NET_H2O = 'BEST_DAY_PRODUCTION_RATE_H2O_NET',
  NET_CONDENSATE = 'BEST_DAY_PRODUCTION_RATE_CONDENSATE_NET',
  TOTAL_LIQUIDS = 'BEST_DAY_PRODUCTION_RATE_TOTAL_LIQUIDS',
  TOTAL_GAS = 'BEST_DAY_PRODUCTION_RATE_TOTAL_GAS',

  // Fluid flows
  FLUID_GAS = 'BEST_DAY_STREAM_FLUID_GAS',
  FLUID_PRODUCED_WATER = 'BEST_DAY_STREAM_FLUID_PRODUCED_WATER',
  FLUID_OIL = 'BEST_DAY_STREAM_FLUID_OIL',
  FLUID_CONDENSATE = 'BEST_DAY_STREAM_FLUID_CONDENSATE',
  FLUID_DISPOSAL_WATER = 'BEST_DAY_STREAM_FLUID_DISPOSAL_WATER',
  FLUID_SOUR_GAS = 'BEST_DAY_STREAM_FLUID_SOUR_GAS',
  FLUID_INJECTION_WATER = 'BEST_DAY_STREAM_FLUID_INJECTION_WATER',
}

export type LabelsKey = keyof typeof Labels;

export const getLabel = (key: LabelsKey) => {
  const value = Labels[key];
  if (isString(value)) {
    return {
      externalId: value,
    } as Label;
  }

  return {
    externalId: `BEST_DAY_${key}`,
  } as Label;
};

export const getProductNameFromLabel = (label: Label) => {
  const specialProductNames = ['CO2', 'H2S', PRODUCT_TYPE_WATER];
  return label.externalId
    .split('_')
    .slice(2)
    .map((word) => {
      if (specialProductNames.includes(word)) {
        return word;
      }
      return startCase(word.toLowerCase());
    })
    .join(' ');
};
