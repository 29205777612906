/* eslint-disable no-param-reassign */
import { Middleware, Dispatch } from '@reduxjs/toolkit';
import { RootStateOrAny } from 'react-redux';

export const savePreferencesToLocalStorage: Middleware<
  Dispatch,
  RootStateOrAny
> = (api) => (next) => (action) => {
  next(action);

  api.getState();
};
