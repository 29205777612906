import styled from 'styled-components/macro';

const TextOverflowEllipsis = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
`;

export default TextOverflowEllipsis;
