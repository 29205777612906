import dayjs, { OpUnitType } from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import duration, { DurationUnitType } from 'dayjs/plugin/duration';
import { CogniteEvent, Timestamp } from '@cognite/sdk';
import get from 'lodash/get';
import { EventDuration } from 'components/EventDistribution';
import {
  DEFAULT_DAY_FORMAT,
  DEFAULT_FORMAT,
  DEFAULT_TIME,
  RANGE,
} from './constants';

export type BestdayDateType = Date | number | string | Timestamp;

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(duration);

export const now = dayjs() as unknown as BestdayDateType;
export const barWidthMillisCount = (barsCount: number) =>
  dayjs.duration(23 / barsCount, 'hours').asMilliseconds();

export const getStartOfDay = (date: BestdayDateType) =>
  dayjs(date)
    .utc()
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toDate();
export const getStartOfMonth = (date: BestdayDateType) =>
  dayjs(date)
    .set('date', 1)
    .set('hour', 0)
    .set('minute', 0)
    .set('second', 0)
    .set('millisecond', 0)
    .toDate();
export const getEndOfDay = (date: BestdayDateType) =>
  dayjs(date)
    .utc()
    .set('hour', 23)
    .set('minute', 59)
    .set('second', 59)
    .set('millisecond', 0)
    .toDate();
/*
 * Returns the start of the current day in local time in UTC
 *
 * To be used when you need the start of the current day in UTC
 * but want to avoid "rolling over" to the previous/next day in case of
 * timezone differences between local time and UTC.
 *
 * Example: If local timezone is UTC+7, and the input date is "Jan 1. 23:00 UTC+7"
 * the output will be "Jan 1. 00:00 UTC+0"
 * (Not Jan 2. 00:00 UTC+0 which you would get from converting to UTC before adjusting)
 */
export const getStartOfLocalDay = (date: BestdayDateType) => {
  const standardizedDate = new Date(date);
  return new Date(
    Date.UTC(
      standardizedDate.getFullYear(),
      standardizedDate.getMonth(),
      standardizedDate.getDate()
    )
  );
};

// Same as getStartOfLocalDay except returns 23:59 of date in UTC
export const getEndOfLocalDay = (date: BestdayDateType) => {
  const standardizedDate = new Date(date);
  return new Date(
    Date.UTC(
      standardizedDate.getFullYear(),
      standardizedDate.getMonth(),
      standardizedDate.getDate(),
      23,
      59,
      59
    )
  );
};

export const adjustToEndOfLastDay = (date: BestdayDateType) => {
  return dayjs(date)
    .subtract(dayjs.duration(1, 'd'))
    .hour(23)
    .minute(50)
    .second(59)
    .toDate();
};
export const getEventDuration = (
  startDate: BestdayDateType,
  endDate?: BestdayDateType
) => {
  const start = dayjs(startDate);
  // Use today if no endtime
  const end = endDate ? dayjs(endDate) : dayjs();
  const days = end.diff(start, 'days');

  if (days < 1) return EventDuration.LESS_THAN_ONE_DAY;
  if (days >= 1 && days < 3) return EventDuration.ONE_TO_THREE_DAYS;
  if (days >= 3 && days < 7) return EventDuration.THREE_TO_SEVEN_DAYS;
  if (days >= 7 && days < 14) return EventDuration.SEVEN_TO_FOURTEEN_DAYS;
  if (days >= 14 && days < 30) return EventDuration.FOURTEEN_TO_THIRTY_DAYS;
  return EventDuration.OVER_THIRTY_DAYS;
};
export const getstartOfPeriod = (timeRangeIndex: number) =>
  +getStartOfLocalDay(+dayjs().subtract(RANGE[timeRangeIndex], 'd'));

export const returnWithFormat = (
  d: BestdayDateType,
  FORMAT: string = DEFAULT_FORMAT
) => dayjs(d).format(FORMAT);
export const DEFAULT_EXPORT_CHUNK = returnWithFormat(now, 'YYYY/MM');
export const getDefaultFormatDay = (date: BestdayDateType): string =>
  dayjs(date).format(DEFAULT_DAY_FORMAT);
export const isHappeningNow = (
  day: BestdayDateType,
  testElem: Partial<CogniteEvent>
) =>
  dayjs(day)
    .endOf('day')
    .startOf('hour')
    .isBetween(
      dayjs(get(testElem, 'startTime')).startOf('day').format(),
      dayjs(get(testElem, 'endTime'))
        .subtract(10, 'minute')
        .endOf('day')
        .endOf('hour')
    );
export const getDefaultTimeLimit = (datePart: BestdayDateType) =>
  dayjs(dayjs(datePart).startOf('month').startOf('day').format()).diff(
    dayjs(datePart).endOf('month').endOf('day').endOf('hours').format(),
    'day'
  ) + 1;
export const getGranularity = (end: BestdayDateType, start: BestdayDateType) =>
  dayjs(end || +new Date()).diff(dayjs(start), 'days') + 1;

export const getDurationByUnitType = (
  value: number,
  unitType?: DurationUnitType
) => dayjs.duration(value, unitType).asMilliseconds();

export const isSameByUnitType = (
  a: BestdayDateType,
  b: BestdayDateType,
  unitType?: OpUnitType
) => dayjs(a).isSame(b, unitType);

export const getDiffByUnitType = (
  a: BestdayDateType,
  b: BestdayDateType,
  unitType?: OpUnitType
) => dayjs(a).diff(b, unitType);

export const addDateByUnitType = (
  date: BestdayDateType,
  value: number,
  unitType?: OpUnitType
) => dayjs(date).add(value, unitType);

export const subtractDateByUnitType = (
  date: BestdayDateType,
  value: number,
  unitType?: OpUnitType
) => dayjs(date).subtract(value, unitType);

export const formatDateAs = {
  date: (d: BestdayDateType) => returnWithFormat(d),
  datetime: (d: BestdayDateType) =>
    returnWithFormat(d, `${DEFAULT_FORMAT} ${DEFAULT_TIME}`),
  timedate: (d: BestdayDateType) =>
    returnWithFormat(d, ` ${DEFAULT_TIME} ${DEFAULT_FORMAT}`),
  humanizedTime: (d: BestdayDateType) => returnWithFormat(d, ''),
  monthWithDate: (d: BestdayDateType) => returnWithFormat(d, 'MMM D, YYYY'),
  monthNameDate: (d: BestdayDateType) => returnWithFormat(d, 'MMM D'),
} as const;
export const getRange = ([start, end]: [number, number]) => {
  return returnWithFormat(start) === returnWithFormat(end)
    ? `${returnWithFormat(start)}`
    : `${returnWithFormat(start)} - ${returnWithFormat(end || new Date())}`;
};
export const getUnixTimestamp = (d: BestdayDateType) => +dayjs(d);
export const getLastDayOfMonth = (d: BestdayDateType) =>
  dayjs(d).endOf('month').get('date');
export const getMount = (d: BestdayDateType): [number, number] => [
  +getStartOfDay(`${d}-${1}`).toISOString(),
  +getEndOfLocalDay(`${d}-${getLastDayOfMonth(d)}`).toISOString(),
];
export const subtractTimezoneOffset = (d: BestdayDateType) =>
  +subtractDateByUnitType(d, Math.abs(new Date(d).getTimezoneOffset()), 'm');
