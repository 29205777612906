import { RootState, AppThunk } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { reportException, ReportedError } from '@cognite/react-errors';

import { useCallback, useEffect } from 'react';
import {
  NewAdvisor,
  AdvisorView,
  Advisor,
  EditAdvisor,
  SelectedAdvisor,
} from 'utils/models/advisors/types';
import { Asset } from '@cognite/sdk';
import { useTranslation } from '@cognite/react-i18n';
import {
  create,
  list,
  edit,
  remove,
  fetchAdvisorAssets,
} from 'utils/models/advisors/api';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useDatasets } from 'features/datasets';
import { notification } from 'antd';
import {
  setLoading,
  getAdvisors,
  addNewAdvisor,
  updateAdvisor,
  removeAdvisorStart,
  removeAdvisorSuccess,
  removeAdvisorError,
  setAdvisorView,
  setSelectedAdvisor,
  setError,
} from './reducer';

const reportError = (error: Error) => reportException(error);

const fetchAdvisors =
  (assetExternalId: string): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      dispatch(setLoading(true));

      const advisors = await list(assetExternalId);

      dispatch(
        getAdvisors({
          advisors,
        })
      );

      return true;
    } catch (error) {
      dispatch(setError(await reportError(error as Error)));
      return false;
    }
  };

export const addAdvisor =
  (
    dataSetId: number,
    advisor: NewAdvisor,
    selectedAssetId?: number
  ): AppThunk<Promise<boolean>> =>
  async (dispatch, getState) => {
    const { user } = getState().auth;
    dispatch(setLoading(true));

    try {
      const newAdvisor = await create(
        {
          ...advisor,
          creator: user,
        },
        dataSetId
      );

      if (
        advisor.selectedAssets.some((asset) => +asset.id === selectedAssetId)
      ) {
        dispatch(addNewAdvisor(newAdvisor));
      }
      return true;
    } catch (error) {
      dispatch(setError(await reportError(error as Error)));
      return false;
    }
  };

const selectAdvisor =
  (advisor?: Advisor): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      let selectedAdvisor: SelectedAdvisor | undefined;
      if (advisor) {
        selectedAdvisor = await fetchAdvisorAssets(advisor);
      }

      dispatch(setSelectedAdvisor(selectedAdvisor));
      return true;
    } catch (error) {
      dispatch(setError(await reportError(error as Error)));
      return false;
    }
  };

export const editAdvisor =
  (
    dataSetId: number,
    advisor: SelectedAdvisor,
    editedAdvisor: EditAdvisor
  ): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const newAdvisor = await edit(advisor, editedAdvisor, dataSetId);

      dispatch(updateAdvisor(newAdvisor));
      dispatch(setSelectedAdvisor(newAdvisor));
      return true;
    } catch (error) {
      dispatch(setError(await reportError(error as Error)));
      return false;
    }
  };

export const removeAdvisor =
  (
    advisor: Advisor,
    errorText: string,
    deleteForAsset?: Asset
  ): AppThunk<Promise<boolean>> =>
  async (dispatch) => {
    try {
      dispatch(removeAdvisorStart());

      const results = await remove(advisor, deleteForAsset);
      const [eventResults, relationshipsResult] = results;
      let reportedEventError;
      let reportedRelationshipsError;
      if (eventResults.status === 'rejected') {
        reportedEventError = await reportError(eventResults.reason);
      }

      if (relationshipsResult.status === 'rejected') {
        reportedRelationshipsError = await reportError(
          relationshipsResult.reason
        );
      }
      // We want to show an error only if both actions (delete event and delete relationships failed)
      if (reportedEventError && reportedRelationshipsError) {
        dispatch(removeAdvisorError());
        notification.error({
          message: `${errorText + reportedEventError.errorId}, ${
            reportedRelationshipsError.errorId
          }`,
        });
        return false;
      }
      dispatch(removeAdvisorSuccess(advisor));
      return true;
    } catch (error) {
      const reportedError = await reportError(error as Error);
      dispatch(removeAdvisorError());
      notification.error({
        message: errorText + reportedError.errorId,
      });
      return false;
    }
  };

export const useAdvisors = () => {
  const { t } = useTranslation('AdvisorCard');
  const { selectedAsset } = useCurrentAsset();
  const { loading, advisors, advisorView, selectedAdvisor, removing, error } =
    useSelector((state: RootState) => state.advisors);
  const { getDataset } = useDatasets();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!selectedAsset) {
      return;
    }

    dispatch(fetchAdvisors(selectedAsset.externalId || ''));
  }, [dispatch, selectedAsset]);

  return {
    loading,
    advisors,
    selectedAsset,
    advisorView,
    selectedAdvisor,
    removing,
    error,
    setError: (err: ReportedError | null) => dispatch(setError(err)),
    addAdvisor: useCallback(
      (advisor: NewAdvisor) =>
        dispatch(
          addAdvisor(getDataset('ADVISORS'), advisor, selectedAsset?.id)
        ) as unknown as Promise<boolean>,
      [dispatch, getDataset, selectedAsset]
    ),
    editAdvisor: useCallback(
      (advisor: SelectedAdvisor, editedAdvisor: EditAdvisor) =>
        dispatch(
          editAdvisor(getDataset('ADVISORS'), advisor, editedAdvisor)
        ) as unknown as Promise<boolean>,
      [dispatch, getDataset]
    ),
    removeAdvisor: useCallback(
      (advisor: Advisor, deleteForAsset?: Asset) => {
        const errorText = t('advisors-card_delete-error-message', {
          defaultValue:
            'There were some errors with deletion of advisor. Please try again later. Error IDs: ',
        });
        return dispatch(
          removeAdvisor(advisor, errorText, deleteForAsset)
        ) as unknown as Promise<boolean>;
      },
      [dispatch, t]
    ),
    setAdvisorView: useCallback(
      (view: AdvisorView) => dispatch(setAdvisorView(view)),
      [dispatch]
    ),
    selectAdvisor: useCallback(
      (advisor?: Advisor) => dispatch(selectAdvisor(advisor)),
      [dispatch]
    ),
  };
};
