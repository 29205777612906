import styled from 'styled-components/macro';
import UnstyledButton from 'components/accessibility/UnstyledButton';
import { Dropdown } from '@cognite/cogs.js';

export const AssetSearchContainer = styled.div`
  overflow-x: hidden;
  height: 48px;
  flex-shrink: 0;
  display: flex;
  align-items: center;

  [data-tippy-root] {
    width: calc(100% - 32px);
    overflow-y: scroll;
    height: 200px;
    background-color: white;
    box-shadow: var(--cogs-z-6);
  }
`;

export const AssetSearchInnerContainer = styled.div`
  padding: 0 16px;
  width: 100%;
`;

export const SearchResultDropdown = styled(Dropdown)`
  height: 100%;

  & > .tippy-content {
    height: 100%;

    & > div {
      height: 100%;
    }
  }
`;

export const SearchResultList = styled.ul`
  margin: 0;
  padding: 8px 16px;
  width: 100%;
`;

export const SearchResultContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  ${SearchResultList} {
    align-self: flex-start;
  }
`;

export const SearchResultItem = styled.li`
  list-style-type: none;
  cursor: pointer;

  &:hover {
    background-color: var(--cogs-greyscale-grey1);
  }
`;

export const SearchResultItemLink = styled(UnstyledButton)`
  padding: 8px 4px;
  width: 100%;
  text-align: left;
`;
