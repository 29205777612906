import { Asset } from '@cognite/sdk';
import { getLabel } from 'utils/labels';

export const isValidWell = (asset: Asset): boolean => {
  return (
    !!asset.labels &&
    !!asset.labels?.some(
      (label) => label.externalId === getLabel('NETWORK_LEVEL_WELL').externalId
    ) &&
    !asset.labels?.some(
      (label) =>
        label.externalId === getLabel('WELL_FLAG_SUSPENDED').externalId ||
        label.externalId === getLabel('WELL_FLAG_WATER_DISPOSAL').externalId ||
        label.externalId === getLabel('WELL_FLAG_WAITING_FOR_PA').externalId
    )
  );
};

// system/subsystem and wells with valid main products
export const isValidAsset = (asset: Asset): boolean => {
  return !!(
    asset.externalId &&
    ((asset.labels &&
      asset.labels.some(
        (label) =>
          label.externalId ===
            getLabel('NETWORK_LEVEL_PRODUCTION_SYSTEM').externalId ||
          label.externalId ===
            getLabel('NETWORK_LEVEL_PRODUCTION_SUBSYSTEM').externalId
      )) ||
      isValidWell(asset))
  );
};
