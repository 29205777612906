import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle`
  :root {
    --bd-grey-4: #e9e9e9;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    margin: 0;
    padding: 0;
    font-family: "Inter", sans-serif !important;
    * {
      box-sizing: border-box;
    }
    .popover-without-arrow {
      .ant-popover-arrow {
        display: none;
      }
    }
    .deviation-quick-menu {
      .ant-popover-inner {
        box-shadow: none;
      }
      .ant-popover-inner-content {
        display: flex;
        padding: 4px;
        box-shadow: 0px 5px 16px rgba(0, 0, 0, 0.04), 0px 10px 24px rgba(0, 0, 0, 0.07), 0px 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
        background: var(--cogs-white);
        border: 1px solid var(--cogs-greyscale-grey3);
      }
    }
    .chart-comments-popup {
      .ant-popover-inner-content {
        padding: 0;
      }
    }

  /**
    TODO(BEST-528):
    This is to fix the issue with icons not being aligned
    correctly inside of buttons.
  */
  .cogs-btn-unstyled, .cogs-btn-unstyled:hover, .cogs-btn-unstyled.focus-visible, .cogs-btn-unstyled:active {
    display: inline-flex;
  }

  .cogs-toast-inner {
    align-items: center;
  }

  body {
    overflow-y: hidden;
  }

  #root {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
    
  p {
    margin-bottom: 0;
  }

  .cogs-body-2.strong {
    color: var(--cogs-text-icon--strong);
  }
}
`;
