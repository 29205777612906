import React from 'react';
import { Detail, Flex, Body } from '@cognite/cogs.js';
import styled from 'styled-components/macro';
import { StatusColors } from 'pages/KPIDashboard/Charts/utils';
import { DeviationStatus, DefermentStatus, DefermentType } from 'utils/models';
import { STATUS_ICONS } from 'components/Sidebar/DeviationsPanel/DeviationPanelCard';
import { asDecimal } from 'utils/numbers';
import DeviationStatusLabel from 'components/Sidebar/DeviationsPanel/DeviationStatusLabel';
import { ReactComponent as Logo } from 'assets/Logo.svg';
import { StatusPart } from '../PanelCard';

export type Props = {
  status: DeviationStatus | DefermentStatus;
  defermentType?: DefermentType;
  statusParts?: StatusPart[];
  isBestDayDeferment?: boolean;
};

const EventStatuses = ({
  status,
  defermentType,
  statusParts,
  isBestDayDeferment,
}: Props) => {
  return (
    <Flex justifyContent="flex-start" alignItems="center">
      <StatusBackground color={StatusColors[status].secondary}>
        <DeviationStatusLabel status={status} marginToIcon={8} />
      </StatusBackground>
      {defermentType && (
        <StatusBackground color="var(--cogs-greyscale-grey2)">
          <DeviationStatusLabel status={defermentType} marginToIcon={8} />
        </StatusBackground>
      )}
      {statusParts &&
        statusParts.map(({ status, percentage }) => {
          return (
            <DeviationStatuses key={status}>
              {STATUS_ICONS[status]}{' '}
              <Detail strong style={{ color: `${StatusColors[status].main}` }}>
                {asDecimal(percentage || 0, {
                  maxLength: 5,
                  precision: 0,
                })}
                %
              </Detail>
            </DeviationStatuses>
          );
        })}
      {isBestDayDeferment && (
        <BestDayLabel alignContent="center">
          <Logo style={{ width: '16px', height: '16px' }} />
          <StyledBody level={3} strong>
            {' '}
            BestDay
          </StyledBody>
        </BestDayLabel>
      )}
    </Flex>
  );
};

const StatusBackground = styled.span<{ color: string }>`
  padding: 4px 8px;
  border-radius: 8px;
  background: ${(props) => props.color};
`;

const DeviationStatuses = styled(Flex)`
  margin-left: 8px;
  align-items: center;
  span {
    margin-left: 4px;
  }
`;

const BestDayLabel = styled(Flex)`
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--cogs-greyscale-grey2);
  margin-left: 4px;
`;

const StyledBody = styled(Body)`
  line-height: 20px;
  padding-left: 6px;
`;

export default EventStatuses;
