import React from 'react';
import { Menu } from '@cognite/cogs.js';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';

import { useMetrics } from '@cognite/metrics';
import useNavigation from 'utils/useNavigation';
import { Pages } from 'utils/models/enums';
import { MenuItemLink, MenuItemA, MenuItemSpan } from './elements';

export type Props = {
  privacyPolicyUrl: string;
};

const ProfileMenu = ({ privacyPolicyUrl }: Props) => {
  const { t } = useTranslation('ProfileMenu');
  const { currentPage, navigate } = useNavigation();
  const metrics = useMetrics('User');

  const privacyPolicyLink = privacyPolicyUrl && (
    <>
      <Menu.Divider />
      <Menu.Item onClick={() => metrics.track('PrivacyPolicy_click')}>
        <MenuItemA href={privacyPolicyUrl} isExternal target="_blank">
          <Trans t={t} i18nKey="privacy-policy_profile-menu">
            Privacy policy
          </Trans>
        </MenuItemA>
      </Menu.Item>
    </>
  );

  return (
    <Menu onClick={() => metrics.track('Avatar_click')}>
      <Menu.Header>
        <Trans t={t} i18nKey="profile-menu_header">
          Account
        </Trans>
      </Menu.Header>

      <Menu.Item>
        <MenuItemSpan
          onClick={() => {
            metrics.track('Profile_click');
            if (currentPage === Pages.User) {
              return;
            }
            navigate({ page: Pages.User });
          }}
          role="link"
        >
          <Trans t={t} i18nKey="profile-menu_profile">
            Profile
          </Trans>
        </MenuItemSpan>
      </Menu.Item>
      <Menu.Item>
        <MenuItemLink to="/logout">
          <Trans t={t} i18nKey="profile-menu_logout">
            Logout
          </Trans>
        </MenuItemLink>
      </Menu.Item>
      {privacyPolicyLink}
    </Menu>
  );
};

export default withI18nSuspense(ProfileMenu);
