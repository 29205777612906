import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { DatasetsState } from './reducer';
import { DatasetType } from './types';

export const useDatasets = () => {
  const { datasets } = useSelector<RootState, DatasetsState>((state) => {
    return state.datasets;
  });

  const getDataset = useCallback(
    (type: DatasetType) => {
      return datasets[type] || 0;
    },
    [datasets]
  );

  return {
    getDataset,
  };
};
