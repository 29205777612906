import { useLocation, useHistory } from 'react-router-dom';
import { Action } from 'history';
import { useMemo, useCallback } from 'react';
import { Pages } from './models/enums';

export type Page = Pages.DeepDive | Pages.Wells | Pages.User | Pages.KPI;

export type PageForMetrics =
  | 'DeepDive'
  | 'WellSummary'
  | 'User'
  | 'WatchedDeepDive'
  | 'WatchedWellSummary'
  | 'WatchedKPIDashboard'
  | 'KPIDashboard';

export type NavigateParams = {
  page: Page;
  id?: string;
  isCollection?: boolean;
};

export type Navigation = {
  currentPage: Pages;
  currentPageForMetrics: PageForMetrics;
  action: Action;
  navigate: (params: NavigateParams) => void;
  goBack: () => void;
  isSummaryPage: boolean;
  isCollectionPage: boolean;
};

const useNavigation = (): Navigation => {
  const { pathname } = useLocation();
  const history = useHistory();

  const [currentPage, currentPageForMetrics, isCollectionPage]: [
    Page,
    PageForMetrics,
    boolean
  ] = useMemo(() => {
    const watchedDeepDivePage = new RegExp(
      `(.*)/collection/${Pages.DeepDive}/([0-9a-z-]+)$`
    );
    const watchedKpiPage = new RegExp(
      `(.*)/collection/${Pages.KPI}/([0-9a-z-]+)$`
    );
    const watchedWellsPage = new RegExp(
      `(.*)/collection/${Pages.Wells}/([0-9a-z-]+)$`
    );
    if (watchedDeepDivePage.test(pathname)) {
      return [Pages.DeepDive, 'WatchedDeepDive', true];
    }
    if (watchedWellsPage.test(pathname)) {
      return [Pages.Wells, 'WatchedWellSummary', true];
    }
    if (watchedKpiPage.test(pathname)) {
      return [Pages.KPI, 'WatchedKPIDashboard', true];
    }
    if (pathname.endsWith(Pages.User)) {
      return [Pages.User, 'User', false];
    }
    if (pathname.endsWith(Pages.Wells)) {
      return [Pages.Wells, 'WellSummary', false];
    }
    if (pathname.endsWith(Pages.DeepDive)) {
      return [Pages.DeepDive, 'DeepDive', false];
    }
    return [Pages.KPI, 'KPIDashboard', false];
  }, [pathname]);

  const navigate = useCallback(
    ({ page, id, isCollection = false }: NavigateParams) => {
      if (id) {
        if (isCollection) {
          history.push(`/assets/collection/${page}/${encodeURIComponent(id)}`);
        } else {
          history.push(`/assets/${encodeURIComponent(id)}/${page}`);
        }
      } else {
        history.push(`/${page}`);
      }
    },
    [history]
  );

  // Check if it's any page in the summary form
  const isSummaryPage = useMemo(
    () => currentPage === Pages.Wells,
    [currentPage]
  );

  return {
    currentPage,
    currentPageForMetrics,
    navigate,
    action: history.action,
    goBack: history.goBack,
    isSummaryPage,
    isCollectionPage,
  };
};

export default useNavigation;
