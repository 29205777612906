import { configureStore, Action } from '@reduxjs/toolkit';
import { savePreferencesToLocalStorage } from 'features/preferences/preferences';
import { useDispatch } from 'react-redux';
import thunk, { ThunkAction } from 'redux-thunk';
import rootReducer from './rootReducer';

function createStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: [thunk, savePreferencesToLocalStorage],
  });
}

const store = createStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export type StoreType = ReturnType<typeof createStore>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;
