import styled from 'styled-components/macro';

const UnstyledButton = styled.button.attrs({ type: 'button', role: 'button' })`
  border: none;
  padding: 0;
  line-height: 16px;
  background: inherit;
  cursor: pointer;
`;

export default UnstyledButton;
