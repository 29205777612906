import { useCallback } from 'react';
import { useAuth } from 'features/auth';
import {
  Parent,
  Parents,
  Comment,
  NewComment,
} from 'utils/models/comments/types';
import {
  create,
  edit,
  list,
  NotificationDetails,
} from 'utils/models/comments/api';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { useProjectContext } from 'containers/AuthContainer';
import { useQuery, useQueryClient } from 'react-query';
import { PerfMetrics } from '@cognite/metrics';
import { METRICS } from 'utils/metrics/enums';
import { getDurationByUnitType } from 'utils/datetime';
import commentsSlice, { CommentsState } from './reducer';

export type UseGetComments = {
  loading: boolean;
  loadingError: Error | null;
  invalidate: () => void;
  getComments: (externalId: string) => Comment[];
  getActiveComments: (externalId: string) => Comment[];
  allComments: CommentsState;
};

export type UseComments = {
  createComment: (
    comment: NewComment,
    datasetExternalId: string,
    notificationDetails: NotificationDetails,
    observationExternalId?: string
  ) => Promise<void>;
  editComment: (
    comment: Comment,
    notificationDetails: NotificationDetails,
    isObservation?: boolean
  ) => Promise<void>;
  deleteComment: (
    comment: Comment,
    notificationDetails: NotificationDetails,
    isObservation?: boolean
  ) => Promise<void>;
};

export const useGetComments = ({
  externalIds,
  resourceType,
}: Parents): UseGetComments => {
  const queryClient = useQueryClient();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const { project } = useProjectContext();

  const commentsState = useSelector<RootState, CommentsState>((state) => {
    return state.comments;
  });

  const getComments = useCallback(
    (externalId: string): Comment[] => {
      return commentsState?.[externalId] || [];
    },
    [commentsState]
  );

  const getActiveComments = useCallback(
    (externalId: string): Comment[] => {
      return (
        commentsState?.[externalId]?.filter(
          (comment) => comment.status !== 'deleted'
        ) || []
      );
    },
    [commentsState]
  );
  const invalidate = useCallback(() => {
    queryClient.invalidateQueries(['fetch-comments', externalIds]);
  }, [externalIds, queryClient]);
  const { isLoading, error } = useQuery<Comment[], Error>(
    ['fetch-comments', externalIds],
    () =>
      list({
        parent: {
          externalIds,
          resourceType,
        },
        project,
      }).then((comments) => {
        dispatch(
          commentsSlice.actions.merge({
            comments,
          })
        );
        return comments;
      }),
    {
      enabled: !!user && !!externalIds.filter(Boolean).length && !!resourceType,
      staleTime: getDurationByUnitType(2, 'minutes'),
    }
  );

  return {
    invalidate,
    loading: isLoading,
    loadingError: error,
    getComments,
    getActiveComments,
    allComments: commentsState,
  };
};

export const useComments = ({
  externalId,
  resourceType,
  assetIds,
}: Parent): UseComments => {
  const dispatch = useDispatch();
  const { project } = useProjectContext();

  const createComment = (
    comment: NewComment,
    datasetExternalId: string,
    notificationDetails: NotificationDetails,
    observationExternalId = ''
  ): Promise<void> => {
    const { isObservation } = comment;
    const track = isObservation
      ? METRICS.CommentsCreation
      : METRICS.CommentCreation;
    const parentExternalId = isObservation ? observationExternalId : externalId;
    PerfMetrics.trackPerfStart(track);
    return create({
      parent: { externalId: parentExternalId, resourceType, assetIds },
      comment,
      project,
      datasetExternalId,
      notificationDetails,
    })
      .then((newComment) => {
        dispatch(
          commentsSlice.actions.create({
            parentExternalId,
            newComment,
            isObservation,
          })
        );

        PerfMetrics.trackPerfEnd(track);
        PerfMetrics.logSuccessEvent(track);
      })
      .catch((e) => {
        PerfMetrics.logFailureEvent(track);
        throw e;
      });
  };

  const editComment = (
    comment: Comment,
    action: 'edited' | 'deleted',
    notificationDetails: NotificationDetails,
    isObservation?: boolean
  ): Promise<void> => {
    const metricName =
      action === 'edited' ? METRICS.CommentUpdate : METRICS.CommentDeletion;
    PerfMetrics.trackPerfStart(metricName);
    return edit({
      comment,
      parent: { externalId, resourceType },
      action,
      project,
      notificationDetails,
    })
      .then((editedComment) => {
        dispatch(
          commentsSlice.actions.edit({
            parentExternalId: externalId,
            editedComment,
            isObservation,
          })
        );
        PerfMetrics.trackPerfEnd(metricName);
        PerfMetrics.logSuccessEvent(metricName);
      })
      .catch((e) => {
        PerfMetrics.logFailureEvent(metricName);
        throw e;
      });
  };

  return {
    createComment,
    editComment: (
      comment: Comment,
      notificationDetails: NotificationDetails,
      isObservation?: boolean
    ) => editComment(comment, 'edited', notificationDetails, isObservation),
    deleteComment: (
      comment: Comment,
      notificationDetails: NotificationDetails,
      isObservation?: boolean
    ) => editComment(comment, 'deleted', notificationDetails, isObservation),
  };
};
