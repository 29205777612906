import uniqueId from 'lodash/uniqueId';
import { ToastOptions } from 'react-toastify';
import { ToastContainers } from './toasts';

export default function createToastOptions(
  toastOptions: ToastOptions = {}
): ToastOptions {
  return {
    position: 'bottom-center',
    hideProgressBar: false,
    pauseOnHover: true,
    closeOnClick: true,
    toastId: uniqueId(),
    containerId: ToastContainers.Default,
    ...toastOptions,
  };
}
