import { BestDayConfig, DataSetMapping } from 'hooks/types';
import { getTenant } from 'utils/tenancy';

export const defaultDataSetMapping: DataSetMapping = {
  ADVISORS: 'BEST_DAY_ADVISORS',
  LINKED_DEFERMENTS: 'BEST_DAY_LINKED_DEFERMENTS',
  DEVIATIONS: 'BEST_DAY_DEVIATION_EVENTS',
  DEVIATION_UPDATES: 'BEST_DAY_DEVIATIONS',
  COMMENTS: 'BEST_DAY_COMMENTS',
  DEFERMENTS: 'BEST_DAY_ENRICHED_DEFERMENTS',
  UNIT_CONVERSIONS: 'UnitConversionFiles',
  CUSTOMER_CONFIGURATION: 'DATASET:BEST_DAY_TEMPLATES',
  CONFIGURATION: 'COMMON_BEST_DAY_CONFIGURATION',
  OBSERVATION: 'COMMON_OBSERVATION',
};

export const ROMANIA_ROOT_ASSETS = [
  'RO_COUNTRY_ASSET',
  'RO_ASSET MOLDOVA',
  'RO_ASSET PETROMAR',
  'RO_ASSET OLTENIA',
  'RO_ASSET VALAHIA',
  'RO_ASSET MUNTENIA',
];

const countrySpecificDataSetMappings: { [key: string]: DataSetMapping } = {
  AT_Country_Asset: {
    ADVISORS: 'BESTDAY_AUSTRIA_ADVISORS',
    LINKED_DEFERMENTS: 'BESTDAY_AUSTRIA_LINK_DEVIATION_TO_DEFERMENT',
    DEVIATIONS: 'AT_deviation_events',
    DEVIATION_UPDATES: 'BESTDAY_AUSTRIA_DEVIATIONS',
    COMMENTS: 'BESTDAY_AUSTRIA_COMMENTS',
    DEFERMENTS: 'BESTDAY_AUSTRIA_ENRICHED_DEFERMENTS',
    UNIT_CONVERSIONS: 'AT:DATASET:UNIT_CONVERSION_FILES',
    CUSTOMER_CONFIGURATION: 'DATASET:BEST_DAY:CONFIGURATION',
    CONFIGURATION: 'DATASET:BEST_DAY:COMMON_CONFIGURATION',
    OBSERVATION: 'DATASET:AT:OBSERVATION_EVENTS',
  },
  RO_COUNTRY_ASSET: {
    ADVISORS: 'BESTDAY_ROMANIA_ADVISORS',
    LINKED_DEFERMENTS: 'BESTDAY_ROMANIA_LINK_DEVIATION_TO_DEFERMENT',
    DEVIATIONS: 'DATASET:RO:DEVIATION_EVENTS',
    DEVIATION_UPDATES: 'BESTDAY_ROMANIA_DEVIATIONS',
    COMMENTS: 'BESTDAY_ROMANIA_COMMENTS',
    DEFERMENTS: 'BESTDAY_ROMANIA_ENRICHED_DEFERMENTS',
    UNIT_CONVERSIONS: 'RO:DATASET:UNIT_CONVERSION_FILES',
    CUSTOMER_CONFIGURATION: 'DATASET:RO_BEST_DAY_CONFIGURATION_TEMPLATES',
    CONFIGURATION: 'DATASET:BEST_DAY:COMMON_CONFIGURATION',
    OBSERVATION: 'DATASET:RO:OBSERVATION_EVENTS',
  },
  'RO_ASSET MOLDOVA': {
    ADVISORS: 'BESTDAY_ROMANIA_ASSET_MOLDOVA_ADVISORS',
    LINKED_DEFERMENTS:
      'BESTDAY_ROMANIA_ASSET_MOLDOVA_LINK_DEVIATION_TO_DEFERMENT',
    DEVIATIONS: 'BESTDAY_ROMANIA_ASSET_MOLDOVA_DEVIATIONS',
    DEVIATION_UPDATES: 'BESTDAY_ROMANIA_DEVIATIONS',
    COMMENTS: 'BESTDAY_ROMANIA_ASSET_MOLDOVA_COMMENTS',
    DEFERMENTS: 'BESTDAY_ROMANIA_ASSET_MOLDOVA_ENRICHED_DEFERMENTS',
    // Use Romania unit conversions for now
    UNIT_CONVERSIONS: 'RO:DATASET:UNIT_CONVERSION_FILES',
    CUSTOMER_CONFIGURATION: 'DATASET:RO_BEST_DAY_CONFIGURATION_TEMPLATES',
    CONFIGURATION: 'DATASET:BEST_DAY:COMMON_CONFIGURATION',
    OBSERVATION: 'DATASET:ASSET_MOLDOVA:OBSERVATION_EVENTS',
  },
  'RO_ASSET PETROMAR': {
    ADVISORS: 'BESTDAY_ROMANIA_ASSET_PETROMAR_ADVISORS',
    LINKED_DEFERMENTS:
      'BESTDAY_ROMANIA_ASSET_PETROMAR_LINK_DEVIATION_TO_DEFERMENT',
    DEVIATIONS: 'BESTDAY_ROMANIA_ASSET_PETROMAR_DEVIATIONS',
    DEVIATION_UPDATES: 'BESTDAY_ROMANIA_DEVIATIONS',
    COMMENTS: 'BESTDAY_ROMANIA_ASSET_PETROMAR_COMMENTS',
    DEFERMENTS: 'BESTDAY_ROMANIA_ASSET_PETROMAR_ENRICHED_DEFERMENTS',
    // Use Romania unit conversions for now
    UNIT_CONVERSIONS: 'RO:DATASET:UNIT_CONVERSION_FILES',
    CUSTOMER_CONFIGURATION: 'DATASET:RO_BEST_DAY_CONFIGURATION_TEMPLATES',
    CONFIGURATION: 'DATASET:BEST_DAY:COMMON_CONFIGURATION',
    OBSERVATION: 'DATASET:ASSET_PETROMAR:OBSERVATION_EVENTS',
  },
  'RO_ASSET OLTENIA': {
    ADVISORS: 'BESTDAY_ROMANIA_ASSET_OLTENIA_ADVISORS',
    LINKED_DEFERMENTS:
      'BESTDAY_ROMANIA_ASSET_OLTENIA_LINK_DEVIATION_TO_DEFERMENT',
    DEVIATIONS: 'BESTDAY_ROMANIA_ASSET_OLTENIA_DEVIATIONS',
    DEVIATION_UPDATES: 'BESTDAY_ROMANIA_DEVIATIONS',
    COMMENTS: 'BESTDAY_ROMANIA_ASSET_OLTENIA_COMMENTS',
    DEFERMENTS: 'BESTDAY_ROMANIA_ASSET_OLTENIA_ENRICHED_DEFERMENTS',
    // Use Romania unit conversions for now
    UNIT_CONVERSIONS: 'RO:DATASET:UNIT_CONVERSION_FILES',
    CUSTOMER_CONFIGURATION: 'DATASET:RO_BEST_DAY_CONFIGURATION_TEMPLATES',
    CONFIGURATION: 'DATASET:BEST_DAY:COMMON_CONFIGURATION',
    OBSERVATION: 'DATASET:ASSET_OLTENIA:OBSERVATION_EVENTS',
  },
  'RO_ASSET VALAHIA': {
    ADVISORS: 'BESTDAY_ROMANIA_ASSET_VALAHIA_ADVISORS',
    LINKED_DEFERMENTS:
      'BESTDAY_ROMANIA_ASSET_VALAHIA_LINK_DEVIATION_TO_DEFERMENT',
    DEVIATIONS: 'BESTDAY_ROMANIA_ASSET_VALAHIA_DEVIATIONS',
    DEVIATION_UPDATES: 'BESTDAY_ROMANIA_DEVIATIONS',
    COMMENTS: 'BESTDAY_ROMANIA_ASSET_VALAHIA_COMMENTS',
    DEFERMENTS: 'BESTDAY_ROMANIA_ASSET_VALAHIA_ENRICHED_DEFERMENTS',
    // Use Romania unit conversions for now
    UNIT_CONVERSIONS: 'RO:DATASET:UNIT_CONVERSION_FILES',
    CUSTOMER_CONFIGURATION: 'DATASET:RO_BEST_DAY_CONFIGURATION_TEMPLATES',
    CONFIGURATION: 'DATASET:BEST_DAY:COMMON_CONFIGURATION',
    OBSERVATION: 'DATASET:ASSET_VALAHIA:OBSERVATION_EVENTS',
  },
  'RO_ASSET MUNTENIA': {
    ADVISORS: 'BESTDAY_ROMANIA_ASSET_MUNTENIA_ADVISORS',
    LINKED_DEFERMENTS:
      'BESTDAY_ROMANIA_ASSET_MUNTENIA_LINK_DEVIATION_TO_DEFERMENT',
    DEVIATIONS: 'BESTDAY_ROMANIA_ASSET_MUNTENIA_DEVIATIONS',
    DEVIATION_UPDATES: 'BESTDAY_ROMANIA_DEVIATIONS',
    COMMENTS: 'BESTDAY_ROMANIA_ASSET_MUNTENIA_COMMENTS',
    DEFERMENTS: 'BESTDAY_ROMANIA_ASSET_MUNTENIA_ENRICHED_DEFERMENTS',
    // Use Romania unit conversions for now
    UNIT_CONVERSIONS: 'RO:DATASET:UNIT_CONVERSION_FILES',
    CUSTOMER_CONFIGURATION: 'DATASET:RO_BEST_DAY_CONFIGURATION_TEMPLATES',
    CONFIGURATION: 'DATASET:BEST_DAY:COMMON_CONFIGURATION',
    OBSERVATION: 'DATASET:ASSET_MUNTENIA:OBSERVATION_EVENTS',
  },
};

const omvConfig: BestDayConfig = {
  rootAssets: [
    {
      name: 'Austria',
      userRole: 'BESTDAY_AUSTRIA_ACCESS',
      externalId: 'AT_Country_Asset',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay-Austria',
          version: 3,
          prefix: 'AT',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'AT',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDayDeferments-Austria',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 2,
        },
      },
      dataSetMapping: countrySpecificDataSetMappings.AT_Country_Asset,
    },
    {
      name: 'Romania',
      userRole: 'BESTDAY_ROMANIA_ACCESS',
      externalId: 'RO_COUNTRY_ASSET',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay-Romania',
          version: 5,
          prefix: 'RO',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'RO',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDayDeferments-Romania',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 2,
        },
      },
      dataSetMapping: countrySpecificDataSetMappings.RO_COUNTRY_ASSET,
      visualConfiguration: {
        hideNetworkTab: true,
      },
    },
    {
      name: 'Moldova',
      userRole: 'BESTDAY_ROMANIA_MOLDOVA_ACCESS',
      externalId: 'RO_ASSET MOLDOVA',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay-Romania',
          version: 5,
          prefix: 'RO',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'RO',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDayDeferments-Romania',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 2,
        },
      },
      dataSetMapping: countrySpecificDataSetMappings['RO_ASSET MOLDOVA'],
      visualConfiguration: {
        hideNetworkTab: true,
      },
    },
    {
      name: 'Petromar',
      userRole: 'BESTDAY_ROMANIA_PETROMAR_ACCESS',
      externalId: 'RO_ASSET PETROMAR',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay-Romania',
          version: 5,
          prefix: 'RO',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'RO',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDayDeferments-Romania',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 2,
        },
      },
      dataSetMapping: countrySpecificDataSetMappings['RO_ASSET PETROMAR'],
      visualConfiguration: {
        hideNetworkTab: true,
      },
    },
    {
      name: 'Oltenia',
      userRole: 'BESTDAY_ROMANIA_OLTENIA_ACCESS',
      externalId: 'RO_ASSET OLTENIA',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay-Romania',
          version: 5,
          prefix: 'RO',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'RO',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDayDeferments-Romania',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 2,
        },
      },
      dataSetMapping: countrySpecificDataSetMappings['RO_ASSET OLTENIA'],
      visualConfiguration: {
        hideNetworkTab: true,
      },
    },
    {
      name: 'Valahia',
      userRole: 'BESTDAY_ROMANIA_VALAHIA_ACCESS',
      externalId: 'RO_ASSET VALAHIA',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay-Romania',
          version: 5,
          prefix: 'RO',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'RO',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDayDeferments-Romania',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 2,
        },
      },
      dataSetMapping: countrySpecificDataSetMappings['RO_ASSET VALAHIA'],
      visualConfiguration: {
        hideNetworkTab: true,
      },
    },
    {
      name: 'Muntenia',
      userRole: 'BESTDAY_ROMANIA_MUNTENIA_ACCESS',
      externalId: 'RO_ASSET MUNTENIA',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay-Romania',
          version: 5,
          prefix: 'RO',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'RO',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDayDeferments-Romania',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 2,
        },
      },
      dataSetMapping: countrySpecificDataSetMappings['RO_ASSET MUNTENIA'],
      visualConfiguration: {
        hideNetworkTab: true,
      },
    },
  ],
};

const demoConfigLervik: BestDayConfig = {
  rootAssets: [
    {
      name: 'Sweden',
      userRole: 'BESTDAY_LOR_SWEDEN_ACCESS',
      externalId: 'LOR_SWEDEN',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay',
          version: 1,
          prefix: 'LOR',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'LOR',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDay-Deferments',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 1,
        },
      },
      dataSetMapping: defaultDataSetMapping,
    },
  ],
};

const demoConfig: BestDayConfig = {
  rootAssets: [
    {
      name: 'Norway',
      userRole: 'BESTDAY_LOR_NORWAY_ACCESS',
      externalId: 'LOR_NORWAY',
      templates: {
        APPLICATION_DATA: {
          group: 'BestDay',
          version: 1,
          prefix: 'LOR',
        },
        CONFIG: {
          group: 'BestDay-Configuration',
          version: 1,
          prefix: 'LOR',
        },
        DEFERMENT_MATRIX: {
          group: 'BestDay-Deferments',
          version: 1,
        },
        INTERNAL_DATA: {
          group: 'BestDay-Internal',
          version: 1,
        },
      },
      dataSetMapping: defaultDataSetMapping,
    },
  ],
};

export const getConfig = () => {
  const tenant = getTenant();
  switch (tenant) {
    case 'omv':
    case 'omv-test':
    case 'omv-dev':
      return omvConfig;
    case 'lervik-industries':
      return demoConfigLervik;
    case 'doyle-industries':
    case 'engdahl-industries':
    case 'mp-demo':
    default:
      return demoConfig;
  }
};

export const getRootAssetConfig = (exId: string) => {
  const rootAssetConfig = getConfig().rootAssets.find(
    (asset) => asset.externalId === exId
  );
  return rootAssetConfig;
};
