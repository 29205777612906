import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { DeviationStatus } from 'utils/models';
import toastSlice, { ToastState } from './reducer';

export const useToast = () => {
  const { toastAction } = useSelector<RootState, ToastState>((state) => {
    return state.toast;
  });
  const dispatch = useDispatch();
  const setToastAction = useCallback(
    (toastAction?: DeviationStatus) => {
      dispatch(toastSlice.actions.setToastAction({ toastAction }));
    },
    [dispatch]
  );
  return {
    toastAction,
    setToastAction,
  };
};
