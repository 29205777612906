import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Pages } from 'utils/models/enums';
import Loadable from 'react-loadable';

const LazyWellSummary = Loadable({
  loader: () => import('containers/WellSummaryContainer'),
  loading: () => null,
});
const LazyDeepDiveContainer = Loadable({
  loader: () => import('containers/DeepDiveContainer'),
  loading: () => null,
});
const LazyKPIDashboard = Loadable({
  loader: () => import('pages/KPIDashboard'),
  loading: () => null,
});

export const AssetRoutes: React.FC = () => {
  return (
    <>
      <Switch>
        <Route
          exact
          path={`/assets/collection/${Pages.DeepDive}/:collectionId`}
          component={LazyDeepDiveContainer}
        />
        <Route
          exact
          path={`/assets/collection/${Pages.KPI}/:collectionId`}
          component={LazyKPIDashboard}
        />
        <Route
          exact
          path={`/assets/collection/${Pages.Wells}/:collectionId`}
          component={LazyWellSummary}
        />

        <Route
          exact
          path={`/assets/:assetExternalId/${Pages.DeepDive}`}
          component={LazyDeepDiveContainer}
        />
        <Route
          exact
          path={`/assets/:assetExternalId/${Pages.Wells}`}
          component={LazyWellSummary}
        />

        <Route
          exact
          path={`/assets/:assetExternalId/${Pages.KPI}`}
          component={LazyKPIDashboard}
        />

        {/* This one is to make sure proper redirection in case users have favorited the former URL */}
        <Redirect
          exact
          from="/assets/:assetExternalId"
          to={`/assets/:assetExternalId/${Pages.DeepDive}`}
        />
      </Switch>
    </>
  );
};

// preload components after 2 seconds
setTimeout(() => {
  LazyWellSummary.preload();
  LazyDeepDiveContainer.preload();
  LazyKPIDashboard.preload();
}, 2000);
