import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ProgressStatus =
  | 'success'
  | 'active'
  | 'exception'
  | 'normal'
  | undefined;

type InitialState = {
  counter: number;
  total: number;
  message: string;
  status: ProgressStatus;
  disabled: boolean;
};
const initialState: InitialState = {
  counter: 0,
  total: 0,
  message: ' Idle… ',
  status: 'normal',
  disabled: true,
};

const exportServiceSlice = createSlice({
  name: 'exportService',
  initialState,
  reducers: {
    increment: (state) => {
      if (state.counter + 1 === state.total) {
        return {
          ...state,
          counter: state.counter + 1,
          message: ' Finalizing… ',
          status: 'success',
        };
      }
      return {
        ...state,
        counter: state.counter + 1,
      };
    },
    toggleTotal: (
      state,
      action: PayloadAction<{
        total: number;
      }>
    ) => ({
      ...state,
      total: action.payload.total,
      counter: 0,
      message: ' Downloading… ',
      status: 'active',
    }),
    error: (
      state,
      action: PayloadAction<{
        message?: string;
      }>
    ) => ({
      ...state,
      message: action.payload.message || ' Error… ',
      status: 'exception',
    }),
    resetCounter: () => initialState,
    stop: (state) => ({
      ...state,
      message: ' Canceled… ',
      status: undefined,
    }),
    disableExport: (
      state,
      action: PayloadAction<{
        disabled: boolean;
      }>
    ) => ({
      ...state,
      disabled: action.payload.disabled,
    }),
  },
});

export type ExportServiceState = ReturnType<typeof exportServiceSlice.reducer>;
export default exportServiceSlice;
