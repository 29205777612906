import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';
import { DataNode } from 'antd/lib/tree';
import { Key } from 'antd/lib/table/interface';
import { RootState } from 'store';

export type AssetTreeState = {
  systems: {
    treeData: Record<string, DataNode[]>;
    expandedKeys: Key[];
    loadedKeys: Record<string, Key[]>;
  };
  withWells: {
    treeData: Record<string, DataNode[]>;
    expandedKeys: Key[];
    loadedKeys: Record<string, Key[]>;
  };
};

const assetTreeSlice = createSlice({
  name: 'assetTree',
  initialState: {
    systems: {
      treeData: {},
      expandedKeys: [],
      loadedKeys: {},
    },
    withWells: {
      treeData: {},
      expandedKeys: [],
      loadedKeys: {},
    },
  } as AssetTreeState,
  reducers: {
    setTreeData: (
      state,
      action: PayloadAction<{
        treeData: Record<string, DataNode[]>;
        loadedKeys: Record<string, Key[]>;
      }>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.withWells.treeData = {
        ...state.withWells.treeData,
        ...action.payload.treeData,
      };
      // eslint-disable-next-line no-param-reassign
      state.withWells.loadedKeys = {
        ...state.withWells.loadedKeys,
        ...action.payload.loadedKeys,
      };
    },
    setTreeDataSystems: (
      state,
      action: PayloadAction<{
        treeData: Record<string, DataNode[]>;
        loadedKeys: Record<string, Key[]>;
      }>
    ) => {
      // eslint-disable-next-line no-param-reassign
      state.systems.treeData = {
        ...state.systems.treeData,
        ...action.payload.treeData,
      };
      // eslint-disable-next-line no-param-reassign
      state.systems.loadedKeys = {
        ...state.systems.loadedKeys,
        ...action.payload.loadedKeys,
      };
    },
    setExpandedKeys: (state, action: PayloadAction<Key[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.withWells.expandedKeys = action.payload;
    },
    setExpandedKeysSystems: (state, action: PayloadAction<Key[]>) => {
      // eslint-disable-next-line no-param-reassign
      state.systems.expandedKeys = action.payload;
    },
  },
});

const selectAssetTree = (state: RootState): AssetTreeState => state.assetTree;

export const selectTreeDataWithWells = createSelector(
  [selectAssetTree],
  ({ withWells }) => withWells.treeData
);

export const selectExpendedKeysWithWells = createSelector(
  [selectAssetTree],
  ({ withWells }) => withWells.expandedKeys
);

export const selectLoadedKeysWithWells = createSelector(
  [selectAssetTree],
  ({ withWells }) => withWells.loadedKeys
);

export const selectTreeDataSystems = createSelector(
  [selectAssetTree],
  ({ systems }) => systems.treeData
);

export const selectExpendedKeysSystems = createSelector(
  [selectAssetTree],
  ({ systems }) => systems.expandedKeys
);

export const selectLoadedKeysSystems = createSelector(
  [selectAssetTree],
  ({ systems }) => systems.loadedKeys
);

export default assetTreeSlice;
