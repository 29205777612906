/* eslint-disable no-param-reassign */
import { createSlice, DeepPartial, PayloadAction } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import mergeWith from 'lodash/mergeWith';
import isArray from 'lodash/isArray';
import { DEFAULT_PREFERENCES, Preferences } from './types';

export const Aggregations = ['total', 'average'] as const;

const initialState = cloneDeep(DEFAULT_PREFERENCES);

const preferencesSlice = createSlice({
  name: 'preferences',
  initialState,
  reducers: {
    set: (state, { payload }: PayloadAction<DeepPartial<Preferences>>) => {
      // Apply required changes
      mergeWith(state, payload, (objValue: unknown, sourceValue: unknown) => {
        if (isArray(sourceValue)) {
          return sourceValue;
        }
        return undefined;
      });
    },
  },
});

export default preferencesSlice;
