import React, { useEffect, lazy, useState, Suspense } from 'react';
import { Route, useHistory } from 'react-router';
import { Location } from 'history';
import { Helmet } from 'react-helmet';
import { useMetrics } from '@cognite/metrics';
import { withI18nSuspense, useTranslation } from '@cognite/react-i18n';
import config from 'utils/config';
import { useRootAssets } from 'utils/models/systems';
import { usePreferences } from 'features/preferences';
import { Pages } from 'utils/models/enums';
import TopBar from 'components/TopBar';
import CurrentAssetProvider from 'containers/CurrentAssetProvider';
import PermissionsCheckContainer from 'containers/PermissionsCheckContainer';
import { PageLayout } from 'components/Layout';
import { Redirect, Switch } from 'react-router-dom';
import {
  ChartSelectionToastContainer,
  NotificationToastContainer,
  StyledToastContainer,
  ZoomProTipToastContainer,
} from 'utils/toasts/toasts';
import SpinProgress from 'components/SpinProgress';
import AssetLoadingErrorGuard from 'containers/AssetLoadingErrorGuard';
import AssetNavigation from 'components/AssetNavigation';
import { AssetRoutes } from './AssetRoutes';

const LazyUserProfile = lazy(() => import('pages/UserProfile'));
const LazyLogout = lazy(() => import('pages/Logout'));
const LazyFirstAccessPage = lazy(() => import('components/FirstAccessPage'));

const BestDay = () => {
  const { t, i18n } = useTranslation('Home');
  const {
    preferences: { language, lastSelectedRootAsset },
    initializePreferences,
    isPreferencesInitialized,
  } = usePreferences();

  const metrics = useMetrics('History');
  const history = useHistory();

  const [rootAssetExtId, setRootAssetExtId] = useState('');

  useEffect(() => {
    const onChange = ({ pathname }: Location, action: string) => {
      if (config.env === 'production') {
        metrics.track('router/LOCATION_CHANGE', { pathname, action });
      }
    };
    return history.listen(onChange);
  }, [metrics, history]);

  useEffect(() => {
    if (!language) {
      return;
    }

    i18n.changeLanguage(language);
  }, [i18n, language]);

  useEffect(() => {
    initializePreferences();
  }, [initializePreferences]);

  const { data: rootAssets, isError } = useRootAssets();

  useEffect(() => {
    if (isError) {
      throw new Error('Unable to fetch root assets');
    }
  }, [isError]);

  useEffect(() => {
    if (rootAssetExtId || !lastSelectedRootAsset || !rootAssets) return;

    const nextRootAsset =
      rootAssets.find((asset) => asset.externalId === lastSelectedRootAsset) ||
      rootAssets[0];
    setRootAssetExtId(nextRootAsset.externalId!);
  }, [lastSelectedRootAsset, rootAssetExtId, rootAssets]);

  return (
    <CurrentAssetProvider>
      <AssetLoadingErrorGuard>
        <Helmet>
          <title>{t('title', { defaultValue: 'BestDay' })}</title>
        </Helmet>
        <PermissionsCheckContainer>
          {!isPreferencesInitialized ? (
            <SpinProgress />
          ) : (
            <>
              {!lastSelectedRootAsset ? (
                <Route path="/" component={LazyFirstAccessPage} />
              ) : (
                <>
                  <Suspense fallback={null}>
                    <TopBar />
                    <PageLayout>
                      <AssetNavigation />
                      <Switch>
                        <Route path="/assets" render={() => <AssetRoutes />} />
                        <Route path="/user" component={LazyUserProfile} />
                        <Route path="/logout" component={LazyLogout} />
                        <Redirect
                          from="/watched/wells"
                          to="/assets/watched/wells"
                        />

                        <Redirect
                          from="/watched/kpi-dashboard"
                          to="/assets/watched/kpi-dashboard"
                        />

                        {rootAssetExtId && (
                          <>
                            <Redirect
                              from="/deep-dive"
                              to={`/assets/${rootAssetExtId}/${Pages.DeepDive}`}
                            />
                            <Redirect
                              from="/wells"
                              to={`/assets/${rootAssetExtId}/${Pages.Wells}`}
                            />
                            <Redirect
                              from="/kpi-dashboard"
                              to={`/assets/${rootAssetExtId}/${Pages.KPI}`}
                            />
                            <Redirect
                              from="/"
                              to={`/assets/${rootAssetExtId}/${Pages.KPI}`}
                            />
                          </>
                        )}
                      </Switch>
                    </PageLayout>
                    <StyledToastContainer />
                    <ChartSelectionToastContainer />
                    <ZoomProTipToastContainer />
                    <NotificationToastContainer />
                  </Suspense>
                </>
              )}
            </>
          )}
        </PermissionsCheckContainer>
      </AssetLoadingErrorGuard>
    </CurrentAssetProvider>
  );
};

export default withI18nSuspense(BestDay);
