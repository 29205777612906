import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import styled from 'styled-components/macro';
import { Checkbox, Badge, Colors, Icon } from '@cognite/cogs.js';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import {
  ExportProductionData,
  ProductionField,
} from 'components/ExportModal/types';
import {
  PRODUCT_TYPE_WATER,
  PRODUCT_TYPE_WATER_INJECTION,
} from 'utils/products';
import { CheckboxList, Container } from '../elements';

export type Props = {
  onChange: (changed: string[]) => void;
};

const ProductionData = ({ onChange }: Props) => {
  const { t } = useTranslation('ProductionData');
  const { t: tProducts } = useTranslation('Products');

  const { allProducts: AP } = useCurrentAsset();
  const [productStates, setProductStates] = useState<ExportProductionData>();
  const [expanded, setExpanded] = useState(true);
  const allProducts = useMemo(
    () => (productStates?.keys ? Object.keys(productStates?.keys).sort() : []),
    [productStates?.keys]
  );

  useEffect(() => {
    if (!allProducts.length && AP) {
      const Products = Object.keys(AP)
        .filter(
          (p) =>
            ![
              'GROSS',
              'TOTAL',
              PRODUCT_TYPE_WATER,
              PRODUCT_TYPE_WATER_INJECTION,
            ].includes(p)
        )
        .sort((a, b) => a.localeCompare(b));
      setProductStates({
        export: true,
        keys: Products.reduce(
          (acc, type) => ({
            ...acc,
            [type]: true,
          }),
          {}
        ),
      });
      onChange(Products);
    }
  }, [allProducts.length, AP, onChange]);

  const selectProduct = useCallback(
    (field: string) => (ticked: boolean) => {
      const productionDataKeys = productStates?.keys as ProductionField;
      const productionDataValue = Object.keys(productionDataKeys).reduce(
        (acc, product) => ({
          ...acc,
          [product]: product === field ? ticked : productionDataKeys[product],
        }),
        {}
      ) as ProductionField;
      const production = {
        export: Object.values(productionDataValue).some((it) => it),
        keys: productionDataValue,
      };
      setProductStates(production);
      onChange(
        production.export
          ? Object.keys(production.keys).filter(
              (key) => production.keys[key] && key
            )
          : []
      );
    },
    [onChange, productStates?.keys]
  );
  const productCount = useMemo(
    () => Object.values(productStates?.keys || {}).filter(Boolean).length,
    [productStates]
  );

  if (allProducts.length === 0) {
    return null;
  }

  return (
    <Container>
      <ExpandRow>
        <Checkbox
          style={{ flex: 1 }}
          name="productionData"
          checked={productStates?.export}
          onChange={(ticked) => {
            setProductStates({
              export: ticked,
              keys: allProducts.reduce(
                (acc, key) => ({
                  ...acc,
                  [key]: ticked,
                }),
                {}
              ),
            });
            onChange(ticked ? allProducts : []);
          }}
        >
          <Trans t={t} i18nKey="productionData_label">
            Production data
          </Trans>
        </Checkbox>
        <ExpandToggle
          tabIndex={0}
          role="button"
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              setExpanded(!expanded);
            }
          }}
          onClick={() => setExpanded(!expanded)}
        >
          <Badge
            text={String(productCount)}
            background={Colors['greyscale-grey2'].hex()}
            style={{ marginRight: 16 }}
          />
          <Icon type={expanded ? 'ChevronDownSmall' : 'ChevronRightSmall'} />
        </ExpandToggle>
      </ExpandRow>
      <ToggleableList visible={expanded}>
        {allProducts.map((product) => {
          if (!productStates?.keys) {
            return null;
          }
          return (
            <li key={product}>
              <Checkbox
                name={`${product}-export`}
                checked={!!productStates?.keys[product]}
                onChange={selectProduct(product)}
              >
                <Trans t={tProducts} i18nKey={`${product}-product`}>
                  {product}
                </Trans>
              </Checkbox>
            </li>
          );
        })}
      </ToggleableList>
    </Container>
  );
};

const ToggleableList = styled(CheckboxList)<{ visible: boolean }>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
`;

const ExpandRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ExpandToggle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 8px;
`;

export default withI18nSuspense(ProductionData);
