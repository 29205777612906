import { DeepPartial } from '@reduxjs/toolkit';
import { useProjectContext } from 'containers/AuthContainer';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import noop from 'lodash/noop';
import { useCallback, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import { assignUndefinedProps } from 'utils/assignUndefinedProps';
import {
  getPreferencesApi,
  setPreferencesApi,
} from 'utils/models/preferences/api';
import preferencesSlice from './reducer';
import { DEFAULT_PREFERENCES, Preferences, UnitPreferences } from './types';

export const usePreferences = () => {
  const { rootAsset } = useCurrentAsset();
  const { project } = useProjectContext();

  const [isPreferencesInitialized, setIsPreferencesInitialized] =
    useState(false);

  const preferences = useSelector<RootState, Preferences>((state) => {
    return state.preferences;
  });

  const dispatch = useDispatch();

  const initializePreferences = useCallback(
    () =>
      getPreferencesApi({ project })
        .then(({ data: prefs }) => {
          const mergedPrefs = assignUndefinedProps(prefs, DEFAULT_PREFERENCES);
          dispatch(preferencesSlice.actions.set(mergedPrefs));
        })
        .finally(() => {
          setIsPreferencesInitialized(true);
        }),
    [dispatch, project]
  );

  const setPreferences = useCallback(
    (prefs: DeepPartial<Preferences>, callback: () => void = noop) => {
      dispatch(preferencesSlice.actions.set(prefs));
      setPreferencesApi({ project, preferences: prefs }).then(callback);
    },
    [dispatch, project]
  );

  const units = useMemo(
    () =>
      (preferences.units[rootAsset?.externalId || 'default'] ||
        preferences.units.default) as UnitPreferences,
    [preferences.units, rootAsset]
  );

  const setUnits = useCallback(
    (unitChanges: UnitPreferences) => {
      const rootAssetExternalId = rootAsset?.externalId || 'default';

      // If not units have been set for this root asset, then set the default
      const mergedUnits = assignUndefinedProps(
        unitChanges,
        preferences.units[rootAssetExternalId] ||
          DEFAULT_PREFERENCES.units.default
      );

      setPreferences({
        units: {
          [rootAssetExternalId]: mergedUnits,
        },
      });
    },
    [preferences.units, rootAsset, setPreferences]
  );

  return {
    initializePreferences,
    isPreferencesInitialized,
    preferences,
    setPreferences,
    units,
    setUnits,
  };
};
