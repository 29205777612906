import { getClient } from 'utils/cognitesdk';

export async function getWellListByExternalIds(externalIds: string[]) {
  const assetList = await getClient().assets.retrieve(
    externalIds.map((externalId) => ({ externalId })),
    { ignoreUnknownIds: true } // Some old relationships are orphaned - this is okay!
  );

  return (
    assetList
      // Filter out items what do not have the network level label
      .filter((a) =>
        a.labels?.some((label) => label.externalId.includes('NETWORK_LEVEL'))
      )
  );
}
