import divide from 'lodash/divide';
import find from 'lodash/find';
import get from 'lodash/get';
import map from 'lodash/map';
import range from 'lodash/range';
import gte from 'lodash/gte';
import reduce from 'lodash/reduce';
import isNumber from 'lodash/isNumber';
import omit from 'lodash/omit';
import filter from 'lodash/filter';
import uniqBy from 'lodash/uniqBy';
import subtract from 'lodash/subtract';
import round from 'lodash/round';
import { DoubleDatapoint } from '@cognite/sdk';
import { Capacity, Production, Deviations } from 'graphql-types';
import { MonthSummeryExport, MonthSummeryExportItem } from 'hooks/types';
import { mapWellTypeToWellTypeTitle } from 'utils/models/wellListItems';
import {
  ConvertValueOptions,
  ConvertValueReturn,
} from 'features/unitConversion';
import { reduceArrToObj } from 'utils';
import { UnitPreferences } from 'features/preferences';
import { DEFAULT_UNIT, HYDROCARBON } from 'utils/products';
import {
  DEFAULT_DAY_FORMAT,
  getDefaultTimeLimit,
  isHappeningNow,
  returnWithFormat,
} from 'utils/datetime';

type Timeseries = {
  datapoints?: DoubleDatapoint[];
  unit?: string;
};
export const defermentProducts = [
  'OIL',
  'NONASSOC_GAS',
  'ASSOC_GAS',
  'CONDENSATE',
];
const getTimeseries = (v?: Capacity | Deviations | Production) =>
  get(v, 'timeSeries');
const getValue = (v: { value?: number }) => get(v, 'value');
export const efficiency = (prod?: number, cap?: number): number =>
  !prod || !cap ? 0 : (prod * 100) / cap;
export const listItemsForExport = (
  responses: MonthSummeryExport[],
  convertValue: (pros: ConvertValueOptions) => ConvertValueReturn,
  units: UnitPreferences,
  datePart: string,
  positive: boolean
): MonthSummeryExportItem[][] => {
  const res = responses.map(
    ({
      products,
      wellName,
      wellType,
      eventsGroup,
      defermentEvent,
    }): MonthSummeryExportItem[] => {
      return map(range(getDefaultTimeLimit(datePart)), (_v, i: number) => {
        const day = returnWithFormat(
          `${datePart}/${i + 1}`,
          DEFAULT_DAY_FORMAT
        );
        return reduceArrToObj(
          map(products, ({ type, capacity, deviations, production }) => {
            const targetUnit = type.match('H2O')
              ? // Water is always qubic-meter
                'M3'
              : get(
                  units,
                  type === 'OIL' ||
                    type === 'CONDENSATE' ||
                    type === HYDROCARBON
                    ? 'liquids'
                    : 'gas'
                );
            const actionFunc = (payload?: Timeseries) => {
              const sourceUnit =
                payload?.unit?.toLocaleUpperCase() || type.match('H2O')
                  ? 'M3'
                  : DEFAULT_UNIT;
              const value = get(
                find(
                  payload?.datapoints,
                  ({ timestamp }) =>
                    returnWithFormat(timestamp, DEFAULT_DAY_FORMAT) === day
                ),
                'value'
              );
              return type === HYDROCARBON
                ? { value, unit: DEFAULT_UNIT }
                : {
                    value:
                      sourceUnit && sourceUnit !== targetUnit && isNumber(value)
                        ? convertValue({
                            sourceUnit,
                            value,
                            targetUnit,
                            product: type,
                            skipRateConversion: true,
                          })?.value || value
                        : value,
                    unit: targetUnit,
                  };
            };
            const values = (v?: Capacity | Deviations | Production) =>
              actionFunc(getTimeseries(v));
            const product = values(find(production, { frequency: 'daily' }));
            const bestdayCapacity = values(
              find(capacity, { type: 'BESTDAY_CAPACITY' })
            );
            const deviationValue = values(deviations);
            const currentEvent = uniqBy(
              filter(eventsGroup, (o) => isHappeningNow(day, o)),
              'status'
            )[0];
            const volume = get(
              find(get(currentEvent, 'volumes'), {
                type: type.toLocaleLowerCase(),
              }),
              'volume'
            );
            const boeProductValue =
              convertValue({
                sourceUnit: product?.unit?.toUpperCase() || DEFAULT_UNIT,
                value: getValue(product) || 0,
                targetUnit: DEFAULT_UNIT,
                product: type,
                skipRateConversion: true,
              })?.value || 0;
            const capacityValue = getValue(bestdayCapacity);
            return {
              [`${type}_production`]: product,
              [`${type}_productionEfficiency`]: {
                unit: '%',
                value:
                  boeProductValue && capacityValue
                    ? efficiency(boeProductValue, capacityValue)
                    : 0,
              },
              ...reduceArrToObj(
                map(capacity, (capacities) => ({
                  [`${type}_${capacities.type}`]: values(capacities),
                }))
              ),
              [`${type}_fractionOfBestDay`]: {
                value:
                  boeProductValue && capacityValue
                    ? divide(boeProductValue, capacityValue)
                    : 0,
                unit: DEFAULT_UNIT,
              },
              ...(positive && !gte(getValue(deviationValue), 0)
                ? {}
                : {
                    [`${type}_deviations`]: deviationValue,
                    [`${type}_DETECTED`]: {
                      value: round(
                        subtract(get(deviationValue, 'value')!, volume || 0),
                        4
                      ),
                      unit: DEFAULT_UNIT,
                    },
                    ...(volume
                      ? {
                          [`${type}_${get(currentEvent, 'status')}`]: {
                            value: volume,
                            unit: DEFAULT_UNIT,
                          },
                        }
                      : {}),
                  }),
            };
          }),
          {
            well: wellName,
            wellType: mapWellTypeToWellTypeTitle(wellType),
            day,
            deferments: {
              value: reduce(
                omit(
                  defermentEvent?.find((o) => isHappeningNow(day, o)),
                  ['startTime', 'endTime']
                ),
                (t, a) => t + a,
                0
              ),
              unit: DEFAULT_UNIT,
            },
          }
        );
      });
    }
  );
  return res;
};
