import { DeepPartial } from '@reduxjs/toolkit';
import { EventFilter, ExternalEvent, CogniteEvent } from '@cognite/sdk';
import merge from 'lodash/merge';
import { getClient } from 'utils/cognitesdk';
import { Deviation } from './types';

export const fetchDeviations = async (
  customFilter: DeepPartial<EventFilter> & Pick<EventFilter, 'activeAtTime'>,
  nextCursor?: string
): Promise<CogniteEvent[]> => {
  const filter = merge(
    {},
    { type: 'production_deviation', subtype: 'bestday_deviation' },
    customFilter
  );
  const cursor = await getClient().events.list({
    filter,
    sort: { createdTime: 'asc' },
    cursor: nextCursor,
    limit: 1000,
  });

  if (!cursor.nextCursor) {
    return cursor.items;
  }

  const nextItems = await fetchDeviations(customFilter, cursor.nextCursor);
  return cursor.items.concat(nextItems);
};

export const countDeviations = async (
  customFilter: DeepPartial<EventFilter> & Pick<EventFilter, 'activeAtTime'>
): Promise<number> => {
  const filter = merge(
    {},
    { type: 'production_deviation', subtype: 'bestday_deviation' },
    customFilter
  );
  const result = await getClient().events.aggregate.count({
    filter,
  });

  return result?.[0].count;
};

export const updateDeviation = async (
  base: Deviation,
  patch: Partial<Deviation>,
  user: string,
  dataSetId: number
): Promise<Deviation> => {
  const now = new Date();
  const updateEventExternalId = `${base.externalId}_UPDATE_${now.getTime()}`;

  const event: ExternalEvent = {
    type: 'production_deviation',
    subtype: 'deviation-update',
    externalId: updateEventExternalId,
    dataSetId,
    assetIds: base.assetIds,
    source: user,
    startTime: base.startDate,
    endTime: base.endDate,
  };

  if (patch.status) {
    event.metadata = {
      [`${patch.status.toUpperCase()}_DATETIME`]: now.toISOString(),
    };
  }

  return getClient()
    .events.create([event])
    .then(() => {
      return merge({}, base, patch);
    });
};
