import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeviationStatus } from 'utils/models';

type InitialState = {
  toastAction?: DeviationStatus;
};
const toastSlice = createSlice({
  name: 'toast',
  initialState: {
    toastAction: undefined,
  } as InitialState,
  reducers: {
    setToastAction: (
      state,
      action: PayloadAction<{ toastAction?: DeviationStatus }>
    ) => {
      const { toastAction } = action.payload;
      return { ...state, toastAction };
    },
  },
});

export type ToastState = ReturnType<typeof toastSlice.reducer>;

export default toastSlice;
