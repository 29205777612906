import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import intradayChartSlice, { intradayChartState } from './reducer';
import { PotentialChartComment, UsePotentialChartComment } from './types';

export const usePotentialChartComment = (): UsePotentialChartComment => {
  const { potentialChartComment } = useSelector<RootState, intradayChartState>(
    (state) => {
      return state.intradayChart;
    }
  );
  const dispatch = useDispatch();

  const setPotentialChartComment = (comment?: PotentialChartComment) => {
    dispatch(
      intradayChartSlice.actions.setPotentialChartComment({
        potentialChartComment: comment,
      })
    );
  };

  return {
    potentialChartComment,
    setPotentialChartComment,
  };
};
