import { DatapointAggregate, DoubleDatapoint } from '@cognite/sdk';
import { GetDatapointsOptions } from 'utils/cognitesdk';

export type FormulaLookup = { [destinationUnit: string]: string };

/**
 * Store the conversion formulas between the different units. For example:
 *
 * <pre><code>
 * {
 *   "C": {
 *     "F": "(({{ value }}) * 9/5) + 32",
 *     "K": "({{ value }}) + 273.15",
 *   },
 *   "F": {
 *     "C": "(({{ value }}) - 32) * 5/9",
 *     "K": "((({{ value }}) - 32) * 5/9) + 273.15",
 *   },
 *   "K": {
 *     "C": "({{ value }}) - 273.15",
 *     "F": "((({{ value }}) - 273.15) * 9/5) + 32",
 *   },
 * }
 * </code></pre>
 */
export type ConversionFormulaMaps = {
  [sourceUnit: string]: FormulaLookup;
};

/**
 * Cluster the peer-unit groups together and provide access based on group ID.
 * For example:
 *
 * <pre><code>
 * {
 *   "temperatures": ["C", "F", "K"],
 *   "distances": ["meters", "feet"],
 *   "volumes": ["m3", "BOE"],
 * }
 * </code></pre>
 */
export type RelatedUnits = { [groupId: string]: string[] };

export type ProductConversions = { [productId: string]: ConversionFormulaMaps };

export type ConversionQueryResponse = {
  externalId: string;
  datapoints: DoubleDatapoint[] | DatapointAggregate[];
  unit: string;
  isStep?: boolean;
  requestId?: string;
  id: number;
};

export type UnitConversionItemRequestRealtime = {
  realtimeProduct: string;
  realtimeUnit: string;
};

export type UnitConversionItemRequest = {
  externalId: string;
  options?: GetDatapointsOptions;
  toUnit?: string;
  needTimeAdjustment?: boolean;
  latestOnly?: boolean;
  realtime?: UnitConversionItemRequestRealtime;
  requestId?: string;
};

export type UnitConverterType = {
  items: UnitConversionItemRequest[];
};

export type Datapoint = DoubleDatapoint | DatapointAggregate;

export type ItemRequestGroups = UnitConversionItemRequest[][];

export const UNIT_FREQUENCY_SUFFIXES = [
  'PERMONTH',
  'PERWEEK',
  'PERDAY',
  'PERHOUR',
  'PERMINUTE',
];
