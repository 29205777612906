import styled from 'styled-components/macro';
import LogoImg from 'assets/Logo.svg';
import { Link } from 'react-router-dom';

export const LogoImage = styled.div`
  background-image: url(${LogoImg});
  width: 32px;
  height: 29px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-right: 8px;
  margin-left: 12px;
`;

export const StyledLogoWrapper = styled(Link)`
  padding: 0 16px;
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const AppName = styled.span`
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;
`;
