/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DynamicCollection } from 'components/DynamicCollection/DynamicCollectionModal';
import { Collection } from 'utils/models/collections';

export type CollectionsState = {
  collections: Collection[];
  dynamicCollections: DynamicCollection[];
  activeCollectionKey?: string;
  addedCollection?: Collection | DynamicCollection;
  editedCollection?: Collection | DynamicCollection;
  deletedCollection?: Collection | DynamicCollection;
  batchEditedCollections?: Collection[];
};

const collectionsSlice = createSlice({
  name: 'collections',
  initialState: {
    collections: [],
    dynamicCollections: [],
    activeCollectionKey: undefined,
    addedCollection: undefined,
    editedCollection: undefined,
    deletedCollection: undefined,
  } as CollectionsState,
  reducers: {
    setActiveCollectionId: (
      state,
      action: PayloadAction<{ collectionKey: string }>
    ) => {
      const { collectionKey } = action.payload;
      state.activeCollectionKey = collectionKey;
    },
    get: (
      state,
      action: PayloadAction<{
        collections: Collection[];
      }>
    ) => {
      const { collections } = action.payload;
      state.collections = collections;
    },

    getDynamicCollections: (
      state,
      action: PayloadAction<{
        dynamicCollections: DynamicCollection[];
      }>
    ) => {
      const { dynamicCollections } = action.payload;
      state.dynamicCollections = dynamicCollections;
    },

    add: (
      state,
      action: PayloadAction<{
        collection: Collection;
      }>
    ) => {
      const { collection } = action.payload;
      state.collections = [...state.collections, collection];
      state.addedCollection = collection;
    },

    addDynamicCollection: (
      state,
      action: PayloadAction<{
        dynamicCollection: DynamicCollection;
      }>
    ) => {
      const { dynamicCollection } = action.payload;
      state.dynamicCollections = [
        ...state.dynamicCollections,
        dynamicCollection,
      ];
      state.addedCollection = dynamicCollection;
    },

    edit: (state, action: PayloadAction<{ collection: Collection }>) => {
      const { collection } = action.payload;
      state.collections = state.collections.map((stateCollection) => {
        if (stateCollection.key === collection.key) {
          return collection;
        }
        return stateCollection;
      });
      state.editedCollection = collection;
    },

    editDynamicCollection: (
      state,
      action: PayloadAction<{ dynamicCollection: DynamicCollection }>
    ) => {
      const { dynamicCollection } = action.payload;
      state.dynamicCollections = state.dynamicCollections.map(
        (stateDynamicCollection) => {
          if (stateDynamicCollection.key === dynamicCollection.key) {
            return dynamicCollection;
          }
          return stateDynamicCollection;
        }
      );
      state.editedCollection = dynamicCollection;
    },

    delete: (state, action: PayloadAction<{ collection: Collection }>) => {
      const { collection } = action.payload;
      state.collections = state.collections.filter(
        ({ key }) => key !== collection.key
      );
      state.deletedCollection = collection;
    },

    deleteDynamicCollection: (
      state,
      action: PayloadAction<{ dynamicCollection: DynamicCollection }>
    ) => {
      const { dynamicCollection } = action.payload;
      state.dynamicCollections = state.dynamicCollections.filter(
        ({ key: id }) => id !== dynamicCollection.key
      );
      state.deletedCollection = dynamicCollection;
    },

    batchEdit: (
      state,
      action: PayloadAction<{ collections: Collection[] }>
    ) => {
      const { collections } = action.payload;
      state.batchEditedCollections = collections;
      collections.forEach((collection) => {
        const index = state.collections.findIndex(
          ({ key }) => key === collection.key
        );
        if (index > -1) {
          state.collections[index] = collection;
        }
      });
    },
  },
});

export default collectionsSlice;
