import { useTranslation } from '@cognite/react-i18n';
import { useCallback } from 'react';

export const useFilterChips = () => {
  const { t } = useTranslation('VolumeChipTitle');
  const { t: tProducts } = useTranslation('Products');

  const equalTo = t('equal-to', {
    defaultValue: 'equal to',
  });

  const lowerThan = t('lower-than', {
    defaultValue: 'lower than',
  });

  const largerThan = t('larger-than', {
    defaultValue: 'larger than',
  });

  const andLowerThan = t('and-lower-than', {
    defaultValue: 'and lower than',
  });

  const volumeChipTitle = useCallback(
    (min: string, max: string, product: string = 'total-hydrocarbon') => {
      const productTranslation = tProducts(`${product}-product`, {
        defaultValue: product,
      });

      if (min === max) {
        return `${productTranslation} ${equalTo} ${max}`;
      }
      if (min.replace(/%/, '') === '0') {
        return `${productTranslation} ${lowerThan} ${max}`;
      }

      if (max.replace(/%/, '') === '100') {
        return `${productTranslation} ${largerThan} ${min}`;
      }

      return `${productTranslation} ${largerThan} ${min} ${andLowerThan} ${max}`;
    },
    [andLowerThan, equalTo, largerThan, lowerThan, tProducts]
  );

  return { volumeChipTitle };
};
