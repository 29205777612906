import { Colors } from '@cognite/cogs.js';
import { DoubleDatapoint } from '@cognite/sdk';
import { ChartData } from 'components/Chart';
import { ChartBarData } from 'components/Chart/Bar/types';
import isNaN from 'lodash/isNaN';
import { getStartOfDay, getStartOfMonth } from 'utils/datetime';
import { KPITrendAggregationType } from '../components/DailyMonthlySelector';

export const StatusColors = {
  detected: {
    main: Colors['greyscale-grey7'].hex(),
    secondary: Colors['greyscale-grey2'].hex(),
    highlightColor: 'rgba(134, 134, 134, 0.06)',
    highlightBorderColor: 'rgba(153, 153, 153, 0.4)',
  },
  ignored: {
    main: '#BB5B00',
    secondary: Colors['yellow-6'].hex(),
    highlightColor: 'rgba(235, 155, 0, 0.06)',
    highlightBorderColor: 'rgba(235, 155, 0, 0.4)',
  },
  resolved: {
    main: Colors.midblue.hex(),
    secondary: 'rgba(79, 148, 250, 0.12)',
    highlightColor: 'rgba(74, 103, 251, 0.06)',
    highlightBorderColor: 'rgba(74, 103, 251, 0.4)',
  },
  explained: {
    main: Colors.midblue.hex(),
    secondary: 'rgba(79, 148, 250, 0.12)',
    highlightColor: 'rgba(134, 134, 134, 0.06)',
    highlightBorderColor: 'rgba(104, 55, 139, 0.4)',
  },
  investigating: {
    main: Colors['purple-2'].hex(),
    secondary: Colors['purple-7'].hex(),
    highlightColor: 'rgba(104, 55, 139, 0.06);',
    highlightBorderColor: 'rgba(104, 55, 139, 0.4)',
  },
  planned: {
    main: '#22633C',
    secondary: 'rgba(57, 162, 99, 0.12)',
  },
  unplanned: {
    main: '#BB5B00',
    secondary: '#FFF1CC',
  },
  actual: {
    main: '#595959',
    secondary: '#F5F5F5',
  },
  future: {
    main: '#595959',
    secondary: '#F5F5F5',
  },
} as {
  [status: string]: {
    main: string;
    secondary: string;
    highlightColor?: string;
    highlightBorderColor?: string;
  };
};

export const calcPercentage = (value: number, total?: number) =>
  total && !isNaN(total) ? (value / total) * 100 : 0;

export const getProductsFromLines = (lines: ChartData[]) =>
  Array.from(new Set(lines.map((l) => l.productName))).filter(Boolean);

export const sumDatapoints = (
  chartData: ChartData[],
  extraOptions: Partial<ChartData | ChartBarData> = {},
  aggregationType: KPITrendAggregationType = 'daily'
) => {
  const timestampMap: { [timestamp: number]: number } = {};
  return chartData.reduce(
    (acc, { data, ...rest }) => ({
      ...acc,
      data: ((data as DoubleDatapoint[]) || []).reduce((acc, dp) => {
        const { value } = dp;
        const timestamp =
          aggregationType === 'monthly'
            ? +getStartOfMonth(dp.timestamp)
            : +getStartOfDay(dp.timestamp);
        const datapoint = acc.find((dp) => +dp.timestamp === timestamp);

        timestampMap[timestamp] =
          (timestampMap[timestamp] || 0) + (!isNaN(value) ? value : 0);

        if (datapoint) {
          datapoint.value = timestampMap[timestamp];
        } else {
          acc.push({
            timestamp: new Date(+timestamp),
            value: timestampMap[timestamp],
          });
        }
        return acc;
      }, [] as DoubleDatapoint[]),
      ...extraOptions,
      ...rest,
    }),
    {
      externalId: '',
      unit: '',
      data: [],
      productName: extraOptions.productName || '',
    }
  );
};

type AggregateDatapointsParams = {
  chartData: ChartData[];
  aggregation: 'sum' | 'average';
  newChartData?: Partial<ChartData | ChartBarData>;
  frequency?: KPITrendAggregationType;
  discardZeros?: boolean;
};

export const aggregateDatapoints = ({
  chartData,
  aggregation,
  newChartData = {},
  frequency = 'daily',
  discardZeros = true,
}: AggregateDatapointsParams) => {
  const timestampMap: {
    [timestamp: number]: {
      aggregatedValue: number;
      averageValue: number;
      numberOfDatapoints: number;
      datapoint: DoubleDatapoint;
    };
  } = {};

  const timestampFunction =
    frequency === 'monthly' ? getStartOfMonth : getStartOfDay;

  const aggregatedChartData = chartData.reduce(
    ({ data: accData = [], ...accRest }, { data, ...rest }) => {
      ((data as DoubleDatapoint[]) || []).forEach((dp) => {
        const { value } = dp;

        if (discardZeros && value === 0) {
          return;
        }

        const timestamp = +timestampFunction(dp.timestamp);

        let { datapoint } = timestampMap[timestamp] || {};

        if (!timestampMap[timestamp]) {
          datapoint = { value: 0, timestamp: new Date(+timestamp) };
          timestampMap[timestamp] = {
            aggregatedValue: 0,
            averageValue: 0,
            numberOfDatapoints: 0,
            datapoint,
          };

          accData.push(datapoint);
        }

        timestampMap[timestamp].numberOfDatapoints += 1;
        timestampMap[timestamp].aggregatedValue += !isNaN(value) ? value : 0;

        let valueToBeUsed = timestampMap[timestamp].aggregatedValue;

        if (aggregation === 'average') {
          timestampMap[timestamp].averageValue =
            timestampMap[timestamp].aggregatedValue /
            timestampMap[timestamp].numberOfDatapoints;

          valueToBeUsed = timestampMap[timestamp].averageValue;
        }

        datapoint.value = valueToBeUsed;
      }, [] as DoubleDatapoint[]);

      return {
        ...accRest,
        data: accData,
        ...newChartData,
        ...rest,
      };
    },
    {
      externalId: '',
      unit: '',
      data: [],
      productName: newChartData.productName || '',
    }
  );

  return {
    ...aggregatedChartData,
    data: aggregatedChartData.data.sort(
      (datapointA, datapointB) => +datapointA.timestamp - +datapointB.timestamp
    ),
  };
};
