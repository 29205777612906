/* eslint-disable no-param-reassign */
import { CogniteEvent } from '@cognite/sdk';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Deferment } from 'utils/models';

const defermentsSlice = createSlice({
  name: 'deferments',
  initialState: {
    loading: false,
    deferments: [],
    convertedDeferments: [],
    updateLoading: false,
    lowDeferments: [],
  } as DefermentsState,
  reducers: {
    getDeferments: (state) => {
      state.loading = true;
      state.error = undefined;
    },
    getDefermentsSuccess: (
      state,
      action: PayloadAction<{
        deferments: Deferment[];
      }>
    ) => {
      const { deferments } = action.payload;
      state.loading = false;
      state.error = undefined;
      state.deferments = deferments;
    },
    getConvertedDefermentsSuccess: (
      state,
      action: PayloadAction<{
        deferments: Deferment[];
      }>
    ) => {
      const { deferments } = action.payload;
      state.loading = false;
      state.error = undefined;
      state.convertedDeferments = deferments;
    },
    getDefermentsError: (state, action: PayloadAction<{ error: Error }>) => {
      const { error } = action.payload;
      state.loading = false;
      state.error = error;
    },
    setSelectedDeferment: (
      state,
      action: PayloadAction<{ selectedDefermentExternalId?: string }>
    ) => {
      const { selectedDefermentExternalId } = action.payload;
      state.selectedDefermentExternalId = selectedDefermentExternalId;
    },
    setLowDeferments: (
      state,
      action: PayloadAction<{ data: CogniteEvent[] }>
    ) => {
      const { data } = action.payload;
      state.lowDeferments = data;
    },
    setSelectedLowDeferment: (
      state,
      action: PayloadAction<{ selectedLowDefermentExternalId?: string }>
    ) => {
      const { selectedLowDefermentExternalId } = action.payload;
      state.selectedLowDefermentExternalId = selectedLowDefermentExternalId;
    },
    updateEventStart: (state) => {
      state.updateLoading = true;
    },
    updateEventSuccess: (
      state,
      action: PayloadAction<{
        updatedDeferment: Deferment;
      }>
    ) => {
      const { updatedDeferment } = action.payload;
      state.deferments = state.deferments.map((def) => {
        if (def.externalId === updatedDeferment.externalId) {
          return updatedDeferment;
        }
        return def;
      });
      state.updateLoading = false;
    },
    updateEventError: (
      state,
      action: PayloadAction<{
        updateError: Error;
      }>
    ) => {
      const { updateError } = action.payload;
      state.updateLoading = false;
      state.updateError = updateError;
    },
  },
});

export type DefermentsState = {
  loading: boolean;
  deferments: Deferment[];
  lowDeferments: CogniteEvent[];
  convertedDeferments: Deferment[];
  updateLoading: boolean;
  error?: Error;
  selectedDefermentExternalId?: string;
  selectedLowDefermentExternalId?: string;
  updateError?: Error;
};

export default defermentsSlice;
