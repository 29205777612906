/* eslint-disable no-param-reassign */
import merge from 'lodash/merge';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserInfo } from './types';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: '',
    project: '',
  } as UserInfo,
  reducers: {
    login: (state, action: PayloadAction<UserInfo>) => {
      return merge({}, state, action.payload);
    },
  },
});

export type AuthState = ReturnType<typeof authSlice.reducer>;

export default authSlice;
