import dayjs from 'dayjs';
import { CogniteEvent, Metadata } from '@cognite/sdk';
import merge from 'lodash/merge';
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import includes from 'lodash/includes';
import last from 'lodash/last';
import { PRODUCT_TYPE_WATER } from 'utils/products';
import indexOf from 'lodash/indexOf';
import {
  DeviationStatus,
  Deviation,
  DeviationVolume,
  InitialDataVolume,
} from './types';

export const DEVIATION_STATUSES: DeviationStatus[] = [
  'detected',
  'ignored',
  'investigating',
  'explained',
];

const PRIORITIES = {
  detected: 1,
  investigating: 2,
  ignored: 3,
  resolved: 4,
  explained: 5,
  commenting: 9,
} as const;

export const getStatus = (
  detected: string,
  ignored?: string,
  resolved?: string
): DeviationStatus => {
  if (!ignored && !resolved) {
    return 'detected';
  }

  const dates = {
    detected: dayjs(detected),
    ignored: ignored ? dayjs(ignored) : undefined,
    resolved: resolved ? dayjs(resolved) : undefined,
  } as const;
  const sortedKeyValues = Object.entries(dates)
    .filter((keyValue) => !!keyValue[1])
    .sort(([keyA, momentA], [keyB, momentB]) => {
      if (!momentA || !momentB) {
        throw new Error('Have an invalid date that was not filtered out');
      }
      if (momentA.unix() === momentB.unix()) {
        return (
          PRIORITIES[keyB as DeviationStatus] -
          PRIORITIES[keyA as DeviationStatus]
        );
      }
      return momentB.unix() - momentA.unix();
    });
  const [status] = sortedKeyValues[0];
  return status as DeviationStatus;
};

export const createDeviationPatch = (
  event: CogniteEvent
): Partial<Deviation> | null => {
  const { externalId, metadata, assetIds } = event;
  if (!externalId) {
    return null;
  }

  if (!assetIds || assetIds.length === 0) {
    return null;
  }

  if (!metadata) {
    return null;
  }

  const {
    ESTIMATED_VOLUME: estimatedVolume,
    PRODUCT: product,
    DETECTED_DATETIME: detectedDate,
    IGNORED_DATETIME: ignoredDate,
    RESOLVED_DATETIME: resolvedDate,
  } = metadata;

  // Start building the patch.

  const patch: Partial<Deviation> = {
    // Note that we omit the external ID. This is *not* something that we want
    // to update on the base event.
    assetIds,
  };

  if (product) {
    patch.product = product;
  }

  if (estimatedVolume && !Number.isNaN(+estimatedVolume)) {
    patch.volume = +estimatedVolume;
  }

  if (detectedDate) {
    patch.detectedDate = new Date(detectedDate);
  }

  if (ignoredDate) {
    patch.ignoredDate = new Date(ignoredDate);
  }

  if (resolvedDate) {
    patch.resolvedDate = new Date(resolvedDate);
  }

  return patch;
};

export const getCurrentValue = (
  totalHCInput: number,
  inputValue: number,
  totalHCGrouped?: number
) => {
  const ratio = totalHCInput === 0 ? 0 : inputValue / totalHCInput;
  if (totalHCGrouped) {
    const value = (totalHCInput - totalHCGrouped) * ratio;
    return { currentValue: value, ratio };
  }
  return { currentValue: inputValue, ratio };
};

export const reducePrecision = (val: string): string => {
  if (val.includes('e')) return Number(val).toString();

  if (val.includes('.')) {
    const valArr = val.split('.');
    if (valArr[1].length > 7) {
      valArr[1] = valArr[1].substring(0, 7);
    }

    return valArr.join('.');
  }
  return val;
};

const deviationMetaDataFixPrecision = (metadata: Metadata): Metadata => {
  const precisionMetadata = [
    'bestday_prediction_current',
    'total_hydrocarbon_deviation_volume_current',
  ].reduce((acc, current) => {
    if (metadata[current]) {
      return { ...acc, [current]: reducePrecision(metadata[current]) };
    }
    return acc;
  }, {});

  return {
    ...metadata,
    ...precisionMetadata,
  };
};

export const createDeviation = (
  event: CogniteEvent,
  products: string[]
): Deviation | null => {
  const {
    externalId,
    metadata,
    startTime,
    endTime,
    assetIds,
    source,
    lastUpdatedTime,
  } = event;
  if (!externalId) {
    return null;
  }

  if (!metadata) {
    return null;
  }

  if (!startTime) {
    return null;
  }

  if (!assetIds || assetIds.length === 0) {
    return null;
  }
  const startDate = new Date(startTime);

  const {
    bestday_prediction_current: bestDayInput,
    total_hydrocarbon_deviation_volume_current: totalHCInput,
    deviation_fraction_of_best_day: fractionOfBestDay,
    first_reaction_time: firstReactionTime,
    product_type: product,
    DETECTED_DATETIME: detectedDate = startDate.toISOString(),
    IGNORED_DATETIME: ignoredDate,
    RESOLVED_DATETIME: resolvedDate,
    hydrocarbon_volume_unit: unit,
    water_volume_unit: waterUnit,
    total_hydrocarbon_deviation_volume_grouped: totalHCGrouped,
  } = deviationMetaDataFixPrecision(metadata);

  if (!totalHCInput || Number.isNaN(+totalHCInput)) {
    return null;
  }

  if (!product) {
    return null;
  }

  const productData = products.map((product) => {
    const defermentInputKey = `${product.toLowerCase()}_actual_deferment_current`;
    const deviationInputKey = `${product.toLowerCase()}_deviation_volume_current`;
    const productionInputKey = `${product.toLowerCase()}_production_current`;
    /*
      thrugh maping the metadatada keys we try to identify the original searched key for product if the keys are broken
      also fixing the product type to don't mix similar keys
      example: nonassoc with assoc
    */
    const getInitilalValueIfKeyIsBroken = last(
      uniq(
        map(metadata, (value, key) => {
          if (includes(key, deviationInputKey)) {
            const original = key.split('_');
            const incoming = defermentInputKey.split('_');
            if (original.length === incoming.length) {
              if (original[0] !== incoming[0]) {
                return '';
              }
              return value;
            }
            if (indexOf(original, incoming[0]) > 0) {
              return value;
            }
            return '';
          }
          return '';
        })
      )
    ) as string;

    return {
      product,
      defermentValue:
        metadata[defermentInputKey] &&
        reducePrecision(metadata[defermentInputKey]),
      initialValue:
        getInitilalValueIfKeyIsBroken &&
        reducePrecision(getInitilalValueIfKeyIsBroken),
      productionValue:
        metadata[productionInputKey] &&
        reducePrecision(metadata[productionInputKey]),
      unit: product === PRODUCT_TYPE_WATER ? waterUnit : unit,
    };
  });

  const initialData = {
    deferments: productData
      .map(
        ({ defermentValue, product }) =>
          +defermentValue && {
            product,
            inputValue: +defermentValue,
            inputValueBOE: +defermentValue,
            unit,
          }
      )
      .filter(Boolean) as InitialDataVolume[],
    production: productData
      .map(
        ({ productionValue, product }) =>
          +productionValue && {
            product,
            inputValue: +productionValue,
            inputValueBOE: +productionValue,
            unit,
          }
      )
      .filter(Boolean) as InitialDataVolume[],
  };

  const volumes = productData
    .map(
      ({ initialValue, product }) =>
        +initialValue && {
          product,
          ...getCurrentValue(+totalHCInput, +initialValue, +totalHCGrouped),
          initialValue: +initialValue,
          unit,
        }
    )
    .filter(Boolean) as DeviationVolume[];

  const remainingTotalHc = totalHCGrouped
    ? +totalHCInput - +totalHCGrouped
    : +totalHCInput;
  const inputFractionOfBestDay = fractionOfBestDay ? +fractionOfBestDay : 0;
  const groupedFractionOfBestDay =
    +totalHCInput === 0
      ? 0
      : inputFractionOfBestDay * (remainingTotalHc / +totalHCInput);

  return {
    externalId,
    startDate,
    endDate: endTime ? new Date(endTime) : undefined,
    status: getStatus(detectedDate, ignoredDate, resolvedDate),
    product,
    groupedVolume: +totalHCGrouped || 0,
    volumes,
    volume: remainingTotalHc,
    inputVolume: +totalHCInput,
    bestDayVolume: +bestDayInput,
    unit: unit || '',
    detectedDate: startDate,
    assetIds,
    source,
    fractionOfBestDay: groupedFractionOfBestDay,
    firstReactionTime: firstReactionTime ? +firstReactionTime : undefined,
    lastUpdatedTime: new Date(lastUpdatedTime),
    initialData,
    ignoredDate: ignoredDate ? new Date(ignoredDate) : undefined,
    resolvedDate: resolvedDate ? new Date(resolvedDate) : undefined,
  };
};

export const createDeviations = (
  items: CogniteEvent[],
  products: string[]
): Deviation[] => {
  const updateEvents = items.filter(
    (event) => event.subtype === 'deviation-update' && !!event.externalId
  );

  const originalEvents = items.filter(
    (event) => event.subtype === 'bestday_deviation'
  );

  return originalEvents.reduce((acc, event) => {
    const baseEvent = createDeviation(event, products);
    if (!baseEvent) {
      return acc;
    }

    const eventUpdates: Partial<Deviation>[] = updateEvents
      .filter((updateEvent) => {
        return updateEvent.externalId?.startsWith(baseEvent.externalId);
      })
      .reduce((list, updateEvent) => {
        const patch = createDeviationPatch(updateEvent);
        if (!patch) {
          return list;
        }
        return [...list, patch];
      }, [] as Partial<Deviation>[]);

    const mergedEvent: Deviation = merge({}, baseEvent, ...eventUpdates);
    mergedEvent.status = getStatus(
      mergedEvent.detectedDate.toISOString(),
      mergedEvent.ignoredDate?.toISOString(),
      mergedEvent.resolvedDate?.toISOString()
    );

    return [...acc, mergedEvent];
  }, [] as Deviation[]);
};

export const convertDeviationStatusToGroupStatus = (
  deviationStatus: DeviationStatus
) => {
  switch (deviationStatus) {
    case 'explained':
    case 'ignored':
    case 'investigating':
    case 'commenting':
      return deviationStatus.toUpperCase();
    default:
      throw new Error(`${deviationStatus} has no equivalent group status`);
  }
};

export const convertGroupStatusToDeviationStatus = (groupStatus: string) => {
  switch (groupStatus) {
    case 'EXPLAINED':
    case 'IGNORED':
    case 'INVESTIGATING':
      return groupStatus.toLowerCase();
    default:
      throw new Error(`${groupStatus} has no equivalent deviation status`);
  }
};
