import React from 'react';
import { useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { Collapse, Tabs, Icon, CollapsePanelProps } from '@cognite/cogs.js';
import styled from 'styled-components/macro';
import { DistributionTabContent, TabContent } from '.';

export type DistributionTab = {
  key: string;
  title: string | React.ReactNode;
  tabContent: TabContent[];
};

type Props = {
  tabs: DistributionTab[];
  defaultActiveKey?: string;
};

const EventDistribution = ({ tabs, defaultActiveKey }: Props) => {
  const { t } = useTranslation('EventDistribution');

  const CustomIcon = ({ isActive }: CollapsePanelProps) => {
    return (
      <Icon
        type="ChevronUpSmall"
        style={{
          transition: 'transform .2s',
          transform: `rotate(${isActive ? 180 : 0}deg)`,
          order: 1,
        }}
      />
    );
  };

  return (
    <StyledCollapse expandIcon={CustomIcon}>
      <Collapse.Panel
        header={t('header_event-distribution', {
          defaultValue: 'Event distribution',
        })}
      >
        <Tabs defaultActiveKey={defaultActiveKey}>
          {tabs.map(({ key, title, tabContent }) => (
            <Tabs.TabPane tab={title} key={key}>
              <DistributionTabContent tabContent={tabContent} />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Collapse.Panel>
    </StyledCollapse>
  );
};

const StyledCollapse = styled(Collapse)`
  background: var(--cogs-white);
  .rc-collapse-header {
    height: 48px;
    font-weight: 500;
    border-top: 1px solid var(--cogs-greyscale-grey4);
    border-bottom: 1px solid var(--cogs-greyscale-grey4);
    padding-left: 16px;
    padding-right: 16px;
    justify-content: space-between;
  }
  .rc-collapse-content {
    padding: 0;
  }
  .rc-collapse-content-box {
    margin-top: 0;
    margin-bottom: 0;
  }
  .rc-tabs-nav-wrap {
    border-bottom: 1px solid var(--cogs-greyscale-grey4);
  }
`;

export default withI18nSuspense(EventDistribution);
