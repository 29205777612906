import styled from 'styled-components/macro';

export const CheckboxList = styled.ul`
  margin-top: 8px;
  list-style-type: none;
  padding-left: 0;

  ul {
    padding-left: 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Section = styled.div`
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
`;
export const StyledLi = styled.li`
  margin-bottom: 6px;
`;
export const Indented = styled.span`
  > li {
    padding-left: 25px;
  }
`;
