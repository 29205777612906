import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import { Icon, Menu } from '@cognite/cogs.js';

import { useMetrics } from '@cognite/metrics';
import { UnstyledA } from 'components/accessibility/UnstyledLink';
import useCustomerSupport from './hooks';

import { version } from '../../../package.json';

const SupportMenu = () => {
  const { t } = useTranslation('SupportMenu');
  const metrics = useMetrics('User');
  const { customerSupport } = useCustomerSupport();

  return (
    <Menu onClick={() => metrics.track('HelpButton_click')}>
      <Menu.Header>
        <Trans t={t} i18nKey="support-menu_support">
          Support
        </Trans>
      </Menu.Header>

      <Menu.Item onClick={() => metrics.track('Documentation_click')}>
        <ExternalA href="https://docs.cognite.com/bestday/">
          <Icon type="Documentation" />
          <Trans t={t} i18nKey="support-menu_read-documentation">
            Read our documentation
          </Trans>
        </ExternalA>
      </Menu.Item>

      <Menu.Item onClick={() => metrics.track('CogniteLearn_click')}>
        <ExternalA href="https://learn.cognite.com/">
          <Icon type="VideoCam" />
          <Trans t={t} i18nKey="support-menu_cognite-learn">
            Cognite Learn
          </Trans>
        </ExternalA>
      </Menu.Item>

      <Menu.Item onClick={() => metrics.track('Support_click')}>
        {customerSupport && (
          <ExternalA href={customerSupport}>
            <Icon type="Comment" />
            <Trans t={t} i18nKey="support-menu_customer-support">
              Customer Support
            </Trans>
          </ExternalA>
        )}
      </Menu.Item>

      <Menu.Divider />

      <Menu.Item onClick={() => metrics.track('Changelog_click')}>
        <ExternalA href="https://docs.cognite.com/bestday/changelog.html">
          <Icon type="Document" />
          <Trans t={t} i18nKey="support-menu_changelog">
            Changelog
          </Trans>
        </ExternalA>
      </Menu.Item>

      <Menu.Divider />

      <Menu.Footer>
        <SupportMenuFooter>
          v{process.env.REACT_APP_VERSION_NAME || version || '0.0.0'}
        </SupportMenuFooter>
      </Menu.Footer>
    </Menu>
  );
};

const SupportMenuFooter = styled.div`
  color: var(--cogs-greyscale-grey10);
  line-height: 150%;
  text-align: center;
`;

const StyledA = styled(UnstyledA)`
  display: flex;
  align-items: center;
  width: 100%;

  :focus {
    color: initial;
  }
`;

const ExternalA = styled(StyledA).attrs({
  isExternal: true,
  target: '_blank',
})``;

export default withI18nSuspense(SupportMenu);
