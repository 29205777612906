import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { AuthState } from './reducer';

export const useAuth = () => {
  const slice = useSelector<RootState, AuthState>((state) => {
    return state.auth;
  });

  return {
    ...slice,
  };
};
