export const HYDROCARBON = 'HYDROCARBON';
export const PRODUCT_TYPE_WATER = 'H2O';
export const PRODUCT_TYPE_WATER_INJECTION = `${PRODUCT_TYPE_WATER}_INJ`;
export const DEFAULT_UNIT = 'BOE';
export const WATER_PRODUCT_DEFINITION = {
  type: PRODUCT_TYPE_WATER,
  phase: 'liquid',
  aggregatesTo: [],
  position: 20,
};
