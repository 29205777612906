import styled from 'styled-components/macro';

export const AssetHeaderContainer = styled.div`
  display: flex;
  height: 54px;
  min-height: 54px;
  justify-content: space-between;
  padding: 0 0 0 16px;
  align-items: center;
  overflow-x: hidden;
`;
