import styled from 'styled-components/macro';

export const StyledInputContainer = styled.div`
  margin-top: 32px;

  label.title {
    font-weight: 500;
    font-size: 14px;
    color: var(--cogs-greyscale-grey9);
  }
`;

export const StyledSplitViewContainer = styled.div`
  margin-top: 24px;
  height: 100%;
`;

export const SplitView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
`;

export const AssetSystemTreeContainer = styled.div`
  border: 2px solid var(--cogs-greyscale-grey4);
  border-radius: 6px;
  padding: 20px;
  height: 515px;
`;

export const AssetSystemTree = styled.div`
  height: 90%;
  overflow-y: auto;
  flex-grow: 1;
`;
