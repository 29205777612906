/* eslint-disable no-console */
import get from 'lodash/get';
import isArray from 'lodash/isArray';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import last from 'lodash/last';
import isDate from 'lodash/isDate';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import uniq from 'lodash/uniq';
import forEach from 'lodash/forEach';
import { ValueWithUnit } from 'utils/models/wellListItems';
import { FormatWellSummarySpec } from 'components/ExportModal/types';
import { formatDateAs } from 'utils/datetime';
import { PRODUCT_TYPE_WATER } from 'utils/products';
import { exportDataClientSide } from '.';

// universalBOM will force csv file to be opened with UTF-8 encoding. Default encoding of Excel for CSV
// is ANSI and not UTF-8. So without forcing Excel some special characters will be malformed
// https://stackoverflow.com/questions/42462764/javascript-export-csv-encoding-utf-8-issue
const universalBOM = '\uFEFF';

const { timedate } = formatDateAs;
const escape = (val: string | number): string => {
  return String(val).replace(/"/g, '""');
};
type Header = {
  name: string;
  unit?: string;
};
/**
 * Removes the property path.
 * and only keeps the last part
 * metadata.product -> product
 */
const removePropertyPath = (val: string | number): string => {
  return last(String(val).split('.')) as string;
};

const isValueWithUnit = (
  object: ValueWithUnit | unknown
): object is ValueWithUnit => {
  return (
    !!object &&
    Object.keys(object as ValueWithUnit)?.includes('value') &&
    Object.keys(object as ValueWithUnit)?.includes('unit')
  );
};

const getHeaders = <T extends Record<string, unknown>[]>(
  potentialHeaders: string[],
  data: T
): Header[] => {
  const headers = [] as Header[];
  const addHeader = (header: Header) => {
    const oldHeader = headers.find((it) => it.name === header.name);
    if (!oldHeader) {
      headers.push(header);
    }
  };
  forEach(data, (item) => {
    forEach(Object.entries(item), ([key, value]) => {
      /**
       * If the value is an object,
       * we iterate over the keys and create
       * lodash propertyPaths for the headers.
       *
       * This doesnt parse recursively though,
       * so it only works one level down.
       */
      if (isValueWithUnit(value)) {
        addHeader({ name: key, unit: value?.unit });
      } else if (isPlainObject(item[key])) {
        forEach(Object.keys(item[key] as Record<string, unknown>), (subKey) =>
          addHeader({ name: `${key}.${subKey}` })
        );
      } else if (value) {
        addHeader({ name: key });
      }
    });
  });

  return potentialHeaders.reduce((acc, potentialHeader) => {
    const header = headers.find((it: Header) => it.name === potentialHeader);
    if (header) {
      return [...acc, header];
    }
    return acc;
  }, [] as Header[]);
};

const isArrayOfStrings = (val: unknown): val is string[] =>
  isArray(val) && val.every(isString);

const isArrayOfNumbers = (val: unknown): val is string[] =>
  isArray(val) && val.every(isNumber);

const toRow =
  (headers: string[]) =>
  <T>(item: T): string => {
    return headers
      .map((header) => {
        /**
         * use lodash.get to get the value
         * since some of the values might come
         * from nested objects like 'metadata.name'
         */
        const val = get(item, header);
        if (val === undefined) {
          return '';
        }
        if (isDate(val)) {
          return timedate(val);
        }
        if (isArrayOfStrings(val) || isArrayOfNumbers(val)) {
          return val.join(', ');
        }
        if (isValueWithUnit(val)) {
          return String(val.value);
        }
        if (isObject(val)) {
          return JSON.stringify(val);
        }
        return String(val);
      })
      .map((entry) => `"${escape(entry)}"`)
      .join(',');
  };

const makeCsv = <T extends Record<string, unknown>[]>(
  headers: Header[],
  rows: T,
  translate?: Function
): string => {
  const rowMaker = toRow(headers.map((it) => it.name));
  const lines = [
    headers
      .map(
        (header) =>
          `"${escape(
            `${removePropertyPath(
              translate ? translate(header.name) : header.name
            )}${header.unit ? ` (${header.unit})` : ''}`
          )}"`
      )
      .join(','),
  ];

  rows?.forEach((item) => lines.push(rowMaker(item)));

  return universalBOM + lines.join('\n');
};
type ResolveExportWellSummaryProps = {
  wellListItemsForExport: Record<string, unknown>[];
  exportWellSummarySpec: FormatWellSummarySpec;
  fileName: string;
  translate: Function;
  callback: Function;
  callError: Function;
};
export const resolveExportWellSummary = ({
  wellListItemsForExport,
  exportWellSummarySpec,
  fileName,
  translate,
  callback,
  callError,
}: ResolveExportWellSummaryProps) => {
  const { data, productionData } = exportWellSummarySpec;
  const potentialHeaders = uniq(
    Object.entries(data)
      .filter(([key, value]) => {
        if (key === 'montly') return false;
        if (!data.montly.export && value.monthly) return false;
        return value.export;
      })
      .map(([key, value]) => {
        if (data.montly.export && value.multiplier) {
          return [...Object.keys(productionData), PRODUCT_TYPE_WATER].map(
            (product) => `${product}_${value.type || key}`
          );
        }
        return key === 'dayByDay' ? 'day' : key;
      })
      .flat()
  );
  const headers = getHeaders(potentialHeaders, wellListItemsForExport);
  const csv = makeCsv(headers, wellListItemsForExport, (header: string) =>
    translate(`${header}_label`, { defaultValue: header })
  );
  return exportDataClientSide(csv, fileName, 'text/csv;encoding:utf-8')
    .catch((error: Error) => {
      if (error) {
        console.log('!!! error exportDataClientSide!!! ', error);
        callError(error);
      }
    })
    .finally(() => {
      callback();
    });
};
