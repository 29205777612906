import { ProductDefinition } from 'graphql-types';

export const findProductDefinition = (
  products: ProductDefinition[] = [],
  product: string
) => products?.find(({ type }) => type === product);

export const isProductAggregateTo = (
  products: ProductDefinition[],
  product: string,
  aggregateTo: string
) =>
  findProductDefinition(products, product)?.aggregatesTo.includes(aggregateTo);
