import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { fetchFacilities } from 'utils/models/systems';
import { RootState } from 'store';
import { reportException } from '@cognite/react-errors';
import facilitiesSlice, { FacilitiesState } from './reducer';

export const useFacilities = () => {
  const { facilities, loading, error } = useSelector<
    RootState,
    FacilitiesState
  >((state) => {
    return state.facilities;
  });
  const dispatch = useDispatch();

  const { rootAsset } = useCurrentAsset();

  const getFacilities = useCallback(() => {
    let canceled = false;
    const cancel = () => {
      canceled = true;
    };
    const rootAssetExtId = rootAsset?.externalId;
    if (!rootAssetExtId) {
      return cancel;
    }

    const fetchData = async () => {
      dispatch(
        facilitiesSlice.actions.getFacilities({
          loading: true,
        })
      );
      try {
        await fetchFacilities(rootAssetExtId).then((items) => {
          if (canceled) {
            return;
          }
          dispatch(
            facilitiesSlice.actions.getFacilitiesSuccess({
              loading: false,
              facilities: items,
            })
          );
        });
      } catch (ex) {
        reportException(ex);
        dispatch(
          facilitiesSlice.actions.getFacilitiesError({
            loading: false,
            error: ex,
          })
        );
      }
    };

    if (!canceled) {
      fetchData();
    }

    return cancel;
  }, [dispatch, rootAsset]);

  return { facilities, loading, error, getFacilities };
};
