import config from 'utils/config';
import { ReportedError, reportException } from '@cognite/react-errors';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { Metrics, useMetrics } from '@cognite/metrics';

export type Language = {
  name: string;
  nativeName: string;
  region?: string;
  isReferenceLanguage: boolean;
  translated: {
    [version: string]: number;
  };
};

type Languages = { [key: string]: Language };

type UseLanguages = () => {
  languages: Languages;
  loading: boolean;
  error?: ReportedError;
};

export const useLanguages: UseLanguages = () => {
  const [error, setError] = useState<ReportedError>();
  const [languages, setLanguages] = useState<Languages>({});
  const [loading, setLoading] = useState(true);
  const metrics = useMetrics('useLanguages');

  useEffect(() => {
    let canceled = false;
    const cancel = () => {
      canceled = true;
    };

    const timer = metrics.start('getLanguages');
    setLoading(true);
    axios
      .get<Languages>(
        `https://api.locize.app/languages/${config.locizeProjectId}`
      )
      .then(({ data }) => {
        if (canceled) {
          return;
        }
        setLanguages(data);
      })
      .catch((ex) => reportException(ex).then((reported) => setError(reported)))
      .finally(() => {
        Metrics.stop(timer);
        if (canceled) {
          return;
        }
        setLoading(false);
      });

    return cancel;
  }, [metrics]);

  return { languages, loading, error };
};
