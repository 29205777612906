import { getClient } from 'utils/cognitesdk';
import { Asset, ExternalId } from '@cognite/sdk';
import { getAssets } from 'utils/apicache';
import { getLabel, LabelsKey } from 'utils/labels';
import { ParentAssetLabel } from 'utils/models/systems';
import uniq from 'lodash/uniq';
import { getConfig, ROMANIA_ROOT_ASSETS } from 'utils/rootAssetConfig';
import { Well } from './types';

export const sortAssetsByName = (a: Asset | Well, b: Asset | Well) =>
  a.name.localeCompare(b.name, undefined, { numeric: true });

export const getRootAssets = () => {
  const configs = getConfig();
  return getClient()
    .assets.list({
      filter: {
        labels: {
          containsAny: [
            getLabel('NETWORK_LEVEL_COUNTRY'),
            getLabel('NETWORK_LEVEL_TOP_LEVEL_ASSET'),
          ].filter(Boolean) as ExternalId[],
        },
      },
    })
    .then((result) => {
      const rootAssetsConfig = configs.rootAssets.map((it) => it.externalId);
      return result.items.filter(
        (it) => !!it.externalId && rootAssetsConfig.includes(it.externalId)
      );
    });
};

export const getWellsAtCountryLevel = async (
  countryExternalId: string
): Promise<Asset[]> => {
  const getWellsAtCountryLevelRecursive = async (
    countryExternalId: string,
    nextCursor?: string
  ): Promise<Asset[]> => {
    const cursor = await getClient().assets.list({
      filter: {
        parentExternalIds: [countryExternalId],
        labels: {
          containsAll: [getLabel('NETWORK_LEVEL_WELL')],
          containsAny: [
            getLabel('OIL_WELL'),
            getLabel('GAS_WELL'),
            getLabel('WATER_INJECTION_WELL'),
          ],
        },
      },
      limit: 1000,
      cursor: nextCursor,
    });

    if (!cursor.nextCursor) {
      return cursor.items;
    }

    const nextItems = await getWellsAtCountryLevelRecursive(
      countryExternalId,
      cursor.nextCursor
    );
    return cursor.items.concat(nextItems);
  };

  const wells = await getWellsAtCountryLevelRecursive(countryExternalId);

  return wells
    .filter(
      (well) =>
        well.externalId &&
        well.labels &&
        well.labels.some(
          (label) =>
            label.externalId === getLabel('OIL_WELL').externalId ||
            label.externalId === getLabel('GAS_WELL').externalId ||
            label.externalId === getLabel('WATER_INJECTION_WELL').externalId
        )
    )
    .sort(sortAssetsByName);
};

export const getParentAssets = async (
  childExternalId: string,
  networkLevelLabel: ParentAssetLabel[],
  searchOption: 'containsAll' | 'containsAny' = 'containsAll'
) => {
  const { items: rels } = await getClient().relationships.list({
    filter: {
      sourceExternalIds: [childExternalId],
      targetTypes: ['asset'],
      sourceTypes: ['asset'],
      labels: {
        containsAll: [getLabel('BELONGS_TO')],
      },
    },
  });

  const externalIds = rels.map((rel) => ({
    externalId: rel.targetExternalId,
  }));

  return getAssets(externalIds, {
    labels: {
      [searchOption]: networkLevelLabel.map((label) => getLabel(label)),
    },
  });
};

export type NetworkingFetchType = 'parent' | 'children';

export const getNetworkingAssets = async (
  externalId: string,
  type: NetworkingFetchType,
  fluidLabelKey?: LabelsKey
) => {
  if (!fluidLabelKey) {
    return [];
  }

  const { items: rels } = await getClient().relationships.list({
    filter: {
      targetExternalIds: type === 'children' ? [externalId] : undefined,
      sourceExternalIds: type === 'parent' ? [externalId] : undefined,
      targetTypes: ['asset'],
      sourceTypes: ['asset'],
      labels: {
        containsAll: fluidLabelKey
          ? [getLabel('FLOWS_TO'), getLabel(fluidLabelKey)]
          : [getLabel('FLOWS_TO')],
      },
    },
  });

  const externalIds = uniq(
    rels.map((rel) =>
      type === 'children' ? rel.sourceExternalId : rel.targetExternalId
    )
  ).map((externalId) => ({ externalId }));

  if (externalIds.length === 0) {
    return [];
  }

  return getClient().assets.retrieve(externalIds, {
    ignoreUnknownIds: true,
  });
};

export const getFluidFlows = async (
  externalId: string,
  type: NetworkingFetchType
) => {
  const { items } = await getClient().relationships.list({
    filter: {
      targetExternalIds: type === 'children' ? [externalId] : undefined,
      sourceExternalIds: type === 'parent' ? [externalId] : undefined,
      targetTypes: ['asset'],
      sourceTypes: ['asset'],
      labels: {
        containsAll: [getLabel('FLOWS_TO')],
      },
    },
  });

  return items
    .filter((item) => item.labels)
    .map((item) =>
      item.labels?.find((label) =>
        label.externalId.startsWith('BEST_DAY_STREAM_')
      )
    )
    .filter(Boolean)
    .map((label) =>
      label?.externalId.replace('BEST_DAY_STREAM_', '')
    ) as string[];
};

export const fetchFacilities = async (parentExternalId: string) => {
  // Super temporary
  const isRomania = ROMANIA_ROOT_ASSETS.includes(parentExternalId);

  // Super temporary
  const options = isRomania
    ? [
        'Facility - Sub-Level - PSH',
        'Facility - Top-Level - PSH',
        'Facility - Sub-Class - PSH',
      ]
    : [
        '["Facility - Sub-Level - PSH"]',
        '["Facility - Top-Level - PSH"]',
        '["Facility - Sub-Class - PSH"]',
      ];

  // Super temporary
  const networkLevelField = isRomania ? 'NETWORK_LEVEL' : 'Network Level';

  const items = (
    await Promise.all(
      options.map((option) =>
        getClient()
          .assets.list({
            filter: {
              parentExternalIds: [parentExternalId],
              metadata: {
                [networkLevelField]: option,
              },
            },
            limit: 1000,
          })
          .then((result) => result.items)
      )
    )
  )
    .flat()
    .filter((asset) => asset.externalId)
    .sort(sortAssetsByName);

  return items;
};
