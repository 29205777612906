import React from 'react';
import SystemTree from 'components/SystemTree/SystemTree';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import SpinProgress from 'components/SpinProgress';
import { usePreferences } from 'features/preferences';
import DynamicCollectionTree from 'components/CollectionsTree/DynamicCollectionTree';
import { useFlag } from '@cognite/react-feature-flags';
import toggles from 'utils/toggles';
import { Tabs } from '@cognite/cogs.js';
import styled from 'styled-components/macro';
import { useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { useAssetTree } from 'features/assetTree';
import AssetSearch from './AssetSearch';
import AssetHeaderBar from './AssetHeaderBar';
import { CollectionsTreeWrapper, ScrollContainer, Wrapper } from './element';

const AssetNavigation = () => {
  const {
    preferences: {
      layout: { showAssetNavigation },
    },
  } = usePreferences();
  const { rootAsset, selectedAsset } = useCurrentAsset();
  const { t } = useTranslation('PageNavigationBar');

  const isDynamicCollectionsEnabled = useFlag(
    toggles.BESTDAY_DYNAMIC_COLLECTIONS
  );

  const { expandedKeys, setexpandedKeys } = useAssetTree();

  if (!rootAsset?.externalId) {
    return (
      <Wrapper
        style={{
          width: showAssetNavigation ? 300 : 0,
        }}
      >
        <SpinProgress />
      </Wrapper>
    );
  }

  const systemsTitle = t('systems_tab_title', { defaultValue: 'Systems' });
  const collectionsTitle = t('collections_tab_title', {
    defaultValue: 'Collections',
  });
  return (
    <Wrapper
      style={{
        width: showAssetNavigation ? 300 : 0,
      }}
    >
      <AssetHeaderBar />
      <AssetSearch />
      <ScrollContainer>
        <StyledTabs
          size="default"
          style={{
            background: 'var(--cogs-greyscale-grey1)',
          }}
        >
          <Tabs.TabPane key="systems" tab={systemsTitle}>
            <SystemTree
              rootExternalId={rootAsset.externalId}
              selectedExternalId={selectedAsset?.externalId}
              expandedKeys={expandedKeys}
              setExpandedKeys={setexpandedKeys}
            />
          </Tabs.TabPane>
          <Tabs.TabPane key="collections" tab={collectionsTitle}>
            <div>
              {isDynamicCollectionsEnabled && <DynamicCollectionTree />}
              <CollectionsTreeWrapper />
            </div>
          </Tabs.TabPane>
        </StyledTabs>
      </ScrollContainer>
    </Wrapper>
  );
};

const StyledTabs = styled(Tabs)`
  .rc-tabs-nav-list {
    flex-basis: 99%;
    .rc-tabs-tab {
      flex-basis: inherit;
      .rc-tabs-tab-btn {
        justify-content: center;
      }
    }
  }
`;

export default withI18nSuspense(AssetNavigation);
