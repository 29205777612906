import React from 'react';

export default () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 192.756 192.756"
    >
      <g fillRule="evenodd" clipRule="evenodd">
        <path fill="#fff" d="M0 0h192.756v192.756H0V0z" />
        <path
          d="M38.604 30.858a28.903 28.903 0 0 0-6.407-.719c-15.919 0-28.929 12.978-28.929 28.93s13.01 28.962 28.929 28.962c15.919 0 28.962-13.01 28.962-28.962a28.78 28.78 0 0 0-19.286-27.263L29.91 52.302h-3.792l12.486-21.444zm69.628.556L95.973 52.302h3.792l12.16-20.888h9.414v55.374H66.39V31.414h23.274l-12.16 20.888h3.792l12.226-20.888h14.71zm56.78 0l-12.355 20.888h3.791l12.26-20.888h21.215l-32.166 55.374-32.199-55.374h39.454z"
          fill="#093b6a"
        />
        <path fill="#82ba51" d="M189.449 98.455H2.834v-3.269h186.615v3.269z" />
        <path d="M31.686 117.055c9.257 0 16.786 8.478 16.786 18.878 0 10.401-7.529 18.85-16.786 18.85-9.229 0-16.758-8.448-16.758-18.85 0-10.401 7.529-18.878 16.758-18.878zm.028-7.864c14.082 0 25.57 11.963 25.57 26.714 0 14.75-11.488 26.712-25.57 26.712s-25.57-11.962-25.57-26.712c0-14.751 11.488-26.714 25.57-26.714zm126.733 39.15l-18.293-37.894h-8.811l24.705 51.166h5.02l24.426-51.166h-8.812l-18.235 37.894zm-90.4-37.95v51.279h7.696v-40.962l14.193 40.962h6.942l14.305-41.073v41.073h7.779v-51.279h-11.99l-13.468 39.066-13.551-39.066H68.047z" />
      </g>
    </svg>
  );
};
