import chunk from 'lodash/chunk';
import map from 'lodash/map';
import isObject from 'lodash/isObject';
import round from 'lodash/round';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import head from 'lodash/head';
import useConfig from 'hooks/useConfig';
import {
  BeginWellSummaryExportProps,
  MonthSummeryExportItem,
  TemplatesMapping,
} from 'hooks/types';
import { usePreferences } from 'features/preferences';
import { useUnitConversion } from 'features/unitConversion';
import { useWellListItems } from 'utils/models/wellListItems';
import { RootState } from 'store';
import {
  externalIdFilter,
  getProductConfig,
  productsFilter,
  syntheticTimeSeriesQuery,
} from 'hooks/useGraphQlQuery';
import {
  getDefermentEvents,
  getDeviationGrup,
  getExportData,
  getSegmentsEvents,
  operationSequenceService,
} from 'hooks/useOperationClient';
import { useDispatch, useSelector } from 'react-redux';
import { useTimeRange } from 'features/timeRange';
import { reduceArrToObj } from 'utils';
import { calculateGranularity } from 'hooks/utils';
import {
  listItemsForExport,
  efficiency,
  resolveExportWellSummary,
} from 'utils/export';
import { returnWithFormat } from 'utils/datetime';
import { useCallback } from 'react';
import exportServiceSlice, { ExportServiceState } from './reducer';

const createAvarage = (arr: number[]): number =>
  round(
    (compact(arr).reduce(
      (sum: number, dp: number) => sum + Math.max(dp, 0),
      0
    ) /
      arr.length) *
      1000
  ) / 1000;
const createSum = (arr: number[]): number =>
  compact(arr).reduce((sum: number, dp: number) => sum + Math.max(dp, 0), 0);
let controller = new AbortController();
export const useExportService = () => {
  const stateObject = useSelector<RootState, ExportServiceState>(
    (state) => state.exportService
  );
  const dispatch = useDispatch();
  const { wellListItems } = useWellListItems();
  const { convertValue } = useUnitConversion();
  const { rootAssetConfig } = useConfig();
  const { exportStart, exportEnd } = useTimeRange();
  const { units } = usePreferences();
  const countDownload = () => {
    dispatch(exportServiceSlice.actions.increment());
  };
  const startDownload = (total: number) => {
    dispatch(exportServiceSlice.actions.toggleTotal({ total }));
  };
  const resetDownload = () => {
    dispatch(exportServiceSlice.actions.resetCounter());
  };
  const handleError = (error: {
    message: { message?: string | undefined };
  }) => {
    dispatch(exportServiceSlice.actions.error(error.message));
  };
  const stopDownload = () => {
    dispatch(exportServiceSlice.actions.stop());
    controller.abort();
    controller = new AbortController();
  };
  const beginWellSummaryExport = async ({
    exportWellSummarySpec,
    fileName,
    callback,
    callError,
    translate,
  }: BeginWellSummaryExportProps) => {
    const {
      data: { dayByDay },
      customFilters: { positive, aggregation },
      productionData,
    } = exportWellSummarySpec;
    setTimeout(() => {
      callback(true);
    }, 300);
    const chunks = chunk(
      wellListItems.map(({ externalId }) => externalId),
      40
    );
    const customFilter = {
      activeAtTime: { min: exportStart, max: exportEnd },
    };
    startDownload(chunks?.length || 0);
    const limit = Math.ceil(
      Math.abs(exportEnd - exportStart) / (1000 * 60 * 60 * 24)
    );
    const granularity = calculateGranularity([exportStart, exportEnd], limit);
    const productsConfig = await getProductConfig({
      templateInfo: rootAssetConfig?.templates as TemplatesMapping,
    }).then((resp) =>
      map(resp, (product) => ({
        ...product,
        prefix: product.type.toLowerCase(),
      }))
    );
    const operation = async (chunk: string[]) => {
      const defermentEvents = await getDefermentEvents({
        ...customFilter,
        assetExternalIds: chunk,
      });
      const segmentEvents = await getSegmentsEvents({
        ...customFilter,
        assetExternalIds: chunk,
      });
      const deviationEvents = await getDeviationGrup(
        segmentEvents,
        productsConfig
      );
      const query = `{
        wellQuery(filter: { _or: [${externalIdFilter(chunk).join(', ')}]}) {
          items {
            asset {
              name
              externalId
            }
            mainProduct {
              datapoints(end: ${exportEnd}, limit: ${limit}) {
                ... on DatapointString {
                  timestamp
                  value: stringValue
                }
              }
            }
            products ${productsFilter([
              ...Object.keys(productionData),
              'H2O',
            ])} {
              type
              production {
                frequency
                timeSeries {
                  externalId
                  isStep
                  unit
                  datapoints(start: ${exportStart}, end: ${exportEnd}, limit: ${limit}) {
                    timestamp
                    value
                  }
                }
                ${syntheticTimeSeriesQuery(exportStart, exportEnd, limit, {
                  granularity,
                })}
              }
              capacity {
                type
                timeSeries {
                  id
                  externalId
                  isStep
                  unit
                  datapoints(start: ${exportStart}, end: ${exportEnd}, limit: ${limit}) {
                    timestamp
                    value
                  } 
                }
              }
              deviations {
                _externalId
                timeSeries {
                  externalId
                  unit
                  datapoints(start: ${exportStart}, end: ${exportEnd}, limit: ${limit}) {
                    timestamp
                    value
                  }
                }
              }
            }
            
          }
        }
      }`;
      const result = await getExportData({
        query,
        templateInfo: rootAssetConfig?.templates as TemplatesMapping,
        defermentEvents,
        deviationEvents,
      });
      countDownload();
      return listItemsForExport(
        result,
        convertValue,
        units,
        returnWithFormat(exportStart, 'YYYY/MM'),
        positive
      ) as unknown as MonthSummeryExportItem[][];
    };
    operationSequenceService({
      chunks,
      fetchOperation: operation,
      callback: ({
        responses,
        message,
        isEnded,
      }: {
        responses?: MonthSummeryExportItem[][][];
        message?: string;
        isEnded?: boolean;
      }) => {
        if (isEnded) resetDownload();
        if (message) {
          handleError({ message: { message } });
        }
        if (responses) {
          resolveExportWellSummary({
            wellListItemsForExport: dayByDay?.export
              ? responses?.flat(3)
              : map(responses, (response) =>
                  map(response, (dailyWells) => {
                    return reduceArrToObj(
                      map(
                        uniq(
                          map(dailyWells, (well) => Object.keys(well)).flat()
                        ),
                        (key) => {
                          const mappedKey = map(dailyWells, key);
                          const value = head(compact(mappedKey));
                          if (key.split('_').includes('productionEfficiency')) {
                            const type = key.replace(
                              '_productionEfficiency',
                              ''
                            );
                            const typeAvarage = (t: string) => {
                              const values = map(
                                dailyWells,
                                `${type}_${t}.value`
                              ) as number[];
                              return aggregation
                                ? createSum(values)
                                : createAvarage(values);
                            };
                            return {
                              [`${type}_productionEfficiency`]: {
                                value: round(
                                  efficiency(
                                    typeAvarage('production'),
                                    typeAvarage('BESTDAY_CAPACITY')
                                  ),
                                  2
                                ),
                                unit: aggregation ? '%' : `%/d`,
                              },
                            };
                          }
                          return {
                            [key]: isObject(value)
                              ? {
                                  value: aggregation
                                    ? createSum(
                                        map(mappedKey, 'value') as number[]
                                      )
                                    : createAvarage(
                                        map(mappedKey, 'value') as number[]
                                      ),
                                  unit: aggregation
                                    ? value?.unit
                                    : `${value?.unit}/d`,
                                }
                              : value,
                          };
                        }
                      )
                    );
                  })
                ).flat(),
            exportWellSummarySpec,
            fileName,
            translate,
            callback,
            callError,
          });
        }
        callback();
      },
      signal: controller.signal,
    });
  };
  const disableExport = useCallback(
    (disabled: boolean) => {
      dispatch(exportServiceSlice.actions.disableExport({ disabled }));
    },
    [dispatch]
  );
  return {
    beginWellSummaryExport,
    stopDownload,
    disableExport,
    ...stateObject,
  };
};
