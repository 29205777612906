import styled, { css } from 'styled-components/macro';

type DividerStyle = {
  height?: number;
  margin?: number;
  color?: string;
};

export const Divider = styled.div<DividerStyle>`
  border-right: 1px solid var(--cogs-greyscale-grey3);
  height: 100%;

  ${({ height }) =>
    height &&
    css`
      height: ${height}px;
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin-left: ${margin}px;
      margin-right: ${margin}px;
    `}

    ${({ color }) =>
    color &&
    css`
      border-right: 1px solid ${color};
    `}
`;
