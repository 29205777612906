/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Datasets } from './types';

export type DatasetsState = {
  datasets: Datasets;
};

const initialState: DatasetsState = {
  datasets: {},
};

const datasetsSlice = createSlice({
  name: 'datasets',
  initialState,
  reducers: {
    getDatasetsSuccess: (
      state,
      action: PayloadAction<{ datasets: Datasets }>
    ) => {
      const { datasets } = action.payload;
      state.datasets = datasets;
    },
  },
});

export default datasetsSlice;
