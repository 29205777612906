import { PRODUCT_TYPE_WATER_INJECTION } from 'utils/products';

// TODO(BEST-460): Make this configurable
export const mapWellTypeToWellTypeTitle = (type: string) => {
  // TODO(BEST-1519): Remove hardcoding
  switch (type) {
    case 'OIL':
      return 'Producer Oil';
    case 'GAS':
      return 'Producer Gas';
    case 'NONASSOC_GAS':
      return 'Producer Natural Gas';
    case 'ASSOC_GAS':
      return 'Producer Associated Gas';
    case 'WATER_INJECTION':
    case PRODUCT_TYPE_WATER_INJECTION:
      return 'Injector';
    default:
      return 'Unknown type';
  }
};
