import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import ErrorAlert from 'components/ErrorAlert';
import Overlay from 'components/Overlay';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import React, { FunctionComponent } from 'react';
import Layers from 'utils/zindex';

interface AssetLoadingErrorGuardProps {}

const AssetLoadingErrorGuard: FunctionComponent<
  AssetLoadingErrorGuardProps
> = ({ children }) => {
  const { t } = useTranslation('AssetLoadingErrorGuard');

  const { error } = useCurrentAsset();

  return error ? (
    <Overlay zIndex={Layers.FATAL_ERROR_OVERLAY}>
      <ErrorAlert
        type="error"
        message={
          <Trans t={t} i18nKey="message_asset-loading-error">
            Failed to load assets
          </Trans>
        }
        description={
          <>
            <Trans t={t} i18nKey="description_asset-loading-error">
              We were unable to get assets for your account, this may be due to
              a lack of access to the BestDay application. Please contact your
              IT support team to be granted access to BestDay.
            </Trans>
          </>
        }
      />
    </Overlay>
  ) : (
    <>{children}</>
  );
};

export default withI18nSuspense(AssetLoadingErrorGuard);
