import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import unitConversionSlice, {
  fetchUnitConversionsForAsset,
  useUnitConversion,
} from 'features/unitConversion';
import { ErrorId, ReportedError, reportException } from '@cognite/react-errors';
import Overlay from 'components/Overlay';
import FeedbackLink from 'components/FeedbackLink';
import Layers from 'utils/zindex';
import { useAuth } from 'features/auth';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import SpinProgress from 'components/SpinProgress';
import { useQuery } from 'react-query';
import ErrorAlert from 'components/ErrorAlert';
import { useCurrentAsset } from './hooks';

export type Props = {
  children: React.ReactNode;
};

const WithCurrentAssetUnitConversion = ({ children }: Props) => {
  const { t } = useTranslation('WithCurrentAssetUnitConversion');

  const [error, setError] = useState<ReportedError>();
  const dispatch = useDispatch();

  const {
    selectedAsset,
    rootAsset,
    loading: loadingCurrentAsset,
  } = useCurrentAsset();

  const { productConversions } = useUnitConversion();

  const { project } = useAuth();

  const { isLoading: loadingRootAssetConversion } = useQuery(
    ['unit-conversion', rootAsset?.externalId],
    () => {
      return rootAsset ? fetchUnitConversionsForAsset(rootAsset) : undefined;
    },
    {
      onSuccess: (rootAssetProductConversion) => {
        if (rootAssetProductConversion && rootAsset?.externalId) {
          dispatch(
            unitConversionSlice.actions.setAssetSpecificConversion({
              [rootAsset.externalId]: rootAssetProductConversion,
            })
          );
        }
      },
      onError: (ex) => {
        reportException(ex as string).then((reported) => {
          setError(reported);
        });
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  const { isLoading: loadingAssetConversion } = useQuery(
    ['unit-conversion', selectedAsset?.externalId],
    () => {
      return selectedAsset
        ? fetchUnitConversionsForAsset(selectedAsset)
        : undefined;
    },
    {
      onSuccess: (assetSpecificConversion) => {
        if (assetSpecificConversion && selectedAsset?.externalId) {
          dispatch(
            unitConversionSlice.actions.setAssetSpecificConversion({
              [selectedAsset.externalId]: assetSpecificConversion,
            })
          );
        }
      },
      staleTime: Infinity,
      cacheTime: Infinity,
    }
  );

  if (
    loadingCurrentAsset ||
    loadingRootAssetConversion ||
    loadingAssetConversion ||
    !productConversions
  ) {
    return <SpinProgress />;
  }

  if (error) {
    const { errorId } = error;
    return (
      <>
        <Overlay zIndex={Layers.FATAL_ERROR_OVERLAY}>
          <ErrorAlert
            key="noUnitConversions"
            type="error"
            message={
              <Trans t={t} i18nKey="noUnitConversions_message">
                No unit conversions specified
              </Trans>
            }
            description={
              <>
                <Trans t={t} i18nKey="noUnitConversions_description">
                  There were no unit conversions specified for the{' '}
                  <strong>{{ project }}</strong> project. Please contact{' '}
                  <FeedbackLink
                    id="PermissionCheck_noUnitConversions"
                    subject={t('noUnitConversions_subject', {
                      defaultValue: 'Unable to use application',
                    })}
                  >
                    support@cognite.com
                  </FeedbackLink>{' '}
                  to have the appropriate conversions defined.
                </Trans>
                <ErrorId id={errorId} />
              </>
            }
          />
        </Overlay>
      </>
    );
  }

  return <>{children}</>;
};

export default withI18nSuspense(WithCurrentAssetUnitConversion);
