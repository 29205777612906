import React from 'react';
import ReactPlaceholder from 'react-placeholder';
import { Divider } from 'antd';
import { Detail, Tooltip } from '@cognite/cogs.js';
import styled from 'styled-components/macro';

type Props = {
  loading: boolean;
  icon: React.ReactNode;
  count?: number;
  tooltip?: string;
};

const DeviationCardIndicator = ({ loading, count, icon, tooltip }: Props) => {
  return (
    <>
      <ReactPlaceholder
        ready={!loading}
        rows={1}
        showLoadingAnimation
        type="rect"
        style={{ width: 30 }}
      >
        {tooltip ? (
          <Tooltip content={tooltip} placement="bottom">
            <Indicator aria-label="indicator">
              {icon}
              {count && <StyledDetail>{count}</StyledDetail>}
            </Indicator>
          </Tooltip>
        ) : (
          <Indicator aria-label="indicator">
            {icon}
            {count && <StyledDetail>{count}</StyledDetail>}
          </Indicator>
        )}
      </ReactPlaceholder>
      <StyledDivider type="vertical" />
    </>
  );
};

const Indicator = styled.div`
  display: inline-grid;
  grid-auto-columns: auto;
  grid-auto-flow: column;
  grid-gap: 8px;
  align-items: center;
`;

const StyledDivider = styled(Divider)`
  height: 16px;
  margin: 0 16px;
  &:last-child {
    display: none;
  }
`;

const StyledDetail = styled(Detail)`
  align-self: center;
  justify-self: end;
`;

export default DeviationCardIndicator;
