import { Aggregate } from '@cognite/sdk';
import chunk from 'lodash/chunk';
import { getClient } from './client';

export type GetDatapointsOptions = {
  start?: Date | number;
  end?: Date | number;
  granularity?: string;
  limit?: number;
  aggregates?: Aggregate[];
};

type GetDatapointsItem = {
  externalId: string;
  options?: GetDatapointsOptions;
};

type Props = {
  items: GetDatapointsItem[];
  options?: GetDatapointsOptions;
};

export const getDatapoints = async ({ items, options = {} }: Props) => {
  if (items.length === 0) {
    return Promise.resolve([]);
  }

  // Split the items into batchs of 100 as the limit for the number of items per request is 100
  const itemBatches = chunk(items, 100);

  const results = (
    await Promise.all(
      itemBatches.map((batch) =>
        getClient().datapoints.retrieve({
          items: batch.map(({ externalId, options: itemOptions }) => ({
            externalId,
            ...itemOptions,
          })),
          ...options,
          ignoreUnknownIds: true,
        })
      )
    )
  ).flat();

  return results.filter((result) => result?.datapoints.length);
};
