import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import some from 'lodash/some';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import reduce from 'lodash/reduce';
import compact from 'lodash/compact';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { RootState } from 'store';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useDeviationGroups } from 'features/deviationGroups';
import { useGetComments } from 'features/comments/hooks';
import { useObservations } from 'features/observations';
import { useAssetTree } from 'features/assetTree';
import commentsFilterSlice, {
  CommentsFilter,
  CommentsFilterState,
} from './reducer';

export const useCommentsFilter = () => {
  const dispatch = useDispatch();
  const { allDeviations } = useDeviationGroups();
  const { selectedAsset } = useCurrentAsset();
  const { restPath } = useAssetTree();
  const { observations } = useObservations(selectedAsset?.externalId);
  const { displayComments, extraFilters } = useSelector<
    RootState,
    CommentsFilterState
  >((state) => state.commentsFilter);
  const toggleDisplayComments = useCallback(
    (displayComments: CommentsFilter) => {
      dispatch(
        commentsFilterSlice.actions.toggleDisplayComments({ displayComments })
      );
    },
    [dispatch]
  );
  const toggleExtraFilters = useCallback(
    (extraFilters: CommentsFilter) => {
      dispatch(
        commentsFilterSlice.actions.toggleExtraFilters({ extraFilters })
      );
    },
    [dispatch]
  );
  const { getActiveComments: getActiveObservationComments } = useGetComments({
    externalIds: observations.map((it) => it.externalId),
    resourceType: 'event',
  });
  const { getActiveComments } = useGetComments({
    externalIds: allDeviations.map((it) => it.externalId),
    resourceType: 'event',
  });
  const length = useMemo(() => 4 - restPath.length, [restPath.length]);
  const display = useMemo(
    () =>
      reduce(
        uniq(
          compact(
            map(
              allDeviations,
              ({ externalId, status }) =>
                !!getActiveComments(externalId).length && status
            )
          )
        ),
        (t, type) => ({
          ...t,
          [type as keyof CommentsFilter]: {
            visible: type !== 'ignored',
            lineId: type,
          },
        }),
        {}
      ),
    [allDeviations, getActiveComments]
  );
  const observationComments = useMemo(
    () =>
      some(
        map(
          observations,
          ({ externalId }) => !!getActiveObservationComments(externalId).length
        )
      )
        ? {
            ['observations' as keyof CommentsFilter]: {
              visible: true,
            },
          }
        : {},
    [getActiveObservationComments, observations]
  );
  const {
    defaultVisible,
    commentsVisibility,
    commentsVisible,
    observationsCommentsVisible,
    commentsFilterVisible,
  } = useMemo(() => {
    const defaultVisible = {
      ...observationComments,
      ...display,
    };
    if (!isEqual(Object.keys(defaultVisible), Object.keys(displayComments))) {
      toggleDisplayComments(defaultVisible);
    }
    const commentsVisible = some(
      omit(displayComments, ['observations']),
      'visible'
    );
    const observationsCommentsVisible = get(
      displayComments,
      'observations.visible'
    ) as unknown as boolean;
    return {
      defaultVisible,
      commentsVisible,
      observationsCommentsVisible,
      commentsVisibility: commentsVisible || observationsCommentsVisible,
      commentsFilterVisible: !isEmpty(displayComments),
    };
  }, [display, displayComments, observationComments, toggleDisplayComments]);
  const isLoading = useMemo(() => true, []);
  return {
    defaultVisible,
    commentsVisibility,
    toggleDisplayComments,
    displayComments,
    extraFilters,
    toggleExtraFilters,
    commentsVisible,
    observationsCommentsVisible,
    commentsFilterVisible,
    length,
    isLoading,
  };
};
/* TBD SET MAX COUNTER FILTER
  const setMaxCount = useCallback(
    (count) => {
      toggleExtraFilters({
        ...extraFilters,
        withChildren: {
          ...extraFilters.withChildren,
          count,
        },
      });
    },
    [extraFilters, toggleExtraFilters]
  );
  useEffect(() => {
    if (
      selectedAsset?.externalId &&
      rootAsset?.externalId &&
      rootAsset?.externalId !== selectedAsset?.externalId &&
      length > extraFilters.withChildren.count!
    ) {
      setMaxCount(length);
      console.log('herer');
    }
  }, [
    extraFilters.withChildren.count,
    length,
    rootAsset?.externalId,
    selectedAsset?.externalId,
    setMaxCount,
  ]); */
