import React from 'react';
import { Dropdown, Icon, Menu } from '@cognite/cogs.js';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { usePreferences } from 'features/preferences';
import RootAssetList from 'components/RootAssetList/RootAssetList';
import { useRootAssetHierarchy } from 'components/RootAssetList/useRootAssetHierarchy';
import { useAssetTree } from 'features/assetTree';
import { StyledAssetSelectorWrapper } from './elements';

const AssetSelector = () => {
  const { rootAsset, setRootAsset, setSelectedAsset } = useCurrentAsset();
  const { setPreferences } = usePreferences();
  const rootAssets = useRootAssetHierarchy();
  const { setexpandedKeys } = useAssetTree();

  if (rootAssets.length === 1 && isEmpty(rootAssets[0].children)) {
    return <span>{rootAsset?.name}</span>;
  }
  return (
    <Dropdown
      key={rootAsset?.externalId}
      trigger="click"
      content={
        <Menu>
          <RootAssetList
            onSelect={(asset) => {
              if (!get(asset, 'isLeaf')) {
                setRootAsset(asset);
              }
              setSelectedAsset(asset);
              setexpandedKeys([asset.id]);
              setPreferences({ lastSelectedRootAsset: asset.externalId });
            }}
          />
        </Menu>
      }
    >
      <StyledAssetSelectorWrapper>
        {rootAsset?.name}
        <Icon type="ChevronDown" />
      </StyledAssetSelectorWrapper>
    </Dropdown>
  );
};

export default AssetSelector;
