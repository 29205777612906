import React from 'react';
import { withI18nSuspense } from '@cognite/react-i18n';
import styled from 'styled-components/macro';
import { Spin } from 'antd';

export type Props = {
  size?: 'small' | 'large';
  message?: React.ReactNode | string;
  className?: string;
  style?: React.CSSProperties;
};

const SpinProgress = ({ size = 'large', message, className, style }: Props) => {
  return (
    <Container className={className} style={style}>
      <Spin size={size} />
      {message}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export default withI18nSuspense(SpinProgress);
