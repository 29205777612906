import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Deviation } from 'utils/models';
import { DeviationGroup } from 'utils/models/deviationGroupes';

const hoveredDeviationSlice = createSlice({
  name: 'hoveredDeviation',
  initialState: {} as { hoveredDeviation?: Deviation | DeviationGroup },
  reducers: {
    setHoveredDeviation: (
      state,
      action: PayloadAction<{ hoveredDeviation?: Deviation | DeviationGroup }>
    ) => {
      const { hoveredDeviation } = action.payload;
      return {
        ...state,
        hoveredDeviation,
      };
    },
  },
});

export type HoveredDeviationState = ReturnType<
  typeof hoveredDeviationSlice.reducer
>;

export default hoveredDeviationSlice;
