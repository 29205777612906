import { RatePreferences } from 'features/preferences';
import { ProductionFrequency } from 'hooks/types';

export const RATES: RatePreferences[] = ['Daily', 'Hourly'];

export const RATES_UNITS: Record<RatePreferences, string> = {
  Daily: 'd',
  Hourly: 'h',
};

export const getRateUnit = (rate: RatePreferences) => {
  if (!rate) return RATES_UNITS.Daily;
  return RATES_UNITS[rate];
};

export const removeRateFromUnit = (unit: string) => {
  return unit
    .replace(`/${RATES_UNITS.Daily}`, '')
    .replace(`/${RATES_UNITS.Hourly}`, '')
    .replace(`/HR`, '')
    .replace(`/H`, '');
};

export const getRateFromFrequency = (frequency?: ProductionFrequency) => {
  if (frequency === 'hourly' || frequency === 'realtime') {
    return 'Hourly';
  }
  return 'Daily';
};

export const getRateCoffecient = (
  fromRate: RatePreferences = 'Daily',
  toRate: RatePreferences = 'Daily',
  skipRateConversion?: boolean
) => {
  if (skipRateConversion) {
    return 1;
  }
  if (fromRate === 'Daily' && toRate === 'Hourly') {
    return 1 / 24;
  }
  if (fromRate === 'Hourly' && toRate === 'Daily') {
    return 24;
  }

  return 1;
};
