/**
 * Based on https://stackoverflow.com/questions/38241480/detect-macos-ios-windows-android-and-linux-os-with-js
 */

type OS = 'MacOS' | 'iOS' | 'Windows' | 'Android' | 'Linux' | '';

export default (): OS => {
  const { userAgent } = window.navigator;
  const platform = window.navigator?.platform?.toLowerCase() || '';
  const macosPlatforms = [
    'macintosh',
    'macintel',
    'macppc',
    'mac68k',
    'darwin',
  ];
  const windowsPlatforms = ['win32', 'win64', 'windows', 'wince'];
  const iosPlatforms = ['iphone', 'ipad', 'ipod'];

  if (macosPlatforms.includes(platform)) {
    return 'MacOS';
  }

  if (iosPlatforms.includes(platform)) {
    return 'iOS';
  }

  if (windowsPlatforms.includes(platform)) {
    return 'Windows';
  }

  if (/Android/.test(userAgent)) {
    return 'Android';
  }

  if (/Linux/.test(platform)) {
    return 'Linux';
  }

  return '';
};
