import React, { useMemo, useState, useCallback } from 'react';
import * as Sentry from '@sentry/browser';
import { useTranslation, withI18nSuspense, Trans } from '@cognite/react-i18n';
import { Body, Button, TextInput, toast } from '@cognite/cogs.js';
import { Collection, Favorite } from 'utils/models/collections';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useCollections } from 'features/collections';
import createToastOptions from 'utils/toasts/createToastOptions';
import AssetSelector from 'components/AssetSelector/AssetSelector';
import { StyledCollectionsModal, StyledInputContainer } from './elements';
import { MAX_FAVORITE_WELLS } from './constants';

type CollectionsModalProps = {
  collection?: Collection;
  onClose: Function;
};

const hasNoListChanges = (favorites: Favorite[], favoriteList: Favorite[]) => {
  return (
    favoriteList.every(
      (originalFavorite: Favorite) =>
        !!favorites.find(
          (favorite: Favorite) =>
            favorite.externalId === originalFavorite.externalId
        )
    ) &&
    favorites.every(
      (originalFavorite: Favorite) =>
        !!favoriteList.find(
          (favorite: Favorite) =>
            favorite.externalId === originalFavorite.externalId
        )
    )
  );
};

const CollectionsModal = ({ collection, onClose }: CollectionsModalProps) => {
  const { rootAsset } = useCurrentAsset();
  const { addCollection, editCollection } = useCollections();
  const [isSaving, setSaving] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [title, setTitle] = useState(collection?.name || '');
  const isNewCollection = collection === undefined;
  const favoriteList: Favorite[] = useMemo(
    () => (isNewCollection ? [] : [...(collection?.favorites ?? [])]),
    [isNewCollection, collection]
  );

  const [favorites, setFavorites] = useState(favoriteList);
  const hasFavoriteListChanges =
    hasNoListChanges(favorites, favoriteList) === false;

  const hasTitleChanges = title !== (collection?.name || '');
  const hasChanges = hasTitleChanges || hasFavoriteListChanges;
  const isValid = title.trim() !== '';

  const { t } = useTranslation('CollectionsModal');
  const modalTitle = isNewCollection
    ? t('modal_title_new', { defaultValue: 'Create new collection' })
    : t('modal_title_edit', { defaultValue: 'Edit collection' });

  const inputTitle = t('modal_inputTitle', {
    defaultValue: 'Name of the collection',
  });

  const invalidTitleText = t('modal_titleError', { defaultValue: 'Required' });

  const cancel = t('cancel_button', { defaultValue: 'Cancel' });
  const save = isNewCollection
    ? t('save_new_button', { defaultValue: 'Create new collection' })
    : t('save_button', { defaultValue: 'Save' });

  const wellsHelperText = t('helper_text', {
    defaultValue: 'Select the wells you want to have in your collection: ',
  });

  const error = isValid ? false : invalidTitleText;
  const maxWellError = favorites.length > MAX_FAVORITE_WELLS;

  const onTitleBlur = () => {
    setIsDirty(true);
  };

  const onCancel = () => {
    onClose();
  };

  const onSave = useCallback(async () => {
    if (!rootAsset?.externalId) {
      return;
    }

    setSaving(true);
    if (!collection) {
      addCollection(title, favorites)
        .then(() => {
          setSaving(false);
          onClose(true, title);
        })
        .catch((error) => {
          setSaving(false);
          toast.error(
            <Body level={2}>
              <Trans t={t} i18nKey="toast-error-create">
                Unable to create collection
              </Trans>
              <p>{error.message}</p>
            </Body>,
            createToastOptions()
          );
          Sentry.captureException(error);
        });
    } else {
      editCollection({
        key: collection.key,
        name: title,
        favorites,
        rootAssetExternalId: rootAsset.externalId,
      })
        .then(() => {
          setSaving(false);
          onClose(true, title);
        })
        .catch((error) => {
          setSaving(false);
          toast.error(
            <Body level={2}>
              <Trans t={t} i18nKey="toast-error-edit">
                Unable to edit collection
              </Trans>
              <p>{error.message}</p>
            </Body>,
            createToastOptions()
          );
          Sentry.captureException(error);
        });
    }
  }, [
    addCollection,
    collection,
    editCollection,
    favorites,
    onClose,
    rootAsset,
    t,
    title,
  ]);

  return (
    <StyledCollectionsModal
      width={600}
      title={modalTitle}
      appElement={document.getElementById('root')!}
      onCancel={onCancel}
      visible
      footer={[
        <Button key="cancel" onClick={onCancel} type="secondary">
          {cancel}
        </Button>,
        <Button
          key="save"
          onClick={onSave}
          disabled={!hasChanges || isSaving || !isValid || maxWellError}
          loading={isSaving}
          type="primary"
        >
          {save}
        </Button>,
      ]}
    >
      <>
        <StyledInputContainer>
          <TextInput
            title={inputTitle}
            variant="default"
            value={title}
            onBlur={onTitleBlur}
            onChange={({ target: { value } }) => setTitle(value)}
            error={isDirty ? error : undefined}
            isValid={isValid}
            fullWidth
          />
        </StyledInputContainer>

        <AssetSelector
          selected={favorites}
          setSelected={setFavorites}
          translation={t}
          helperText={wellsHelperText}
          assetType="well"
        />
      </>
    </StyledCollectionsModal>
  );
};

export default withI18nSuspense(CollectionsModal);
