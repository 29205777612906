import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type InitialState = {
  selectedTimerange?: [number, number | undefined];
  unorderedSelectedTimerange?: [number, number | undefined];
  hoveredTimestamp?: number;
};

const chartSlice = createSlice({
  name: 'chart',
  initialState: {} as InitialState,
  reducers: {
    setSelectedTimerange: (
      state,
      action: PayloadAction<{
        selectedTimerange?: [number, number | undefined];
        unorderedSelectedTimerange?: [number, number | undefined];
      }>
    ) => {
      const { selectedTimerange, unorderedSelectedTimerange } = action.payload;
      return {
        ...state,
        selectedTimerange,
        unorderedSelectedTimerange,
      };
    },
    setHoveredTimestamp: (
      state,
      action: PayloadAction<{ hoveredTimestamp?: number }>
    ) => {
      const { hoveredTimestamp } = action.payload;
      return {
        ...state,
        hoveredTimestamp,
      };
    },
  },
});

export type ChartState = ReturnType<typeof chartSlice.reducer>;

export default chartSlice;
