import styled from 'styled-components/macro';
import { Body, Modal, Title } from '@cognite/cogs.js';
import SelectedWells from './SelectedWells';

export const StyledCollectionsModal = styled(Modal)`
  .cogs-modal-header {
    font-size: var(--cogs-t4-font-size);
    font-weight: 600;
  }

  .cogs-modal-footer {
    display: flex;
    justify-content: flex-end;

    > button:not(:last-child) {
      margin-right: 8px;
    }
  }
`;

export const StyledInputContainer = styled.div`
  margin-top: 32px;

  label.title {
    font-weight: 500;
    font-size: 14px;
    color: var(--cogs-text-icon--strong);
  }
`;

export const StyledSelectedWells = styled(SelectedWells)`
  width: 50%;
  height: 100%;
  display: inline-grid;
`;

export const SelectedWellsList = styled.div`
  height: 100%;
  overflow-y: scroll;
  min-width: 200px;
`;

export const SelectedWellsContainer = styled.div`
  align-content: start;
  display: inline-grid;
  padding: 16px 0 0 8px;
  margin-left: 12px;
  overflow-y: clip;
`;

export const StyledChooseText = styled.div`
  margin: 12px 0;
`;

export const ErrorCard = styled.div`
  border-radius: 8px;
  background-color: var(--cogs-bg-status-large--danger);
  margin: 10px 10px 10px 2px;
  padding: 12px;
`;

export const ErrorTitle = styled(Title)`
  color: var(--cogs-text-danger);
  margin-bottom: 5px;
`;

export const StyledBody = styled(Body)<{ error: boolean }>`
  ${({ error }) => (error ? 'color: var(--cogs-text-danger)' : '')};
`;
