import { HttpRequestOptions } from '@cognite/sdk';
import { getClient } from 'utils/cognitesdk';
import { SIDECAR } from 'utils/sidecar';
import chunk from 'lodash/chunk';
import { Parents } from '.';
import { Comment, Parent, NewComment } from './types';

const { bestdayApiBaseUrl } = SIDECAR;
export const COMMENTS_ENDPOINT = new URL('comments', bestdayApiBaseUrl).href;

const normalizeCommentDates = (comment: Comment) => {
  return {
    ...comment,
    createdDate: new Date(comment.createdDate),
    editedDate: comment.editedDate ? new Date(comment.editedDate) : undefined,
  };
};

type ListParams = {
  parent: Parents;
  project: string;
};

export const list = async ({
  parent,
  project,
}: ListParams): Promise<Comment[]> => {
  const eid = chunk(parent.externalIds, 1000);
  return Promise.all(
    eid.map((externalIdsSet) =>
      getClient()
        .post<Comment[]>(`${COMMENTS_ENDPOINT}/getComments`, {
          withCredentials: true,
          headers: {
            'x-cdp-project': project,
          },
          data: {
            parent: {
              externalIds: externalIdsSet,
              resourceType: parent.resourceType,
            },
          },
        })
        .then((response) => response.data.map(normalizeCommentDates))
    )
  ).then((e) => e.flat());
};

export type NotificationDetails = {
  mentionedUserIds: string[];
  assetExternalId: string;
  assetName: string;
};

type CreateParams = {
  parent: Parent;
  comment: NewComment;
  project: string;
  datasetExternalId: string;
  notificationDetails: NotificationDetails;
};

export const create = async ({
  parent,
  comment: { content, richContent, dateRange },
  project,
  datasetExternalId,
  notificationDetails,
}: CreateParams): Promise<Comment> => {
  // FIX
  return getClient()
    .post<Comment>(COMMENTS_ENDPOINT, {
      withCredentials: true,
      data: {
        parent: {
          externalId: parent.externalId,
          resourceType: parent.resourceType,
        },
        commentContent: content,
        richCommentContent: richContent,
        datasetExternalId,
        notificationDetails,
        dateRange,
      },
      headers: {
        'x-cdp-project': project,
      },
    })
    .then((response) => normalizeCommentDates(response.data));
};

type EditParams = {
  comment: Comment;
  parent: Parent;
  action: 'edited' | 'deleted';
  project: string;
  notificationDetails: NotificationDetails;
};

export const edit = async ({
  comment,
  parent,
  action,
  project,
  notificationDetails,
}: EditParams): Promise<Comment> => {
  const url = `${COMMENTS_ENDPOINT}/${comment.externalId}`;

  const requestOptions: HttpRequestOptions = {
    withCredentials: true,
    data: {
      content: action === 'edited' ? comment.content : undefined,
      richContent: action === 'edited' ? comment.richContent : undefined,
      notificationDetails:
        action === 'edited' ? notificationDetails : undefined,
      parent,
    },
    headers: {
      'x-cdp-project': project,
    },
  };

  const request = () => {
    if (action === 'edited') {
      return getClient().put<Comment>(url, requestOptions);
    }
    return getClient().delete<Comment>(url, requestOptions);
  };

  return request().then((response) => normalizeCommentDates(response.data));
};
