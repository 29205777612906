import { getTimeSeries } from 'utils/apicache';
import { getClient, getDatapoints } from 'utils/cognitesdk';
import {
  DoubleDatapoint,
  DatapointAggregate,
  StringDatapoint,
  SyntheticQuery,
  SyntheticDataValue,
  DatapointInfo,
  Asset,
} from '@cognite/sdk';
import { getEndOfDay, getStartOfDay } from 'utils/datetime';
import isNumber from 'lodash/isNumber';
import keyBy from 'lodash/keyBy';
import uniqBy from 'lodash/uniqBy';
import { UnitPreferences } from 'features/preferences';
import last from 'lodash/last';
import chunk from 'lodash/chunk';
import retry from 'async-retry';
import isError from 'lodash/isError';
import {
  ConversionFormulaMaps,
  ProductConversions,
  FormulaLookup,
  UnitConverterType,
  ItemRequestGroups,
  UnitConversionItemRequest,
  ConversionQueryResponse,
  UNIT_FREQUENCY_SUFFIXES,
  Datapoint,
} from './types';
import { ConvertValueOptions, ConvertValueReturn } from '.';

export const UNIT_CONVERSION_DATASET_ID = 'UnitConversionFiles';

type UnionDatapoint = DoubleDatapoint | DatapointAggregate | StringDatapoint;

export const isDoubleDatapoint = (
  dp: UnionDatapoint
): dp is DoubleDatapoint => {
  return isNumber((dp as DoubleDatapoint).value);
};

export const isDoubleDatapoints = (
  datapoints: DoubleDatapoint[] | DatapointAggregate[]
): datapoints is DoubleDatapoint[] => {
  if (datapoints.length === 0) {
    return false;
  }
  return isDoubleDatapoint(datapoints[0]);
};

export const getDatapointValue = (datapoint: Datapoint) => {
  if (isDoubleDatapoint(datapoint)) {
    return datapoint.value;
  }
  return datapoint.average || 0;
};

// It only supports one aggregation per item
export const createUnitConverterClientSide = (
  units: UnitPreferences,
  productConversions: ProductConversions,
  convertValue: (options: ConvertValueOptions) => ConvertValueReturn,
  getPreferredUnitByProduct: (product: string, units: UnitPreferences) => string
) => {
  return async ({
    items,
  }: UnitConverterType): Promise<ConversionQueryResponse[]> => {
    if (items.length === 0) {
      return [];
    }

    const adjustedItems = items.map((item) => {
      if (!item.needTimeAdjustment) {
        return item;
      }

      const options = item.options || {};

      const adjustedOptions = {
        ...options,
        start: options.start ? +getStartOfDay(options.start) : undefined,
        end: options.end ? +getEndOfDay(options.end) : undefined,
      };

      return {
        ...item,
        options: adjustedOptions,
      };
    });

    const timeSeriesMap = keyBy(
      (await getTimeSeries(items.map(({ externalId }) => externalId))).filter(
        Boolean
      ),
      'externalId'
    );

    const adjustedItemsMap = keyBy(adjustedItems, 'externalId');

    const chunks = chunk(adjustedItems, 10);

    const results = (
      await Promise.all(
        chunks.map((chunk) =>
          getDatapoints({
            items: chunk.map(({ externalId, options }) => ({
              externalId,
              options,
            })),
          })
        )
      )
    ).flat();

    const datapoints = results.map<ConversionQueryResponse>(
      ({ externalId = '', unit: rawUnit = '', datapoints, id }) => {
        const unit = rawUnit.toUpperCase();
        const timeSerie = timeSeriesMap[externalId];
        if (!timeSerie) {
          throw Error(`Time series not found for ${externalId}`);
        }
        const product = timeSerie.metadata
          ? timeSerie.metadata.product_type
          : '';

        const { latestOnly, options, toUnit } = adjustedItemsMap[externalId];

        const aggregate = options ? (options.aggregates || [])[0] : 'average';
        const latestDatapoint = latestOnly
          ? [
              last(datapoints as DatapointInfo[]) || {
                value: 0,
                timestamp: new Date(),
              },
            ]
          : undefined;

        const getDefaultValue = () => ({
          id,
          externalId,
          unit,
          datapoints: (latestDatapoint || datapoints).map(
            (datapoint: Datapoint) => {
              return {
                value: isDoubleDatapoint(datapoint)
                  ? datapoint.value
                  : // It only supports one aggregation per item for now
                    datapoint[aggregate] || 0,
                timestamp: datapoint.timestamp,
              };
            }
          ),
        });

        if (!unit) {
          return getDefaultValue();
        }

        // Remove timeframe from source unit
        const sourceUnit = removeUnitSuffix(unit);
        const preferredUnit =
          toUnit || getPreferredUnitByProduct(product, units);

        return {
          id,
          externalId,
          unit: preferredUnit || sourceUnit,
          datapoints: (latestDatapoint || datapoints).map(
            (datapoint: Datapoint) => {
              const value = isDoubleDatapoint(datapoint)
                ? datapoint.value
                : // It only supports one aggregation per item for now
                  datapoint[aggregate] || 0;
              return {
                value:
                  preferredUnit && preferredUnit !== sourceUnit
                    ? convertValue({
                        product,
                        value,
                        sourceUnit,
                      })?.value || 0
                    : value,
                timestamp: datapoint.timestamp,
              };
            }
          ),
        };
      }
    );

    return datapoints;
  };
};

export const createUnitConverter = (
  units: UnitPreferences,
  productConversions: ProductConversions,
  getPreferredUnitByProduct: (product: string, units: UnitPreferences) => string
) => {
  return async ({
    items,
  }: UnitConverterType): Promise<ConversionQueryResponse[]> => {
    if (items.length === 0) {
      return [];
    }

    const adjustedItems = items.map((item) => {
      if (!item.needTimeAdjustment) {
        return item;
      }

      const options = item.options || {};

      const adjustedOptions = {
        ...options,
        start: options.start ? +getStartOfDay(options.start) : undefined,
        end: options.end ? +getEndOfDay(options.end) : undefined,
      };

      return {
        ...item,
        options: adjustedOptions,
      };
    });

    const uniqueExternalIds = uniqBy(items, 'externalId');

    const timeSeriesResults = keyBy(
      await getTimeSeries(
        uniqueExternalIds.map(({ externalId }) => externalId)
      ),
      'externalId'
    );

    // Separate the items into 2 groups: one needs unit conversion, and the other does not
    const {
      stepMap,
      tsWithConversion: conversions,
      noConversionTimeseriesSet,
    } = adjustedItems.reduce(
      (acc, { externalId, realtime, toUnit, options = {}, requestId }) => {
        const timeSeries = timeSeriesResults[externalId];

        if (!timeSeries) {
          acc.tsWithErrors.push(`Time series not found for ${externalId}`);
          return acc;
        }

        const {
          id,
          isString,
          isStep,
          unit: sourceUnitWithTimeframe,
          metadata = {},
        } = timeSeries;
        const { product_type: product } = metadata;

        const { granularity, start, end, limit, aggregates } = options;

        // Quick approach to hourly data
        // We can come up with a more optimal code later
        const isHourlyData = externalId.endsWith('_PLS_PRODUCTION');
        if (isHourlyData) {
          let conversionFormula = '{{ value }}';
          const sourceUnit = removeUnitSuffix(sourceUnitWithTimeframe || '');
          const targetUnit =
            toUnit || getPreferredUnitByProduct(product, units);
          if (
            sourceUnit &&
            sourceUnit !== targetUnit &&
            productConversions[product]
          ) {
            const formulaLookup = productConversions[product][sourceUnit];
            if (formulaLookup) {
              conversionFormula =
                formulaLookup[targetUnit] || conversionFormula;
            }
          }

          const hourlyFormula = `${conversionFormula.replace(
            /\{\{ ?value ?\}\}/g,
            `TS{externalId='${externalId}', aggregate='${
              aggregates?.[0] || 'average'
            }', granularity='${granularity}'}`
          )}*24.0`;

          acc.tsWithConversion.push([
            {
              id,
              expression: {
                expression: hourlyFormula,
                start: options.start,
                end: options.end,
                limit: 1000,
              } as SyntheticQuery,
              externalId,
              unit: targetUnit,
              aggregate: 'value',
              requestId,
            },
          ]);

          return acc;
        }

        // Realtime data split logic
        if (realtime) {
          const { realtimeUnit, realtimeProduct } = realtime;
          acc.stepMap[externalId] = false;
          // If no formula and variables are found, then fallback to:
          // - NET_GAS formula being simply TOTAL_GAS
          // - TOTAL_GAS variable being the timeserie externalId itself
          const {
            PRODUCT_SPLIT_FORMULAS_DICT = JSON.stringify({
              NET_GAS: 'TOTAL_GAS',
            }),
            PRODUCT_SPLIT_VARIABLES_DICT = JSON.stringify({
              TOTAL_GAS: externalId,
            }),
          } = metadata;

          const productSplitFormulasDict = JSON.parse(
            PRODUCT_SPLIT_FORMULAS_DICT
          ) as Record<string, string>;
          const productSplitVariablesDict = JSON.parse(
            PRODUCT_SPLIT_VARIABLES_DICT
          ) as Record<string, string>;

          const products = Object.keys(productSplitFormulasDict).filter(
            // It's only NET_GAS that matters for now
            // Will split the byproducts later
            (prod) => prod === 'NET_GAS'
          );
          const productVariables = Object.keys(productSplitVariablesDict);

          const variables = productVariables.reduce((acc, prod) => {
            const variable = productSplitVariablesDict[prod];
            const isTransformationFactor = variable.endsWith(
              'TRANFORMATION_FACTOR'
            );
            return {
              ...acc,
              [prod]: `TS{externalId='${
                productSplitVariablesDict[prod]
              }', aggregate='${
                isTransformationFactor
                  ? 'stepinterpolation'
                  : aggregates?.[0] || 'average'
              }', granularity='${granularity}'}`,
            };
          }, {} as Record<string, string>);

          const replaceSplittingFormula = (splittingFormula: string) =>
            Object.entries(variables).reduce((acc, [name, value]) => {
              return acc.replace(name, value);
            }, splittingFormula);

          let conversionFormula = '{{ value }}';
          let targetUnit = realtimeUnit || '';
          if (realtimeUnit && productConversions[realtimeProduct]) {
            const formulaLookup =
              productConversions[realtimeProduct][realtimeUnit];
            targetUnit =
              toUnit || getPreferredUnitByProduct(realtimeProduct, units);

            // We need to add the frequency suffix to units when converting
            // from units also with suffix (Ex.: NM3PERHOUR -> NM3PERDAY and so on )
            if (formulaLookup) {
              conversionFormula =
                formulaLookup[`${targetUnit}PERDAY`] || conversionFormula;
            }
          }

          const expressions = products.reduce((acc, prod) => {
            return {
              ...acc,
              [prod]: conversionFormula.replace(
                /\{\{ ?value ?\}\}/g,
                `(${replaceSplittingFormula(productSplitFormulasDict[prod])})`
              ),
            };
          }, {} as Record<string, string>);

          // Hardcode NET_GAS for now
          if (expressions.NET_GAS) {
            acc.tsWithConversion.push([
              {
                id,
                expression: {
                  expression: expressions.NET_GAS,
                  start: options.start,
                  end: options.end,
                  limit: 1000,
                } as SyntheticQuery,
                externalId,
                unit: removeUnitSuffix(targetUnit),
                aggregate: 'value',
                requestId,
              },
            ]);
          }

          return acc;
        }

        acc.stepMap[externalId] = isStep;
        if (!sourceUnitWithTimeframe || !product) {
          acc.noConversionTimeseriesSet.add(externalId);
          return acc;
        }

        if (isString) {
          acc.tsWithErrors.push(
            `Time series [${externalId}] is a string time series`
          );
          return acc;
        }

        if (!product) {
          acc.tsWithErrors.push(
            `Time series [${externalId}] does not have a metadata.product_type`
          );
          return acc;
        }

        // Remove timeframe from source unit
        const sourceUnit = removeUnitSuffix(
          // Make sure unit is uppercase since deviations timeseries might have
          // lowercase units --'
          sourceUnitWithTimeframe.toUpperCase()
        );
        const preferredUnit =
          toUnit || getPreferredUnitByProduct(product, units);
        if (!preferredUnit || preferredUnit === sourceUnit) {
          acc.noConversionTimeseriesSet.add(externalId);
          return acc;
        }

        const productFormulaMaps = productConversions[product];
        if (!productFormulaMaps) {
          acc.noConversionTimeseriesSet.add(externalId);
          return acc;
        }

        const formulaMap = productFormulaMaps[sourceUnit];
        if (!formulaMap) {
          acc.noConversionTimeseriesSet.add(externalId);
          return acc;
        }

        const formula = formulaMap[preferredUnit];
        if (!formula) {
          acc.noConversionTimeseriesSet.add(externalId);
          return acc;
        }

        let args = [`externalId='${externalId}'`];
        if (granularity && aggregates) {
          args = aggregates.map(() =>
            [
              `externalId='${externalId}'`,
              `granularity='${granularity}'`,
              `aggregate='average'`,
            ].join(',')
          );
        }

        const expressions = args
          .map((arg) => `TS{${arg}}`)
          .map((arg) => formula.replace(/\{\{ ?value ?\}\}/g, arg))
          .map((expression, idx) => {
            return {
              id,
              expression: {
                expression,
                start,
                end,
                limit,
              },
              externalId,
              aggregate: granularity && aggregates ? aggregates[idx] : 'value',
              unit: preferredUnit,
              requestId,
            };
          });

        acc.tsWithConversion.push(expressions);
        return acc;
      },
      {
        noConversionTimeseriesSet: new Set<string>(), // Keep track of timeseries that do not need conversion
        tsWithConversion: [] as {
          id: number;
          expression: SyntheticQuery;
          externalId: string;
          aggregate: string;
          unit: string;
          requestId?: string;
        }[][],
        tsWithErrors: [] as string[],
        stepMap: {} as { [id: string]: boolean | undefined },
      }
    );

    const appendToLastOrAddNewArray = (
      requestGroups: ItemRequestGroups,
      item: UnitConversionItemRequest,
      limitPerGroup: number
    ) => {
      const DEFAULT_LIMIT = 500;
      const itemLimit =
        item.options && item.options.limit ? item.options.limit : DEFAULT_LIMIT;

      const lastGroup = last(requestGroups);
      const numOfItemsInLastGroup = lastGroup
        ? lastGroup.reduce(
            (currentSum, currentItem) =>
              (currentItem.options?.limit || 0) + currentSum,
            0
          )
        : 0;

      if (lastGroup && numOfItemsInLastGroup + itemLimit < limitPerGroup) {
        lastGroup.push(item);
      } else {
        requestGroups.push([item]);
      }
    };

    /* We further separate the group that doesn't need unit conversion into 2 sub-groups:
      One that has aggregation and the other does not. The reason for that is because for requests
      that need aggregations, the limit for 1 request is 10000 datapoints as opposed to 100000
      datapoints per request for no-aggregation requests.
    */
    const noConversionItemGroups = adjustedItems
      .filter((item) => noConversionTimeseriesSet.has(item.externalId))
      .reduce(
        (acc, cur) => {
          const [withAggregationGroups, withoutAggregationGroups] = acc;
          const opts = cur.options;

          if (opts && opts.aggregates) {
            appendToLastOrAddNewArray(withAggregationGroups, cur, 10000);
          } else {
            appendToLastOrAddNewArray(withoutAggregationGroups, cur, 100000);
          }

          return acc;
        },
        [[] as ItemRequestGroups, [] as ItemRequestGroups]
      );

    const flatItemRequestGroups = noConversionItemGroups.flat().flat();
    const noConversionItemResultsMap = keyBy(
      (
        await Promise.allSettled(
          noConversionItemGroups.flat().map((itemsGroup) =>
            getDatapoints({
              items: itemsGroup,
            })
          )
        )
      )
        .map((result) => {
          return result.status === 'fulfilled' ? result.value : undefined;
        })
        .flat()
        .map((value, index) => {
          const { datapoints, externalId: extId, unit, id } = value || {};
          return {
            id,
            externalId: extId!,
            unit: unit || '',
            datapoints,
            isStep: stepMap[extId!],
            requestId: flatItemRequestGroups[index].requestId,
          } as ConversionQueryResponse;
        }),
      ({ requestId, externalId }) => requestId || externalId
    );

    /* We divide the items that need unit conversion into batches because the limit for the
      syntheticQuery is 10 expressions per request
    */
    const conversionRequestBatches = chunk(
      conversions.flat().map(({ expression }) => expression),
      10
    );

    const conversionItemResults = (
      await Promise.all(
        conversionRequestBatches.map((batch) =>
          getClient().timeseries.syntheticQuery(batch)
        )
      )
    ).flat();

    const conversionItemResultsMap: {
      [extId: string]: ConversionQueryResponse;
    } = {};
    let i = 0;
    conversions.forEach((conversion) => {
      conversion.forEach(
        ({ externalId, requestId, unit, aggregate, id }, j) => {
          const conversionRes = conversionItemResults[i + j];

          const lookupId = requestId || externalId;
          conversionRes.datapoints.forEach((dp, idx) => {
            if (!conversionItemResultsMap[lookupId]) {
              conversionItemResultsMap[lookupId] = {
                id,
                externalId,
                datapoints: [],
                unit,
                isStep: stepMap[externalId],
                requestId,
              };
            }
            const { datapoints } = conversionItemResultsMap[lookupId];

            if (!datapoints[idx]) {
              (datapoints as Datapoint[]).push({
                timestamp: dp.timestamp,
                [aggregate]: (dp as SyntheticDataValue).value,
              });
            } else {
              datapoints[idx] = {
                ...datapoints[idx],
                [aggregate]: (dp as SyntheticDataValue).value,
              };
            }
          });
        }
      );
      i += conversion.length;
    });

    return items.map(({ externalId, requestId }) => {
      return (
        noConversionItemResultsMap[requestId || externalId] ||
        conversionItemResultsMap[requestId || externalId]
      );
    });
  };
};

export const getSanitizedFormulaGroupings = (groupings: FormulaLookup) => {
  return Object.entries(groupings).reduce((acc, [destUnit, formula]) => {
    if (!formula) {
      return acc;
    }
    return {
      ...acc,
      [destUnit]: formula,
    };
  }, {});
};

export const getSanitizedFormulaMap = (formulaMap: ConversionFormulaMaps) => {
  return Object.entries(formulaMap).reduce(
    (acc, [sourceUnit, formulaGroupings]) => {
      return {
        ...acc,
        [sourceUnit]: getSanitizedFormulaGroupings(formulaGroupings),
      };
    },
    {}
  );
};

export const getSanitizedProductConversions = (
  input: ProductConversions
): ProductConversions => {
  return Object.entries(input).reduce((acc, [product, formulaMap]) => {
    return {
      ...acc,
      [product]: getSanitizedFormulaMap(formulaMap),
    };
  }, {});
};

export const getConversionFormula = (
  productConversions: ProductConversions,
  productName: string,
  sourceUnit: string,
  targetUnit: string
) => {
  const productFormulaMaps = productConversions?.[productName];

  if (!productFormulaMaps) {
    return null;
  }

  const formulaMap = productFormulaMaps[sourceUnit];

  if (!formulaMap) {
    return null;
  }

  return formulaMap[targetUnit];
};

export const removeUnitSuffix = (unit: string) =>
  UNIT_FREQUENCY_SUFFIXES.reduce(
    (acc, frequency) =>
      unit.includes(frequency) ? unit.replace(frequency, '') : acc,
    unit
  );

export const convertDataPoints = ({
  product,
  sourceUnit,
  targetUnit,
  productConversions,
  dataPoints,
}: {
  product: string;
  sourceUnit?: string;
  targetUnit: string;
  productConversions: ProductConversions;
  dataPoints: DoubleDatapoint[];
}) => {
  if (!sourceUnit || sourceUnit === targetUnit) {
    return { unit: sourceUnit || '', dataPoints };
  }
  const formula = getConversionFormula(
    productConversions,
    product,
    sourceUnit,
    targetUnit
  );
  if (formula) {
    const coefficient = +formula.replace(/[(]?{{ ?value ?}}[)]? ?\*/, '');
    if (!Number.isNaN(coefficient)) {
      return {
        unit: targetUnit,
        dataPoints: dataPoints.map((dp: DoubleDatapoint) => {
          return {
            timestamp: dp.timestamp,
            value: dp.value * coefficient,
          };
        }),
      };
    }
  }
  return { unit: sourceUnit, dataPoints };
};

const parseNestedJson = (jsonObject: Record<string, unknown>) => {
  return Object.entries(jsonObject).reduce((acc, [key, value]) => {
    try {
      const parsedValue = JSON.parse(value as string);
      const children = parseNestedJson(parsedValue);
      acc[key] = children;
    } catch (err) {
      if (value) {
        acc[key] = value as string;
      }
    }
    return acc;
  }, {} as Record<string, Record<string, unknown> | string>);
};

const fetchProductConversionFile = async (id: number) => {
  return retry(
    async () => {
      const [url] = await getClient()
        .files.getDownloadUrls([{ id }])
        .then((files) => files.map(({ downloadUrl }) => downloadUrl));
      const rawUnitConversions = await fetch(url).then((data) => data.json());
      const unitConversions = parseNestedJson(rawUnitConversions);
      return unitConversions as ProductConversions;
    },
    {
      retries: 5,
      factor: 1,
    }
  );
};

export const fetchUnitConversionsForAsset = async (asset: Asset) => {
  const assetIds = [asset.parentId!, asset.id].filter(Boolean);

  const data = await retry(
    () => {
      return getClient().files.list({
        filter: {
          assetIds,
        },
      });
    },
    {
      retries: 5,
      factor: 1,
    }
  );

  if (!data.items.length) {
    // Unit conversion files might not exist - and thats okay.
    return false;
  }

  try {
    const assetConversionFileId = data.items.find(
      (fileInfo) =>
        fileInfo.assetIds?.includes(asset.id) &&
        fileInfo.source === 'Best Day Configuration' // TODO(BEST-1311): Need to find a better way to pick the correct file if there are multiple files, but works for now until a new customer
    )?.id;
    const rootConversionFileId = data.items.find(
      (fileInfo) =>
        fileInfo.assetIds?.includes(asset.parentId!) &&
        fileInfo.source === 'Best Day Configuration' // TODO(BEST-1311): Need to find a better way to pick the correct file if there are multiple files, but works for now until a new customer
    )?.id;

    if (assetConversionFileId) {
      return await fetchProductConversionFile(assetConversionFileId!);
    }
    if (rootConversionFileId) {
      return await fetchProductConversionFile(rootConversionFileId);
    }
    return false;
  } catch (error) {
    const errorMessage = isError(error) ? error.message : 'Unknown';
    throw new Error(
      `Failed to fetch conversion file for asset ${asset.externalId}. Cause: "${errorMessage}"`
    );
  }
};
