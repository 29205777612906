import React from 'react';
import { Trans, useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { formatDateAs } from 'utils/datetime';

export type Props = {
  startDate: Date;
  endDate: Date | undefined;
  showOnlyStart?: boolean;
};
const { monthWithDate } = formatDateAs;

const Duration = ({ startDate, endDate, showOnlyStart }: Props) => {
  const { t } = useTranslation('Duration');
  const start = monthWithDate(startDate);
  const end = endDate
    ? monthWithDate(endDate)
    : t('ongoing', { defaultValue: 'Ongoing' });

  if (showOnlyStart) {
    return <> {start}</>;
  }

  return endDate ? (
    <>
      {start} &#8594; {end}
    </>
  ) : (
    <>
      {end}&nbsp;
      <Trans t={t} i18nKey="since">
        since
      </Trans>
      &nbsp;
      {start}
    </>
  );
};

export default withI18nSuspense(Duration);
