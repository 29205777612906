import { useEffect, useState } from 'react';
import { usePreferences } from 'features/preferences';
import dayjs from 'dayjs';

const dayjsLocaleMap = {
  en: () => import('dayjs/locale/en').then((module) => module.default),
  de: () => import('dayjs/locale/de').then((module) => module.default),
  ro: () => import('dayjs/locale/ro').then((module) => module.default),
};

export const useLocale = () => {
  const [locale, setLocale] = useState<Locale>();

  const {
    preferences: { language },
  } = usePreferences();

  useEffect(() => {
    let localeLang = 'en';

    if (language.startsWith('de')) {
      localeLang = 'de';
    } else if (language.startsWith('nb')) {
      localeLang = 'nb';
    } else if (language.startsWith('ro')) {
      localeLang = 'ro';
    }

    import(
      /* webpackInclude: /(en|de|nb|ro)/ */
      `date-fns/locale/${localeLang}`
    )
      .then((module) => setLocale(module.default))
      .catch(() => {
        setLocale(undefined);
      });

    dayjsLocaleMap[localeLang as keyof typeof dayjsLocaleMap]()
      .then(dayjs.locale)
      .catch(() => dayjs.locale('en'));
  }, [language]);

  return {
    locale,
  };
};
