import React from 'react';
import isEmpty from 'lodash/isEmpty';
import RootAssetItem, { RootAssetItemProps } from './RootAssetItem';
import { useRootAssetHierarchy } from './useRootAssetHierarchy';

const RootAssetList = ({ onSelect }: Pick<RootAssetItemProps, 'onSelect'>) => {
  const rootAssets = useRootAssetHierarchy();

  return (
    <>
      {rootAssets.map(({ asset, children }) => {
        const items = [
          <RootAssetItem key={asset.id} asset={asset} onSelect={onSelect} />,
        ];

        if (!isEmpty(children)) {
          items.push(
            ...children.map((child) => (
              <RootAssetItem
                key={child.id}
                asset={child}
                onSelect={onSelect}
                isChild
              />
            ))
          );
        }

        return items;
      })}
    </>
  );
};

export default RootAssetList;
