import React, { useCallback, useMemo } from 'react';
import { Deviation } from 'utils/models';

import { useDeviations, useHoveredDeviation } from 'features/deviations';
import { useGetComments } from 'features/comments';
import { DeviationGroup, getIfIsGroup } from 'utils/models/deviationGroupes';
import { getStartOfDay } from 'utils/datetime';
import DeviationPanelCard from './DeviationPanelCard';

export type Props = Deviation & {
  setSelectedDeviation: (deviationId: string) => void;
  wellName?: string;
  wellExternalId?: string;
  chartSelectionModeTimerange?: [number, number];
  chartHoveredTimestamp?: number;
  setSize?: Function;
};

const DeviationPanelCardWrapper = ({
  wellName,
  wellExternalId,
  setSelectedDeviation,
  chartSelectionModeTimerange,
  chartHoveredTimestamp,
  setSize,
  ...rest
}: Props) => {
  const measuredRef = useCallback(
    (node) => {
      if (node !== null && setSize) {
        setSize(node.getBoundingClientRect().height + 16);
      }
    },
    [setSize]
  );

  const deviationPanelEvent = rest as Deviation | DeviationGroup;
  const { externalId } = deviationPanelEvent;
  const { hoveredDeviation } = useHoveredDeviation();

  const { updateDeviationStatus } = useDeviations();

  const extIds = useMemo(() => [externalId], [externalId]);
  const { getActiveComments, loading: loadingComments } = useGetComments({
    externalIds: extIds,
    resourceType: 'event',
  });

  const comments = getActiveComments(externalId);

  const isDeviationGroup = getIfIsGroup(deviationPanelEvent);

  /**
   * We first check if the selection mode is going on in the chart and we give priority to the timerange selected there.
   * Otherwise, we apply the highlight according to the hovered-over deviation.
   */
  const isHovered = useMemo(() => {
    if (chartSelectionModeTimerange) {
      const [start, end] = chartSelectionModeTimerange;
      return (
        !isDeviationGroup &&
        +getStartOfDay(deviationPanelEvent.startDate) >= start &&
        (!deviationPanelEvent.startDate ||
          +getStartOfDay(deviationPanelEvent.startDate) <= end)
      );
    }

    if (chartHoveredTimestamp) {
      return (
        chartHoveredTimestamp >=
          +getStartOfDay(deviationPanelEvent.startDate) &&
        chartHoveredTimestamp <= +getStartOfDay(deviationPanelEvent.startDate)
      );
    }

    return !hoveredDeviation || hoveredDeviation.externalId === externalId;
  }, [
    chartHoveredTimestamp,
    chartSelectionModeTimerange,
    deviationPanelEvent.startDate,
    externalId,
    hoveredDeviation,
    isDeviationGroup,
  ]);

  return (
    <div ref={measuredRef}>
      <DeviationPanelCard
        deviationPanelEvent={deviationPanelEvent}
        isHovered={isHovered}
        commentsCount={comments.length}
        loadingComments={loadingComments}
        setSelectedDeviation={setSelectedDeviation}
        updateDeviationStatus={updateDeviationStatus}
        wellName={wellName}
        wellExternalId={wellExternalId}
      />
    </div>
  );
};

export default DeviationPanelCardWrapper;
