import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Icon } from '@cognite/cogs.js';
import { DataNode } from 'antd/lib/tree';
import { Key } from 'antd/lib/table/interface';
import last from 'lodash/last';
import { useAssetTree } from 'features/assetTree';
import { useSelector } from 'react-redux';
import useNavigation from 'utils/useNavigation';
import UnstyledLink from 'components/accessibility/UnstyledLink';
import { Pages } from 'utils/models/enums';
import { RootState } from 'store';
import { TreeWrapper } from './elements';

export type DataNodeWithExId = DataNode & {
  externalId?: string;
  type?: string;
};

interface CheckInfo {
  node: DataNode;
  checked: boolean;
}
type CheckedKeys =
  | React.ReactText[]
  | {
      checked: React.ReactText[];
      halfChecked: React.ReactText[];
    };

type SystemTreeProps = {
  rootExternalId: string;
  selectedExternalId?: string;
  checkable?: boolean;
  checkedKeys?: number[];
  expandedKeys: Key[];
  filterValue?: string;
  onlySystems?: boolean;
  setExpandedKeys: (nextExpandedKeys: Key[]) => void;
  onSelectCheckbox?: (
    asset: { id: number; externalId: string; node: DataNodeWithExId },
    selected: boolean
  ) => void;
};

const MIN_LENGTH_OF_SEARCH_WORD_EXPANDING = 3;

const SystemTree = ({
  rootExternalId,
  selectedExternalId,
  checkable,
  checkedKeys,
  expandedKeys,
  filterValue,
  onlySystems,
  setExpandedKeys,
  onSelectCheckbox,
}: SystemTreeProps) => {
  const [selectedKeys, setSelectedKeys] = useState<Key>('');
  const { onLoadData, assetPath } = useAssetTree();
  const assetTreeData = useSelector((state: RootState) => state.assetTree);
  const { isCollectionPage } = useNavigation();
  const { currentPage } = useNavigation();

  const [lastExpanded, setLastExpanded] = useState('');

  const treeData = useMemo(() => {
    if (onlySystems) {
      return assetTreeData.systems.treeData;
    }
    return assetTreeData.withWells.treeData;
  }, [assetTreeData, onlySystems]);

  const strippedPath = useMemo(() => {
    if (assetPath?.length) {
      const parsedPath = assetPath.filter((asset) =>
        asset.labels?.some((label) =>
          label.externalId.includes('NETWORK_LEVEL')
        )
      );

      const leaf = last(parsedPath);
      if (leaf) {
        setSelectedKeys(leaf.id);
      }
      return parsedPath.slice(1);
    }
    return [];
  }, [assetPath]);

  /**
   * When selecting an new asset (e.g. via the asset search), determine the path of assets upwards to the root
   * Then sequentially load data along that path, set expanded keys and new selected key
   */
  const expandFromAsset = useCallback(
    async (exId: string) => {
      if (strippedPath.length === 0 || lastExpanded === selectedExternalId)
        return;

      setLastExpanded(exId);

      setExpandedKeys(strippedPath.map((x) => x.id));
    },
    [strippedPath, lastExpanded, selectedExternalId, setExpandedKeys]
  );

  useEffect(() => {
    if (!selectedExternalId) {
      return;
    }

    expandFromAsset(selectedExternalId);
  }, [selectedExternalId, expandFromAsset, lastExpanded]);

  const handleNodeChecked = (_: CheckedKeys, info: CheckInfo) => {
    if (onSelectCheckbox) {
      const node = info.node as DataNodeWithExId;
      onSelectCheckbox(
        {
          id: node.key as number,
          externalId: node.externalId!,
          node,
        },
        info.checked
      );
    }
  };

  const expandAllFiltered = useCallback(
    (data: DataNode[]) => {
      if (
        filterValue &&
        filterValue.length >= MIN_LENGTH_OF_SEARCH_WORD_EXPANDING
      ) {
        const keysToExpand = data.reduce(function flatten(
          prev: Key[],
          item: DataNode
        ): Key[] {
          if (item.children && item.children.length > 0) {
            const children = item.children.reduce(flatten, [] as Key[]);
            if (children.length > 0) {
              return [...prev, item.key, ...children];
            }
          }
          return [...prev, item.key];
        },
        [] as Key[]);
        setExpandedKeys(keysToExpand);
      } else {
        setExpandedKeys([]);
      }
    },
    [filterValue, setExpandedKeys]
  );

  const recursiveFilter = useCallback(
    (data: DataNode[]): DataNode[] => {
      if (!filterValue) {
        return data;
      }
      return data.reduce(function filter(
        prev: DataNode[],
        item: DataNode
      ): DataNode[] {
        if (item.children && item.children.length > 0) {
          const children = item.children.reduce(filter, []);
          if (children.length > 0) {
            prev.push({
              ...item,
              children,
            });
          }
        } else if (
          (item?.title as string)
            ?.toLowerCase()
            .includes(filterValue.toLowerCase())
        ) {
          prev.push(item);
        }
        return prev;
      },
      [] as DataNode[]);
    },
    [filterValue]
  );

  const filteredTreeData = useMemo(() => {
    if (!filterValue) {
      return treeData[rootExternalId] || [];
    }
    const filtered = recursiveFilter(treeData[rootExternalId]);
    expandAllFiltered(filtered);
    return filtered;
  }, [
    expandAllFiltered,
    filterValue,
    recursiveFilter,
    treeData,
    rootExternalId,
  ]);
  return (
    <TreeWrapper
      checkable={checkable}
      checkStrictly
      checkedKeys={checkedKeys}
      onCheck={handleNodeChecked}
      expandedKeys={expandedKeys}
      selectedKeys={!isCollectionPage ? [selectedKeys] : []}
      treeData={filteredTreeData || []}
      titleRender={(node) => {
        const assetNode = node as DataNode & { externalId: string };
        if (checkable) {
          return assetNode.title;
        }

        return (
          <UnstyledLink
            to={`/assets/${encodeURIComponent(assetNode.externalId)}/${
              assetNode.isLeaf || currentPage === Pages.DeepDive
                ? Pages.DeepDive
                : Pages.KPI
            }`}
            style={{
              width: '100%',
              display: 'inline-block',
            }}
          >
            {assetNode.title}
          </UnstyledLink>
        );
      }}
      key={rootExternalId}
      loadData={onLoadData}
      onExpand={(expanded) => {
        setExpandedKeys(expanded as number[]);
      }}
      blockNode
      switcherIcon={<Icon type="CaretDown" />}
    />
  );
};

export default SystemTree;
