import React from 'react';
import styled from 'styled-components/macro';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import { Button, Menu, Title } from '@cognite/cogs.js';

import { useMetrics } from '@cognite/metrics';
import Flex from 'components/Layout/Flex';
import NotificationMenuItem from './components/NotificationMenuItem';
import { useNotifications } from './hook';

const NotificationMenu = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation('NotificationMenu');
  const metrics = useMetrics('User');

  const { data: notifications } = useNotifications();

  return (
    <StyledMenu onClick={() => metrics.track('NotificationButton_click')}>
      <StyledMenuHeader>
        <Flex.Row
          style={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Title level={5}>
            <Trans t={t} i18nKey="notification-menu_title">
              Notifications
            </Trans>
          </Title>

          <Button icon="CloseLarge" type="ghost" onClick={onClose} />
        </Flex.Row>
      </StyledMenuHeader>

      <MenuItemList>
        {notifications?.map((notification) => (
          <NotificationMenuItem {...notification} onClose={onClose} />
        ))}
      </MenuItemList>
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)`
  width: 501px;
  padding: 12px;
  background: var(--cogs-greyscale-grey1);
`;

const StyledMenuHeader = styled(Menu.Header)`
  padding: 0px 0px 12px 8px;
  margin: 0px;
`;

const MenuItemList = styled.div`
  max-height: 516px;
  overflow: auto;
  display: flex;
  flex-direction: column;
`;

export default withI18nSuspense(NotificationMenu);
