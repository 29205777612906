import React from 'react';
import { useTranslation, withI18nSuspense, Trans } from '@cognite/react-i18n';
import { Body, Overline } from '@cognite/cogs.js';
import { Favorite } from 'utils/models/collections';
import FavoriteListItem from './FavoriteListItem';
import {
  SelectedWellsContainer,
  SelectedWellsList,
  StyledChooseText,
  ErrorCard,
  ErrorTitle,
  StyledBody,
} from './elements';
import { MAX_FAVORITE_WELLS } from './constants';

type SelectedWellsProps = {
  favorites: Favorite[];
  setFavorites: Function;
  removeFavorite: Function;
  maxAssets?: number;
  assetType: 'well' | 'system';
};

const SelectedWells = ({
  favorites,
  setFavorites,
  removeFavorite,
  assetType,
  maxAssets = MAX_FAVORITE_WELLS,
}: SelectedWellsProps) => {
  const { t } = useTranslation('SelectedWells');

  const maxWellsErrorTitle = t('max_wellsErrorTitle', {
    defaultValue: 'Maximum number of wells reached',
  });

  const maxWellsErrorDescription = t(`max_${assetType}sErrorDescription`, {
    defaultValue: `A collection can only contain up to {{maxWells}} ${assetType}s.`,
    maxWells: maxAssets,
  });

  const onRemove = (favorite: Favorite) => {
    removeFavorite(favorite);
    setFavorites(
      favorites.filter(
        (listFavorite) => listFavorite.externalId !== favorite.externalId
      )
    );
  };

  return (
    <SelectedWellsContainer className="z-1">
      <StyledBody level={3} error={favorites.length > maxAssets} strong>
        <Trans
          t={t}
          i18nKey={`num-${assetType}-selected`}
          count={favorites.length}
        >
          {{ num: favorites.length }} {assetType} selected
        </Trans>
      </StyledBody>

      <StyledChooseText>
        <Overline level={2}>
          <Trans t={t} i18nKey={`choose-${assetType}`}>
            Choose one of these
          </Trans>
        </Overline>
      </StyledChooseText>
      <SelectedWellsList>
        {favorites.map((favorite: Favorite) => (
          <FavoriteListItem
            key={favorite.externalId}
            onRemove={onRemove}
            favorite={favorite}
          />
        ))}
      </SelectedWellsList>
      {favorites.length > maxAssets && (
        <ErrorCard>
          <ErrorTitle level={6}>{maxWellsErrorTitle}</ErrorTitle>
          <Body level={2}>{maxWellsErrorDescription}</Body>
        </ErrorCard>
      )}
    </SelectedWellsContainer>
  );
};

export default withI18nSuspense(SelectedWells);
