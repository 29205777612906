import React, { useState, useMemo } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import { useMetrics } from '@cognite/metrics';
import { Body, Button, Modal, toast } from '@cognite/cogs.js';
import { reportException } from '@cognite/react-errors';
import styled from 'styled-components/macro';
import isObject from 'lodash/isObject';
import { RootState } from 'store';
import useConfig from 'hooks/useConfig';
import { useProjectContext } from 'containers/AuthContainer';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import { useUnitConversion } from 'features/unitConversion';
import { usePreferences } from 'features/preferences';
import { useExportService } from 'features/export';
import { exportData, resolveExportWellSummary } from 'utils/export';
import { formatDateAs } from 'utils/datetime';
import useNavigation from 'utils/useNavigation';
import { Pages } from 'utils/models/enums';
import { useWellListItems } from 'utils/models/wellListItems';
import { getEnrichmentConfig } from 'hooks/useGraphQlQuery';
import { useCollections } from 'features/collections';
import { useTimeRange } from 'features/timeRange';
import createToastOptions from 'utils/toasts/createToastOptions';
import { createWellSummaryExport } from 'utils/models/wellListItems/exportWellSummary';
import Contents from './Contents';
import WellSummaryContents from './Contents/WellSummaryContents';
import { DateRange, FormatSpec, FormatWellSummarySpec } from './types';

export type Props = {
  onClose: () => void;
};

const { date } = formatDateAs;
const ExportModal = ({ onClose }: Props) => {
  const metrics = useMetrics('Export');
  const { t } = useTranslation('ExportModal');
  const { t: tContents } = useTranslation('ExportModalContents');
  const { currentPage, isCollectionPage } = useNavigation();
  const { loading: wellsLoading, wellListItems } = useWellListItems();
  const { datasets } = useSelector((state: RootState) => state.datasets);
  const { project } = useProjectContext();
  const { selectedAsset } = useCurrentAsset();
  const { start, end, exportStart, exportEnd } = useTimeRange();
  const { productConversions } = useUnitConversion();
  const { rootAssetConfig } = useConfig();
  const { units } = usePreferences();
  const { activeCollection } = useCollections();
  const { beginWellSummaryExport, disabled } = useExportService();
  const [exporting, setExporting] = useState(false);
  const [visible, setVisible] = useState(true);
  const [exportSpec, setExportSpec] = useState<FormatSpec>();
  const [exportWellSummarySpec, setExportWellSummarySpec] =
    useState<FormatWellSummarySpec>();
  const shouldEnableExportButton = useMemo(() => {
    if (exportWellSummarySpec?.data?.montly?.export && disabled) {
      return false;
    }
    return (
      (exportSpec?.data &&
        Object.values(exportSpec?.data).some((value) =>
          isObject(value) ? Object.values(value).length > 0 : !!value
        )) ||
      (exportWellSummarySpec?.data &&
        Object.values(exportWellSummarySpec?.data).some(
          (value) => value.export
        ))
    );
  }, [disabled, exportSpec?.data, exportWellSummarySpec?.data]);
  const renderTitle = (dateRange: DateRange) => {
    const [start, end] = dateRange;
    const title = t('export-file_title', {
      defaultValue: 'BestDay export',
    });
    return `${title} ${date(start)} - ${date(end)}.zip`;
  };
  const { data: config } = useQuery({
    queryKey: ['getEnrichmentConfig', datasets?.CUSTOMER_CONFIGURATION],
    queryFn: () =>
      getEnrichmentConfig({
        templateInfo: rootAssetConfig?.templates!,
        dataSetId: datasets?.CUSTOMER_CONFIGURATION,
      }),
    enabled: !!rootAssetConfig?.templates && !!datasets?.CUSTOMER_CONFIGURATION,
  });
  const beginExport = () => {
    if (!selectedAsset?.externalId || !exportSpec || !rootAssetConfig) {
      return;
    }
    metrics.track('ExportOptions_click');

    if (exportSpec.data.comments) {
      metrics.track('Comments_exported');
    }
    if (exportSpec.data.deferments) {
      metrics.track('Deferments_exported');
    }
    if (exportSpec.data.bestDayDeferments) {
      metrics.track('Bestday_Deferments_exported');
    }
    if (exportSpec.data.deviations) {
      metrics.track('Deviations_exported');
    }

    /* if (exportSpec.data.selectedTab === 'month') {
      if (exportSpec.data.productionEfficiency) {
        metrics.track('productionEfficiency');
      }
    } */
    const selectedProducts = Object.values(
      exportSpec.data.productionData || {}
    );

    if (
      exportSpec.data.productionData &&
      selectedProducts.some((value) => !!value)
    ) {
      metrics.track('Production.Production_exported');

      if (selectedProducts.every((value) => !!value)) {
        metrics.track('Production.AllProducts_exported');
      }
    }
    setExporting(true);
    exportData({
      asset: selectedAsset,
      spec: exportSpec,
      fileTitle: renderTitle(exportSpec.dateRange),
      productConversions,
      units,
      templateInfo: rootAssetConfig.templates,
      project,
    })
      .then(() => {
        setVisible(false);
        onClose();
      })
      .catch((e) => {
        reportException(e);
        setExporting(false);
      });
  };
  const callError = (error: Error) => {
    reportException(error);
    toast.error(
      <Body level={2}>
        <Trans t={t} i18nKey="toast-export-well-summary-error">
          Could not export well summary {{ errorMessage: error?.message ?? '' }}
        </Trans>
      </Body>,
      createToastOptions()
    );
  };
  const handleCallback = () => {
    setExporting(false);
    handleCancelClick();
  };
  const beginExportWellSummary = async () => {
    if (!exportWellSummarySpec || !rootAssetConfig) {
      return;
    }
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      data: { montly, dayByDay, ...rest },
    } = exportWellSummarySpec;
    setExporting(true);
    const createFileName = () => {
      const fileName = [
        date(montly ? exportStart : start),
        date(montly ? exportEnd : end),
        'wellsummary_export',
      ];
      if (isCollectionPage) {
        fileName.push('collection');
        if (activeCollection?.name) {
          fileName.push(activeCollection.name);
        }
      } else {
        fileName.push('system');
        if (selectedAsset?.name) {
          fileName.push(selectedAsset.name);
        }
      }
      if (dayByDay.export) {
        fileName.push('daily');
      }
      return `${fileName.join('_')}.csv`;
    };
    const fileName = createFileName();

    if (!montly.export && rootAssetConfig.templates) {
      metrics.track('ExportWellSummaryOptions_click');
      Object.entries(rest).forEach(([key, value]) => {
        if (value.export) {
          metrics.track(`${key}_exported`);
        }
      });
      resolveExportWellSummary({
        wellListItemsForExport: createWellSummaryExport(
          wellListItems,
          exportWellSummarySpec,
          config?.capacities?.find((it) => it.isReference)?.type
        ),
        exportWellSummarySpec,
        fileName,
        translate: tContents,
        callback: handleCallback,
        callError,
      });
    } else {
      beginWellSummaryExport({
        exportWellSummarySpec,
        fileName,
        translate: tContents,
        callback: (isDownloading: boolean) =>
          isDownloading ? setVisible(false) : handleCallback(),
        callError,
      });
    }
  };
  const handleCancelClick = () => {
    setVisible(false);
    onClose();
  };
  const title = useMemo(() => {
    if (currentPage === Pages.Wells) {
      return t('wellsummary_title', { defaultValue: 'Well Summary Export' });
    }
    return t('title', { defaultValue: 'Export data' });
  }, [currentPage, t]);
  const uniqeProducts = useMemo(() => {
    const products = new Set(wellListItems.map((it) => it.type));
    return Array.from(products)
      .filter(Boolean)
      .map((product) => {
        return {
          type: product,
          capacities: Array.from(
            new Set(
              wellListItems
                .filter((it) => it.type === product)
                .map((it) => it.capacities.map((it) => it.type))
                .flat()
            )
          ).map((it) => {
            const reference = config?.capacities.find((it) => it.isReference);
            return {
              type: it,
              isReference: !!reference?.isReference && reference.type === it,
            };
          }),
        };
      });
  }, [config?.capacities, wellListItems]);
  return (
    <StyledExportModal
      title={title}
      appElement={document.getElementById('root')!}
      onCancel={handleCancelClick}
      visible={visible}
      footer={[
        <Button key="cancel" onClick={handleCancelClick} type="secondary">
          <Trans t={t} i18nKey="cancel_button">
            Cancel
          </Trans>
        </Button>,
        <Button
          key="export"
          onClick={
            currentPage === Pages.Wells ? beginExportWellSummary : beginExport
          }
          disabled={wellsLoading || !shouldEnableExportButton || exporting}
          loading={exporting}
          type="primary"
        >
          <Trans t={t} i18nKey="export_button">
            Export
          </Trans>
        </Button>,
      ]}
    >
      {currentPage === Pages.Wells ? (
        <>
          <WellSummaryContents
            products={uniqeProducts}
            onChange={setExportWellSummarySpec}
          />
        </>
      ) : (
        <Contents onChange={setExportSpec} />
      )}
    </StyledExportModal>
  );
};
const StyledExportModal = styled(Modal)`
  .cogs-modal-header {
    font-size: var(--cogs-t5-font-size);
    font-weight: 600;
  }

  .cogs-modal-footer {
    display: flex;
    justify-content: flex-end;
    > button {
      margin-left: 8px;
    }
  }
`;
export default withI18nSuspense(ExportModal);
