import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type VisibilityData = {
  visible: boolean;
  count?: number;
  lineId?: string;
};
export type CommentsFilter = {
  [key: string]: VisibilityData;
};

type InitialState = {
  displayComments: CommentsFilter;
  extraFilters: CommentsFilter;
};

const commentsFilterSlice = createSlice({
  name: 'commentsFilter',
  initialState: {
    displayComments: {},
    extraFilters: {
      volume: {
        visible: false,
        count: 10,
      },
      withChildren: {
        visible: true,
        lineId: 'Well',
        count: 3,
      },
    },
  } as InitialState,
  reducers: {
    toggleDisplayComments: (
      state,
      action: PayloadAction<{
        displayComments: CommentsFilter;
      }>
    ) => {
      const { displayComments } = action.payload;
      return {
        ...state,
        displayComments,
      };
    },
    toggleExtraFilters: (
      state,
      action: PayloadAction<{
        extraFilters: CommentsFilter;
      }>
    ) => {
      const { extraFilters } = action.payload;
      return {
        ...state,
        extraFilters,
      };
    },
  },
});
export type CommentsFilterState = ReturnType<
  typeof commentsFilterSlice.reducer
>;
export default commentsFilterSlice;
