import { Asset } from '@cognite/sdk';
import startCase from 'lodash/startCase';
import { Well, NetworkLevel } from './types';

export const NETWORK_LEVEL_LABEL_PREFIX = 'BEST_DAY_NETWORK_LEVEL_';
export const WELL_TYPE_LABEL_PREFIX = 'BEST_DAY_WELL_FLAG_';
export const ARTIFICIAL_LIFT_LABEL_PREFIX = 'BEST_DAY_ARTIFICIAL_LIFT_';
export const PUMP_LABEL_PREFIX = 'BEST_DAY_PUMP_';

export const getNetworkLevel = (asset: Asset): NetworkLevel => {
  const { labels } = asset;
  if (!labels) {
    throw new Error(`Missing labels on ${asset.externalId}`);
  }
  const networkLevelLabel = labels.find((label) =>
    label.externalId.startsWith(NETWORK_LEVEL_LABEL_PREFIX)
  );
  if (!networkLevelLabel) {
    throw new Error(`Missing network level on ${asset.externalId}`);
  }

  // Transform, for example, "PRODUCTION_SYSTEM" into "Production System" which
  // is the value used when building the timeseries external ids across the app
  return startCase(
    networkLevelLabel.externalId
      .replace(NETWORK_LEVEL_LABEL_PREFIX, '')
      .replace('_', ' ')
      .toLowerCase()
  ) as NetworkLevel;
};

export const buildWell = (asset: Asset): Well | undefined => {
  return {
    id: asset.id!,
    externalId: asset.externalId!,
    name: asset.name,
  };
};

export const isWell = (asset: Asset) => getNetworkLevel(asset) === 'Well';
