import { Alert } from 'antd';
import styled from 'styled-components/macro';

const ErrorAlert = styled(Alert)`
  max-width: 600px;
  margin: 0 auto;
  margin-bottom: 10px;
`;

export default ErrorAlert;
