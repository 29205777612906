import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Deferment } from 'utils/models/deferments';
import {
  DeviationGroup,
  DeviationSegment,
} from 'utils/models/deviationGroupes';

type InitialState = {
  deviationGroups: DeviationGroup[];
  deviationSegments: DeviationSegment[];
  bestDayDeferments: Deferment[];
  loading: boolean;
  error?: Error;
  updateLoading: boolean;
  updateError?: Error;
};

const deviationGroupsSlice = createSlice({
  name: 'deviationGroups',
  initialState: {
    deviationGroups: [],
    deviationSegments: [],
    bestDayDeferments: [],
    loading: false,
    updateLoading: false,
  } as InitialState,
  reducers: {
    getDeviationGroups: (
      state,
      action: PayloadAction<{ loading: boolean }>
    ) => {
      const { loading } = action.payload;
      return { ...state, loading, deviationGroups: [], deviationSegments: [] };
    },
    getDeviationGroupsSuccess: (
      state,
      action: PayloadAction<{
        deviationGroups: DeviationGroup[];
        deviationSegments: DeviationSegment[];
        bestDayDeferments: Deferment[];
        loading: boolean;
      }>
    ) => {
      const { deviationGroups, bestDayDeferments, deviationSegments, loading } =
        action.payload;
      return {
        ...state,
        deviationGroups,
        deviationSegments,
        bestDayDeferments,
        loading,
        error: undefined,
      };
    },
    getDeviationGroupsError: (
      state,
      action: PayloadAction<{ loading: boolean; error: Error }>
    ) => {
      const { loading, error } = action.payload;
      return {
        ...state,
        deviationGroups: [],
        deviationSegments: [],
        loading,
        error,
      };
    },
    updateEvent: (state, action: PayloadAction<{ updateLoading: boolean }>) => {
      const { updateLoading } = action.payload;
      return { ...state, updateLoading };
    },
    updateEventSuccess: (
      state,
      action: PayloadAction<{
        updatedGroup: DeviationGroup;
        updateLoading: boolean;
      }>
    ) => {
      const { updatedGroup, updateLoading } = action.payload;
      return {
        ...state,
        deviationGroups: state.deviationGroups.map((group: DeviationGroup) => {
          if (group.externalId !== updatedGroup.externalId) {
            return group;
          }
          return updatedGroup;
        }),
        updateLoading,
      };
    },
    updateEventError: (
      state,
      action: PayloadAction<{
        updateLoading: boolean;
        updateError?: Error;
      }>
    ) => {
      const { updateLoading, updateError } = action.payload;
      return { ...state, updateLoading, updateError };
    },
  },
});

export type DeviationGroupsState = ReturnType<
  typeof deviationGroupsSlice.reducer
>;

export default deviationGroupsSlice;
