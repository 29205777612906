import { useCurrentAsset } from 'containers/CurrentAssetProvider';

const useConfig = () => {
  const { rootAssetConfig } = useCurrentAsset();

  return {
    rootAssetConfig,
  };
};

export default useConfig;
