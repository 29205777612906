import { ProductDefinition } from 'graphql-types';
import useConfig from 'hooks/useConfig';
import { getProductsConfig, useTemplatesQuery } from 'hooks/useGraphQlQuery';
import { useCallback, useMemo } from 'react';

export const useProducts = () => {
  const { rootAssetConfig } = useConfig();
  const { data: productDefinitions } = useTemplatesQuery<ProductDefinition[]>(
    { fn: getProductsConfig, key: 'getProducts' },
    { templateInfo: rootAssetConfig?.templates },
    {
      onSuccess: (response) => {
        if (response.length === 0) {
          throw new Error('No product definition config could be found.');
        }
        return response;
      },
      enabled: !!rootAssetConfig?.templates,
      cacheTime: 60 * 60 * 1000,
    }
  );

  const sortedDefinitions = useMemo(
    () =>
      productDefinitions?.sort(
        (a, b) => (a.position || 0) - (b.position || 0)
      ) || [],
    [productDefinitions]
  );

  const filterByAggregatedTo = useCallback(
    (aggregateTo: string) => {
      return sortedDefinitions?.filter((a) =>
        a.aggregatesTo.includes(aggregateTo)
      );
    },
    [sortedDefinitions]
  );

  return {
    productDefinitions: sortedDefinitions,
    filterByAggregatedTo,
    products: useMemo(
      () =>
        sortedDefinitions?.map((product: ProductDefinition) => product.type) ||
        [],
      [sortedDefinitions]
    ),
  };
};
