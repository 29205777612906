/* eslint-disable @typescript-eslint/no-explicit-any */

type ActionMap<M extends { [index: string]: any }> = {
  [Key in keyof M]: M[Key] extends undefined
    ? {
        type: Key;
      }
    : {
        type: Key;
        payload: M[Key];
      };
};

type ProductPayload = {
  TOGGLE_PRODUCT: {
    name: string;
  };
  SET_PRODUCT_VISIBILITY: {
    name: string;
    nextState: boolean;
  };
  SET_ALL_PRODUCTS: {
    products: string[];
  };
};

export type ProductActions =
  ActionMap<ProductPayload>[keyof ActionMap<ProductPayload>];

export type Products = {
  [key: string]: boolean;
};

export type ProductsState = {
  allProducts: Products;
  products: string[];
};

export const productsReducer = (
  state: ProductsState,
  action: ProductActions
): ProductsState => {
  const getProducts = (allProducts: Products) =>
    Object.keys(allProducts).filter((product) => allProducts[product]);

  switch (action.type) {
    case 'SET_ALL_PRODUCTS': {
      const allProducts = action.payload.products.reduce<{
        [product: string]: boolean;
      }>((acc, cur) => {
        return {
          ...acc,
          [cur]: true,
        };
      }, {});

      return {
        ...state,
        allProducts,
        products: getProducts(allProducts),
      };
    }

    case 'TOGGLE_PRODUCT': {
      const allProducts = {
        ...state.allProducts,
        [action.payload.name]: !state.allProducts[action.payload.name],
      };
      return {
        ...state,
        allProducts,
        products: getProducts(allProducts),
      };
    }

    case 'SET_PRODUCT_VISIBILITY': {
      const { name, nextState } = action.payload;
      const allProducts = {
        ...state.allProducts,
        [name]: nextState,
      };
      return {
        ...state,
        allProducts,
        products: getProducts(allProducts),
      };
    }

    default:
      return state;
  }
};
