import React from 'react';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';
import { DeviationStatus, DefermentStatus, DefermentType } from 'utils/models';

import styled from 'styled-components/macro';
import { StatusColors } from 'pages/KPIDashboard/Charts/utils';
import { STATUS_ICONS } from '../DeviationPanelCard';

export type Props = {
  status: DeviationStatus | DefermentStatus | DefermentType;
  marginToIcon?: number;
};

const DeviationStatusLabel = ({ status, marginToIcon = 16 }: Props) => {
  const { t: tEventStatus } = useTranslation('EventStatus');
  const icon = {
    ...STATUS_ICONS,
    planned: undefined,
    unplanned: undefined,
    actual: undefined,
    future: undefined,
    commenting: undefined,
  }[status];

  return (
    <StyledDeviationStatusLabel
      className="status-label"
      color={StatusColors[status].main}
    >
      {icon}
      <span
        style={{
          marginLeft: icon ? marginToIcon : 4,
          textTransform: 'capitalize',
        }}
      >
        <Trans t={tEventStatus} i18nKey={`${status}-status`}>
          {status}
        </Trans>
      </span>
    </StyledDeviationStatusLabel>
  );
};

const StyledDeviationStatusLabel = styled.span<{ color: string }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.color};
  font-weight: 500;
`;

export default withI18nSuspense(DeviationStatusLabel);
