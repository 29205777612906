import React from 'react';
import { Provider } from 'react-redux';
import GlobalStyles from 'global-styles';
import { ErrorBoundary } from '@cognite/react-errors';
import { I18nContainer } from '@cognite/react-i18n';
import { BrowserRouter as Router } from 'react-router-dom';
import { StoreType } from 'store';

export type Props = {
  store: StoreType;
  children: React.ReactNode;
  tenant: string;
};

const AppProviders = ({ store, children, tenant }: Props) => {
  return (
    <Provider store={store}>
      <GlobalStyles />
      <I18nContainer>
        <ErrorBoundary instanceId="app-root">
          <Router key={tenant} basename={tenant}>
            {children}
          </Router>
        </ErrorBoundary>
      </I18nContainer>
    </Provider>
  );
};

export default AppProviders;
