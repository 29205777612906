import { getClient } from 'utils/cognitesdk';
import { createDatasets } from 'features/datasets';
import { DataSetMapping } from 'hooks/types';
import { reportException } from '@cognite/react-errors';
import { Errors } from './hooks';

class VerificationError extends Error {
  public readonly key: keyof Errors;
  public readonly msg: string;
  public readonly source?: Error;
  constructor(key: keyof Errors, msg: string, source?: Error) {
    super(msg);
    this.key = key;
    this.msg = msg;
    this.source = source;
  }
}

export const isVerificationError = (e: Error): e is VerificationError => {
  return e instanceof VerificationError;
};

export const COLUMN_NAME = 'dataSetExternalIdPrefix';

export const verifyDatasets = async (datasetMapping: DataSetMapping) => {
  if (datasetMapping) {
    const externalIds = Object.values(datasetMapping);

    const datasetEvents = await getClient()
      .datasets.retrieve(
        externalIds.map((externalId) => ({
          externalId,
        }))
      )
      .catch((ex) => {
        reportException(ex);
        throw new VerificationError('dataSetsNotDefined', ex);
      });

    return createDatasets(datasetEvents, datasetMapping);
  }
  return {};
};
