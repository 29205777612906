import numeral from 'numeral';

export const toFixed = (number: number, precision: number) => {
  const tenToPrecision = 10 ** precision;
  return (Math.round(number * tenToPrecision) / tenToPrecision).toFixed(
    precision
  );
};

export type DecimalOptions = {
  precision?: number;
  maxLength?: number;
};

export const asDecimal = (v: number | string, options?: DecimalOptions) => {
  const { precision = 0, maxLength = 0 } = options || {};
  const value = +v;
  if (Number.isNaN(value)) {
    throw new Error(`Non-number provided to decimal formatter (${v})`);
  }
  let string = value.toString();
  if (precision) {
    string = toFixed(value, precision);
  }
  if (maxLength && string.length >= maxLength) {
    let format = '0';
    for (let i = 0; i < precision; i += 1) {
      if (i === 0) {
        format += '.';
      }
      format += '0';
    }
    format += 'a';
    string = numeral(value).format(format);
  }
  return string;
};

export const toPercentage = (value: number, total: number): number => {
  if (total === 0) {
    return 0;
  }
  return (value * 100) / total;
};
