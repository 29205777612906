import React, { useMemo } from 'react';
import { useSearch } from '@cognite/sdk-react-query-hooks';
import { Asset } from '@cognite/sdk';
import { Body } from '@cognite/cogs.js';
import { useCurrentAsset } from 'containers/CurrentAssetProvider';
import SpinProgress from 'components/SpinProgress';
import { useTranslation, Trans, withI18nSuspense } from '@cognite/react-i18n';

import { useMetrics } from '@cognite/metrics';
import { isValidAsset } from 'utils/asset/validAssets';
import {
  SearchResultItem,
  SearchResultList,
  SearchResultContainer,
  SearchResultItemLink,
} from './element';

type Props = {
  searchText: string;
  onAssetClicked: () => void;
};

const AssetSearchResult = ({ searchText, onAssetClicked }: Props) => {
  const { t } = useTranslation('AssetsSearch');
  const { setSelectedAsset, rootAsset } = useCurrentAsset();
  const metrics = useMetrics('AssetSelector');
  const { data: assets, isLoading } = useSearch<Asset>('assets', searchText, {
    filter: { rootIds: [{ id: rootAsset?.rootId }] },
  });

  const foundAssets = useMemo(() => {
    if (!assets) {
      return [];
    }

    if (isLoading) {
      return assets;
    }

    const searchWords = searchText.split(' ');

    const results = assets
      .filter((asset) =>
        searchWords.every((searchWord) =>
          asset.name.toLowerCase().includes(searchWord.toLowerCase())
        )
      )
      .filter((asset) => isValidAsset(asset))
      .sort((asset1, asset2) =>
        asset1.name.localeCompare(asset2.name, undefined, {
          numeric: true,
        })
      );

    return results;
  }, [assets, isLoading, searchText]);

  const handleAssetSelection = async (asset: Asset) => {
    onAssetClicked();
    setSelectedAsset(asset);
  };

  const handleItemClick = (asset: Asset) => {
    metrics.track('AssetSearch_input');
    handleAssetSelection(asset);
  };

  if (isLoading) {
    return <SpinProgress />;
  }

  return (
    <SearchResultContainer>
      {foundAssets.length === 0 ? (
        <Body level={2}>
          <Trans key="asset-search_no-results" t={t}>
            No results found
          </Trans>
        </Body>
      ) : (
        <SearchResultList>
          {foundAssets.map((asset) => (
            <SearchResultItem key={asset.id}>
              <SearchResultItemLink onClick={() => handleItemClick(asset)}>
                {asset.name}
              </SearchResultItemLink>
            </SearchResultItem>
          ))}
        </SearchResultList>
      )}
    </SearchResultContainer>
  );
};

export default withI18nSuspense(AssetSearchResult);
