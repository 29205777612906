import React from 'react';
import { useTranslation, withI18nSuspense } from '@cognite/react-i18n';
import { useMetrics } from '@cognite/metrics';

type Props = {
  id: string;
  subject?: string;
  children?: string;
};

const FeedbackLink = ({
  id,
  subject: customSubject,
  children = 'support@cognite.com',
}: Props) => {
  const { t } = useTranslation('FeedbackLink');
  const metrics = useMetrics('FeedbackLink');

  const subject =
    customSubject !== undefined
      ? customSubject
      : t('subject', { defaultValue: 'Support request' });

  return (
    <a
      href={`mailto:support@cognite.com?subject=${encodeURIComponent(subject)}`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={() => {
        metrics.track('click', { instanceId: id });
      }}
    >
      {children}
    </a>
  );
};

export default withI18nSuspense(FeedbackLink);
