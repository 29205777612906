import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'store';
import {
  getBestDayDefermentFields,
  getBestDayDefermentMatrix,
} from 'hooks/useGraphQlQuery';
import useConfig from 'hooks/useConfig';
import retry from 'async-retry';
import { reportException } from '@cognite/react-errors';
import defermentFieldsSlice, { DefermentFieldsState, Field } from './reducer';
import { FilledFields, Level } from './types';

const retryOptions: retry.Options = {
  retries: 5,
  factor: 1,
};

export const useDefermentFields = () => {
  const { fields, ignoredFields, loading, error } = useSelector<
    RootState,
    DefermentFieldsState
  >((state) => {
    return state.defermentFields;
  });
  const dispatch = useDispatch();
  const { rootAssetConfig } = useConfig();

  const getDefermentFields = useCallback(() => {
    let canceled = false;
    const cancel = () => {
      canceled = true;
    };

    const fetch = async () => {
      try {
        const items = await retry(
          // Temporary retry until sdk supports retry by default https://github.com/cognitedata/cognite-sdk-js/pull/705
          () => {
            return getBestDayDefermentFields({
              templateInfo: rootAssetConfig?.templates!,
            });
          },
          {
            ...retryOptions,
            onRetry: (e, attempt) => {
              // eslint-disable-next-line no-console
              console.log(
                `retrying getBestDayDefermentFields after ${e}... (attempt ${attempt})`
              );
            },
          }
        );

        const { fields, ignoredFields } = items.reduce(
          (acc, item) => {
            if (item.type === 'bestday-deferment-category-fields-ignored') {
              acc.ignoredFields = item.fields.sort(
                (a, b) => a.position - b.position
              );
            } else {
              acc.fields = item.fields.sort((a, b) => a.position - b.position);
            }
            return acc;
          },
          { fields: [] as Field[], ignoredFields: [] as Field[] }
        );
        dispatch(
          defermentFieldsSlice.actions.getDefermentFieldsSuccess({
            loading: false,
            fields,
            ignoredFields,
          })
        );
      } catch (ex) {
        reportException(ex);
        dispatch(
          defermentFieldsSlice.actions.getDefermentFieldsError({
            loading: false,
            error: ex,
          })
        );
      }
    };

    if (!canceled) {
      dispatch(
        defermentFieldsSlice.actions.getDefermentFields({
          loading: true,
        })
      );
      fetch();
    }

    return cancel;
  }, [dispatch, rootAssetConfig?.templates]);

  return {
    fields,
    ignoredFields,
    loading,
    error,
    getDefermentFields,
  };
};

export const useDefermentMatrix = () => {
  const { defermentMatrix, matrixError } = useSelector<
    RootState,
    DefermentFieldsState
  >((state) => {
    return state.defermentFields;
  });
  const dispatch = useDispatch();

  const { rootAssetConfig } = useConfig();

  const [defermentFieldOptions, setDefermentFieldOptions] = useState<{
    [type: string]: Level[];
  }>({});

  const getDefermentFieldOptions = useCallback(
    (filledFields: FilledFields[], items: Level[], itemPosition = 0) => {
      const sortedItems = [...items].sort((a, b) =>
        a.name.localeCompare(b.name, undefined, {
          numeric: true,
        })
      );
      const newLevels = sortedItems.reduce((acc, item) => {
        acc[item.type] = sortedItems.filter(({ type }) => type === item.type);
        return acc;
      }, {} as { [type: string]: Level[] });

      const fieldsToReset = filledFields
        .filter(({ position }) => position > itemPosition)
        .map(({ type }) => type);

      setDefermentFieldOptions((prevState) => {
        const cleanedPrevState = Object.entries(prevState)
          .filter(([key]) => !fieldsToReset.includes(key))
          .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
          }, {} as { [type: string]: Level[] });

        return {
          ...cleanedPrevState,
          ...newLevels,
        };
      });

      sortedItems.forEach((item) => {
        const correspondingField = filledFields.find(
          ({ key, type }) => key === item.key && type === item.type
        );
        if (correspondingField && item.subLevels) {
          getDefermentFieldOptions(
            filledFields,
            item.subLevels,
            correspondingField.position
          );
        }
      });
    },
    []
  );

  const getDefermentMatrix = useCallback(
    (fields: Field[]) => {
      let canceled = false;
      const cancel = () => {
        canceled = true;
      };

      if (fields.length === 0) {
        return cancel;
      }

      const fetch = async () => {
        try {
          const items = await retry(
            // Temporary retry until sdk supports retry by default https://github.com/cognitedata/cognite-sdk-js/pull/705
            () => {
              return getBestDayDefermentMatrix({
                templateInfo: rootAssetConfig?.templates!,
                filledFields: fields,
              });
            },
            {
              ...retryOptions,
              onRetry: (e, attempt) => {
                // eslint-disable-next-line no-console
                console.log(
                  `retrying getBestDayDefermentMatrix after ${e}... (attempt ${attempt})`
                );
              },
            }
          );

          if (!canceled) {
            dispatch(
              defermentFieldsSlice.actions.getDefermentMatrixSuccess({
                defermentMatrix: items,
              })
            );
          }
        } catch (ex) {
          reportException(ex);
          dispatch(
            defermentFieldsSlice.actions.getDefermentMatrixError({
              error: ex,
            })
          );
        }
      };

      if (!canceled) {
        fetch();
      }

      return cancel;
    },
    [dispatch, rootAssetConfig]
  );

  return {
    defermentFieldOptions,
    defermentMatrix,
    matrixError,
    getDefermentFieldOptions,
    getDefermentMatrix,
  };
};
