import React, { useCallback } from 'react';
import { PerfMetrics } from '@cognite/metrics';
import { reportException } from '@cognite/react-errors';
import { useProjectContext } from 'containers/AuthContainer';
import { useQuery, useQueryClient } from 'react-query';
import styled from 'styled-components/macro';
import {
  deleteNotification as deleteNotificationApi,
  getNotifications,
  Notification,
} from 'utils/models/notifications/api';
import { METRICS } from 'utils/metrics/enums';
import { Icon, Title, toast } from '@cognite/cogs.js';
import createToastOptions from 'utils/toasts/createToastOptions';
import { Trans, useTranslation } from '@cognite/react-i18n';
import { ToastContainers } from 'utils/toasts';
import Flex from 'components/Layout/Flex';

const NOTIFICATION_REFRESH_TIME = 10000;

type DeleteNotificationProps = {
  deleting?: boolean;
  deleted?: true;
};

type NotificationList = (Notification & DeleteNotificationProps)[];

export const useNotifications = () => {
  const { project } = useProjectContext();
  const { t } = useTranslation('NotificationMenu', { useSuspense: true });

  const queryClient = useQueryClient();

  const showNotificationToast = useCallback(
    (count: number) => {
      toast.open(
        () => (
          <NotificationToast>
            <StyledIcon type="BellFilled" />
            <NotificationText level={6}>
              <Trans t={t} i18nKey="new-notification-toast-text" count={count}>
                You have new notifications
              </Trans>
            </NotificationText>
          </NotificationToast>
        ),
        createToastOptions({
          containerId: ToastContainers.Notifications,
          hideProgressBar: true,
          position: 'bottom-left',
        })
      );
    },
    [t]
  );

  const { ...rest } = useQuery<NotificationList>(
    ['Notifications', project],
    async () => {
      const { data } = await getNotifications({ project });
      const oldNotificationCount = rest.data ? rest.data?.length : data.length;
      const newNotificationsCount = data.length - oldNotificationCount;

      const sortedNotifications = data.sort(
        (notiA, notiB) => +notiB.createdTime - +notiA.createdTime
      );

      if (sortedNotifications.length > 0) {
        const [{ createdTime: recentNotificationLatestTime }] =
          sortedNotifications;

        const lastTimeNotifiedUser = Number(
          localStorage.getItem('last-notification-received-time')
        );

        const isTaggedWhenOffline =
          (!lastTimeNotifiedUser && sortedNotifications.length > 0) ||
          (recentNotificationLatestTime &&
            lastTimeNotifiedUser &&
            recentNotificationLatestTime.getTime() > lastTimeNotifiedUser);

        if (newNotificationsCount > 0 || isTaggedWhenOffline) {
          showNotificationToast(newNotificationsCount);

          localStorage.setItem(
            'last-notification-received-time',
            recentNotificationLatestTime.getTime().toString()
          );
        }
      }

      return sortedNotifications;
    },
    {
      refetchInterval: NOTIFICATION_REFRESH_TIME,
    }
  );

  const getCommentNotification = useCallback(
    (commentExtId: string) => {
      return rest.data?.find(
        (notification) => notification.commentExtId === commentExtId
      );
    },
    [rest.data]
  );

  const updateDeleteStatus = useCallback(
    (notificationId: string, deleting: boolean) => {
      queryClient.setQueryData<NotificationList>(
        ['Notifications', project],
        (old) => {
          return [...(old || [])].map((notification) => {
            if (notification.notificationId === notificationId)
              return { ...notification, deleting };

            return notification;
          });
        }
      );
    },
    [project, queryClient]
  );

  // set the status to deleted and remove the notification after X amount of time for remove animation to take place
  const removeNotification = useCallback(
    (notificationId: string) => {
      queryClient.setQueryData<NotificationList>(
        ['Notifications', project],
        (old) =>
          [...(old || [])].filter(
            (notification) => notification.notificationId !== notificationId
          )
      );
    },
    [project, queryClient]
  );

  const deleteNotification = useCallback(
    (notificationId: string) => {
      updateDeleteStatus(notificationId, true);
      deleteNotificationApi({
        project,
        notificationId,
      })
        .then(() => {
          removeNotification(notificationId);
        })
        .catch((e) => {
          updateDeleteStatus(notificationId, false);
          reportException(e);
          PerfMetrics.logFailureEvent(METRICS.AcknowledgeNotification);
          toast.error(
            t('failed-to-acknowledge-notification', {
              defaultValue:
                'Failed to acknowledge notification. Please try again.',
            }),
            createToastOptions()
          );
        });
    },
    [project, removeNotification, t, updateDeleteStatus]
  );

  return {
    ...rest,
    count: rest.data?.length || 0,
    getCommentNotification,
    deleteNotification,
  };
};

const NotificationText = styled(Title)`
  color: var(--cogs-white);
  margin-left: 4px;
`;

const StyledIcon = styled(Icon)`
  margin: 0px 8px;
`;

const NotificationToast = styled(Flex.Row)`
  align-items: center;
  color: white;
`;
