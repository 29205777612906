import { useCallback, useMemo } from 'react';
import {
  DEFAULT_UNIT,
  HYDROCARBON,
  PRODUCT_TYPE_WATER,
  PRODUCT_TYPE_WATER_INJECTION,
} from 'utils/products';
import { useUnitCategories } from './useUnitCategories';

export const useDynamicCollectionConfig = () => {
  const { unitCategories } = useUnitCategories();

  const config = useMemo(() => {
    return {
      variables: [
        {
          category: 'product',
          type: 'PRODUCTION',
          name: HYDROCARBON,
          unit: DEFAULT_UNIT,
        },
        {
          category: 'capacity',
          type: 'BESTDAY_CAPACITY',
          name: HYDROCARBON,
          unit: DEFAULT_UNIT,
        },
        {
          category: 'capacity',
          type: 'MPC',
          name: HYDROCARBON,
          unit: DEFAULT_UNIT,
        },
        {
          category: 'deferments',
          type: 'ACTUAL',
          name: HYDROCARBON,
          unit: DEFAULT_UNIT,
        },
        {
          category: 'deferments',
          type: 'FUTURE',
          name: HYDROCARBON,
          unit: DEFAULT_UNIT,
        },
        {
          category: 'deviations',
          type: 'DEVIATION',
          name: HYDROCARBON,
          unit: DEFAULT_UNIT,
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: 'OIL',
          unit: 'TONNES',
        },
        {
          category: 'deferments',
          type: 'ACTUAL',
          name: 'OIL',
          unit: 'TONNES',
        },
        {
          category: 'deferments',
          type: 'FUTURE',
          name: 'OIL',
          unit: 'TONNES',
        },
        {
          category: 'deviations',
          type: 'DEVIATION',
          name: 'OIL',
          unit: 'TONNES',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: 'CONDENSATE',
          unit: 'TONNES',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: 'NONASSOC_GAS',
          unit: 'NM3',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: 'ASSOC_GAS',
          unit: 'NM3',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: PRODUCT_TYPE_WATER,
          unit: 'M3',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: PRODUCT_TYPE_WATER_INJECTION,
          unit: 'M3',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: 'GROSS',
          unit: 'M3',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: 'TOTAL_LIQUID',
          unit: 'M3',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: 'TOTAL_GAS',
          unit: 'NM3',
        },
        {
          category: 'product',
          type: 'PRODUCTION',
          name: 'CO2',
          unit: 'NM3',
        },
        {
          category: 'measurement',
          type: 'PRODUCTION',
          name: 'WC_RATIO',
          unit: '%',
        },
        {
          category: 'measurement',
          type: 'PRODUCTION',
          name: 'GOR_RATIO',
          unit: 'NM3/M3',
        },
      ],
      wellTypes: [
        'OIL',
        'NONASSOC_GAS',
        PRODUCT_TYPE_WATER_INJECTION,
        'ASSOC_GAS',
      ],
      conjunctions: ['and', 'or'],
      symbols: ['gt', 'lt', 'eq'],
    };
  }, []);

  return {
    config,
    units: useCallback(
      (product: string) =>
        unitCategories.find((it) => it.products.includes(product))?.units || [],
      [unitCategories]
    ),
  };
};
