import { createLayers } from '@cognite/z-index';

const LAYERS = [
  'ERROR_WATCHER',
  'AUTH_OVERLAY',
  'FATAL_ERROR_OVERLAY',
  'COMMENT_ERROR_DIALOG',
  'COMMENT_DELETION_DIALOG',
  'TOOLTIP',
  'SIDE_BAR',
  'TOP_BAR',

  // Some components of the production progress bars
  'PRODUCTION_ARROW',
  'PRODUCTION_INDICATOR',
  'IPSC_INDICATOR',
  'PRODUCTION_BAR',
  'CHART_TARGETS_MENU',
  'DEFERMENT_PANEL_ALERT',
  'NETWORK_TREE_LOADER',
] as const;

export default createLayers<typeof LAYERS[number]>(LAYERS);
