import { getClient } from 'utils/cognitesdk';
import { Asset, ExternalId, Relationship } from '@cognite/sdk';
import { v4 as uuidv4 } from 'uuid';
import { getLabel } from 'utils/labels';
import {
  NewAdvisor,
  Advisor,
  EditAdvisor,
  SelectedAsset,
  SelectedAdvisor,
} from './types';

const createAdvisorRelationships = (
  advisor: Advisor,
  assets: SelectedAsset[],
  dataSetId: number
) => {
  const relationshipItems = assets.map(
    (asset) =>
      ({
        externalId: uuidv4(),
        startTime: advisor.createdDate,
        confidence: 1,
        labels: [getLabel('ADVISOR')],
        sourceExternalId: asset.externalId || '',
        sourceType: 'asset',
        targetExternalId: advisor.externalId,
        targetType: 'event',
        dataSetId,
      } as Relationship)
  );

  return getClient().relationships.create(relationshipItems);
};

export const create = async (newAdvisor: NewAdvisor, dataSetId: number) => {
  const { url, creator, description, title, selectedAssets } = newAdvisor;

  let advisorExternalId = '';

  try {
    const [response] = await getClient().events.create([
      {
        externalId: uuidv4(),
        source: creator,
        type: 'advisor',
        subtype: 'active',
        metadata: {
          title,
          url,
          description,
        },
        assetIds: selectedAssets.map((asset) => asset.id),
        dataSetId,
      },
    ]);

    advisorExternalId = String(response.externalId);

    const advisor: Advisor = {
      ...newAdvisor,
      externalId: advisorExternalId,
      // Change it to timestamp for better clarity
      createdDate: response.createdTime.getTime(),
      assetIds: selectedAssets.map((asset) => asset.id),
    };

    await createAdvisorRelationships(advisor, selectedAssets, dataSetId);

    return advisor;
  } catch (error) {
    if (advisorExternalId) {
      getClient().events.delete([
        {
          externalId: advisorExternalId,
        },
      ]);
    }
    throw error;
  }
};

export const list = async (assetExternalId: string) => {
  const { items: advisorRelationships } = await getClient().relationships.list({
    filter: {
      sourceExternalIds: [assetExternalId],
      sourceTypes: ['asset'],
      targetTypes: ['event'],
      labels: {
        containsAll: [getLabel('ADVISOR')],
      },
    },
  });

  if (advisorRelationships.length === 0) {
    return [];
  }

  const externalIds = Array.from(
    new Set(
      advisorRelationships.map((relationship) => relationship.targetExternalId)
    )
  ).map(
    (externalId) =>
      ({
        externalId,
      } as ExternalId)
  );

  if (externalIds.length === 0) {
    return [];
  }

  const events = await getClient().events.retrieve(externalIds, {
    ignoreUnknownIds: true,
  });

  const advisors = events
    .filter((event) => event.type === 'advisor')
    .map<Advisor>(
      ({ externalId, metadata, createdTime, source, assetIds }) => ({
        externalId: externalId || '',
        description: metadata?.description || '',
        url: metadata?.url || '',
        title: metadata?.title || '',
        createdDate: createdTime.getTime(),
        creator: source || '',
        assetIds: assetIds || [],
      })
    );

  return advisors;
};

export const edit = async (
  advisor: SelectedAdvisor,
  editedAdvisor: EditAdvisor,
  dataSetId: number
) => {
  const assetIdsSet = new Set(advisor.assetIds);
  const newAssetIdsSet = new Set(advisor.assetIds);
  const assetsToAdd: SelectedAsset[] = [];
  const assetsToDelete: SelectedAsset[] = [];

  editedAdvisor.assetSelections.forEach((selection) => {
    if (selection.selected) {
      if (!assetIdsSet.has(selection.asset.id)) {
        assetsToAdd.push(selection.asset);
        newAssetIdsSet.add(selection.asset.id);
      }
    } else if (assetIdsSet.has(selection.asset.id)) {
      assetsToDelete.push(selection.asset);
      newAssetIdsSet.delete(selection.asset.id);
    }
  });

  const newAssetIds = Array.from(newAssetIdsSet);

  const assetsChanged = assetsToAdd.length > 0 || assetsToDelete.length > 0;

  const changes: { [key: string]: string } = {
    title: editedAdvisor.title,
    url: editedAdvisor.url,
    description: editedAdvisor.description,
  };

  await getClient().events.update([
    {
      externalId: advisor.externalId,
      update: {
        subtype: {
          set: 'edited',
        },
        metadata: {
          set: changes,
        },
        assetIds: assetsChanged
          ? {
              set: newAssetIds,
            }
          : undefined,
      },
    },
  ]);

  if (assetsToDelete.length > 0) {
    const { items: assetRelationshipsToDelete } =
      await getClient().relationships.list({
        filter: {
          sourceExternalIds: assetsToDelete.map((asset) => asset.externalId!),
          sourceTypes: ['asset'],
          targetExternalIds: [advisor.externalId],
          targetTypes: ['event'],
          labels: { containsAll: [getLabel('ADVISOR')] },
        },
      });

    await getClient().relationships.delete(
      assetRelationshipsToDelete.map((relationship) => ({
        externalId: relationship.externalId,
      }))
    );
  }

  if (assetsToAdd.length > 0) {
    await createAdvisorRelationships(advisor, assetsToAdd, dataSetId);
  }

  const newAdvisor = {
    ...advisor,
    ...editedAdvisor,
    assetIds: newAssetIds,
  };

  return newAdvisor;
};

export const remove = async (advisor: Advisor, deleteForAsset?: Asset) => {
  let updatedAssetIds: number[];
  let eventRequest;
  const { items } = await getClient().relationships.list({
    filter: {
      sourceTypes: ['asset'],
      targetExternalIds: [advisor.externalId],
      targetTypes: ['event'],
      labels: { containsAll: [getLabel('ADVISOR')] },
    },
  });

  if (deleteForAsset) {
    updatedAssetIds = advisor.assetIds.filter(
      (assetId) => assetId !== deleteForAsset.id
    );

    eventRequest = getClient().events.update([
      {
        externalId: advisor.externalId,
        update: {
          assetIds: { set: updatedAssetIds },
        },
      },
    ]);
  } else {
    eventRequest = getClient().events.delete([
      {
        externalId: advisor.externalId,
      },
    ]);
  }

  const assetRelationshipsToDelete = deleteForAsset
    ? items.filter(
        ({ sourceExternalId }) => sourceExternalId === deleteForAsset.externalId
      )
    : items;

  return Promise.allSettled([
    eventRequest,

    getClient().relationships.delete(
      assetRelationshipsToDelete.map((relationship) => ({
        externalId: relationship.externalId,
      }))
    ),
  ]);
};

export const fetchAdvisorAssets = async (
  advisor: Advisor
): Promise<SelectedAdvisor> => {
  return {
    ...advisor,
    assets:
      advisor.assetIds.length > 0
        ? await getClient().assets.retrieve(
            advisor.assetIds.map((id) => ({
              id,
            })),
            {
              ignoreUnknownIds: true,
            }
          )
        : [],
  };
};
